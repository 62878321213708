export enum ReshipReasonEnum {
    SHIPPING_ERROR = 'shipping-error',
    PRODUCTION_ERROR_SPOILED_FOOD = 'production-error-spoiled-food',
    PRODUCTION_ERROR_PACKAGING = 'production-error-packaging',
    PRODUCTION_ERROR_FOREIGN_OBJECTS_IN_FOOD = 'production-error-foreign-objects-in-food',
    WAREHOUSE_ERROR_MISSING_INCORRECT = 'warehouse-error-missing-incorrect',
    WAREHOUSE_DEFROSTED = 'warehouse-defrosted',
    SHIPPING_DEFROSTED = 'shipping-defrosted',
    FFJ_DEFROSTED = 'ffj-defrosted',
    ATC_ERROR = 'atc-error',
    SYSTEM_ERROR = 'system-error',
}