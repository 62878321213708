import axios from 'axios'
import {BillingStatusEnum, DeliveryNoteModel} from '../models/DeliveryNoteModel'
import {DeliveryNoteLineRequestModel} from '../models/DeliveryNoteLineRequestModel'
import { UpdateDeliveryNoteRequestModel } from '../models/UpdateDeliveryNoteRequestModel'
import { InvoiceStateEnum } from '../../b2b-invoices'

const API_URL = process.env.REACT_APP_API_URL
const DELIVERY_NOTES_URL = `${API_URL}/api/b2b/delivery-notes/`
const INVOICES_URL = `${API_URL}/api/b2b/invoices/`

export const DELIVERY_NOTES_PER_PAGE = 20

interface ListDeliveryNotesResponse {
  results: Array<DeliveryNoteModel>,
  count: number,
}
export function listDeliveryNotes({ distributorId, page = 1, billingStatus }): Promise<{data: ListDeliveryNotesResponse}> {
  return axios.get(DELIVERY_NOTES_URL, {
    params: {
      distributor: distributorId,
      billing_status: billingStatus,
      offset: (page - 1) * DELIVERY_NOTES_PER_PAGE,
      limit: DELIVERY_NOTES_PER_PAGE
    },
  })
}

export function getDeliveryNoteById(id: number): Promise<{data: DeliveryNoteModel}> {
  const endpoint = `${DELIVERY_NOTES_URL}${id}/`
  return axios.get(endpoint)
}

export function createDeliveryNote(orderId, date) {
  return axios.post(DELIVERY_NOTES_URL, {
    order: orderId,
    date,
  })
}

export function updateDeliveryNoteState(deliveryNote: DeliveryNoteModel) {
  const endpoint = `${DELIVERY_NOTES_URL}${deliveryNote.id}/`

  const body = {
    state: deliveryNote.state,
  }
  return axios.patch(endpoint, body)
}

export function generateEdiFile(deliveryNoteId: number) {
  const endpoint = `${DELIVERY_NOTES_URL}${deliveryNoteId}/generate-edi-file/`
  return axios.post(endpoint)
}

export function generatePdfFile(deliveryNoteId: number) {
  const endpoint = `${DELIVERY_NOTES_URL}${deliveryNoteId}/generate-pdf/`
  return axios.post(endpoint)
}

export function bulkInsertDeliveryNoteLines(
  deliveryNoteId,
  deliveryNoteLines: Array<DeliveryNoteLineRequestModel>
) {
  const endpoint = `${DELIVERY_NOTES_URL}${deliveryNoteId}/delivery-note-lines/`
  return axios.post(endpoint, {delivery_note_lines: deliveryNoteLines})
}

export interface UpdateDeliveryNoteProps {
  deliveryNoteId: number
  request: UpdateDeliveryNoteRequestModel
}
export function updateDeliveryNote({
  deliveryNoteId,
  request,
}: UpdateDeliveryNoteProps) {
  const endpoint = `${DELIVERY_NOTES_URL}${deliveryNoteId}/`
  return axios.patch(endpoint, request)
}

export enum BulkActionsEnum {
  EMIT_INVOICES = 'emit_bulk_invoices',
}
export function performBulkAction({
  deliveryNoteIds,
  action,
  billing_status, 
  distributor,
}: {
  deliveryNoteIds: Array<number>
  action: BulkActionsEnum
  billing_status?: BillingStatusEnum
  distributor?: number
}) {
  return axios.post(`${INVOICES_URL}massive-actions/`, {
    delivery_notes: deliveryNoteIds,
    action,
    billing_status,
    distributor,
  })
}