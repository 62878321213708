import axios from 'axios'
import {ShippingAddressModel} from '../models/ShippingAddressModel'

const API_URL = process.env.REACT_APP_API_URL
const SHIPPING_ADDRESSES_URL = `${API_URL}/api/b2b/shipping-addresses/`

export function listDistributorShippingAddresses(
  distributorId: number
): Promise<{data: Array<ShippingAddressModel>}> {
  return axios.get(SHIPPING_ADDRESSES_URL, {params: {distributor: distributorId, state: 'active'}})
}

export function retrieveDistributorShippingAddress(
  id: number
): Promise<{data: ShippingAddressModel}> {
  return axios.get(`${SHIPPING_ADDRESSES_URL}${id}/`)
}

export function createDistributorShippingAddress(
  address: ShippingAddressModel
): Promise<{data: ShippingAddressModel}> {
  return axios.post(SHIPPING_ADDRESSES_URL, address)
}

export function updateDistributorShippingAddress(
  address: ShippingAddressModel
): Promise<{data: ShippingAddressModel}> {
  return axios.patch(`${SHIPPING_ADDRESSES_URL}${address.id}/`, address)
}
