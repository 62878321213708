/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {useIntl} from 'react-intl'

import {usePathProps} from '../../modules/user-profile/hooks/usePathProps'
import {PageTitle} from '../../../_metronic/layout/core'
import {RecoveryTable} from '../../modules/tasks'

const RecoveryTasksComponent: FC = () => {
  return (
    <>
      <RecoveryTable />
    </>
  )
}

const RecoveryTasksPage: FC = () => {
  const intl = useIntl()
  usePathProps()

  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'app.recovery'})}</PageTitle>
      <RecoveryTasksComponent />
    </>
  )
}

export {RecoveryTasksPage}
