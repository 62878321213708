import {shallowEqual, useSelector} from 'react-redux'
import * as user from '../redux/UserRedux'
import {UserModel} from '../models/UserModel'
import {useIntl} from 'react-intl'
import {Link} from 'react-router-dom'

export default function Friends() {
  const intl = useIntl()
  const currentUser: UserModel | undefined = useSelector(user.currentUserSelector, shallowEqual)
  const status: string = useSelector(user.userStatusSelector, shallowEqual)

  return (
    <div className='card p-10 h-100'>
      <h1 className='mb-8'>
        {intl.formatMessage({id: 'userProfile.friends'})} (
        {currentUser?.mgm_coupons_redemptions.length})
      </h1>
      {status === 'loading_request_user' ? (
        <div className='spinner-border text-primary' role='status' />
      ) : (
        <>
          {currentUser?.mgm_coupons_redemptions.length > 0 ? (
            <table className='table table-hover table-row-dashed table-row-gray-200 align-middle gs-0 gy-4'>
              <thead className='table-header'>
                <tr className='text-end fw-bolder'>
                  <th className='text-start'>{intl.formatMessage({id: 'userProfile.email'})}</th>
                  <th className='text-start'>
                    {intl.formatMessage({id: 'userProfile.orderRedeemed'})}
                  </th>
                  <th className='text-start'>
                    {intl.formatMessage({id: 'userProfile.hasActivePlan'})}
                  </th>
                  <th className='text-start'>
                    {intl.formatMessage({id: 'userProfile.hasPaidOrders'})}
                  </th>
                  <th className='text-start'>
                    {intl.formatMessage({id: 'userProfile.discountEarned'})}
                  </th>
                </tr>
              </thead>
              <tbody>
                {currentUser?.mgm_coupons_redemptions.map((redemption) => (
                  <tr key={redemption.user.id}>
                    <td>
                      <Link to={`/user/${redemption.user.id}/profile`}>
                        <a>{redemption.user.email}</a>
                      </Link>
                    </td>
                    <td className='text-nowrap'>
                      {!!redemption.saleorder ? (
                        <Link to={`/user/${redemption.user.id}/order/${redemption.saleorder.id}/`}>
                          {redemption.saleorder.code}
                        </Link>
                      ) : (
                        '-'
                      )}
                    </td>
                    <td className='text-center'>
                      {redemption.has_active_plans
                        ? intl.formatMessage({id: 'userProfile.yes'})
                        : intl.formatMessage({id: 'userProfile.no'})}
                    </td>
                    <td className='text-center'>
                      {redemption.has_regular_orders_paid
                        ? intl.formatMessage({id: 'userProfile.yes'})
                        : intl.formatMessage({id: 'userProfile.no'})}
                    </td>
                    <td className='text-center'>
                      {redemption.mgm_reciprocal_discount?.length > 0
                        ? redemption?.mgm_reciprocal_discount?.match(/\d+% ?/g)[0]
                        : '-'}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <p>{intl.formatMessage({id: 'userProfile.noFriends'})}</p>
          )}
        </>
      )}
    </div>
  )
}
