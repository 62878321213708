/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {useIntl} from 'react-intl'

import {usePathProps} from '../../modules/user-profile/hooks/usePathProps'
import {PageTitle} from '../../../_metronic/layout/core'
import {LogsTimeline} from '../../modules/logs'
import {useHistory, useParams} from 'react-router-dom'

const LogsPage: FC = () => {
  const intl = useIntl()
  usePathProps()
  const router = useHistory()
  const {id, petId, planId, orderId} = useParams() as any

  function getKind() {
    const path = router.location.pathname

    if (path.includes('pet')) {
      return 'pet'
    }
    if (path.includes('plan')) {
      return 'plan'
    }
    if (path.includes('order')) {
      return 'order'
    }
    if (path.includes('user')) {
      return 'user'
    }
  }

  function getId() {
    if (petId) {
      return petId
    }
    if (planId) {
      return planId
    }
    if (orderId) {
      return orderId
    }
    if (id) {
      return id
    }
  }

  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'logs.title'})}</PageTitle>
      <LogsTimeline kind={getKind() as any} id={getId()} />
    </>
  )
}

export {LogsPage}
