import axios from 'axios'
import {TaskModel} from '../../tasks'
import {ClientModel} from '../models/ClientModel'

const API_URL = process.env.REACT_APP_API_URL

export const CLIENTS_URL = `${API_URL}/api/users/`

type SearchResponse = {
  users?: Array<ClientModel>
  orphan_tasks?: Array<TaskModel>
}

export function searchClients(query: string): Promise<{data: SearchResponse}> {
  const params = {
    q: query,
    timestamp: new Date().getTime(),
  }

  return axios.get(CLIENTS_URL, {params})
}
