import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {useIntl} from 'react-intl'
import {PetModel} from '../models/PetModel'
import {OrderTable} from '../../orders'
import {selectedPetSelector} from '../redux/PetRedux'
import * as orderRedux from '../../orders/redux/OrderRedux'
import {useEffect, useState} from 'react'

export default function PetOrders() {
  const intl = useIntl()
  const dispatch = useDispatch()
  const selectedPet: PetModel | undefined = useSelector(selectedPetSelector, shallowEqual)
  const orders = useSelector(orderRedux.petOrdersSelector, shallowEqual)
  const orderStatus: string = useSelector(orderRedux.orderStatusSelector, shallowEqual)
  const loading = orderStatus === 'loading_list_orders'

  return (
    <section className='card p-10'>
      <h1 className='mb-8'>
        {intl.formatMessage({id: 'pets.petOrders'}, {petName: selectedPet?.name || '-'})}
      </h1>
      {loading ? (
        <div className='spinner-border text-primary' role='status' />
      ) : (
        <OrderTable
          orders={orders?.results}
          page={orders?.page}
          count={orders?.count}
          loading={loading}
          displayPetName={false}
          onRefresh={() => dispatch(orderRedux.actions.listPetOrders(selectedPet?.id, orders.page))}
          onPageChange={(page) => dispatch(orderRedux.actions.listPetOrders(selectedPet?.id, page))}
          emptyMessage={intl.formatMessage({id: 'orders.noOrdersForSelectedPet'})}
        />
      )}
    </section>
  )
}
