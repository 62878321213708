import {Modal} from 'react-bootstrap'
import {DistributorModel} from '../../distributors'
import {useIntl} from 'react-intl'
import {useState} from 'react'
import {createOrderFromEdi} from '../services/OrderCRUD'
import {toast} from 'react-toastify'

type PropsType = {
  visible: boolean
  onClose: () => void
  onCreated: () => void
  distributors: Array<DistributorModel>
}

export default function ModalEdiForm({
  visible = false,
  onClose = () => {},
  onCreated = () => {},
  distributors = [],
}: PropsType) {
  const intl = useIntl()
  const [edi, setEdi] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(false)
  const [selectedDistributor, setSelectedDistributor] = useState<DistributorModel | null>(null)

  async function onCreateOrder() {
    setLoading(true)
    try {
      const {data} = await createOrderFromEdi(selectedDistributor?.id || 0, edi)
      if (data) {
        toast.success(intl.formatMessage({id: 'b2bOrders.createOrderFromEdiSuccess'}))
        onCreated()
      } else {
        toast.error(intl.formatMessage({id: 'b2bOrders.createOrderFromEdiError'}))
      }
    } catch (error) {
      console.warn(error)
      toast.error(intl.formatMessage({id: 'b2bOrders.createOrderFromEdiError'}))
    } finally {
      setLoading(false)
    }
  }

  return (
    <Modal show={visible} onHide={onClose} size='lg' centered>
      <Modal.Header closeButton>
        <Modal.Title>{intl.formatMessage({id: 'b2bOrders.createOrderFromEdi'})}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='row'>
          <div className='col-12'>
            <div className='form-group w-250px mb-4'>
              <label className='fw-bolder mb-1'>
                {intl.formatMessage({id: 'b2bOrders.selectDistributor'})}
              </label>
              <select
                className='form-control form-select'
                value={selectedDistributor?.id}
                onChange={(e) => {
                  const distributorId = e.target.value
                  const distributor = distributors.find((d) => d.id === parseInt(distributorId, 10))
                  setSelectedDistributor(distributor || null)
                }}
              >
                <option value=''>{intl.formatMessage({id: 'b2bOrders.selectDistributor'})}</option>
                {distributors.map((distributor) => {
                  return (
                    <option key={distributor.id} value={distributor.id}>
                      {distributor.name}
                    </option>
                  )
                })}
              </select>
            </div>
            <div className='form-group'>
              <label className='fw-bolder mb-1'>
                {intl.formatMessage({id: 'b2bOrders.pasteEdi'})}
              </label>
              <textarea
                value={edi}
                onChange={(e) => setEdi(e.target.value)}
                className='form-control'
                placeholder={intl.formatMessage({id: 'b2bOrders.pasteEdiPlaceholder'})}
                rows={10}
              ></textarea>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button className='btn btn-tertiary' onClick={onClose}>
          {intl.formatMessage({id: 'b2bOrders.cancel'})}
        </button>
        <button
          disabled={loading || !edi || !selectedDistributor?.id}
          className='btn btn-primary'
          onClick={onCreateOrder}
        >
          {intl.formatMessage({id: 'b2bOrders.createOrder'})}
        </button>
      </Modal.Footer>
    </Modal>
  )
}
