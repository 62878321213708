import { useDispatch, shallowEqual, useSelector } from 'react-redux'
import * as user from '../redux/UserRedux'
import { UserModel } from '../models/UserModel'
import { useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { GenericForm, FormField } from '../../../components/GenericForm'
import { toast } from 'react-toastify'
import superUsers from '../../tasks/constants/superUsers'
import { authUserSelector } from '../../auth'
import { addStamps } from '../services/UserCRUD'

export default function PersonalInfo() {
  const intl = useIntl()
  const dispatch = useDispatch()
  const authUser = useSelector(authUserSelector, shallowEqual)
  const currentUser: UserModel | undefined = useSelector(user.currentUserSelector, shallowEqual)
  const status: string = useSelector(user.userStatusSelector, shallowEqual)
  const error: any | undefined = useSelector(user.userErrorSelector, shallowEqual)
  const [editing, setEditing] = useState(false)
  const [addingStamp, setAddingStamp] = useState(false)

  async function onAddStamp() {
    try {
      setAddingStamp(true)
      await addStamps(currentUser?.id, 1)

      setEditing(false);
      toast.success(intl.formatMessage({ id: 'userProfile.stampAdded' }))
    } catch (error) {
      console.warn('Error adding stamp', error)
      toast.error(intl.formatMessage({ id: 'userProfile.errorAddingStamp' }))
    } finally {
      setAddingStamp(false)
    }
  }

  function onSubmit(formState: UserModel) {
    const {
      id,
      name,
      email,
      phone,
      preferred_language,
      optin,
      is_eligible_for_trial,
      is_influencer,
      is_partial,
      alternative_phone,
      notes,
      register_source,
      mgm_coupon_code,
    } = formState as UserModel
    dispatch(
      user.actions.updateUser({
        id,
        props: {
          name,
          email,
          phone,
          preferred_language,
          optin,
          is_eligible_for_trial,
          is_influencer,
          is_partial,
          alternative_phone,
          notes,
          register_source,
          mgm_coupon_code,
        },
      })
    )
  }

  useEffect(() => {
    if (status === 'success_update_user') {
      setEditing(false)

      toast.success(intl.formatMessage({ id: 'userProfile.successToast' }), {
        position: 'top-right',
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })

      dispatch(user.actions.requestUser(currentUser?.id))
    }
    if (status === 'success_unsubscribe_from_newsletter') {
      setEditing(false)

      toast.success(intl.formatMessage({ id: 'userProfile.unsubscribeSuccessToast' }), {
        position: 'top-right',
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })

      dispatch(user.actions.requestUser(currentUser?.id))
    }
  }, [status])

  const formFields: Array<FormField<UserModel>> = [
    {
      id: 'email',
      label: intl.formatMessage({ id: 'userProfile.emailLabel' }),
      inputType: 'email',
    },
    {
      id: 'name',
      label: intl.formatMessage({ id: 'userProfile.nameLabel' }),
      inputType: 'text',
    },
    {
      id: 'phone',
      label: intl.formatMessage({ id: 'userProfile.phoneLabel' }),
      inputType: 'text',
      maxLength: 14,
    },
    {
      id: 'alternative_phone',
      label: intl.formatMessage({ id: 'userProfile.alternativePhoneLabel' }),
      inputType: 'text',
      maxLength: 14,
    },
    {
      id: 'preferred_language',
      label: intl.formatMessage({ id: 'userProfile.preferredLanguageLabel' }),
      inputType: 'select',
      options: [
        {
          value: 'es',
          label: 'Español',
        },
        {
          value: 'fr',
          label: 'Français',
        },
        {
          value: 'it',
          label: 'Italiano',
        },
        {
          value: 'ca',
          label: 'Català',
        },
        {
          value: 'de',
          label: 'Deutsch',
        },
        {
          value: 'pt',
          label: 'Português',
        },
      ],
    },
    {
      id: 'mgm_coupon_code',
      label: intl.formatMessage({ id: 'userProfile.mgmLabel' }),
      inputType: 'text',
    },
    {
      id: 'optin',
      label: intl.formatMessage({ id: 'userProfile.optinLabel' }),
      inputType: 'checkbox',
    },
    {
      id: 'is_eligible_for_trial',
      label: intl.formatMessage({ id: 'userProfile.eligibleForTrialLabel' }),
      inputType: 'checkbox',
    },
    {
      id: 'is_influencer',
      label: intl.formatMessage({ id: 'userProfile.isInfluencerLabel' }),
      inputType: 'checkbox',
    },
    {
      id: 'is_partial',
      label: intl.formatMessage({ id: 'userProfile.partialLabel' }),
      inputType: 'checkbox',
      readonly: !superUsers?.includes(authUser?.email),
      setter: (state: UserModel, value: boolean) => {
        if (value && !window.confirm(intl.formatMessage({ id: 'userProfile.partialConfirmation' }))) {
          return state
        }
        return {
          ...state,
          is_partial: value,
        }
      },
    },
    {
      id: 'notes',
      label: intl.formatMessage({ id: 'userProfile.notesLabel' }),
      inputType: 'textarea',
    },
    {
      id: 'register_source',
      label: intl.formatMessage({ id: 'userProfile.registerSourceLabel' }),
      inputType: 'text',
      readonly: true,
    },
    {
      id: 'loyalty_level',
      label: intl.formatMessage({ id: 'userProfile.loyaltyLevelLabel' }),
      inputType: 'text',
      readonly: true,
    },
    {
      id: 'loyalty_stamps',
      label: intl.formatMessage({ id: 'userProfile.loyaltyStampsLabel' }),
      inputType: 'number',
      readonly: true,
    }
  ]

  return (
    <div className='card p-10'>
      {status === 'loading_request_user' ? (
        <div className='spinner-border text-primary' role='status' />
      ) : (
        <>
          {currentUser ? (
            <>
              <GenericForm
                columns={2}
                error={error}
                editing={editing}
                toggleEdit={() => setEditing(!editing)}
                title={intl.formatMessage({ id: 'userProfile.personalInfo' })}
                initialState={currentUser}
                fields={formFields}
                ctaLabel={intl.formatMessage({ id: 'userProfile.saveChanges' })}
                onSubmit={onSubmit}
                submitting={status === 'loading_update_user'}
                submittingLabel={intl.formatMessage({ id: 'userProfile.pleaseWait' })}
              >
                {{
                  afterForm: <>
                    {currentUser.optin && (
                      <button
                        disabled={status === 'loading_unsubscribe_from_newsletter'}
                        className='btn btn-secondary d-block mt-4 text-nowrap'
                        onClick={() =>
                          dispatch(user.actions.unsubscribeFromNewsletter(currentUser?.email))
                        }
                      >
                        {status === 'loading_unsubscribe_from_newsletter' ? (
                          <>
                            <span
                              className='spinner-border spinner-border-sm me-4'
                              role='status'
                              aria-hidden='true'
                            />
                            {intl.formatMessage({ id: 'userProfile.pleaseWait' })}
                          </>
                        ) : <>
                          <span className='fas fa-ban me-4' />
                          {intl.formatMessage({ id: 'userProfile.unsubscribeFromNewsletter' })}
                        </>}
                      </button>
                    )}
                    {
                      superUsers?.includes(authUser?.email) && <button
                        disabled={addingStamp}
                        className='btn btn-secondary d-block mt-4 text-nowrap'
                        onClick={onAddStamp}
                      >
                        {addingStamp ? (
                          <>
                            <span
                              className='spinner-border spinner-border-sm me-4'
                              role='status'
                              aria-hidden='true'
                            />
                            {intl.formatMessage({ id: 'userProfile.pleaseWait' })}
                          </>
                        ) : <>
                          <span className='fas fa-paw me-4' />
                          { intl.formatMessage({ id: 'userProfile.addStamp' }) }
                        </>}
                      </button>
                    }
                  </>,
                }}
              </GenericForm>
            </>
          ) : (
            <p>{intl.formatMessage({ id: 'userProfile.noUser' })}</p>
          )}
        </>
      )}
    </div>
  )
}
