import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {UserModel} from '../models/UserModel'
import {ShippingAddressModel} from '../models/ShippingAddressModel'
import {BillingAddressModel} from '../models/BillingAddressModel'
import {useIntl} from 'react-intl'
import {currentUserSelector, userStatusSelector} from '../redux/UserRedux'
import {FormField, GenericForm} from '../../../components/GenericForm'
import {useEffect, useState} from 'react'
import * as userRedux from '../redux/UserRedux'
import {toast} from 'react-toastify'
import {Dropdown, Modal} from 'react-bootstrap'
import COUNTRIES from '../../../constants/countries'
import COUNTRY_NAMES from '../../../constants/countryNames'
import {listShippingServices} from '../../orders/services/OrderCRUD'

const LOADING_STATUS = 'LOADING_STATUS'
const EDITING_STATUS = 'EDITING_STATUS'
const SAVING_STATUS = 'SAVING_STATUS'
const REMOVING_STATUS = 'REMOVING_STATUS'
const IDLE_STATUS = 'IDLE_STATUS'
const MAX_BILLING_ADDRESSES = 1

export default function Addresses() {
  const intl = useIntl()
  const dispatch = useDispatch()
  const currentUser: UserModel | undefined = useSelector(currentUserSelector, shallowEqual)
  const status = useSelector(userStatusSelector, shallowEqual)
  const [shippingServices, setShippingServices] = useState([])
  const billingAddresses: Array<BillingAddressModel> = currentUser?.billing_addresses || []
  const shippingAddresses: Array<ShippingAddressModel> = currentUser?.shipping_addresses || []
  const shippingAddressesFields: FormField<ShippingAddressModel>[] = [
    {
      id: 'country',
      label: intl.formatMessage({id: 'userProfile.countryLabel'}),
      inputType: 'select',
      options: COUNTRIES.map((country) => ({
        value: country,
        label: COUNTRY_NAMES[country],
      })),
    },
    {
      id: 'name',
      label: intl.formatMessage({id: 'userProfile.nameLabel'}),
      inputType: 'text',
    },
    {
      id: 'street',
      label: intl.formatMessage({id: 'userProfile.streetLabel'}),
      inputType: 'text',
    },
    {
      id: 'city',
      label: intl.formatMessage({id: 'userProfile.cityLabel'}),
      inputType: 'text',
    },
    {
      id: 'postcode',
      label: intl.formatMessage({id: 'userProfile.postcodeLabel'}),
      inputType: 'text',
    },
    /* TODO: {
      id: 'region',
      label: intl.formatMessage({ id: 'userProfile.regionLabel' }),
      inputType: 'text',
      getter: (address: ShippingAddressModel) => address?.region
    }, */
    {
      id: 'phone',
      label: intl.formatMessage({id: 'userProfile.phoneLabel'}),
      inputType: 'text',
      maxLength: 14,
    },
    {
      id: 'comments',
      label: intl.formatMessage({id: 'userProfile.commentLabel'}),
      inputType: 'text',
    },
  ]
  let billingAddressesFields: FormField<BillingAddressModel>[] = [
    ...(shippingAddressesFields as []),
    {
      id: 'vat_number',
      label: intl.formatMessage({id: 'userProfile.vatLabel'}),
      inputType: 'text',
    },
  ] as FormField<BillingAddressModel>[]

  // Remove comments form billing fields
  billingAddressesFields = billingAddressesFields.filter((field) => field.id !== 'comments')

  shippingAddressesFields.push({
    id: 'shipping_service',
    label: intl.formatMessage({id: 'userProfile.shippingService'}),
    inputType: 'select',
    options: shippingServices.map((service) => ({
      value: service.id,
      label: service.name,
    })),
  })

  const [shippingAddressStatusses, setShippingAddressStatusses] = useState<Array<string>>([
    LOADING_STATUS,
  ])
  const [billingAddressStatusses, setBillingAddressStatusses] = useState<Array<string>>([
    LOADING_STATUS,
  ])
  const [visibleShippingAddressModal, setVisibleShippingAddressModal] = useState<boolean>(false)
  const [visibleBillingAddressModal, setVisibleBillingAddressModal] = useState<boolean>(false)

  useEffect(() => {
    if (billingAddresses.length > 0) {
      setBillingAddressStatusses(billingAddresses.map(() => IDLE_STATUS))
    }
    if (shippingAddresses.length > 0) {
      setShippingAddressStatusses(shippingAddresses.map(() => IDLE_STATUS))
    }
  }, [billingAddresses.length, shippingAddresses.length])

  useEffect(() => {
    switch (status) {
      case 'success_add_shipping_address':
        toast.success(intl.formatMessage({id: 'userProfile.successAddShippingAddress'}))
        setShippingAddressStatusses(shippingAddresses.map(() => IDLE_STATUS))
        setVisibleShippingAddressModal(false)
        break
      case 'success_add_billing_address':
        toast.success(intl.formatMessage({id: 'userProfile.successAddBillingAddress'}))
        setBillingAddressStatusses(billingAddresses.map(() => IDLE_STATUS))
        setVisibleBillingAddressModal(false)
        break
      case 'success_update_shipping_address':
        toast.success(intl.formatMessage({id: 'userProfile.successUpdateShippingAddress'}))
        setShippingAddressStatusses(shippingAddresses.map(() => IDLE_STATUS))
        break
      case 'success_update_billing_address':
        toast.success(intl.formatMessage({id: 'userProfile.successUpdateBillingAddress'}))
        setBillingAddressStatusses(billingAddresses.map(() => IDLE_STATUS))
        break
      case 'success_remove_shipping_address':
        toast.success(intl.formatMessage({id: 'userProfile.successRemoveShippingAddress'}))
        setShippingAddressStatusses(shippingAddresses.map(() => IDLE_STATUS))
        break
      case 'success_remove_billing_address':
        toast.success(intl.formatMessage({id: 'userProfile.successRemoveBillingAddress'}))
        setBillingAddressStatusses(billingAddresses.map(() => IDLE_STATUS))
        break
      case 'error_add_shipping_address':
        toast.error(intl.formatMessage({id: 'userProfile.errorAddShippingAddress'}))
        setShippingAddressStatusses(shippingAddresses.map(() => IDLE_STATUS))
        break
      case 'error_add_billing_address':
        toast.error(intl.formatMessage({id: 'userProfile.errorAddBillingAddress'}))
        setBillingAddressStatusses(billingAddresses.map(() => IDLE_STATUS))
        break
      case 'error_update_shipping_address':
        toast.error(intl.formatMessage({id: 'userProfile.errorUpdateShippingAddress'}))
        setShippingAddressStatusses(shippingAddresses.map(() => IDLE_STATUS))
        break
      case 'error_update_billing_address':
        toast.error(intl.formatMessage({id: 'userProfile.errorUpdateBillingAddress'}))
        setBillingAddressStatusses(billingAddresses.map(() => IDLE_STATUS))
        break
      case 'error_remove_shipping_address':
        toast.error(intl.formatMessage({id: 'userProfile.errorRemoveShippingAddress'}))
        setShippingAddressStatusses(shippingAddresses.map(() => IDLE_STATUS))
        break
      case 'error_remove_billing_address':
        toast.error(intl.formatMessage({id: 'userProfile.errorRemoveBillingAddress'}))
        setBillingAddressStatusses(billingAddresses.map(() => IDLE_STATUS))
        break

      default:
        break
    }
  }, [status])

  function toggleShippingEdit(index) {
    const newStatusses = [...shippingAddressStatusses]
    newStatusses[index] = newStatusses[index] === EDITING_STATUS ? IDLE_STATUS : EDITING_STATUS
    setShippingAddressStatusses(newStatusses)
  }

  function toggleBillingEdit(index: number) {
    const newStatusses = [...billingAddressStatusses]
    newStatusses[index] = newStatusses[index] === EDITING_STATUS ? IDLE_STATUS : EDITING_STATUS
    setBillingAddressStatusses(newStatusses)
  }

  function saveShippingAddress(index: number, address: ShippingAddressModel) {
    const newStatusses = [...shippingAddressStatusses]
    newStatusses[index] = SAVING_STATUS
    setShippingAddressStatusses(newStatusses)

    dispatch(userRedux.actions.updateShippingAddress(address))
  }

  function saveBillingAddress(index: number, address: BillingAddressModel) {
    const newStatusses = [...billingAddressStatusses]
    newStatusses[index] = SAVING_STATUS
    setBillingAddressStatusses(newStatusses)

    dispatch(userRedux.actions.updateBillingAddress(address))
  }

  function removeShippingAddress(index: number, address: ShippingAddressModel) {
    const confirmed = window.confirm(intl.formatMessage({id: 'userProfile.confirmAddressRemoval'}))

    if (confirmed) {
      const newStatusses = [...shippingAddressStatusses]
      newStatusses[index] = REMOVING_STATUS
      setShippingAddressStatusses(newStatusses)

      dispatch(userRedux.actions.removeShippingAddress(address.id))
    }
  }

  function removeBillingAddress(index: number, address: BillingAddressModel) {
    const confirmed = window.confirm(intl.formatMessage({id: 'userProfile.confirmAddressRemoval'}))

    if (confirmed) {
      const newStatusses = [...billingAddressStatusses]
      newStatusses[index] = REMOVING_STATUS
      setBillingAddressStatusses(newStatusses)

      dispatch(userRedux.actions.removeBillingAddress(address.id))
    }
  }

  function onAddShippingAddress() {
    setVisibleShippingAddressModal(true)
  }

  function onAddBillingAddress() {
    setVisibleBillingAddressModal(true)
  }

  async function fetchShippingServices() {
    try {
      const {data: services} = await listShippingServices()
      if (services) {
        setShippingServices(services)
      }
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    if (!shippingServices.length) {
      fetchShippingServices()
    }
  }, [shippingServices])

  return (
    <>
      <div className='card p-10'>
        <h1 className='mb-8'>{intl.formatMessage({id: 'userProfile.addresses'})}</h1>
        <div className='d-flex mb-8 w-100'>
          <Dropdown className='w-100'>
            <Dropdown.Toggle className='w-100' variant='primary' id='dropdown-basic'>
              <span className='fa fa-plus me-4 '></span>
              {intl.formatMessage({id: 'userProfile.addAddress'})}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {billingAddresses?.length < MAX_BILLING_ADDRESSES && (
                <Dropdown.Item className='py-3 ps-5 fs-6' onClick={onAddBillingAddress}>
                  {intl.formatMessage({id: 'userProfile.addBillingAddress'})}
                </Dropdown.Item>
              )}
              <Dropdown.Item onClick={onAddShippingAddress} className='py-3 ps-5 fs-6'>
                {intl.formatMessage({id: 'userProfile.addShippingAddress'})}
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <div className=''>
          {status === 'loading_request_user' ? (
            <div className='spinner-border text-primary' role='status' />
          ) : (
            <>
              {billingAddresses?.length > 0 ? (
                <section>
                  {billingAddresses.map((billingAddress: BillingAddressModel, index) => (
                    <article className='mb-3' key={billingAddress.id}>
                      <GenericForm
                        columns={2}
                        title={intl.formatMessage({id: 'userProfile.billing'})}
                        fields={billingAddressesFields}
                        initialState={billingAddress}
                        ctaLabel={intl.formatMessage({id: 'userProfile.saveChanges'})}
                        onSubmit={(address: BillingAddressModel) =>
                          saveBillingAddress(index, address)
                        }
                        toggleEdit={() => toggleBillingEdit(index)}
                        editing={billingAddressStatusses[index] === EDITING_STATUS}
                        submitting={billingAddressStatusses[index] === SAVING_STATUS}
                        submittingLabel={intl.formatMessage({id: 'userProfile.saving'})}
                      >
                        {{
                          afterForm: (
                            <button
                              className='btn btn-tertiary d-inline-block'
                              onClick={() => removeBillingAddress(index, billingAddress)}
                              disabled={billingAddressStatusses[index] === REMOVING_STATUS}
                            >
                              <span className='indicator-label'>
                                <span className='fas fa-trash me-4'></span>
                                {intl.formatMessage({id: 'userProfile.remove'})}
                              </span>
                              <span className='indicator-progress'>
                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                              </span>
                            </button>
                          ),
                        }}
                      </GenericForm>
                    </article>
                  ))}
                </section>
              ) : (
                <>
                  <p>{intl.formatMessage({id: 'userProfile.noBillingAddresses'})}</p>
                </>
              )}
              {shippingAddresses?.length > 0 ? (
                <section>
                  {shippingAddresses.map((shippingAddress: ShippingAddressModel, index) => (
                    <article className='mb-3' key={shippingAddress.id}>
                      <GenericForm
                        columns={2}
                        title={intl.formatMessage({id: 'userProfile.shipping'})}
                        fields={shippingAddressesFields}
                        initialState={shippingAddress}
                        ctaLabel={intl.formatMessage({id: 'userProfile.saveChanges'})}
                        onSubmit={(shippingAddress) => saveShippingAddress(index, shippingAddress)}
                        toggleEdit={() => toggleShippingEdit(index)}
                        editing={[EDITING_STATUS, SAVING_STATUS].includes(
                          shippingAddressStatusses[index]
                        )}
                        submitting={shippingAddressStatusses[index] === SAVING_STATUS}
                        submittingLabel={intl.formatMessage({id: 'userProfile.saving'})}
                      >
                        {{
                          afterForm: (
                            <button
                              className='btn btn-tertiary d-inline-block'
                              onClick={() => removeShippingAddress(index, shippingAddress)}
                              disabled={shippingAddressStatusses[index] === REMOVING_STATUS}
                            >
                              <span className='indicator-label'>
                                <span className='fas fa-trash me-4'></span>
                                {intl.formatMessage({id: 'userProfile.remove'})}
                              </span>
                              <span className='indicator-progress'>
                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                              </span>
                            </button>
                          ),
                        }}
                      </GenericForm>
                    </article>
                  ))}
                </section>
              ) : (
                <>
                  <p>{intl.formatMessage({id: 'userProfile.noShippingAddresses'})}</p>
                </>
              )}
            </>
          )}
        </div>
      </div>
      <Modal
        show={visibleShippingAddressModal}
        centered
        onHide={() => setVisibleShippingAddressModal(false)}
      >
        <Modal.Body>
          <GenericForm
            editable={false}
            columns={2}
            title={intl.formatMessage({id: 'userProfile.addShippingAddress'})}
            fields={shippingAddressesFields}
            initialState={
              {
                name: currentUser?.name || '',
                street: '',
                city: '',
                country: currentUser?.store?.country?.code || 'ES',
                postcode: '',
                phone: '',
              } as ShippingAddressModel
            }
            ctaLabel={intl.formatMessage({id: 'userProfile.add'})}
            onSubmit={(shippingAddress) => {
              dispatch(userRedux.actions.addShippingAddress(shippingAddress))
            }}
            toggleEdit={() => {}}
            editing={true}
            submitting={status === 'loading_add_shipping_address'}
            submittingLabel={intl.formatMessage({id: 'userProfile.saving'})}
          />
        </Modal.Body>
      </Modal>
      <Modal
        show={visibleBillingAddressModal}
        centered
        onHide={() => setVisibleBillingAddressModal(false)}
      >
        <Modal.Body>
          <GenericForm
            editable={false}
            columns={2}
            title={intl.formatMessage({id: 'userProfile.addBillingAddress'})}
            fields={billingAddressesFields}
            initialState={
              {
                name: currentUser?.name || '',
                street: '',
                city: '',
                country: currentUser?.store?.country?.code || 'ES',
                postcode: '',
                vat_number: '',
              } as BillingAddressModel
            }
            ctaLabel={intl.formatMessage({id: 'userProfile.add'})}
            onSubmit={(billingAddress) => {
              dispatch(userRedux.actions.addBillingAddress(billingAddress))
            }}
            toggleEdit={() => {}}
            editing={true}
            submitting={status === 'loading_add_billing_address'}
            submittingLabel={intl.formatMessage({id: 'userProfile.saving'})}
          />
        </Modal.Body>
      </Modal>
    </>
  )
}
