import {FC} from 'react'
import {useIntl} from 'react-intl'

import {PageTitle} from '../../../_metronic/layout/core'
import ShopifyImport from '../../modules/shopify/components/ShopifyImport'

const ShopifyImportPage: FC = () => {
  const intl = useIntl()

  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'app.shopifyImport'})}</PageTitle>
      <div className='row gy-4 mw-700px'>
        <div className='card p-10'>
          <ShopifyImport/>
        </div>
      </div>
    </>
  )
}

export {ShopifyImportPage}
