/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {PageTitle} from '../../../_metronic/layout/core'
import {PersonalInfo, Addresses, AccountLink} from '../../modules/user-profile'
import {usePathProps} from '../../modules/user-profile/hooks/usePathProps'
import {useIntl} from 'react-intl'
import Agents from '../../modules/user-profile/components/Agents'

const UserProfileComponent: FC = () => {
  return (
    <>
      <div className='row'>
        <div className='col-12 col-lg-6'>
          <div className='mb-4'>
            <PersonalInfo />
          </div>
          <div className='mb-4'>
            <Agents />
          </div>
        </div>
        <div className='col-12 col-lg-6 mw-500px'>
          <div className='mb-4'>
            <AccountLink />
          </div>
          <Addresses />
        </div>
      </div>
    </>
  )
}

const UserProfilePage: FC = () => {
  usePathProps()
  const intl = useIntl()

  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'menu.userProfile'})}</PageTitle>
      <UserProfileComponent />
    </>
  )
}

export {UserProfilePage}
