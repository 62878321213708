import {FC} from 'react'
import {useIntl} from 'react-intl'

import {PageTitle} from '../../../_metronic/layout/core'
import {LeadDistributorReport} from '../../modules/lead-distributors'

const LeadDistributorReportPage: FC = () => {
  const intl = useIntl()

  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'leadDistributorReport.title'})}</PageTitle>
      <div className='row gy-4'>
        <div className='card p-10'>
          <LeadDistributorReport />
        </div>
      </div>
    </>
  )
}

export {LeadDistributorReportPage}
