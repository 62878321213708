import axios from 'axios'
import {UserModel} from '../models/UserModel'
import {CouponModel} from '../models/CouponModel'
import {ShippingAddressModel} from '../models/ShippingAddressModel'
import {BillingAddressModel} from '../models/BillingAddressModel'

const API_URL = process.env.REACT_APP_API_URL

export function getUserById(id: number): Promise<{data: UserModel}> {
  const USERS_URL = `${API_URL}/api/users/${id}/`
  return axios.get(USERS_URL)
}

export function updateUser({id, ...props}: any): Promise<{data: UserModel}> {
  const USERS_URL = `${API_URL}/api/users/${id}/`
  return axios.patch(USERS_URL, {
    mgm_code: props?.mgm_coupon_code,
    ...props,
  })
}

export function listCoupons(id: number): Promise<{data: Array<CouponModel>}> {
  const endpoint = `${API_URL}/api/coupons/?u=${id}`
  return axios.get(endpoint)
}

export function addCoupon(coupon: string, userId: number) {
  const endpoint = `${API_URL}/api/users/${userId}/add-coupon/`
  return axios.post(endpoint, {coupon})
}

export function removeCoupon(coupon: string, userId: number) {
  const endpoint = `${API_URL}/api/users/${userId}/remove-coupon/`
  return axios.post(endpoint, {coupon})
}

export function removeBankcard(bankcardId: number) {
  const endpoint = `${API_URL}/api/bankcards/${bankcardId}/`
  return axios.delete(endpoint)
}

export function updateBankcard(bankcardId: number, props: any) {
  const endpoint = `${API_URL}/api/bankcards/${bankcardId}/`
  return axios.patch(endpoint, {...props})
}

export function addShippingAddress(userId: number, shippingAddress: Partial<ShippingAddressModel>) {
  const endpoint = `${API_URL}/api/shipping-addresses/?u=${userId}`
  return axios.post(endpoint, shippingAddress)
}

export function addBillingAddress(userId: number, billingAddress: Partial<BillingAddressModel>) {
  const endpoint = `${API_URL}/api/billing-addresses/?u=${userId}`
  return axios.post(endpoint, billingAddress)
}

export function updateShippingAddress(shippingAddress: ShippingAddressModel) {
  const endpoint = `${API_URL}/api/shipping-addresses/${shippingAddress.id}/`
  return axios.patch(endpoint, {...shippingAddress})
}

export function updateBillingAddress(billingAddress: BillingAddressModel) {
  const endpoint = `${API_URL}/api/billing-addresses/${billingAddress.id}/`
  return axios.patch(endpoint, {...billingAddress})
}

export function removeShippingAddress(shippingAddressId: number) {
  const endpoint = `${API_URL}/api/shipping-addresses/${shippingAddressId}/`
  return axios.delete(endpoint)
}

export function removeBillingAddress(billingAddressId: number) {
  const endpoint = `${API_URL}/api/billing-addresses/${billingAddressId}/`
  return axios.delete(endpoint)
}

export function requestCall(name: string, phone: string) {
  const endpoint = `${API_URL}/api/call-requests/`
  const data = {
    name,
    phone,
  }

  return axios.post(endpoint, data)
}

export function unsubscribeFromNewsletter(email: string) {
  const endpoint = `${API_URL}/api/users/unsubscribe-from-nl/`
  const data = {
    email,
  }

  return axios.post(endpoint, data)
}

export function addStamps(userId: number, stamps: number) {
  const endpoint = `${API_URL}/api/loyalty/add-stamps/`

  return axios.put(endpoint, {
    user_id: userId,
    number_of_stamps: stamps,
  })
}