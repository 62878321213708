/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'

import { PageTitle } from '../../../_metronic/layout/core'
import debounce from 'lodash.debounce'
import { searchPlaces } from '../../modules/lead-distributors'
import { Place } from '../../modules/lead-distributors/models/Place'

const DEBOUNCE_MILLISECONDS = 800

const SearchShopsPage: FC = () => {
  const intl = useIntl()
  const [query, setQuery] = useState('')
  const [nextPageToken, setNextPageToken] = useState<string | null>(null)
  const [results, setResults] = useState<Place[]>([])
  const [loading, setLoading] = useState(false)

  async function search(aQuery: string, aNextPageToken?: string) {
    try {
      setLoading(true)
      const { data } = await searchPlaces(aQuery, aNextPageToken)

      if (aNextPageToken) {
        setResults([
          ...results,
          ...(data.results || [])
        ])
      } else {
        setResults(data.results || [])
      }
      setNextPageToken(data.next_page_token)
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  const onChange = useMemo(
    () => debounce(
      (aQuery) => search(aQuery),
      DEBOUNCE_MILLISECONDS
    ),
    []
  )

  useEffect(() => {
    setNextPageToken(null)
    setResults([])
    onChange(query)
  }, [query])

  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'menu.searchShops' })}</PageTitle>
      <div className='row'>
        <div className='col-12'>
          <div className='card p-10 h-100 w-750px'>
            <label className='fw-bolder mb-1'>
              {intl.formatMessage({ id: 'searchShops.inputLabel' })}
            </label>
            <input
              value={query}
              onChange={(e) => setQuery(e.target.value)}
              type='search'
              className='form-control mb-8 mw-700px'
              placeholder={intl.formatMessage({ id: 'searchShops.inputPlaceholder' })}
            />
            <section>
              {
                !results?.length && loading && (
                  <div className='text-center'>
                    <span className='spinner-border spinner-border-lg text-primary me-3' role='status' aria-hidden='true'></span>
                  </div>
                )
              }
              {
                results.map((result, index) => (
                  <a key={index} className='card text-dark shadow mb-4 overflow-hidden mw-700px border-0'
                    target='_blank'
                    rel='noreferrer'
                    href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(result.name)},+${encodeURIComponent(result.formatted_address)}`}
                  >
                    <div className='d-flex w-100 gx-2 justify-content-between'>
                      <div className='w-auto flex-grow-1 px-5 py-4 overflow-hidden'>
                        <span className='d-block fw-bolder fs-4 text-truncate w-100 mb-0'>{result.name}</span>
                        <span className='d-block text-truncate mb-0'>{result.formatted_address}</span>
                        <div className='text-muted mb-1'>
                          <span className='fas fa-star text-warning me-1'></span>
                          {
                            result.rating || '-'
                          } · {
                            result.user_ratings_total || '0'
                          } {intl.formatMessage({ id: 'searchShops.reviews' })}
                        </div>
                        <div className='mb-2'>
                          {
                            result.business_status === 'OPERATIONAL' && (
                              <div className='d-inline-block me-2 badge bg-success'>En activo</div>
                            ) 
                          }{
                            result.business_status === 'CLOSED_TEMPORARILY' && (
                              <div className='d-inline-block me-2 badge bg-warning text-dark'>Cierre temporal</div>
                            )
                          }
                          {
                            result.business_status === 'CLOSED_PERMANENTLY' && (
                              <div className='d-inline-block me-2 badge bg-danger'>Cierre permanente</div>
                            )
                          }
                          {
                            result.open_now ? (
                              <div className='d-inline-block me-2 badge bg-success'>Abierto ahora</div>
                            ) : (
                              <div className='d-inline-block me-2 badge bg-danger'>Cerrado ahora</div>
                            )
                          }
                        </div>
                      </div>
                      <div className='flex-shrink-0 bg-light-dark' style={{
                        height: '122px',
                        width: '300px'
                      }}>
                        <iframe
                          title='map'
                          width='100%'
                          height='100%'
                          frameBorder='0'
                          style={{ border: 0, padding: 0, margin: '-2px' }}
                          src={`https://www.google.com/maps/embed/v1/place?key=${process.env.REACT_APP_GOOGLE_MAPS_KEY}&q=${encodeURIComponent(result.name)},+${encodeURIComponent(result.formatted_address)}&zoom=16`}
                          referrerPolicy="no-referrer-when-downgrade"
                        />
                      </div>
                    </div>
                  </a>
                ))
              }
              { nextPageToken?.length > 0 && <button className='btn btn-primary mt-8 mx-auto' onClick={() => search(query, nextPageToken)}>
                <span className='spinner-border spinner-border-sm me-3' role='status' aria-hidden='true' hidden={!loading}></span>
                {intl.formatMessage({ id: 'searchShops.loadMore' })}
              </button> }
            </section>
          </div>
        </div>
      </div>
    </>
  )
}


export { SearchShopsPage }
