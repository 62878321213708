import { useState } from "react";
import DateRangeSelector from "../../../components/DateRangeSelector";
import { useIntl } from "react-intl";
import moment from "moment";
import { sendOrdersExcel } from "../services/DashboardCRUD";
import { toast } from "react-toastify";
import {MultiSelect} from 'react-multi-select-component'
import { OrderStateEnum } from "../../orders";


export default function OrdersReport() {
    const intl = useIntl()
    const stateOptions = Object.values(OrderStateEnum).map((state) => ({ label: state, value: state }))
    const initialSince = new Date(new Date().setDate(new Date().getDate() - new Date().getDay() + 1))
    const initialUntil = new Date(new Date().setDate(new Date().getDate() - new Date().getDay() + 7))
    const [since, setSince] = useState(initialSince)
    const [until, setUntil] = useState(initialUntil)
    const [selectedStates, setSelectedStates] = useState([])
    const [generatingOrdersReport, setGeneratingOrdersReport] = useState(false)
    const multiSelectStrings = {
        allItemsAreSelected: intl.formatMessage({id: 'tasks.allOptionsSelected'}),
        clearSearch: intl.formatMessage({id: 'tasks.clearSearch'}),
        clearSelected: intl.formatMessage({id: 'tasks.clearSelected'}),
        noOptions: intl.formatMessage({id: 'tasks.noOptions'}),
        search: intl.formatMessage({id: 'tasks.search'}),
        selectAll: intl.formatMessage({id: 'tasks.selectAll'}),
        selectAllFiltered: intl.formatMessage({id: 'tasks.selectAllFiltered'}),
        selectSomeItems: intl.formatMessage({id: 'tasks.selectSomeItems'}),
        create: intl.formatMessage({id: 'tasks.create'}),
    }

    async function generateOrdersReport() {
        const hasConfirmed = window.confirm(
            intl.formatMessage({ id: 'businessDashboard.confirmOrdersReport' }, {
                since: moment(since).format('DD/MM/YYYY'),
                until: moment(until).format('DD/MM/YYYY'),
            }),
        )

        if (hasConfirmed) {
            try {
                setGeneratingOrdersReport(true)
                await sendOrdersExcel({
                    since: moment(since).format('YYYY-MM-DD'),
                    until: moment(until).format('YYYY-MM-DD'),
                    states: selectedStates,
                })
                await new Promise((resolve) => setTimeout(resolve, 2000))
                toast.success(intl.formatMessage({ id: 'businessDashboard.ordersReportGenerated' }))
            } catch (error) {
                toast.error(intl.formatMessage({ id: 'businessDashboard.ordersReportError' }))
                console.warn(error)
            } finally {
                setGeneratingOrdersReport(false)
            }
        }
    }

    return <>
        <h1 className="title mb-8">
            {intl.formatMessage({ id: 'businessDashboard.generateOrdersReport' })}
        </h1>
        <div className="d-flex gap-6 flex-column align-items-start ">
            <DateRangeSelector
                initialSince={initialSince}
                initialUntil={initialUntil}
                onSelected={(since, until) => {
                    setSince(since)
                    setUntil(until)
                }}
                disableFutureDates={false}
            />
            <div className="form-group mw-100">
                <label className="form-label mb-2">
                    {intl.formatMessage({ id: 'businessDashboard.states' })}
                </label>
                <MultiSelect
                    overrideStrings={multiSelectStrings}
                    className='multi-select mw-100 mt-0'
                    labelledBy='Select'
                    disableSearch={true}
                    options={stateOptions}
                    value={
                        stateOptions?.filter(({ value }) => selectedStates.includes(value))
                    }
                    onChange={(newValues) => {    
                        setSelectedStates(newValues.map(({ value }) => value))
                    }}
                    valueRenderer={(selected) =>
                        selected.length
                            ? selected
                                ?.map(({value}) => value)
                                .join(', ')
                            : ''
                    }
                />
            </div>
            <button
                disabled={selectedStates.length === 0 || generatingOrdersReport }
                className='btn btn-primary mt-auto'
                onClick={generateOrdersReport}
            >
                {
                    generatingOrdersReport ?
                        <span className='spinner-border spinner-border-sm me-3'></span>
                        : <span className='fas fa-file-excel me-3'></span>
                }
                {intl.formatMessage({ id: 'businessDashboard.generateOrdersReport' })}
            </button>
        </div>
    </>
}