import {useIntl} from 'react-intl'
import {ReactNode, useEffect, useState} from 'react'
import clsx from 'clsx'
import {toast} from 'react-toastify'
import {Dropdown} from 'react-bootstrap'

import {DeliveryNoteModel, DeliveryNoteStateEnum} from '../models/DeliveryNoteModel'
import {DELIVERY_NOTES_PER_PAGE, generateEdiFile, generatePdfFile, getDeliveryNoteById} from '../services/DeliveryNoteCRUD'
import { Pagination } from '@mui/material'
import { BulkActionsEnum } from '../../delivery-notes/services/DeliveryNoteCRUD'

type PropsType = {
  deliveryNotes: Array<DeliveryNoteModel>
  loading: boolean
  children: ReactNode
  page: number
  count: number
  onUpdateState: (deliveryNote: DeliveryNoteModel, state: DeliveryNoteStateEnum) => void
  onPageChange: (page: number) => void
  onGenerateInvoice: (deliveryNote: DeliveryNoteModel) => void
  onEdit: (deliveryNote: DeliveryNoteModel) => void
  onPerformBulkAction: (action: BulkActionsEnum, deliveryNoteIds: Array<number>) => Promise<any>
}

const NEUTRAL_STATES = []
const SUCCESS_STATES = [DeliveryNoteStateEnum.DELIVERED]
const FAIL_STATES = [DeliveryNoteStateEnum.CANCELLED, DeliveryNoteStateEnum.NOT_DELIVERED]

const STATE_TRANSITIONS = {
  [DeliveryNoteStateEnum.DELIVERED]: [
    DeliveryNoteStateEnum.CANCELLED,
    DeliveryNoteStateEnum.NOT_DELIVERED,
  ],
  [DeliveryNoteStateEnum.CANCELLED]: [DeliveryNoteStateEnum.NOT_DELIVERED],
  [DeliveryNoteStateEnum.NOT_DELIVERED]: [
    DeliveryNoteStateEnum.DELIVERED,
    DeliveryNoteStateEnum.CANCELLED,
  ],
}
type BULK_STATE = 'loading' | 'idle'

export default function DeliveryNoteList({
  deliveryNotes,
  loading,
  children,
  page,
  count,
  onUpdateState,
  onPageChange,
  onGenerateInvoice,
  onEdit,
  onPerformBulkAction,
}: PropsType) {
  const intl = useIntl()
  const urlParams = new URLSearchParams(window.location.search)
  const deliveryNoteId = urlParams.get('deliveryNote')
  const [selectedDeliveryNotes, setSelectedDeliveryNotes] = useState<DeliveryNoteModel[]>([])
  const hasSelectedAllDeliveryNotes = selectedDeliveryNotes?.length === deliveryNotes?.length
  const selectedCount = hasSelectedAllDeliveryNotes ? count : selectedDeliveryNotes.length
  const [bulkState, setBulkState] = useState<BULK_STATE>('idle')

  function getDeliveryNotes() {
    if (deliveryNoteId) {
      const deliveryNote = deliveryNotes.find(
        (deliveryNote) => deliveryNote.id === parseInt(deliveryNoteId)
      );
      if (deliveryNote) {
        return [
          deliveryNote,
          ...deliveryNotes.filter((i) => i.id !== deliveryNote.id),
        ]
      }
    }
    return deliveryNotes;
  }

  function downloadFile(url, fileName) {
    const element = document.createElement('a')
    element.target = '_blank'
    element.href = url
    element.download = fileName
    document.body.appendChild(element)
    element.click()
  }

  async function onGenerateEdi(deliveryNote: DeliveryNoteModel) {
    try {
      if (deliveryNote.edi_file_url) {
        downloadFile(deliveryNote.edi_file_url, `${deliveryNote.code}.txt`)
      } else {
        await generateEdiFile(deliveryNote.id)
        const {data} = await getDeliveryNoteById(deliveryNote.id)
        if (data.edi_file_url) {
          downloadFile(data.edi_file_url, `${data.code}.txt`)
        } else {
          toast.error(intl.formatMessage({id: 'deliveryNotes.ediGenerationError'}))
        }
      }
    } catch (error) {
      toast.error(intl.formatMessage({id: 'deliveryNotes.ediGenerationError'}))
      console.warn(error)
    }
  }

  async function onGeneratePdf(deliveryNote: DeliveryNoteModel) {
    try {
      if (deliveryNote.pdf_file_url) {
        downloadFile(deliveryNote.pdf_file_url, `${deliveryNote.code}.pdf`)
      } else {
        await generatePdfFile(deliveryNote.id)
        const {data} = await getDeliveryNoteById(deliveryNote.id)
        if (data.pdf_file_url) {
          downloadFile(data.pdf_file_url, `${data.code}.pdf`)
        } else {
          toast.error(intl.formatMessage({id: 'deliveryNotes.pdfGenerationError'}))
        }
      }
    } catch (error) {
      toast.error(intl.formatMessage({id: 'deliveryNotes.pdfGenerationError'}))
      console.warn(error)
    }
  }

  async function emitInvoices() {
    try {
      const hasConfirmed = window.confirm(
        intl.formatMessage(
          {id: 'deliveryNotes.bulkEmitInvoicesConfirmation'},
          {count: selectedCount}
        )
      )
      if (!hasConfirmed) {
        return
      }
      if (hasSelectedAllDeliveryNotes) {
        await onPerformBulkAction(BulkActionsEnum.EMIT_INVOICES, [])
      } else {
        await onPerformBulkAction(BulkActionsEnum.EMIT_INVOICES, selectedDeliveryNotes?.map((note) => note.id))
      }
      toast.success(
        intl.formatMessage(
          {id: 'deliveryNotes.bulkEmitInvoicesSuccess'},
          {count: selectedCount}
        )
      )
      setBulkState('loading')
      await 
      setBulkState('idle')
    } catch (error) {
      toast.error(intl.formatMessage({id: 'deliveryNotes.emitInvoicesError'}))
      console.warn(error)
      setBulkState('idle')
    }
  }

  useEffect(() => {
    setSelectedDeliveryNotes([])
  }, [deliveryNotes])


  return (
    <div className='card'>
      <div className='card-body'>
        <h2 className='mb-8'>{intl.formatMessage({id: 'deliveryNotes.deliveryNoteList'})}</h2>
        <div className='row mb-8'>{children}</div>
        {loading && <div className='spinner-border text-primary my-10' />}

        {!loading && deliveryNotes?.length === 0 && (
          <p>{intl.formatMessage({id: 'deliveryNotes.noDeliveryNotes'})}</p>
        )}
        
        {!loading && (
          <section className='d-flex px-0 gap-4 align-items-end mb-8'>
            <div className='me-4'>
              <label className={clsx('mb-2', !selectedCount && 'text-muted')}>
                {selectedCount > 0 ? (
                  <>
                    {intl.formatMessage(
                      { id: 'deliveryNotes.countSelectedNotes' },
                      { count: selectedCount }
                    )}
                  </>
                ) : (
                  <>{intl.formatMessage({ id: 'deliveryNotes.noSelectedNotes' })}</>
                )}
              </label>
              <Dropdown>
                <Dropdown.Toggle variant='primary' disabled={selectedCount < 1}>
                  <span className='me-4 fas w-15px d-inline-block fa-layer-group' />
                  {intl.formatMessage({ id: 'deliveryNotes.bulkActions' })}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item className='py-3 ps-5 fs-6' onClick={emitInvoices}>
                    <span className='me-4 fas w-15px d-inline-block fa-file-invoice-dollar text-muted' />
                    {intl.formatMessage({ id: 'deliveryNotes.emitInvoices' })}
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </section>
        )}
        
        {!loading && deliveryNotes?.length > 0 && (
          <div
            className='table-responsive'
            style={{
              height: 'calc(100vh - 420px)',
            }}
          >
            <p>{intl.formatMessage({id: 'deliveryNotes.count'}, {count, visible: deliveryNotes.length})}</p>
            <table className='table table-hover table-row-dashed table-row-gray-200 align-start gs-0 gy-4'>
              <thead className='table-header sticky-top bg-white '>
                <tr className='text-left fw-bolder align-middle'>
                  <th className='text-center ps-6 pe-4'>
                    <input
                      type='checkbox'
                      className='cursor-pointer form-check w-25px h-25px'
                      disabled={bulkState === 'loading'}
                      checked={selectedDeliveryNotes.length === deliveryNotes.length}
                      onChange={() => {
                        if (selectedDeliveryNotes.length === deliveryNotes.length) {
                          setSelectedDeliveryNotes([])
                        } else {
                          setSelectedDeliveryNotes(deliveryNotes)
                        }
                      }}
                    />
                  </th>
                  <th className='ps-4'>{intl.formatMessage({id: 'deliveryNotes.deliveryNote'})}</th>
                  <th className=''>{intl.formatMessage({id: 'deliveryNotes.distributor'})}</th>
                  <th className=''>{intl.formatMessage({id: 'deliveryNotes.order'})}</th>
                  <th className=''>{intl.formatMessage({id: 'deliveryNotes.date'})}</th>
                  <th className=''>{intl.formatMessage({id: 'deliveryNotes.summary'})}</th>
                  <th className=''>{intl.formatMessage({id: 'deliveryNotes.subtotal'})}</th>
                  <th className=''>{intl.formatMessage({id: 'deliveryNotes.total'})}</th>
                  <th className=''>{intl.formatMessage({id: 'deliveryNotes.download'})}</th>
                  <th className=''>{intl.formatMessage({id: 'deliveryNotes.actions'})}</th>
                </tr>
              </thead>
              <tbody>
                {getDeliveryNotes()?.map((deliveryNote) => (
                  <tr
                    className={clsx({
                      'align-top': true,
                      'bg-light-success':
                        deliveryNoteId && deliveryNoteId === deliveryNote.id.toString(),
                    })}
                  >
                    <td className='text-center ps-6 pe-4'>
                      <input
                        type='checkbox'
                        className='cursor-pointer form-check w-25px h-25px'
                        checked={selectedDeliveryNotes.includes(deliveryNote)}
                        disabled={bulkState === 'loading'}
                        onChange={() => {
                          if (selectedDeliveryNotes.includes(deliveryNote)) {
                            setSelectedDeliveryNotes(selectedDeliveryNotes.filter((t) => t.id !== deliveryNote.id))
                          } else {
                            setSelectedDeliveryNotes([...selectedDeliveryNotes, deliveryNote])
                          }
                        }}
                      />
                    </td>
                    <td className='ps-4'>
                      <div className='mb-2'>
                        {deliveryNote.code}
                      </div>
                      {STATE_TRANSITIONS[deliveryNote.state]?.length > 0 ? (
                        <Dropdown>
                          <Dropdown.Toggle
                            id='dropdown-create-b2b-deliveryNote'
                            variant={clsx({
                              primary: NEUTRAL_STATES.includes(deliveryNote.state),
                              success: SUCCESS_STATES.includes(deliveryNote.state),
                              danger: FAIL_STATES.includes(deliveryNote.state),
                            })}
                            className={clsx({
                              'badge badge-sm px-4 py-2 mb-2': true,
                            })}
                          >
                            {deliveryNote.state}
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            {STATE_TRANSITIONS[deliveryNote.state]?.map((state) => (
                              <Dropdown.Item
                                className='p-4'
                                key={state}
                                onClick={() => onUpdateState(deliveryNote, state)}
                              >
                                {state}
                              </Dropdown.Item>
                            ))}
                          </Dropdown.Menu>
                        </Dropdown>
                      ) : (
                        <span
                          className={clsx({
                            'badge badge-sm px-4 py-2 mb-2': true,
                            'badge-success': SUCCESS_STATES.includes(deliveryNote.state),
                            'badge-danger': FAIL_STATES.includes(deliveryNote.state),
                          })}
                        >
                          {deliveryNote.state}
                        </span>
                      )}
                      <span className={clsx({
                            'badge badge-sm px-4 py-2 mb-2': true,
                            'badge-primary': deliveryNote.billing_status === 'invoiced',
                            'badge-warning text-dark': deliveryNote.billing_status === 'pending',
                          })}>
                        {deliveryNote.billing_status}
                      </span>
                    </td>
                    <td className=''>{deliveryNote.distributor_name || '-'}</td>
                    <td className=''>{deliveryNote.order}</td>
                    <td className=''>
                      {deliveryNote.date ? new Date(deliveryNote.date).toLocaleDateString() : '-'}
                    </td>
                    <td className='border-none'>
                      <div className='d-flex align-items-center'>
                        {deliveryNote?.delivery_note_lines?.length ? (
                          <div className='w-100 '>
                            {deliveryNote?.delivery_note_lines
                              ?.filter((line: any) => line.served_units > 0)
                              ?.map((line: any) => (
                                <div className='d-flex justify-content-between'>
                                  <div className='w-300px'>
                                    <span className='fw-bolder'>{line.served_units}</span> x{' '}
                                    {line.order_line.name}
                                  </div>
                                </div>
                              ))}
                          </div>
                        ) : (
                          <div className='text-muted'>
                            {intl.formatMessage({id: 'deliveryNotes.noLines'})}
                          </div>
                        )}
                      </div>
                    </td>
                    <td>
                      {deliveryNote.subtotal}€
                    </td>
                    <td>
                      {deliveryNote.total}€
                    </td>
                    <td className=''>
                      <Dropdown>
                        <Dropdown.Toggle className='btn btn-sm btn-secondary'>
                          <span className='fa fa-download me-3'></span>
                          {intl.formatMessage({ id: 'deliveryNotes.download' })}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item className='p-4' onClick={() => onGenerateEdi(deliveryNote)}>
                            <span className='fa fa-file-alt me-3'></span>
                            {intl.formatMessage({ id: 'deliveryNotes.edi' })}
                          </Dropdown.Item>
                          <Dropdown.Item className='p-4' onClick={() => onGeneratePdf(deliveryNote)}>
                            <span className='fa fa-file me-3'></span>
                            {intl.formatMessage({ id: 'deliveryNotes.pdf' })}
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </td>
                    <td className=''>
                        <Dropdown>
                          <Dropdown.Toggle className='btn btn-sm btn-secondary'>
                            <span className='fa fa-ellipsis-h me-3'></span>
                            {intl.formatMessage({ id: 'deliveryNotes.actions' })}
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Item className='p-4' onClick={() => onEdit(deliveryNote)}>
                              <span className='fa fa-edit me-3'></span>
                              {intl.formatMessage({ id: 'deliveryNotes.edit' })}
                            </Dropdown.Item>
                            <Dropdown.Item className='p-4' onClick={() => onGenerateInvoice(deliveryNote)}>
                              <span className='fa fa-file-invoice me-3'></span>
                              {intl.formatMessage({ id: 'b2bOrders.generateInvoice' })}
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            {
              count > 0 && <Pagination
                className='mx-auto mt-4 d-flex justify-content-center'
                count={Math.ceil(count / DELIVERY_NOTES_PER_PAGE)}
                page={page}
                onChange={(_, page) => onPageChange(page)}
              />

            }
          </div>
        )}
      </div>
    </div>
  )
}
