import {RootState} from '../../../../setup'

export const pendingTaskSelector = (state: RootState) => state.task.pendingTask
export const pendingTaskFiltersSelector = (state: RootState) => state.task.pendingTaskFilters
export const pendingTasksSelector = (state: RootState) => state.task.pendingTasks
export const pendingTaskStatusSelector = (state: RootState) => state.task.pendingTaskStatus
export const pendingTasksStatusSelector = (state: RootState) => state.task.pendingTasksStatus
export const pendingTaskErrorSelector = (state: RootState) => state.task.pendingTaskError
export const selectedTaskSelector = (state: RootState) => state.task.selectedTask
export const selectedTaskStatusSelector = (state: RootState) => state.task.selectedTaskStatus
export const selectedTaskErrorSelector = (state: RootState) => state.task.selectedTaskError
export const groupsSelector = (state: RootState) => state.task.groups
export const staffUsersSelector = (state: RootState) => state.task.staffUsers
export const notReachableExpiredTasksSelector = (state: RootState) =>
  state.task.notReachableExpiredTasks.results
export const alarmTasksSelector = (state: RootState) => state.task.alarmTasks.results || []
export const alarmTasksStatusSelector = (state: RootState) => state.task.alarmTasksStatus
export const alarmTasksCountSelector = (state: RootState) => state.task.alarmTasks.count || 0
export const alarmTasksPageSelector = (state: RootState) => state.task.alarmTasks.page || 0
export const possibleTasksSelector = (state: RootState) => state.task.possibleTasks.results
export const pendingCustomerTasksSelector = (state: RootState) =>
  state.task.pendingCustomerTasks.results
export const possibleTasksStatusSelector = (state: RootState) => state.task.possibleTasksStatus
export const pendingCustomerTasksStatusSelector = (state: RootState) =>
  state.task.pendingCustomerTasksStatus
export const notReachableExpiredTasksStatusSelector = (state: RootState) =>
  state.task.notReachableExpiredTasksStatus
export const pendingCustomerCountSelector = (state: RootState) =>
  state.task.pendingCustomerTasks.count || 0
export const possibleCountSelector = (state: RootState) => state.task.possibleTasks.count || 0
export const notReachableCountSelector = (state: RootState) =>
  state.task.notReachableExpiredTasks.count || 0
export const pendingCustomerPageSelector = (state: RootState) =>
  state.task.pendingCustomerTasks.page || 0
export const possiblePageSelector = (state: RootState) => state.task.possibleTasks.page || 0
export const notReachablePageSelector = (state: RootState) =>
  state.task.notReachableExpiredTasks.page || 0
export const pendingsPerAgentSelector = (state: RootState) => state.task.pendingsPerAgent || []
export const pendingsPerAgentStatusSelector = (state: RootState) =>
  state.task.pendingsPerAgentStatus || 'loading'
export const pendingAgentTasksSliceSelector = (state: RootState) => state.task.pendingAgentTasks
export const pendingAgentTasksSelector = (state: RootState) => state.task.pendingAgentTasks.results
export const pendingAgentTasksStatusSelector = (state: RootState) =>
  state.task.pendingAgentTasksStatus
export const pendingAgentCountSelector = (state: RootState) =>
  state.task.pendingAgentTasks.count || 0
export const pendingAgentPageSelector = (state: RootState) => state.task.pendingAgentTasks.page || 0
export const pendingAgentTaskProfileSelector = (state: RootState) =>
  state.task.pendingAgentTasks.taskProfile || null
export const pendingAgentTaskResolutionSelector = (state: RootState) =>
  state.task.pendingAgentTasks.taskResolution || null
export const pendingAgentCurrentAttemptNumberSelector = (state: RootState) => state.task.pendingAgentTasks.currentAttemptNumber
export const pendingAgentOrderSelector = (state: RootState) => state.task.pendingAgentTasks.order || '-created_at'
export const pendingAgentSinceSelector = (state: RootState) =>
  state.task.pendingAgentTasks.since || null
export const pendingAgentUntilSelector = (state: RootState) =>
  state.task.pendingAgentTasks.until || null
export const recoveryTasksSelector = (state: RootState) => state.task.recoveryTasks.results || []
export const recoveryTasksStatusSelector = (state: RootState) => state.task.recoveyTasksStatus
export const recoveryCountSelector = (state: RootState) => state.task.recoveryTasks.count || 0
export const recoveryPageSelector = (state: RootState) => state.task.recoveryTasks.page || 0
export const unknownTasksPageSelector = (state: RootState) => state.task.unknownTasks.page || 0
export const unknownTasksCountSelector = (state: RootState) => state.task.unknownTasks.count || 0
export const unknownTasksStatusSelector = (state: RootState) => state.task.unknownTasksStatus
export const unknownTasksSelector = (state: RootState) => state.task.unknownTasks.results || []
export const newLeadTasksSelector = (state: RootState) => state.task.newLeadTasks.results || []
export const newLeadTasksStatusSelector = (state: RootState) => state.task.newLeadTasksStatus
export const newLeadTasksPageSelector = (state: RootState) => state.task.newLeadTasks.page || 0
export const newLeadTasksCountSelector = (state: RootState) => state.task.newLeadTasks.count || 0
export const noRecipesTasksSelector = (state: RootState) => state.task.noRecipesTasks.results || []
export const noRecipesTasksStatusSelector = (state: RootState) => state.task.noRecipesTasksStatus
export const noRecipesTasksPageSelector = (state: RootState) => state.task.noRecipesTasks.page || 0
export const noRecipesTasksCountSelector = (state: RootState) =>
  state.task.noRecipesTasks.count || 0
export const leadCallRequestTasksSelector = (state: RootState) =>
  state.task.leadCallRequestTasks.results || []
export const leadCallRequestTasksStatusSelector = (state: RootState) =>
  state.task.leadCallRequestTasksStatus
export const leadCallRequestTasksPageSelector = (state: RootState) =>
  state.task.leadCallRequestTasks.page || 0
export const leadCallRequestTasksCountSelector = (state: RootState) =>
  state.task.leadCallRequestTasks.count || 0
export const noAnswerTasksCountSelector = (state: RootState) => state.task.noAnswerTasks.count || 0
export const noAnswerTasksPageSelector = (state: RootState) => state.task.noAnswerTasks.page || 0
export const noAnswerTasksStatusSelector = (state: RootState) => state.task.noAnswerTasksStatus
export const noAnswerTasksSelector = (state: RootState) => state.task.noAnswerTasks.results || []
export const clientTasksSelector = (state: RootState) => state.task.clientTasks.results || []
export const clientTasksStatusSelector = (state: RootState) => state.task.clientTasksStatus
export const clientTasksPageSelector = (state: RootState) => state.task.clientTasks.page || 0
export const clientTasksCountSelector = (state: RootState) => state.task.clientTasks.count || 0
