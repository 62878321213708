import { DeliveryChangeModel } from '../../delivery-changes/models/DeliveryChangeModel'
import { Coupon } from './Coupon'
import { GroupedMealLine } from './GroupedMealLine'
import { Line } from './Line'
import { NonMealLine } from './NonMealLine'

export enum OrderStateEnum {
  PROPOSAL = 'proposal',
  CONFIRMED = 'confirmed',
  PAUSED = 'paused',
  LOCKED = 'locked',
  PROCESSING = 'processing',
  COMPLETED = 'completed',
  CANCELLED = 'cancelled',
  DISCARDED = 'discarded',
  PAYMENT_HOLD = 'payment-hold',
  REACTIVATING = 'reactivating',
  SHIPPED = 'shipped',
  RETURNED = 'returned',
  UNDELIVERED = 'undelivered',
  READY_TO_SHIP = 'ready-to-ship',
  MERGED = 'merged',
  WITH_INCIDENCE = 'with-incidence',
  PICKING = 'picking',
}

export const WARNING_STATES = [
  OrderStateEnum.PROPOSAL,
]

export const DEFAULT_STATES = [
  OrderStateEnum.CONFIRMED,
]

export const WIP_STATES = [
  OrderStateEnum.LOCKED,
  OrderStateEnum.PROCESSING,
  OrderStateEnum.PICKING,
  OrderStateEnum.READY_TO_SHIP,
  OrderStateEnum.SHIPPED,
]

export const SUCCESSFUL_STATES = [
  OrderStateEnum.COMPLETED,
  OrderStateEnum.MERGED,
]

export const ERROR_STATES = [
  OrderStateEnum.CANCELLED,
  OrderStateEnum.DISCARDED,
  OrderStateEnum.PAYMENT_HOLD,
  OrderStateEnum.REACTIVATING,
  OrderStateEnum.RETURNED,
  OrderStateEnum.UNDELIVERED,
  OrderStateEnum.WITH_INCIDENCE,
  OrderStateEnum.PAUSED,
]

export const STATES_WITH_INVOICE = [
  OrderStateEnum.LOCKED,
  OrderStateEnum.PROCESSING,
  OrderStateEnum.COMPLETED,
  OrderStateEnum.SHIPPED,
  OrderStateEnum.READY_TO_SHIP,
  OrderStateEnum.MERGED,
  OrderStateEnum.WITH_INCIDENCE,
  OrderStateEnum.PICKING,
]

export interface OrderModel {
  id: number
  coupons: Coupon[]
  user_cardinality: number
  plan_cardinality: number
  state: OrderStateEnum
  promise_date: string
  charge_date: string
  code: string
  kind: string
  created_at: Date
  total_price_exc_tax: number
  total_price_inc_tax: number
  total_price_tax: number
  products_exc_tax: number
  products_inc_tax: number
  products_tax: number
  discounts_exc_tax: number
  discounts_inc_tax: number
  discounts_tax: number
  net_shipping_inc_tax: number
  net_shipping_exc_tax: number
  net_shipping_tax: number
  shipping_description: string
  lines: Line[]
  meal_price_per_day: number
  meal_price_per_day_without_discounts: number
  is_trial: boolean
  days: number
  grouped_meal_lines: GroupedMealLine[]
  non_meal_lines: NonMealLine[]
  pet_id: number
  gross_margin_ratio: number
  tracking_links: string[]
  label_links: string[]
  is_modifyable?: boolean
  can_be_edited_in_non_editable_states?: boolean
  shipping_address?: {
    id?: number
    city?: string
    comments?: string
    country?: string
    name?: string
    phone?: string
    postcode?: string
    region?: string
    street?: string
  }
  user_id?: number
  delivery_changes?: Array<DeliveryChangeModel>
  is_merged: boolean
  is_merged_parent: boolean
  parent_sale_order?: OrderModel
  shipping_service: number
  invoice_url?: string
}
