import { SUCCESSFUL_STATES, WIP_STATES } from "..";
import superUsers from "../../tasks/constants/superUsers";

export default function isEditable({order, authUser}) {
    if (
      order?.can_be_edited_in_non_editable_states ||
      superUsers.includes(authUser?.email)
    ) {
      return true;
    }

    if (
      WIP_STATES.includes(order?.state) ||
      SUCCESSFUL_STATES.includes(order?.state)
    ) { 
      return false;
    }
    
    return true;
}