import {useIntl} from 'react-intl'
import {PetModel} from '../models/PetModel'
import {useDispatch, useSelector} from 'react-redux'

import {selectedPetSelector, petStatusSelector, actions} from '../redux/PetRedux'
import {useEffect} from 'react'
import {toast} from 'react-toastify'

export default function PetDocuments() {
  const selectedPet: PetModel | undefined = useSelector(selectedPetSelector)
  const status: string = useSelector(petStatusSelector)
  const intl = useIntl()
  const dispatch = useDispatch()

  useEffect(() => {
    switch (status) {
      case 'success_upload_documents':
        toast.success(intl.formatMessage({id: 'petProfile.uploadDocumentSuccess'}))
        break
      case 'error_upload_documents':
        toast.error(intl.formatMessage({id: 'petProfile.uploadDocumentError'}))
        break
      case 'success_remove_document':
        toast.success(intl.formatMessage({id: 'petProfile.removeDocumentSuccess'}))
        break
      case 'error_remove_document':
        toast.error(intl.formatMessage({id: 'petProfile.removeDocumentError'}))
        break
      default:
        break
    }
  }, [status])

  function onFilesSelected(event: any) {
    const files = Array.from(event.target.files)

    if (files.length > 0) {
      dispatch(actions.uploadPetDocuments(selectedPet?.id, files as Array<File>))
    }
  }

  function onRemove(fileId) {
    const hasConfirmed = window.confirm(
      intl.formatMessage({id: 'petProfile.confirmDeleteDocument'})
    )
    if (hasConfirmed) {
      dispatch(actions.removePetDocument(selectedPet?.id, fileId))
    }
  }

  return (
    <section className='card p-10'>
      <h1 className='mb-4'>{intl.formatMessage({id: 'petProfile.fileUploads'})}</h1>
      <div className='d-flex align-items-center gap-10'>
        {!selectedPet?.documents?.length ? (
          <span className='text-muted'>{intl.formatMessage({id: 'petProfile.noFileUploads'})}</span>
        ) : (
          <div
            style={{
              marginTop: '1rem',
              width: '100%',
              display: 'grid',
              gridTemplateColumns: 'repeat(3, minmax(140px, 1fr))',
              gap: '1rem',
            }}
          >
            {selectedPet?.documents?.map((file) => (
              <a target='_blank' rel='noreferrer' href={file.url}>
                <div
                  className=' w-100 d-flex align-items-center justify-content-center bg-light-primary text-center mb-2 rounded'
                  style={{
                    height: '120px',
                  }}
                >
                  <span className='fs-1 text-primary fa fa-file' />
                </div>
                <div className='w-100'>
                  <p className='mb-1 text-dark fw-bolder fs-8'>{file.name}</p>
                  <p className='mb-1 text-muted fs-9'>
                    {new Date(file.date)?.toLocaleString('es-ES', {
                      day: '2-digit',
                      month: '2-digit',
                      year: 'numeric',
                      hour: '2-digit',
                      minute: '2-digit',
                    })}
                  </p>
                  <button
                    onClick={(e) => {
                      e.preventDefault()
                      e.stopPropagation()
                      onRemove(file.id)
                    }}
                    className='btn btn-sm btn-tertiary p-0 fs-8'
                  >
                    <span className='fa fa-trash me-2' />
                    {intl.formatMessage({id: 'petProfile.deleteDocument'})}
                  </button>
                </div>
              </a>
            ))}
          </div>
        )}
      </div>
      <input
        disabled={status === 'loading_upload_documents'}
        id='petFileUpload'
        type='file'
        className='d-none'
        multiple={true}
        onChange={onFilesSelected}
      />
      <label
        aria-disabled={status === 'loading_upload_documents'}
        htmlFor='petFileUpload'
        className='btn btn-primary mt-5 ms-auto'
      >
        {status === 'loading_upload_documents' ? (
          <span className='spinner-border spinner-border-sm me-2' />
        ) : (
          <span className='fa fa-upload me-2' />
        )}
        {intl.formatMessage({id: 'petProfile.uploadFile'})}
      </label>
    </section>
  )
}
