import {PetModel} from '../models/PetModel'
import {PetKindModel} from '../models/PetKindModel'
import {MealPreferenceModel} from '../models/MealPreferenceModel'

import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL
export const PETS_URL = `${API_URL}/api/pets/`
export const MEAL_PREFERENCES_URL = `${API_URL}/api/meal-preferences/`

export function getPetById(id: number): Promise<{data: PetModel}> {
  return axios.get(PETS_URL + `${id}/`)
}

export function listPets(userId: number) {
  return axios.get(PETS_URL + '?u=' + encodeURIComponent(userId))
}

export function listMealPreferences(petId: number): Promise<{data: Array<MealPreferenceModel>}> {
  return axios.get(MEAL_PREFERENCES_URL + '?pe=' + encodeURIComponent(petId))
}

interface UpdateProps {
  id: number
  props: any
}
export async function updatePet({id, props}: UpdateProps): Promise<{data: PetModel}> {
  return axios.patch(PETS_URL + `${id}/`, props)
}

interface UploadPetDocumentsProps {
  id: number
  documents: Array<File>
}
export async function uploadPetDocuments({
  id,
  documents,
}: UploadPetDocumentsProps): Promise<{data: PetModel}> {
  const formData = new FormData()

  documents.forEach((document) => {
    formData.append('documents', document)
  })

  return axios.post(PETS_URL + `${id}/add-documents/`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}

interface RemovePetDocumentsProps {
  petId: number
  documentId: string
}
export async function removePetDocument({
  petId,
  documentId,
}: RemovePetDocumentsProps): Promise<{data: PetModel}> {
  const body = {
    document_id: documentId,
  }
  return axios.patch(PETS_URL + `${petId}/delete-document/`, body)
}

export async function deletePet(id, destructive = false) {
  if (destructive) {
    return axios.delete(PETS_URL + `${id}/`)
  }
  return axios.patch(PETS_URL + `${id}/`, {is_active: false})
}

export async function setAsPriorityPet(id) {
  return axios.patch(PETS_URL + `${id}/set-as-priority/`)
}

export async function recalculatePlans(id) {
  return axios.patch(PETS_URL + `${id}/recalculate-plans/`)
}

export async function createNewPlan(id) {
  return axios.patch(PETS_URL + `${id}/create-new-plan/`)
}

type UpdateMealPreferencesProps = {
  mealPreferenceId: number
  preference: 'add' | 'auto' | 'discard'
  weight: number
}

export async function updateMealPreference({
  mealPreferenceId,
  preference,
  weight,
}: UpdateMealPreferencesProps): Promise<{data: MealPreferenceModel}> {
  return axios.patch(MEAL_PREFERENCES_URL + `${mealPreferenceId}/`, {preference, weight})
}

export function listPetKinds(): Promise<{data: Array<PetKindModel>}> {
  const endpoint = `${API_URL}/api/pet-kinds/`

  return axios.get(endpoint)
}

export function listActivityLevels({pet_kind = 1}) {
  const endpoint = `${API_URL}/api/activity-levels/?pet_kind=${encodeURIComponent(pet_kind)}`

  return axios.get(endpoint)
}

export function listAllergies({pet_kind = 1}) {
  const endpoint = `${API_URL}/api/allergies/?pet_kind=${encodeURIComponent(pet_kind)}`

  return axios.get(endpoint)
}

export function listBodyConditions({pet_kind = 1}) {
  const endpoint = `${API_URL}/api/body-conditions/?pet_kind=${encodeURIComponent(pet_kind)}`

  return axios.get(endpoint)
}

export function listBreeds({pet_kind = 1}) {
  const endpoint = `${API_URL}/api/breeds/?pet_kind=${encodeURIComponent(pet_kind)}`

  return axios.get(endpoint)
}

export function listCurrentFoodTypes({pet_kind = 1}) {
  const endpoint = `${API_URL}/api/current-food-types/?pet_kind=${encodeURIComponent(pet_kind)}`

  return axios.get(endpoint)
}

export function listHealthIssues({pet_kind = 1}) {
  const endpoint = `${API_URL}/api/health-issues/?pet_kind=${encodeURIComponent(pet_kind)}`

  return axios.get(endpoint)
}

export function setPetAvatar({petId = 0, file = ''}) {
  const endpoint = `${PETS_URL}${encodeURIComponent(petId)}/set-avatar/`
  const body = new FormData()
  body.append('file', file, 'avatar.jpg')

  return axios.post(endpoint, body, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}
