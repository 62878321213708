import {useIntl} from 'react-intl'
import {useSelector} from 'react-redux'
import {orderStatusSelector, selectedOrderSelector} from '../redux/OrderRedux'

export default function OrderDetail() {
  const intl = useIntl()
  const selectedOrder = useSelector(selectedOrderSelector)
  const status = useSelector(orderStatusSelector)

  return (
    <>
      <section className='card p-10'>
        <h1>{intl.formatMessage({id: 'orderDetail.prices'})}</h1>
        {status === 'loading_selected_order' && (
          <div className='spinner-border text-primary mt-8' role='status' />
        )}
        {status === 'idle' && (
          <div className='table-responsive'>
            <table className='table table-hover table-row-dashed table-row-gray-200 align-middle gs-0 gy-4'>
              <thead className='table-header'>
                <tr className='fw-bolder'>
                  <th></th>
                  <th className='text-end'>{intl.formatMessage({id: 'orderDetail.withoutTax'})}</th>
                  <th className='text-end'>{intl.formatMessage({id: 'orderDetail.appliedTax'})}</th>
                  <th className='text-end'>{intl.formatMessage({id: 'orderDetail.withTax'})}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{intl.formatMessage({id: 'orderDetail.products'})}</td>
                  <td className='text-end'>
                    {intl.formatNumber(selectedOrder?.products_exc_tax, {
                      style: 'currency',
                      currency: 'EUR',
                    })}
                  </td>
                  <td className='text-end'>
                    {intl.formatNumber(selectedOrder?.products_tax, {
                      style: 'currency',
                      currency: 'EUR',
                    })}
                  </td>
                  <td className='text-end'>
                    {intl.formatNumber(selectedOrder?.products_inc_tax, {
                      style: 'currency',
                      currency: 'EUR',
                    })}
                  </td>
                </tr>
                <tr>
                  <td>{intl.formatMessage({id: 'orderDetail.shippingCosts'})}</td>
                  <td className='text-end'>
                    {intl.formatNumber(selectedOrder?.net_shipping_exc_tax, {
                      style: 'currency',
                      currency: 'EUR',
                    })}
                  </td>
                  <td className='text-end'>
                    {intl.formatNumber(selectedOrder?.net_shipping_tax, {
                      style: 'currency',
                      currency: 'EUR',
                    })}
                  </td>
                  <td className='text-end'>
                    {intl.formatNumber(selectedOrder?.net_shipping_inc_tax, {
                      style: 'currency',
                      currency: 'EUR',
                    })}
                  </td>
                </tr>
                <tr>
                  <td>
                    {intl.formatMessage({id: 'orderDetail.discounts'})}
                    {selectedOrder?.coupons?.length > 0 && (
                      <div className='text-muted'>
                        {selectedOrder?.coupons
                          ?.map((coupon) => coupon?.discount?.code)
                          ?.join(', ')}
                      </div>
                    )}
                  </td>
                  <td className='text-end'>
                    {intl.formatNumber(selectedOrder?.discounts_exc_tax, {
                      style: 'currency',
                      currency: 'EUR',
                    })}
                  </td>
                  <td className='text-end'>
                    {intl.formatNumber(selectedOrder?.discounts_tax, {
                      style: 'currency',
                      currency: 'EUR',
                    })}
                  </td>
                  <td className='text-end'>
                    {intl.formatNumber(selectedOrder?.discounts_inc_tax, {
                      style: 'currency',
                      currency: 'EUR',
                    })}
                  </td>
                </tr>
                <tr className='fw-bolder fs-6'>
                  <td>{intl.formatMessage({id: 'orderDetail.total'})}</td>
                  <td className='text-end'>
                    {intl.formatNumber(selectedOrder?.total_price_exc_tax, {
                      style: 'currency',
                      currency: 'EUR',
                    })}
                  </td>
                  <td className='text-end'>
                    {intl.formatNumber(selectedOrder?.total_price_tax, {
                      style: 'currency',
                      currency: 'EUR',
                    })}
                  </td>
                  <td className='text-end'>
                    {intl.formatNumber(selectedOrder?.total_price_inc_tax, {
                      style: 'currency',
                      currency: 'EUR',
                    })}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        )}
      </section>
    </>
  )
}
