import axios from 'axios'
import {OrderModel} from '../../orders'

const API_URL = process.env.REACT_APP_API_URL

export interface CommentsResponse {
  tasks?: Array<{
    comments?: Array<{
      task_id: number
      comment: string
      created_at: string
      user: {
        id: number
        name: string
        cs_groups: Array<{
          id: number
          name: string
        }>
      }
    }>
    created_at: string
    id: number
    name: string
  }>
  user_notes?: string
  pets_notes?: Array<{
    id: number
    name: string
    notes?: string
  }>
  delivery_changes?: Array<{
    id: number
    orders: Array<OrderModel>
    agent: number
    created_at: string
    reason: string
    observations: string
    done: boolean
  }>
}

export default function listComments(userId): Promise<{data: CommentsResponse}> {
  const endpoint = `${API_URL}/api/users/${userId}/comments/`

  return axios.get(endpoint)
}
