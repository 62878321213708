/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {useIntl} from 'react-intl'

import {usePathProps} from '../../modules/user-profile/hooks/usePathProps'
import {PageTitle} from '../../../_metronic/layout/core'
import {NotReachableTable} from '../../modules/tasks'

const NotReachableComponent: FC = () => {
  return (
    <>
      <NotReachableTable />
    </>
  )
}

const NotReachablePage: FC = () => {
  const intl = useIntl()
  usePathProps()

  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'notReachable.title'})}</PageTitle>
      <NotReachableComponent />
    </>
  )
}

export {NotReachablePage}
