import axios from "axios";
import { Paginated } from "../../../models/Paginated";
import { CallLog } from "../models/CallLog";

export const CALLS_PER_PAGE = 20
const API_URL = process.env.REACT_APP_API_URL
const CALL_LOGS_URL = `${API_URL}/api/crm/call-logs/`

export function listUserCallLogs({userId, page}): Promise<{data:Paginated<CallLog>}> {
    const limit = CALLS_PER_PAGE
    const offset = (page - 1) * limit

    const params: any = {
        customer: userId,
        limit,
        offset
    }

    return axios.get(CALL_LOGS_URL, {params})
}