import clsx from 'clsx'
import { useIntl } from 'react-intl'
import { useEffect, useState } from 'react'

import { toast } from 'react-toastify'
import { Pagination } from '@mui/material'
import { Dropdown } from 'react-bootstrap'
import { ShopifyOrder } from '../models/ShopifyOrder'
import { ShopifyOrderLine } from '../models/ShopifyOrderLine'

type BULK_STATE = 'loading' | 'idle'
const ORDERS_PER_PAGE = 20

type Props = {
  page: number
  count: number
  orders?: Array<ShopifyOrder>
  loading?: boolean
  children?: React.ReactNode
  onPageChange: (page: number) => void
  onRefresh: () => void
  onBulkGeneratePickingSheet?: (orders: Array<number>) => void
  onBulkGenerateWarehouseOperationsSheet?: (orders: Array<number>) => void
  onBulkPrintTags?: (orders: Array<number>) => void
  onBulkSetProcessing?: (orders: Array<number>) => void
  onBulkGenerateProductsNecessitySheet?: (orders: Array<number>) => void
}

export default function OrderList({
  page = 1,
  count = 0,
  orders = null,
  loading = false,
  onPageChange,
  onRefresh,
  children,
  onBulkGeneratePickingSheet,
  onBulkGenerateWarehouseOperationsSheet,
  onBulkPrintTags,
  onBulkSetProcessing,
  onBulkGenerateProductsNecessitySheet,
}: Props) {
  const intl = useIntl()
  const [selectedOrders, setSelectedOrders] = useState([])
  const [bulkState, setBulkState] = useState<BULK_STATE>('idle')
  const hasSelectedAllOrders = selectedOrders?.length === orders?.length
  const selectedCount = hasSelectedAllOrders ? count : selectedOrders.length

  function getName(orderLine: ShopifyOrderLine) {
    let productName = orderLine?.product_raw_name ||
      orderLine?.product_variant?.product?.name ||
      orderLine?.product_variant?.product?.related_meal?.name;
    let traits = orderLine?.product_variant?.traits;

    if (productName?.length && traits?.length) {
      return `${productName} (${traits})`;
    }
    return productName;
  }

  function getSummary(order: ShopifyOrder) {
    return order?.lines
      ?.sort((a, b) =>
        getName(a)?.localeCompare(getName(b))
      )
      .map((line, i) => (
        <li key={i}>
          <strong className='text-center me-1 d-inline-block'>
            {line.quantity}
          </strong>x{' '}
          {getName(line)}
        </li>
      ))
  }

  function getTextSummary(order) {
    return order?.lines
      ?.sort((a, b) =>
        getName(a)?.localeCompare(getName(b))
      )
      ?.map((line) => {
        return `${line.quantity} x ${getName(line)}`
      })
      ?.join('\r\n')
  }

  async function copyToClipboard(text) {
    try {
      if (navigator?.clipboard) {
        navigator.clipboard.writeText(text)
      } else {
        var textField = document.createElement('textarea')
        textField.textContent = text
        document.body.appendChild(textField)
        textField.select()
        document?.execCommand('copy')
        textField.remove()
      }
      toast.success(intl.formatMessage({ id: 'shopifyOrders.copiedToClipboard' }))
    } catch (error) {
      console.warn({ error })
      toast.error(intl.formatMessage({ id: 'shopifyOrders.copyToClipboardError' }))
    }
  }

  useEffect(() => {
    setSelectedOrders([])
  }, [orders])

  async function onPrintTags() {
    const hasConfirmed = window.confirm(
      intl.formatMessage(
        { id: 'shopifyOrders.bulkPrintTagsConfirmation' },
        { count: selectedCount }
      )
    )
    if (!hasConfirmed) {
      return
    }
    setBulkState('loading')
    try {
      if (hasSelectedAllOrders) {
        await onBulkPrintTags([])
      } else {
        await onBulkPrintTags(selectedOrders?.map((order) => order.id))
      }
      toast.success(
        intl.formatMessage(
          { id: 'shopifyOrders.bulkPrintTagsSuccess' },
          { count: selectedCount }
        )
      )
    } catch (error) {
      console.warn({ error })
      toast.error(intl.formatMessage({ id: 'shopifyOrders.bulkPrintTagsError' }))
    } finally {
      setBulkState('idle')
    }
  }

  function onSetProcessing() {
    const hasConfirmed = window.confirm(
      intl.formatMessage(
        { id: 'shopifyOrders.bulkSetProcessingConfirmation' },
        { count: selectedCount }
      )
    )
    if (!hasConfirmed) {
      return
    }
    setBulkState('loading')
    try {
      if (hasSelectedAllOrders) {
        onBulkSetProcessing([])
      } else {
        onBulkSetProcessing(selectedOrders?.map((order) => order.id))
      }
      toast.success(
        intl.formatMessage(
          { id: 'shopifyOrders.bulkSetProcessingSuccess' },
          { count: selectedCount }
        )
      )
    } catch (error) {
      console.warn({ error })
      toast.error(intl.formatMessage({ id: 'shopifyOrders.bulkSetProcessingError' }))
    } finally {
      setBulkState('idle')
    }
  }

  function onGeneratePickingSheet() {
    const hasConfirmed = window.confirm(
      intl.formatMessage(
        { id: 'shopifyOrders.bulkGeneratePickingSheetConfirmation' },
        { count: selectedCount }
      )
    )
    if (!hasConfirmed) {
      return
    }
    setBulkState('loading')
    try {
      if (hasSelectedAllOrders) {
        onBulkGeneratePickingSheet([])
      } else {
        onBulkGeneratePickingSheet(selectedOrders?.map((order) => order.id))
      }
      toast.success(
        intl.formatMessage(
          { id: 'shopifyOrders.bulkGeneratePickingSheetSuccess' },
          { count: selectedCount }
        )
      )
    } catch (error) {
      console.warn({ error })
      toast.error(intl.formatMessage({ id: 'shopifyOrders.bulkGeneratePickingSheetError' }))
    } finally {
      setBulkState('idle')
    }
  }

  function onGenerateWarehouseOperationsSheet() {
    const hasConfirmed = window.confirm(
      intl.formatMessage(
        { id: 'shopifyOrders.bulkGenerateWarehouseOperationsSheetConfirmation' },
        { count: selectedCount }
      )
    )
    if (!hasConfirmed) {
      return
    }
    setBulkState('loading')
    try {
      if (hasSelectedAllOrders) {
        onBulkGenerateWarehouseOperationsSheet([])
      } else {
        onBulkGenerateWarehouseOperationsSheet(selectedOrders?.map((order) => order.id))
      }
      toast.success(
        intl.formatMessage(
          { id: 'shopifyOrders.bulkGenerateWarehouseOperationsSheetSuccess' },
          { count: selectedCount }
        )
      )
    } catch (error) {
      console.warn({ error })
      toast.error(intl.formatMessage({ id: 'shopifyOrders.bulkGenerateWarehouseOperationsSheetError' }))
    } finally {
      setBulkState('idle')
    }
  }

  function onGenerateProductsNecessitySheet() {
    const hasConfirmed = window.confirm(
      intl.formatMessage(
        { id: 'shopifyOrders.bulkGenerateProductsNecessitySheetConfirmation' },
        { count: selectedCount }
      )
    )
    if (!hasConfirmed) {
      return
    }
    setBulkState('loading')
    try {
      if (hasSelectedAllOrders) {
        onBulkGenerateProductsNecessitySheet([])
      } else {
        onBulkGenerateProductsNecessitySheet(selectedOrders?.map((order) => order.id))
      }
      toast.success(
        intl.formatMessage(
          { id: 'shopifyOrders.bulkGenerateProductsNecessitySheetSuccess' },
          { count: selectedCount }
        )
      )
    } catch (error) {
      console.warn({ error })
      toast.error(intl.formatMessage({ id: 'shopifyOrders.bulkGenerateProductsNecessitySheetError' }))
    } finally {
      setBulkState('idle')
    }
  }

  return (
    <>
      <div className='row mb-8'>
        {children}
        {!loading && count > 0 && (
          <div className='text-muted mt-4 mb-0'>
            {intl.formatMessage({ id: 'common.results' }, { count })}
          </div>
        )}
      </div>
      <section className='d-flex px-0 gap-4 align-items-end mb-8'>
        <div className='me-4'>
          <label className={clsx('mb-2', !selectedCount && 'text-muted')}>
            {selectedCount > 0 ? (
              <>
                {intl.formatMessage(
                  { id: 'shopifyOrders.countSelectedOrders' },
                  { count: selectedCount }
                )}
              </>
            ) : (
              <>{intl.formatMessage({ id: 'shopifyOrders.noSelectedOrders' })}</>
            )}
          </label>
          <Dropdown>
            <Dropdown.Toggle variant='primary' disabled={selectedCount < 1}>
              <span className='me-4 fas fa-layer-group' />
              {intl.formatMessage({ id: 'shopifyOrders.bulkActions' })}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item className='py-3 ps-5 fs-6' onClick={onGeneratePickingSheet}>
                <span className='me-4 fas fa-file-alt text-muted' />
                {intl.formatMessage({ id: 'shopifyOrders.generatePickingSheet' })}
              </Dropdown.Item>
              <Dropdown.Item className='py-3 ps-5 fs-6' onClick={onGenerateWarehouseOperationsSheet}>
                <span className='me-4 fas fa-file-alt text-muted' />
                {intl.formatMessage({ id: 'shopifyOrders.generateWarehouseOperationsSheet' })}
              </Dropdown.Item>
              <Dropdown.Item className='py-3 ps-5 fs-6' onClick={onPrintTags}>
                <span className='me-4 fas fa-file-alt text-muted' />
                {intl.formatMessage({ id: 'shopifyOrders.printTags' })}
              </Dropdown.Item>
              <Dropdown.Item className='py-3 ps-5 fs-6' onClick={onSetProcessing}>
                <span className='me-4 fas fa-file-alt text-muted' />
                {intl.formatMessage({ id: 'shopifyOrders.setProcessing' })}
              </Dropdown.Item>
              <Dropdown.Item className='py-3 ps-5 fs-6' onClick={onGenerateProductsNecessitySheet}>
                <span className='me-4 fas fa-file-alt text-muted' />
                {intl.formatMessage({ id: 'shopifyOrders.generateProductsNecessitySheet' })}
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <div>
          <button className='btn btn-primary' onClick={onRefresh}>
            <span className='me-4 fas fa-sync' />
            {intl.formatMessage({ id: 'shopifyOrders.refresh' })}
          </button>
        </div>
      </section>

      {loading && <div className='spinner-border text-primary' role='status' />}
      {!loading && !orders.length && <p>
        {intl.formatMessage({ id: 'shopifyOrders.noOrders' })}
      </p>}
      {!loading && orders.length > 0 && (
        <div className='mt-0 table-responsive'>
          <table className='table table-hover table-row-dashed table-row-gray-200 align-start gs-0 gy-4'>
            <thead className='table-header sticky-top bg-white'>
              <tr className='fw-bolder align-bottom'>
                <th className='text-center ps-6 pe-4'>
                  <input
                    type='checkbox'
                    className='cursor-pointer form-check w-25px h-25px'
                    disabled={bulkState === 'loading'}
                    checked={selectedOrders.length === orders.length}
                    onChange={() => {
                      if (selectedOrders.length === orders.length) {
                        setSelectedOrders([])
                      } else {
                        setSelectedOrders(orders)
                      }
                    }}
                  />
                </th>
                <th className=''>{intl.formatMessage({ id: 'shopifyOrders.order' })}</th>
                <th className=''>{intl.formatMessage({ id: 'shopifyOrders.email' })}</th>
                <th className=''>{intl.formatMessage({ id: 'shopifyOrders.summary' })}</th>
                <th className=''>
                  {intl.formatMessage({ id: 'shopifyOrders.creationDate' })}
                </th>
                <th className=''>
                  {intl.formatMessage({ id: 'shopifyOrders.updateDate' })}
                </th>
                <th className=''>
                  {intl.formatMessage({ id: 'shopifyOrders.promiseDate' })}
                </th>
                <th>
                  {intl.formatMessage({ id: 'shopifyOrders.trackingLink' })}
                </th>
              </tr>
            </thead>
            <tbody>
              {orders.map((order: ShopifyOrder) => (
                <tr key={order.id} className='align-items-center h-60px'>

                  <td className='text-center ps-6 pe-4'>
                    <input
                      type='checkbox'
                      className='cursor-pointer form-check w-25px h-25px'
                      checked={selectedOrders.includes(order)}
                      disabled={bulkState === 'loading'}
                      onChange={() => {
                        if (selectedOrders.includes(order)) {
                          setSelectedOrders(selectedOrders.filter((t) => t.id !== order.id))
                        } else {
                          setSelectedOrders([...selectedOrders, order])
                        }
                      }}
                    />
                  </td>

                  <td className=''>
                    <div className='d-flex flex-column align-items-start'>
                      <span
                        className='d-block fw-bolder text-primary mb-1'
                      >
                        {order?.reference}
                      </span>
                      <div
                        className={clsx(
                          'badge',
                          ['completed'].includes(order?.state || '') && 'badge-success',
                          ['locked'].includes(order?.state || '') && 'badge-dark',
                          ['processing'].includes(order?.state || '') && 'badge-primary',
                          order?.state === 'failed' && 'badge-danger'
                        )}
                      >
                        {order.state}
                      </div>
                    </div>
                  </td>
                  <td className='w-200px text-wrap text-break'>
                    {order?.email}
                  </td>
                  <td className='w-200px'>
                    <ul
                      className='list-unstyled m-0 cursor-pointer'
                      onClick={() => copyToClipboard(getTextSummary(order))}
                    >
                      {getSummary(order)}
                    </ul>
                  </td>

                  <td className=''>
                    {
                      new Date(order?.created_at).toLocaleDateString('es-ES', {
                        day: 'numeric',
                        month: 'short',
                        year: 'numeric',
                      })
                    }
                  </td>
                  <td className=''>
                    {
                      new Date(order?.updated_at).toLocaleDateString('es-ES', {
                        day: 'numeric',
                        month: 'short',
                        year: 'numeric',
                      })
                    }
                  </td>
                  <td className=''>
                    {
                      new Date(order?.promise_date).toLocaleDateString('es-ES', {
                        day: 'numeric',
                        month: 'short',
                        year: 'numeric',
                      })
                    }
                  </td>
                  <td>
                    <div className="w-150px text-start">
                      {
                        order?.tracking_link ?
                          <div className="d-flex flex-column gap-2">
                            <input
                              onClick={(e) => {
                                e.stopPropagation()
                              }}
                              type="text"
                              value={order?.tracking_link}
                              className="form-control form-control-solid fs-8 py-1 px-3 w-100 text-truncate"
                              readOnly
                            />
                            <div className="d-flex gap-2 justify-content-between">
                              <button
                                className="btn btn-primary btn-sm flex-1 w-100 py-2 px-2 d-flex flex-nowrap align-items-center justify-content-center"
                                onClick={(e) => {
                                  e.stopPropagation()
                                  copyToClipboard(order?.tracking_link)
                                }}
                              >
                                <span className="fa fa-copy me-2"></span>
                                {
                                  intl.formatMessage({ id: 'orderTrackingHistory.copy' })
                                }
                              </button>
                              <a
                                onClick={(e) => e.stopPropagation()}
                                className="btn btn-primary btn-sm flex-1 w-100 py-2 px-2 d-flex flex-nowrap align-items-center justify-content-center"
                                href={order?.tracking_link}
                                target="_blank"
                                rel="noreferrer">
                                <span className="fa fa-external-link-alt me-2"></span>
                                {
                                  intl.formatMessage({ id: 'orderTrackingHistory.open' })
                                }
                              </a>
                            </div>
                          </div>
                          : '-'
                      }
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {
            count > 0 && (
              <>
                <Pagination
                  className='mx-auto mt-4 d-flex justify-content-center'
                  count={Math.ceil(count / ORDERS_PER_PAGE)}
                  page={page}
                  onChange={(_, page) => onPageChange(page)}
                />
              </>
            )
          }
        </div>
      )}
    </>
  )
}
