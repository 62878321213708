/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useContext} from 'react'
import {Link} from 'react-router-dom'
import clsx from 'clsx'
import {useLayout} from '../../core'
import {toAbsoluteUrl} from '../../../helpers'
import {AsideMenu} from './AsideMenu'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {getStoreFlag} from '../../../../_metronic/helpers/'
import {UserModel} from '../../../../app/modules/user-profile/models/UserModel'
import {useIntl} from 'react-intl'
import {currentUserSelector, actions} from '../../../../app/modules/user-profile/redux/UserRedux'
import AircallContext from '../../AircallContext'
import './AsideDefault.css'

export const AsideDefault: FC = () => {
  const {config, classes} = useLayout()
  const {aside} = config
  const currentUser: UserModel | undefined = useSelector(currentUserSelector, shallowEqual)
  const intl = useIntl()
  const dispatch = useDispatch()
  const aircallContext: any = useContext(AircallContext)

  function resetCurrentUser() {
    dispatch(actions.resetUser())
  }

  return (
    <div
      id='kt_aside'
      className={clsx('aside', classes.aside.join(' '))}
      data-kt-drawer='true'
      data-kt-drawer-name='aside'
      data-kt-drawer-activate='{default: true, lg: false}'
      data-kt-drawer-overlay='true'
      data-kt-drawer-width="{default:'200px', '300px': '250px'}"
      data-kt-drawer-direction='start'
      data-kt-drawer-toggle='#kt_aside_mobile_toggle'
    >
      <div
        style={{minHeight: '65px'}}
        className='aside-logo d-flex justify-content-start ps-0 pe-4'
        id='kt_aside_logo'
      >
        {currentUser ? (
          <>
            <Link to='/'>
              <button
                type='button'
                className='btn-close border-none outline-none btn-close-white me-2 py-3 px-4 selected-client-close'
                aria-label='Close'
                onClick={resetCurrentUser}
              ></button>
            </Link>
            <div className='w-100 selected-client'>
              <p className='text-nowrap form-label text-primary mb-0'>
                {intl.formatMessage({id: 'menu.selectedClient'})}
              </p>
              <div className='flex flex-column-fluid text-light'>
                <span className='d-block text-truncate w-100 fs-7'>
                  <img className='mw-10px me-2' src={getStoreFlag(currentUser?.store?.name)} />
                  {currentUser?.email}
                </span>
              </div>
            </div>
          </>
        ) : (
          <div className='d-flex w-100 h-100 text-center justify-content-center align-items-center'>
            {aside.theme === 'dark' && (
              <Link to='/dashboard'>
                <img
                  alt='Logo'
                  className='h-25px logo'
                  src={toAbsoluteUrl('/media/logos/logo-1-dark.svg')}
                />
              </Link>
            )}
            {aside.theme === 'light' && (
              <Link to='/dashboard'>
                <img
                  alt='Logo'
                  className='h-25px logo'
                  src={toAbsoluteUrl('/media/logos/logo-1.svg')}
                />
              </Link>
            )}
          </div>
        )}
      </div>
      <div className='aside-menu flex-column-fluid'>
        <AsideMenu asideMenuCSSClasses={classes.asideMenu} />
      </div>
      <div
        className='aside-footer flex-column-auto py-6 px-5 bottom-0 position-absolute w-100'
        id='kt_aside_footer'
      >
        <button
          onClick={() => {
            aircallContext?.setModalVisible(true)
          }}
          className='btn text-start btn-custom btn-primary w-100 mb-4'
        >
          <span className='fas ms-3 fa-phone-alt text-muted' />
          <span className='btn-label w-100 d-block text-start ms-3'>
            {intl.formatMessage({id: 'app.openPhone'})}
          </span>
        </button>
        <a
          target='_blank'
          className='btn text-start btn-custom btn-primary w-100'
          href={process.env.REACT_APP_FAQS_URL}
        >
          <span className='fas ms-3 fa-question-circle text-muted' />
          <span className='btn-label w-100 d-block text-start ms-3'>FAQs</span>
        </a>
      </div>
    </div>
  )
}
