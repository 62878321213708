import {useEffect, useState} from 'react'
import {Button, Modal} from 'react-bootstrap'
import {useIntl} from 'react-intl'

import {TaskModel} from '../models/TaskModel'
import SPECIAL_RESOLUTION_CODES from '../constants/specialResolutionCodes'
import Select from 'react-select'

type PropsModal = {
  visible: boolean
  tasks: Array<TaskModel>
  onHide: () => void
  onSubmit: (comment: string) => void
}

export const BulkCommentModal: React.FC<PropsModal> = ({
  tasks = [],
  visible = false,
  onHide,
  onSubmit,
}) => {
  const intl = useIntl()
  const [comment, setComment] = useState<string>('')

  useEffect(() => {
    setComment('')
  }, [visible])

  function onFormSubmit(event) {
    event.preventDefault()
    onHide()
    onSubmit(comment)
  }

  return (
    <Modal show={visible} onHide={onHide} aria-labelledby='contained-modal-title-vcenter' centered>
      <form onSubmit={onFormSubmit}>
        <Modal.Header>
          <Modal.Title id='contained-modal-title-vcenter'>
            {intl.formatMessage({id: 'tasks.addComment'})}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='form-group'>
            <textarea
              placeholder={intl.formatMessage({id: 'tasks.bulkCommentPlaceholder'})}
              maxLength={320}
              className='form-control'
              id='comment'
              rows={3}
              value={comment}
              onChange={(e) => setComment(e.target.value)}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='tertiary' onClick={onHide}>
            {intl.formatMessage({id: 'tasks.cancel'})}
          </Button>
          <Button disabled={!comment} variant='primary' className='ms-10' type='submit'>
            {intl.formatMessage({id: 'tasks.bulkCommentCount'}, {count: tasks?.length})}
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  )
}
