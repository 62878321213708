import { useState } from "react"
import { useIntl } from "react-intl"
import { generateReport } from "../services/InfluencersCRUD";
import { toast } from "react-toastify";

export default function InfluencerStats() {
    const intl = useIntl()
    const [code, setCode] = useState<string>('')
    const [loading, setLoading] = useState<boolean>(false)

    async function onGenerateReport() {
        try {
            setLoading(true);
            await generateReport(code);
            toast.success(intl.formatMessage({ id: 'influencerStats.reportGenerated' }))
            setCode('')
        } catch (error) {
            toast.error(intl.formatMessage({ id: 'influencerStats.reportGenerationError' }))
        } finally {
            setLoading(false);
        }
    }

    return (
        <div>
            <h1 className="mb-5">
                { intl.formatMessage({ id: 'influencerStats.title' }) }
            </h1>
            <label className="form-label">
                { intl.formatMessage({ id: 'influencerStats.influencerCode' }) }
            </label>
            <input
                className="form-control mw-300px"
                value={code}
                onChange={(e) => setCode(e.target.value)}
                placeholder={ intl.formatMessage({ id: 'influencerStats.influencerCodePlaceholder' }) }
            />
            <button className="btn btn-primary mt-3" onClick={onGenerateReport} disabled={loading}>
                { intl.formatMessage({ id: 'influencerStats.generate' }) }
            </button>
        </div>
    )
}