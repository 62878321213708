import {useIntl} from 'react-intl'
import {ReactNode, useState} from 'react'
import clsx from 'clsx'
import {toast} from 'react-toastify'
import {Dropdown} from 'react-bootstrap'
import Pagination from '@mui/material/Pagination'
import {generateEdiFile, generatePdfFile, getInvoiceById} from '../services/InvoiceCRUD'
import {InvoiceModel, InvoiceStateEnum} from '../models/InvoiceModel'
import { INVOICES_PER_PAGE } from '../services/InvoiceCRUD'

const NEUTRAL_STATES = [InvoiceStateEnum.PARTIAL, InvoiceStateEnum.PENDING_PAYMENT]
const SUCCESS_STATES = [InvoiceStateEnum.PAID]
const FAIL_STATES = [InvoiceStateEnum.CANCELLED]

const STATE_TRANSITIONS = {
  [InvoiceStateEnum.PENDING_PAYMENT]: [
    InvoiceStateEnum.PAID,
    InvoiceStateEnum.CANCELLED,
    InvoiceStateEnum.PARTIAL,
  ],
  [InvoiceStateEnum.PAID]: [InvoiceStateEnum.PARTIAL],
  [InvoiceStateEnum.CANCELLED]: [InvoiceStateEnum.PENDING_PAYMENT],
  [InvoiceStateEnum.PARTIAL]: [InvoiceStateEnum.PAID],
}

type PropsType = {
  invoices: Array<InvoiceModel>
  loading: boolean
  children: ReactNode
  page?: number
  count?: number
  onUpdateState: (invoice: InvoiceModel, state: InvoiceStateEnum) => void
  onPageChange?: (page: number) => void
  onCreateCreditNote: (invoice: InvoiceModel) => void
}

export default function InvoiceList({invoices, loading, page, count, children, onUpdateState, onPageChange, onCreateCreditNote}: PropsType) {
  const intl = useIntl()
  const urlParams = new URLSearchParams(window.location.search)
  const invoiceId = urlParams.get('invoice')
  const [generatingEdi, setGeneratingEdi] = useState<InvoiceModel | null>(null)
  const [generatingPdf, setGeneratingPdf] = useState<InvoiceModel | null>(null)

  function getInvoices() {
    if (invoiceId) {
      const invoice = invoices.find((invoice) => invoice.id === parseInt(invoiceId))
      if (invoice) {
        return [
          invoice,
          ...invoices
            .filter((i) => i.id !== invoice.id)
            .sort((a, b) => b.number.localeCompare(a.number)),
        ]
      }
    }
    return invoices.sort((a, b) => b.number.localeCompare(a.number))
  }

  function downloadFile(url, fileName) {
    const element = document.createElement('a')
    element.target = '_blank'
    element.href = url
    element.download = fileName
    document.body.appendChild(element)
    element.click()
  }

  async function onGenerateEdi(invoice: InvoiceModel) {
    try {
      setGeneratingEdi(invoice)
      if (invoice.edi_file_url) {
        downloadFile(invoice.edi_file_url, `${invoice.code}.txt`)
      } else {
        await generateEdiFile(invoice.id)
        const {data} = await getInvoiceById(invoice.id)
        if (data.edi_file_url) {
          downloadFile(data.edi_file_url, `${data.code}.txt`)
        } else {
          toast.error(intl.formatMessage({id: 'invoices.ediGenerationError'}))
        }
      }
    } catch (error) {
      toast.error(intl.formatMessage({id: 'invoices.ediGenerationError'}))
      console.warn(error)
    } finally {
      setGeneratingEdi(null)
    }
  }

  async function onGeneratePdf(invoice: InvoiceModel) {
    try {
      setGeneratingPdf(invoice)
      if (invoice.pdf_file_url) {
        downloadFile(invoice.pdf_file_url, `${invoice.code}.pdf`)
      } else {
        await generatePdfFile(invoice.id)
        const {data} = await getInvoiceById(invoice.id)
        if (data.pdf_file_url) {
          downloadFile(data.pdf_file_url, `${data.code}.pdf`)
        } else {
          toast.error(intl.formatMessage({id: 'invoices.pdfGenerationError'}))
        }
      }
    } catch (error) {
      toast.error(intl.formatMessage({id: 'invoices.pdfGenerationError'}))
      console.warn(error)
    } finally {
      setGeneratingPdf(null)
    }
  }

  return (<>
    <div className='card'>
      <div className='card-body'>
        <h2 className='mb-8'>{intl.formatMessage({id: 'invoices.invoiceList'})}</h2>
        <div className='row mb-8'>{children}</div>
        {loading && <div className='spinner-border text-primary my-10' />}
        {!loading && invoices?.length === 0 && (
          <p>{intl.formatMessage({id: 'invoices.noInvoices'})}</p>
        )}
        {!loading && invoices?.length > 0 && (
          <div
            className='table-responsive'
            style={{
              height: 'calc(100vh - 420px)',
            }}
          >
            <p className='text-muted'>
              {intl.formatMessage({id: 'invoices.count'}, {
                count,
                visible: invoices.length
              })}
            </p>
            <table className='table table-hover table-row-dashed table-row-gray-200 align-middle gs-0 gy-4'>
              <thead className='table-header sticky-top bg-white'>
                <tr className='text-left fw-bolder'>
                  <th className='ps-4'>{intl.formatMessage({id: 'invoices.code'})}</th>
                  <th>{intl.formatMessage({id: 'invoices.number'})}</th>
                  <th className=''>{intl.formatMessage({id: 'invoices.order'})}</th>
                  <th className=''>{intl.formatMessage({id: 'invoices.date'})}</th>
                  <th className=''>{intl.formatMessage({id: 'invoices.kind'})}</th>
                  <th className=''>{intl.formatMessage({id: 'invoices.state'})}</th>
                  <th className=''>{intl.formatMessage({id: 'invoices.edi'})}</th>
                  <th className=''>{intl.formatMessage({id: 'invoices.pdf'})}</th>
                  <th className=''>{intl.formatMessage({id: 'invoices.createCreditNote'})}</th>
                </tr>
              </thead>
              <tbody>
                {getInvoices()?.map((invoice) => (
                  <tr
                    className={clsx({
                      'align-middle ': true,
                      'bg-light-success': invoiceId && invoiceId === invoice.id.toString(),
                    })}
                  >
                    <td className='ps-4'>{invoice.code}</td>
                    <td className=''>{invoice.number}</td>
                    <td className=''>{invoice.order}</td>
                    <td className=''>
                      {invoice.date ? new Date(invoice.date).toLocaleDateString() : '-'}
                    </td>
                    <td className=''>
                      {invoice.is_proforma && <span className='badge badge-light'>
                        {intl.formatMessage({id: 'invoices.proforma'})}
                      </span>}
                      {invoice.is_credit && <span className='badge badge-warning text-dark'>
                        {intl.formatMessage({id: 'invoices.credit'})}
                      </span>}
                      {
                        invoice.is_preview && <span className='badge badge-light-primary'>
                          {intl.formatMessage({id: 'invoices.preview'})}
                        </span>
                      }
                      {
                        !invoice.is_proforma && !invoice.is_credit && !invoice.is_preview && <span className='badge badge-primary'>
                          {intl.formatMessage({id: 'invoices.invoice'})}
                        </span>
                      }
                    </td>
                    <td className=''>
                      {!invoice.is_proforma && STATE_TRANSITIONS[invoice.state]?.length > 0 && (
                        <Dropdown>
                          <Dropdown.Toggle
                            id='dropdown-create-b2b-invoice'
                            variant={clsx({
                              primary: NEUTRAL_STATES.includes(invoice.state),
                              success: SUCCESS_STATES.includes(invoice.state),
                              danger: FAIL_STATES.includes(invoice.state),
                            })}
                            className={clsx({
                              'badge badge-sm px-4 py-2': true,
                            })}
                          >
                            {invoice.state}
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            {STATE_TRANSITIONS[invoice.state]?.map((state) => (
                              <Dropdown.Item
                                className='p-4'
                                key={state}
                                onClick={() => onUpdateState(invoice, state)}
                              >
                                {state}
                              </Dropdown.Item>
                            ))}
                          </Dropdown.Menu>
                        </Dropdown>
                      )}
                      { !invoice.is_proforma && !STATE_TRANSITIONS[invoice.state]?.length && (
                        <span
                          className={clsx({
                            'badge badge-sm px-4 py-2': true,
                            'badge-success': SUCCESS_STATES.includes(invoice.state),
                            'badge-danger': FAIL_STATES.includes(invoice.state),
                          })}
                        >
                          {invoice.state}
                        </span>
                      )}
                      { invoice.is_proforma && '-' }
                    </td>
                    <td className=''>
                      <button
                        onClick={() => onGenerateEdi(invoice)}
                        disabled={!!generatingEdi}
                        className={clsx({
                          'btn btn-sm btn-tertiary text-primary': true,
                          'fa fa-download': generatingEdi?.id !== invoice.id,
                        })}
                      >
                        {generatingEdi?.id === invoice.id && (
                          <span className='spinner-border text-primary spinner-border-sm' />
                        )}
                      </button>
                    </td>
                    <td className=''>
                      <button
                        onClick={() => onGeneratePdf(invoice)}
                        disabled={!!generatingPdf}
                        className={clsx({
                          'btn btn-sm btn-tertiary text-primary': true,
                          'fa fa-download': generatingPdf?.id !== invoice.id,
                        })}
                      >
                        {generatingPdf?.id === invoice.id && (
                          <span className='spinner-border text-primary spinner-border-sm' />
                        )}
                      </button>
                    </td>
                    <td className=''>
                      {
                        !invoice.is_credit && !invoice.is_proforma && 
                          <button
                            className='btn btn-sm btn-tertiary text-primary fa fa-file-invoice'
                            onClick={() => onCreateCreditNote(invoice)}/>
                      }
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            {
              count > 0 && (
                <Pagination
                  className='d-flex justify-content-center'
                  count={Math.ceil(count / INVOICES_PER_PAGE)}
                  page={page}
                  onChange={(e, page) => onPageChange && onPageChange(page)}
                />
              )
            }
          </div>
        )}
      </div>
    </div>
  </>)
}
