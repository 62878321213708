/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'

import {useIntl} from 'react-intl'
import {useSelector} from 'react-redux'
import {PageTitle} from '../../../_metronic/layout/core'
import PendingAgentTable from '../../modules/tasks/components/PendingAgentTable'
import {currentUserSelector} from '../../modules/user-profile'
import {usePathProps} from '../../modules/user-profile/hooks/usePathProps'

const TasksComponent: FC = () => {
  return (
    <>
      <div className='row'>
        <div className='col-12'>
          <PendingAgentTable />
        </div>
      </div>
    </>
  )
}

const AgentTasksPage: FC = () => {
  const intl = useIntl()
  usePathProps()
  const currentUser = useSelector(currentUserSelector)

  return (
    <>
      <PageTitle breadcrumbs={[]}>
        {currentUser?.name
          ? intl.formatMessage(
              {id: 'tasks.agentTasks'},
              {agent: currentUser?.name || currentUser?.email || '-'}
            )
          : intl.formatMessage({id: 'tasks.unassignedAgentTasks'})}
      </PageTitle>
      <TasksComponent />
    </>
  )
}

export {AgentTasksPage}
