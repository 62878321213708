import { useEffect, useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { FormattedMessage, useIntl } from 'react-intl'
import Select from 'react-select'
import { useDispatch, useSelector } from 'react-redux'
import { actions, selectedPlanSelector } from '../redux/PlanRedux'
import { listCategories, listProducts } from '../../orders/services/OrderCRUD'

const FR_STORE_ID = 6
const ES_STORE_ID = 1

export default function AddProductModal({ visible, onClose }) {
  const [selectedProduct, setSelectedProduct] = useState(null)
  const [selectedCategory, setSelectedCategory] = useState(null)
  const [selectedVariant, setSelectedVariant] = useState(null)

  const [categories, setCategories] = useState([])
  const [products, setProducts] = useState([])
  const productOptions = products.map((product) => ({
    value: product.id,
    label: product.name,
  }))
  const variantOptions = products?.find((product) => product.id === selectedProduct?.value)?.variants
    ?.map((variant) => ({
      value: variant.id,
      label: variant.name,
    })) || []
  const [units, setUnits] = useState(1)
  const intl = useIntl()
  const dispatch = useDispatch()
  const selectedPlan = useSelector(selectedPlanSelector)

  async function fetchCategories() {
    try {
      const { data: categories } = await listCategories()
      setCategories(
        categories
          .map((category) => ({
            value: category.id,
            label: category.name,
          }))
          .sort((a, b) => a.label.localeCompare(b.label))
      )
      if (categories.length > 0) {
        setSelectedCategory(categories[0])
      }
    } catch (error) {
      console.warn(error)
    }
  }

  async function fetchProducts() {
    if (!selectedCategory?.id) {
      return
    }
    try {
      const { data: products } = await listProducts(selectedCategory?.id)

      setProducts(products)
    } catch (error) {
      console.warn(error)
    }
  }

  useEffect(() => {
    fetchCategories()
  }, [])

  useEffect(() => {
    if (products?.length === 1) {
      setSelectedProduct(products[0])
    }
  }, [products])

  useEffect(() => {
    if (selectedCategory) {
      setSelectedProduct(null)
      fetchProducts()
    }
  }, [selectedCategory])

  useEffect(() => {
    if (selectedProduct) {
      setSelectedVariant(null)
    }
  }, [selectedProduct])

  useEffect(() => {
    setSelectedProduct(null)
    setSelectedVariant(null)
    setUnits(1)
  }, [visible])

  function onConfirm() {
    dispatch(
      actions.setExtraProductsInSelectedPlan([
        ...selectedPlan?.extra_products.map((extraProduct) => ({
          product_variant: extraProduct.product_variant.id,
          units: extraProduct.units,
        })),
        {
          product_variant: selectedVariant.value,
          units,
        },
      ])
    )
    onClose()
  }

  useEffect(() => {
    setSelectedVariant(null)
  }, [selectedProduct])

  useEffect(() => {
    if (variantOptions?.length === 1) {
      setSelectedVariant(variantOptions[0])
    }
  }, [variantOptions])

  return (
    <Modal show={visible} onClose={onClose}>
      <Modal.Header>
        <h4>
          <FormattedMessage id='planDetail.addProductToPlan' />
        </h4>
      </Modal.Header>
      <Modal.Body>
        <div className='form-group mb-6'>
          <label className='fw-bolder mb-2'>
            <FormattedMessage id='planDetail.selectCategory' />
          </label>
          <Select 
            className="react-select-container"
            classNamePrefix="react-select" 
            options={categories}
            value={categories?.find((category) => category.value === selectedCategory?.value)}
            onChange={setSelectedCategory}
            placeholder={intl.formatMessage({ id: 'planDetail.select' })}
          />
        </div>
        {
          selectedCategory && (
            <div className='form-group mb-6'>
              <label className='fw-bolder mb-2'>
                <FormattedMessage id='planDetail.selectProduct' />
              </label>
              <Select 
                className="react-select-container"
                classNamePrefix="react-select" 
                options={productOptions}
                value={productOptions?.find((product) => product.value === selectedProduct?.value)}
                onChange={setSelectedProduct}
                placeholder={intl.formatMessage({ id: 'planDetail.select' })}
              />
            </div>)
        }
        {variantOptions?.length > 1 && <div className='form-group mb-6'>
          <label className='fw-bolder mb-2'>
            <FormattedMessage id='planDetail.selectVariant' />
          </label>
          <Select className="react-select-container"
  classNamePrefix="react-select" 
            options={variantOptions}
            value={variantOptions?.find((variant) => variant.value === selectedVariant?.value)}
            onChange={setSelectedVariant}
            placeholder={intl.formatMessage({ id: 'planDetail.select' })}
          />
        </div>}
        {selectedProduct && (
          <div className='form-group'>
            <label className='fw-bolder mb-2'>
              <FormattedMessage id='planDetail.units' />
            </label>
            <input
              min={1}
              max={1000}
              type='number'
              className='form-control'
              value={units}
              onChange={(e) => setUnits(parseInt(e.target.value, 10 || 0))}
            />
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <div className='d-flex justify-content-between w-100'>
          <Button variant='tertiary' onClick={onClose}>
            <FormattedMessage id='planDetail.close' />
          </Button>
          <Button variant='primary' onClick={onConfirm} disabled={!(selectedProduct && units > 0)}>
            <FormattedMessage id='planDetail.addProduct' />
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  )
}
