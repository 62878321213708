/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'

import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import AgentPerformanceTable from '../../modules/tasks/components/AgentPerformanceTable'
import {usePathProps} from '../../modules/user-profile/hooks/usePathProps'

const AgentPerformancePage: FC = () => {
  const intl = useIntl()
  usePathProps()

  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'tasks.agentPerformance'})}</PageTitle>
      <div className='row'>
        <div className='col-12'>
          <AgentPerformanceTable />
        </div>
      </div>
    </>
  )
}

export {AgentPerformancePage}
