import {Calendar, momentLocalizer} from 'react-big-calendar'
import moment from 'moment'
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop'
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import {pendingTasksSelector, pendingTasksStatusSelector} from '../redux/TaskRedux'
import {useDispatch, useSelector} from 'react-redux'
import {Link} from 'react-router-dom'
import * as taskRedux from '../redux/TaskRedux'
import {TaskSetResolutionModel} from '../models/TaskSetResolutionModel'
import {useEffect, useState} from 'react'
import 'moment/locale/es'
import {useLang} from '../../../../_metronic/i18n/Metronici18n'
import {useIntl} from 'react-intl'
import {solvableStates} from './Task'
import clsx from 'clsx'
import {authUserSelector} from '../../auth'

const DnDCalendar = withDragAndDrop(Calendar)
const DEFAULT_POSTPONE_ID = 177

export default function TaskCalendar() {
  const locale = useLang()
  const intl = useIntl()
  const status = useSelector(pendingTasksStatusSelector)
  const tasks = useSelector(pendingTasksSelector)
  const pendingTask = useSelector(taskRedux.pendingTaskSelector)
  const authUser = useSelector(authUserSelector)
  const dispatch = useDispatch()
  const [events, setEvents] = useState([])
  moment.locale(locale, {
    week: {
      dow: 1,
      doy: 1,
    },
    locale,
  })
  const localizer = momentLocalizer(moment)

  function fetchCurrentWeek() {
    const since = moment().startOf('week').format('YYYY-MM-DD')
    const until = moment().endOf('week').format('YYYY-MM-DD')
    dispatch(taskRedux.actions.retrievePendingTasks(since, until))
  }
  useEffect(() => {
    fetchCurrentWeek()
  }, [])

  useEffect(() => {
    setEvents(
      tasks?.map((task) => ({
        start: moment(task.dispatch_after).toDate(),
        end: moment(task.dispatch_after).add(1, 'hour').toDate(),
        title: (
          <Link
            style={{
              width: '100%',
              height: '100%',
              display: 'block',
              marginTop: '-15px',
              paddingTop: '15px',
              cursor: 'pointer',
            }}
            to={task?.user?.id ? `/user/${task?.user?.id}/task/${task?.id}` : `/task/${task?.id}`}
          >
            {task?.task_profile?.name}: {task?.user ? task?.user?.name || task.user?.email : ''}
          </Link>
        ),
        task,
      })) || []
    )
  }, [tasks])

  function onEventDrop(event) {
    const postponeId =
      pendingTask?.resolutions?.length > 0
        ? pendingTask?.resolutions?.find((resolution) => resolution.code === 'postpone')?.id
        : DEFAULT_POSTPONE_ID
    const dispatchAfter = new Date(event.start).toISOString()
    const eventIndex = events.findIndex((anEvent) => anEvent?.task.id === event.event.task.id)
    const updatedEvent = {
      ...event.event,
      start: moment(dispatchAfter).toDate(),
      end: moment(dispatchAfter).add(1, 'hour').toDate(),
    }

    const updatedEvents = [
      ...events.slice(0, eventIndex),
      updatedEvent,
      ...events.slice(eventIndex + 1),
    ]
    setEvents(updatedEvents)
    dispatch(taskRedux.actions.setPendingTask(event.event.task))
    const taskSetResolution: TaskSetResolutionModel = {
      task_resolution: postponeId,
      dispatch_after: dispatchAfter,
    }
    dispatch(
      taskRedux.actions.resolvePendingTask(taskSetResolution, false, event.event.task.is_alarm)
    )
  }

  function eventPropGetter(event) {
    let backgroundColor = '#3174ad'
    let color = 'white'
    let borderColor = 'rgba(0, 0, 0, 0.2)'
    if (solvableStates.includes(event.task.state)) {
      backgroundColor = '#009ef7'
    }
    if (event.task.is_alarm) {
      borderColor = '#d7483d !important'
    }
    if (['solved', 'cancelled', 'finished', 'expired'].includes(event.task.state)) {
      backgroundColor = '#7e8299'
    }

    var style = {
      backgroundColor,
      borderColor,
      color,
    }
    return {style}
  }

  function onRangeChange(range) {
    if (range.length > 0) {
      const since = moment(range[0]).format('YYYY-MM-DD')
      const until = moment(range[range.length - 1]).format('YYYY-MM-DD')

      dispatch(taskRedux.actions.retrievePendingTasks(since, until))
    }
  }

  return (
    <div className='card'>
      <div className='card-body'>
        <>
          <button className='btn btn-primary mb-8' onClick={fetchCurrentWeek}>
            {status === 'loading' ? (
              <div className='spinner-border spinner-border-sm me-4' role='status' />
            ) : (
              <span className='fas fa-sync me-4' />
            )}
            {intl.formatMessage({id: 'tasks.refresh'})}
          </button>
          <a className='btn btn-primary ms-4 mb-8' href={`/agent-tasks/${authUser?.id}/`}>
            <i className='fas fa-list me-4'></i>
            {intl.formatMessage({id: 'tasks.viewAsList'})}
          </a>
        </>
        <div className='position-relative'>
          <DnDCalendar
            onRangeChange={onRangeChange}
            className={clsx(status === 'loading' ? 'opacity-50' : 'opacity-100')}
            defaultDate={moment().toDate()}
            defaultView='day'
            views={['week', 'day']}
            events={events}
            localizer={localizer}
            onEventDrop={onEventDrop}
            resizable={false}
            style={{height: '100vh'}}
            startsWithMonday={true}
            loading={status === 'loading'}
            eventPropGetter={eventPropGetter}
            culture={['es']}
            messages={{
              next: intl.formatMessage({id: 'calendar.next'}),
              previous: intl.formatMessage({id: 'calendar.previous'}),
              today: intl.formatMessage({id: 'calendar.today'}),
              month: intl.formatMessage({id: 'calendar.month'}),
              week: intl.formatMessage({id: 'calendar.week'}),
              day: intl.formatMessage({id: 'calendar.day'}),
              agenda: intl.formatMessage({id: 'calendar.agenda'}),
            }}
          />
          {status === 'loading' && (
            <div
              style={{
                backgroundColor: 'rgba(255, 255, 255, 0.6)',
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                zIndex: '9999999999 !important',
              }}
              className='z-index-1 d-flex justify-content-center align-items-center'
            >
              <div className='spinner-border spinner-lg text-primary mb-400px' role='status'>
                <span className='sr-only'>Loading...</span>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
