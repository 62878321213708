import axios from 'axios'
import {TaxModel} from '../models/TaxModel'

const API_URL = process.env.REACT_APP_API_URL
const TAXES_URL = `${API_URL}/api/b2b/taxes/`

export function listTaxes(): Promise<{data: Array<TaxModel>}> {
  return axios.get(TAXES_URL)
}

export function retrieveTax(id: number): Promise<{data: TaxModel}> {
  return axios.get(`${TAXES_URL}${id}/`)
}

export function createTax(tax: TaxModel): Promise<{data: TaxModel}> {
  return axios.post(TAXES_URL, tax)
}

export function updateTax(tax: TaxModel): Promise<{data: TaxModel}> {
  return axios.patch(`${TAXES_URL}${tax.id}/`, tax)
}
