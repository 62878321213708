/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { PageTitle } from '../../../_metronic/layout/core'
import {
  LeadDistributorList,
  ModalLeadDistributorForm,
  LeadDistributorModel,
  listLeadDistributors,
  createLeadDistributor,
  updateLeadDistributor,
} from '../../modules/lead-distributors'
import { toast } from 'react-toastify'

enum SORT_OPTIONS {
  ID = 'id',
  NAME = 'name',
}

enum PENDING_REVIEW_OPTIONS {
  ALL = 'all',
  PENDING_REVIEW = 'pendingReview',
  NOT_PENDING_REVIEW = 'noPendingReview',
}

const LeadDistributorsPage: FC = () => {
  const intl = useIntl()
  const [leadDistributors, setLeadDistributors] = useState([])
  const [loading, setLoading] = useState(false)
  const [modalFormVisible, setModalFormVisible] = useState(false)
  const [editingDistributor, setEditingDistributor] = useState<LeadDistributorModel | undefined>(
    undefined
  )
  const [sortBy, setSortBy] = useState(SORT_OPTIONS.ID)
  const [asc, setAsc] = useState(true)
  const sortLabels = {
    [SORT_OPTIONS.ID]: intl.formatMessage({ id: 'distributors.sortById' }),
    [SORT_OPTIONS.NAME]: intl.formatMessage({ id: 'distributors.sortByName' }),
  }
  const [pendingReviewFilter, setPendingReviewFilter] = useState(PENDING_REVIEW_OPTIONS.ALL)

  const fetchDistributors = async () => {
    try {
      setLoading(true)
      const { data } = await listLeadDistributors()
      setLeadDistributors(data)

    } catch (error) {
      console.warn(error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchDistributors()
  }, [])

  function onAddLeadDistributor() {
    setEditingDistributor(undefined)
    setModalFormVisible(true)
  }

  async function onSaveDistributor(distributor: LeadDistributorModel) {
    setModalFormVisible(false)
    setLoading(true)
    try {
      if (distributor.id) {
        const response = await updateLeadDistributor(distributor)
        setLeadDistributors([...leadDistributors.filter((s) => s.id !== distributor.id), response.data])
      } else {
        const response = await createLeadDistributor(distributor)
        setLeadDistributors([...leadDistributors, response.data])
      }
      fetchDistributors()
      toast.success(intl.formatMessage({ id: 'distributors.saved' }))
    } catch (error) {
      if (Object.entries(error?.response?.data)?.length > 0) {
        Object.entries(error?.response?.data).forEach(([key, value]) => {
          toast.error(`${key}: ${value}`)
        })
      } else {
        toast.error(intl.formatMessage({ id: 'distributors.errorSaving' }))
      }

      console.warn(error)
    } finally {
      setLoading(false)
    }
  }

  function getLeadDistributors({sortBy, pendingReviewFilter}) {
    return leadDistributors
      ?.sort((a, b) => {
        if (sortBy === SORT_OPTIONS.NAME) {
          if (asc) {
            return a.name.localeCompare(b.name);
          }
          return b.name.localeCompare(a.name);
        }
        if (asc) {
          return a.id - b.id;
        }
        return b.id - a.id;
      })
      ?.filter((distributor) => {
        if (pendingReviewFilter === PENDING_REVIEW_OPTIONS.PENDING_REVIEW) {
          return distributor.pending_review;
        }
        if (pendingReviewFilter === PENDING_REVIEW_OPTIONS.NOT_PENDING_REVIEW) {
          return !distributor.pending_review;
        }
        return true;
      });
  }

  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'menu.leadDistributors' })}</PageTitle>
      <div className='row'>
        <div className='col-12'>
          <div className='card p-10'>
            {sortBy && <LeadDistributorList
              leadDistributors={getLeadDistributors({sortBy, pendingReviewFilter})}
              loading={loading}
              onAddLeadDistributor={onAddLeadDistributor}
            >
              <div className='mw-200px d-inline-block me-3 mb-4'>
                <label className='form-label' htmlFor='sortBy'>
                  {intl.formatMessage({ id: 'distributors.sortBy' })}
                </label>
                <select
                  id='sortBy'
                  className='form-control form-select'
                  value={sortBy}
                  onChange={(e) => setSortBy(e.target.value as SORT_OPTIONS)}
                >
                  {Object.values(SORT_OPTIONS).map((option) => (
                    <option key={option} value={option}>
                      {sortLabels[option]}
                    </option>
                  ))}
                </select>
              </div>
              <div className='mw-100px d-inline-flex align-items-end me-8'>
                <button
                  className='btn btn-primary text-light'
                  onClick={() => setAsc(!asc)}
                >
                  {
                    sortBy === SORT_OPTIONS.ID &&
                    (asc ?
                      <span className='fas fa-sort-amount-down' />
                      : <span className='fas fa-sort-amount-up' />
                    )
                  }
                  {
                    sortBy === SORT_OPTIONS.NAME &&
                    (asc ?
                      <span className='fas fa-sort-alpha-down' />
                      : <span className='fas fa-sort-alpha-up' />
                    )
                  }
                </button>
              </div>
              <div className='mw-250px d-inline-block me-3 mb-4'>
                <label className='form-label'>
                  {intl.formatMessage({ id: 'distributors.filterByPendingReview' })}
                </label>
                <select
                  className='form-select'
                  value={pendingReviewFilter}
                  onChange={(e) => setPendingReviewFilter(e.target.value as PENDING_REVIEW_OPTIONS)}
                >
                  {Object.values(PENDING_REVIEW_OPTIONS).map((option) => (
                    <option key={option} value={option}>
                      {intl.formatMessage({ id: `distributors.${option}` })}
                    </option>
                  ))}
                </select>
              </div>
            </LeadDistributorList>}
            <ModalLeadDistributorForm
              distributor={editingDistributor}
              visible={modalFormVisible}
              onCancel={() => setModalFormVisible(false)}
              onSaveDistributor={onSaveDistributor}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export { LeadDistributorsPage }
