import { useEffect, useState } from "react"
import { addFile, retrieveDistributor } from "../services/DistributorCRUD"
import { DistributorModel } from "../models/DistributorModel"
import { toast } from "react-toastify"
import { useIntl } from "react-intl"

enum State {
    IDLE = 'IDLE',
    LOADING = 'LOADING',
    UPLOADING = 'UPLOADING',
}
export default function DistributorFiles({id}) {
    const [distributor, setDistributor] = useState<DistributorModel | null>(null)
    const [state, setState] = useState(State.LOADING)
    const intl = useIntl()

    async function fetchDistributor(id) {
      try {
        setState(State.LOADING)
        const {data} = await retrieveDistributor(id)
        setDistributor(data)
      } catch (error) {
        console.warn(error)
      } finally {
        setState(State.IDLE)
      }
    }
    
    async function onAttachFile(event) {
        const file = event.target.files[0]
        if (!file) return
        try {
            setState(State.UPLOADING)
            await addFile({ 
                distributorId: distributor.id,
                file,
            });
            fetchDistributor(id)
        } catch (error) {
            console.warn(error)
            toast.error(intl.formatMessage({id: 'distributorDetail.errorAttaching'}));
        } finally {
            setState(State.IDLE)
        }
    }

    useEffect(() => {
      fetchDistributor(id)
    }, [id])

    return (<>
        <div className='card'>
            <div className="card-body">
                <h1 className="mb-4">
                    {
                        intl.formatMessage({id: 'distributorDetail.attachedFiles'})
                    }
                </h1>
                {
                    state === State.LOADING && <div className="text-center">
                        <div className="spinner-border text-primary"></div>
                    </div>
                }
                {   
                    state === State.IDLE && !distributor?.files?.length && <p>
                        {
                            intl.formatMessage({id: 'distributorDetail.noFiles'})
                        }
                    </p>
                }
                {
                    state === State.IDLE && distributor?.files?.length > 0 && <ul>
                        {
                            distributor.files.map(file => <li key={file.id}>
                                <a href={file.file_url} target='_blank' download={file.file_name} rel='noreferrer'>
                                    {file.file_name}
                                </a>
                            </li>)
                        }
                    </ul>
                }
                <input 
                    id="distributor-file-input" 
                    type="file" 
                    className="d-none"
                    onChange={onAttachFile}
                    hidden/>

                <label htmlFor="distributor-file-input" className="btn btn-primary" aria-disabled={state === State.UPLOADING}>
                    {
                        state === State.UPLOADING ?
                        <span className="spinner-border spinner-border-sm me-2"></span> :
                        <span className="fas fa-icon fa-paperclip me-3"></span>
                    }
                    {
                        intl.formatMessage({id: 'distributorDetail.attachFile'})
                    }
                </label>
            </div>
        </div>
    </>);
}