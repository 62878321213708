import {PetModel} from '../models/PetModel'

const PET_KIND_CAT = 2
const CAT_AVATAR = '/media/svg/avatars/cat-avatar.svg'
const DOG_AVATAR = '/media/svg/avatars/dog-avatar.svg'

export function getPetAvatar(pet: PetModel) {
  if (pet?.processed_avatar_url_cache) {
    return pet?.processed_avatar_url_cache
  }
  if (pet?.pet_kind?.id === PET_KIND_CAT) {
    return CAT_AVATAR
  }
  return DOG_AVATAR
}
