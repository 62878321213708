import {useState, useEffect} from 'react'
import {useIntl} from 'react-intl'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {toast} from 'react-toastify'

import {FormField, GenericForm} from '../../../components/GenericForm'
import {OrderModel, OrderStateEnum} from '../models/OrderModel'
import * as orderRedux from '../redux/OrderRedux'
import {selectedOrderSelector} from '../redux/OrderRedux'
import { authUserSelector } from '../../auth'
import isEditable from '../services/isEditable'

export default function OrderEditableDetail() {
  const intl = useIntl()
  const dispatch = useDispatch()
  const [editing, setEditing] = useState(false)
  const selectedOrder = useSelector(selectedOrderSelector, shallowEqual)
  const status = useSelector(orderRedux.orderStatusSelector, shallowEqual)
  const error = useSelector(orderRedux.orderErrorSelector, shallowEqual)
  const authUser = useSelector(authUserSelector, shallowEqual)
  const fields: Array<FormField<OrderModel>> = [
    {
      id: 'charge_date',
      label: intl.formatMessage({id: 'orderDetail.chargeDate'}),
      inputType: 'text',
      readonly: true,
    },
    {
      id: 'promise_date',
      label: intl.formatMessage({id: 'orderDetail.promiseDate'}),
      inputType: 'date',
      setter(state, value) {
        //  TODO: remove this after moving to new picking center
        if (value === '2024-09-06') {
          return state
        }
        return { ...state, promise_date: value }
      },
    },
    {
      id: 'can_be_edited_in_non_editable_states',
      label: intl.formatMessage({id: 'orderDetail.canBeEditedInNonEditableStates'}),
      inputType: 'checkbox',
    }
  ]

  useEffect(() => {
    switch (status) {
      case 'success_update_selected_order':
        setEditing(false)
        toast.success(intl.formatMessage({id: 'orderDetail.successUpdate'}))
        break
      case 'error_update_selected_order':
        setEditing(true)
        toast.error(intl.formatMessage({id: 'orderDetail.errorUpdate'}))
        break
    }
  }, [status])

  function onSubmit(updatedOrder: OrderModel) {
    dispatch(orderRedux.actions.updateSelectedOrder(updatedOrder))
  }

  return (
    <section className='card p-10'>
      <GenericForm
        editable={
          isEditable({ order: selectedOrder, authUser }) || 
          selectedOrder?.state === OrderStateEnum.LOCKED // Exception for clumsy customers
        }
        editing={editing}
        loading={status === 'loading_selected_order'}
        error={error}
        columns={2}
        title={intl.formatMessage({id: 'orderDetail.orderDates'})}
        fields={fields}
        initialState={selectedOrder}
        ctaLabel={intl.formatMessage({id: 'orderDetail.ctaLabel'})}
        submitting={status === 'loading_update_selected_order'}
        toggleEdit={() => setEditing(!editing)}
        onSubmit={onSubmit}
        submittingLabel={intl.formatMessage({id: 'orderDetail.loading'})}
      />
    </section>
  )
}
