/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {useIntl} from 'react-intl'

import {usePathProps} from '../../modules/user-profile/hooks/usePathProps'
import {PageTitle} from '../../../_metronic/layout/core'
import {UnknownTable} from '../../modules/tasks'

const UnkownTasksComponent: FC = () => {
  return (
    <>
      <UnknownTable />
    </>
  )
}

const UnknownTasksPage: FC = () => {
  const intl = useIntl()
  usePathProps()

  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'app.unknownTasks'})}</PageTitle>
      <UnkownTasksComponent />
    </>
  )
}

export {UnknownTasksPage}
