/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {useIntl} from 'react-intl'

import {usePathProps} from '../../modules/user-profile/hooks/usePathProps'
import {PageTitle} from '../../../_metronic/layout/core'
import {NoRecipesTable} from '../../modules/tasks'

const NoRecipesComponent: FC = () => {
  return (
    <>
      <NoRecipesTable />
    </>
  )
}

const NoRecipesPage: FC = () => {
  const intl = useIntl()
  usePathProps()

  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'noRecipes.title'})}</PageTitle>
      <NoRecipesComponent />
    </>
  )
}

export {NoRecipesPage}
