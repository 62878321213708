/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {useIntl} from 'react-intl'

import {usePathProps} from '../../modules/user-profile/hooks/usePathProps'
import {PageTitle} from '../../../_metronic/layout/core'
import {NoAnswerTable} from '../../modules/tasks'

const NoAnswerComponent: FC = () => {
  return (
    <>
      <NoAnswerTable />
    </>
  )
}

const NoAnswerPage: FC = () => {
  const intl = useIntl()
  usePathProps()

  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'noAnswer.title'})}</PageTitle>
      <NoAnswerComponent />
    </>
  )
}

export {NoAnswerPage}
