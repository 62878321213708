/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {PageTitle} from '../../../_metronic/layout/core'
import {Bankcards, Coupons} from '../../modules/user-profile'
import {usePathProps} from '../../modules/user-profile/hooks/usePathProps'
import {useIntl} from 'react-intl'
import Friends from '../../modules/user-profile/components/Friends'

const WalletComponent: FC = () => {
  return (
    <>
      <div className='row'>
        <div className='col-12 mb-4'>
          <Coupons />
        </div>
        <div className='col-5 mb-4'>
          <Bankcards />
        </div>
        <div className='col-7 mb-4'>
          <Friends />
        </div>
      </div>
    </>
  )
}

const WalletPage: FC = () => {
  usePathProps()
  const intl = useIntl()

  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'menu.wallet'})}</PageTitle>
      <WalletComponent />
    </>
  )
}

export {WalletPage}
