import clsx from 'clsx'
import {FC, useState} from 'react'
import {useIntl} from 'react-intl'
import {getStoreFlag} from '../../../../_metronic/helpers/'
import {ClientModel} from '../models/ClientModel'

type Props = {
  client: ClientModel
  focused: boolean
}

const ClientCard: FC<Props> = (props: Props) => {
  const intl = useIntl()
  const {client, focused} = props
  const storeFlag = getStoreFlag(client.store_name)
  const [hovered, setHovered] = useState(false)
  const cardClasses = clsx(
    'card px-5 py-4 overflow-hidden',
    !(focused || hovered) && 'border border-3 border-light bg-light',
    (focused || hovered) && 'border border-3 border-primary bg-white'
  )

  return (
    <>
      <div
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
        className={cardClasses}
      >
        <div className='fw-bold fs-4 d-flex mb-1'>
          <div className='symbol symbol-20px'>
            <img className='rounded-1 mr-10' src={storeFlag} alt={client?.store_name} />
          </div>
          <span className='mx-2'>{client.email}</span>
          {client.is_partial && (
            <span className='badge p-2 bg-warning mx-2 text-dark'>
              {intl.formatMessage({id: 'clientSearch.partial'})}
            </span>
          )}
        </div>
        <div className='mb-2'>
          <span className='mx-8'>
            {client.name ? (
              <>
                {client.name} {client.last_name}&nbsp;&nbsp;
              </>
            ) : (
              <span className='text-muted'>
                {intl.formatMessage({id: 'clientSearch.unknownName'})}&nbsp;&nbsp;
              </span>
            )}
          </span>

          {client.phone ? (
            <>
              <span className='fas fa-phone-alt text-gray-600 mx-2' />
              {client.phone}
            </>
          ) : null}
        </div>
        <div className='mx-1 mb-2 h-1 scroll-x mw-800px'>
          <span className='fas fa-paw text-gray-600 ml-2 mx-1' />
          {client?.pets ? (
            <>
              {client?.pets?.split(' - ').map((pet, i) => (
                <span key={i} className='mx-1 badge badge-pill bg-gray-600'>
                  {pet}
                </span>
              ))}
            </>
          ) : (
            <span className='mx-1 text-muted'>
              {intl.formatMessage({id: 'clientSearch.noPets'})}
            </span>
          )}
        </div>

        <p className='mx-1 h-1 mb-0 scroll-x mw-800px'>
          <span className='fas fa-box text-gray-600 mx-1' />
          {client?.orders ? (
            <>
              {client?.orders?.split(' - ').map((order, i) => (
                <span key={i} className='mx-1 badge badge-pill bg-gray-600'>
                  {order}
                </span>
              ))}
            </>
          ) : (
            <span className='mx-1 text-muted'>
              {intl.formatMessage({id: 'clientSearch.noOrders'})}
            </span>
          )}
        </p>
      </div>
    </>
  )
}

export {ClientCard}
