import {createContext} from 'react'

type Props = {
  modalVisible: boolean
  setModalVisible: (boolean) => void
  phoneNumber?: string
  setPhoneNumber: (string) => void
}
export default createContext<Props>({
  modalVisible: false,
  setModalVisible: () => {},
  phoneNumber: '',
  setPhoneNumber: () => {},
})
