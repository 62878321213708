import {useIntl} from 'react-intl'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {actions, planStatusSelector} from '../redux/PlanRedux'

import {default as PlanTable} from './PlanTable'
import {currentUserSelector} from '../../user-profile'
import {useEffect} from 'react'

export default function PlanList() {
  const intl = useIntl()
  const status = useSelector(planStatusSelector, shallowEqual)
  const dispatch = useDispatch()
  const currentUser = useSelector(currentUserSelector, shallowEqual)

  useEffect(() => {
    dispatch(actions.listPlans(currentUser?.id))
  }, [dispatch, currentUser?.id])

  return (
    <main className='card p-10'>
      <h1 className='text-2xl font-bold mb-8'>{intl.formatMessage({id: 'plans.title'})}</h1>
      {status === 'loading_list_plans' ? (
        <div className='spinner-border text-primary' role='status' />
      ) : (
        <PlanTable />
      )}
    </main>
  )
}
