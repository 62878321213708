/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'

import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import {usePathProps} from '../../modules/user-profile/hooks/usePathProps'
import {PlanList} from '../../modules/plans'

const PlansComponent: FC = () => {
  return (
    <>
      <div className='row'>
        <div className='col-12'>
          <PlanList />
        </div>
      </div>
    </>
  )
}

const PlansPage: FC = () => {
  const intl = useIntl()
  usePathProps()

  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'menu.plans'})}</PageTitle>
      <PlansComponent />
    </>
  )
}

export {PlansPage}
