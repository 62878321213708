import {Modal} from 'react-bootstrap'
import {OrderModel} from '../models/OrderModel'
import {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {updateOrder} from '../services/OrderCRUD'
import {toast} from 'react-toastify'

interface Props {
  visible: boolean
  order: OrderModel
  onClose: () => void
  onSaved: () => void
}

export default function ModalComments({visible, order, onClose, onSaved}: Props) {
  const intl = useIntl()
  const [comments, setComments] = useState<string>('')

  useEffect(() => {
    if (!order?.comments) {
      setComments('')
    } else {
      setComments(order?.comments)
    }
  }, [order?.comments])

  async function onSave() {
    await updateOrder({
      ...order,
      comments,
    })
    toast.success(intl.formatMessage({id: 'b2bOrders.commentsSaved'}))
    onSaved()
  }

  return (
    <Modal show={visible} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>{intl.formatMessage({id: 'b2bOrders.comments'})}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <textarea
          placeholder={intl.formatMessage({id: 'b2bOrders.commentsPlaceholder'})}
          className='form-control'
          rows={5}
          value={comments}
          onChange={(e) => setComments(e.target.value)}
        />
      </Modal.Body>
      <Modal.Footer>
        <button className='btn btn-text' onClick={onClose}>
          {intl.formatMessage({id: 'b2bOrders.cancel'})}
        </button>
        <button className='btn btn-primary' onClick={onSave}>
          {intl.formatMessage({id: 'b2bOrders.save'})}
        </button>
      </Modal.Footer>
    </Modal>
  )
}
