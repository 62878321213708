/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { toast } from 'react-toastify'
import { useHistory } from 'react-router-dom'
import Select from 'react-select'
import { listShippingServices } from '../../modules/plans'
import { PageTitle } from '../../../_metronic/layout/core'
import {
  OrderList,
  OrderModel,
  OrderStateEnum,
  createOrder,
  listOrders,
  updateOrder,
  SaleableProductModel,
  ModalOrderForm,
  listSaleableProducts,
  ModalOrderLines,
  OrderLineModel,
  createOrderLine,
  removeOrderLine,
  ModalEdiForm,
  BulkActionsEnum,
  performBulkAction,
} from '../../modules/b2b-orders'
import { DistributorModel, listDistributors } from '../../modules/distributors'
import { ModalInvoiceForm, InvoiceKind } from '../../modules/b2b-invoices'
import {
  DeliveryNoteLineRequestModel,
  ModalDeliveryNoteForm,
  bulkInsertDeliveryNoteLines,
  createDeliveryNote,
} from '../../modules/delivery-notes'
import ModalComments from '../../modules/b2b-orders/components/ModalComments'
import COUNTRIES from '../../constants/countries'
import COUNTRY_NAMES from '../../constants/countryNames'
import DateRangeSelector from '../../components/DateRangeSelector'

const DEBOUNCE_TIMEOUT = 800
const ANY_STATE = 'ANY_STATE'
const ANY_SERVICE = 'ANY_SERVICE'
const EMPTY_ORDER = {
  shipping_fee: 0.0,
  total_discounts: 0.0,
  promise_date: null,
  shipping_address: null,
  billing_address: null,
  distributor: null,
}

const B2BOrdersPage: FC = () => {
  const intl = useIntl()
  const history = useHistory()
  const [shippingServices, setShippingServices] = useState([])
  const [visibleCommentsModal, setVisibleCommentsModal] = useState(false)
  const [visibleOrderModal, setVisibleOrderModal] = useState(false)
  const [visibleParseEdiModal, setVisibleParseEdiModal] = useState(false)
  const [visibleLinesModal, setVisibleLinesModal] = useState(false)
  const [visibleInvoiceModal, setVisibleInvoiceModal] = useState(false)
  const [visibleDeliveryNoteModal, setVisibleDeliveryNoteModal] = useState(false)
  const [order, setOrder] = useState<OrderModel | undefined>(undefined)
  const [distributors, setDistributors] = useState([])
  const [products, setProducts] = useState<Array<SaleableProductModel>>([])
  const [orders, setOrders] = useState<Array<OrderModel>>([])
  const [loading, setLoading] = useState(false)
  const [selectedShippingService, setSelectedShippingService] = useState(null)
  const [selectedDistributor, setSelectedDistributor] = useState<DistributorModel | null>(null)
  const [selectedState, setSelectedState] = useState<OrderStateEnum | null>(OrderStateEnum.CONFIRMED)
  const [promiseDateRange, setPromiseDateRange] = useState<{ since: Date; until: Date }>({
    since: new Date(new Date().getTime() + 24 * 60 * 60 * 1000),
    until: new Date(new Date().getTime() + 48 * 60 * 60 * 1000),
  })
  const [code, setCode] = useState<string | null>(null)
  const [country, setCountry] = useState<string | null>(null)
  const [page, setPage] = useState(1)
  const [count, setCount] = useState(0)

  async function fetchOrders(withLoader = true) {
    try {
      if (withLoader) {
        setLoading(true);
      }

      const since = promiseDateRange.since.toLocaleDateString('en-CA', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      });
      const until = promiseDateRange.until.toLocaleDateString('en-CA', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      });
      const { data } = await listOrders({
        state: selectedState,
        distributorId: selectedDistributor?.id,
        shipping_service: selectedShippingService?.id,
        page,
        country,
        code,
        since,
        until,
      });
      setOrders(data?.results);
      setCount(data?.count);
    } catch (error) {
      console.warn(error)
    } finally {
      if (withLoader) {
        setLoading(false)
      }
    }
  }

  async function fetchProducts(distributorId) {
    try {
      setLoading(true)
      const { data } = await listSaleableProducts(distributorId)
      setProducts(data)
    } catch (error) {
      console.warn(error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    const timeout = setTimeout(() => {
      fetchOrders()
    }, DEBOUNCE_TIMEOUT)

    return () => {
      clearTimeout(timeout)
    }
  }, [selectedDistributor, selectedShippingService, selectedState, page, promiseDateRange, code, country])

  useEffect(() => {
    setPage(1)
    setCount(0)
  }, [selectedDistributor, selectedShippingService, selectedState, promiseDateRange, code, country])

  useEffect(() => {
    if (order?.distributor) {
      fetchProducts(order?.distributor)
    }
  }, [order?.distributor])

  const fetchDistributors = async () => {
    try {
      const { data } = await listDistributors('active')
      setDistributors(data)
    } catch (error) {
      console.warn(error)
    }
  }

  useEffect(() => {
    fetchDistributors()
  }, [])

  function onCreateOrder() {
    setOrder(EMPTY_ORDER as OrderModel)
    setVisibleOrderModal(true)
  }

  async function createNewOrder(orderModel: OrderModel) {
    try {
      setLoading(true)
      setSelectedState(OrderStateEnum.PROPOSAL)
      const { data: newlyCreatedOrder } = await createOrder(orderModel)
      setOrder(newlyCreatedOrder)
      toast.success(intl.formatMessage({ id: 'b2bOrders.createSuccess' }), {
        position: toast.POSITION.TOP_RIGHT,
      })
      setVisibleOrderModal(false)
      setVisibleLinesModal(true)
      await fetchOrders()
    } catch (error) {
      toast.error(intl.formatMessage({ id: 'b2bOrders.createError' }))
      console.warn(error)
    } finally {
      setLoading(false)
    }
  }

  function onParseEdi() {
    setVisibleParseEdiModal(true)
  }

  async function updateExistingOrder(orderModel: OrderModel) {
    try {
      await updateOrder(orderModel)
      setVisibleOrderModal(false)
      fetchOrders()
      setOrder(undefined)
      toast.success(intl.formatMessage({ id: 'b2bOrders.updateSuccess' }))
    } catch (error) {
      toast.error(intl.formatMessage({ id: 'b2bOrders.updateError' }))
      console.warn(error)
    }
  }

  function onSaveCreatedOrder(order: OrderModel) {
    if (order.id) {
      updateExistingOrder(order)
    } else {
      createNewOrder(order)
    }
  }

  function onStateChange(event: React.ChangeEvent<HTMLSelectElement>) {
    if (event.target.value === ANY_STATE) {
      setSelectedState(null)
    } else {
      setSelectedState(event.target.value as OrderStateEnum)
    }
  }

  function onEditOrder(order: OrderModel) {
    setOrder(order)
    setVisibleOrderModal(true)
  }

  function onEditLines(order: OrderModel) {
    setOrder(order)
    setVisibleLinesModal(true)
  }

  async function onAddLine(line: OrderLineModel) {
    try {
      await createOrderLine(line)
      fetchOrders()
      toast.success(intl.formatMessage({ id: 'b2bOrders.createLineSuccess' }))
    } catch (error) {
      toast.error(intl.formatMessage({ id: 'b2bOrders.createLineError' }))
      console.warn(error)
    }
  }

  async function onRemoveLine(line: OrderLineModel) {
    const hasConfirmed = window.confirm(
      `${intl.formatMessage({ id: 'b2bOrders.deleteLineConfirmation' })}\r\n${line.units} x ${line.name
      }`
    )
    if (!hasConfirmed) {
      return
    }
    try {
      await removeOrderLine(line)
      fetchOrders()
      toast.success(intl.formatMessage({ id: 'b2bOrders.deleteLineSuccess' }))
    } catch (error) {
      toast.error(intl.formatMessage({ id: 'b2bOrders.deleteLineError' }))
      console.warn(error)
    }
  }

  useEffect(() => {
    if (order?.id) {
      const updatedOrder = orders.find((o) => o.id === order.id)
      if (updatedOrder) {
        setOrder(updatedOrder)
      }
    }
  }, [orders, order?.id])

  function onGenerateProforma(order: OrderModel) {
    setOrder(order)
    setVisibleInvoiceModal(true)
  }

  function onGenerateDeliveryNote(order: OrderModel) {
    setOrder(order)
    setVisibleDeliveryNoteModal(true)
  }

  async function onCreateDeliveryNote(date, deliveryNoteLines: DeliveryNoteLineRequestModel[]) {
    try {
      const { data: deliveryNote } = await createDeliveryNote(order?.id, date)
      if (deliveryNote.id) {
        await bulkInsertDeliveryNoteLines(deliveryNote.id, deliveryNoteLines)
      } else {
        toast.error(intl.formatMessage({ id: 'b2bOrders.createDeliveryNoteError' }))
        return
      }

      setVisibleDeliveryNoteModal(false)
      toast.success(
        intl.formatMessage(
          { id: 'b2bOrders.createDeliveryNoteSuccess' },
          {
            code: deliveryNote.code,
          }
        )
      )
      history.push(`/delivery-notes?deliveryNote=${deliveryNote.id}`)
    } catch (error) {
      toast.error(intl.formatMessage({ id: 'b2bOrders.createDeliveryNoteError' }))
      console.warn(error)
    }
  }

  function onCreatedOrder() {
    setVisibleParseEdiModal(false)
    fetchOrders()
  }

  async function onUpdateState(order: OrderModel, state: OrderStateEnum) {
    try {
      await updateOrder({
        ...order,
        state,
      })
      fetchOrders(false)
      toast.success(intl.formatMessage({ id: 'b2bOrders.updateStateSuccess' }))
    } catch (error) {
      toast.error(intl.formatMessage({ id: 'b2bOrders.updateStateError' }))
      console.warn(error)
    }
  }

  async function onPerformBulkAction(action: BulkActionsEnum, orders: number[]) {
    const since = promiseDateRange.since.toLocaleDateString('en-CA', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    });
    const until = promiseDateRange.until.toLocaleDateString('en-CA', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    });

    return performBulkAction({
      action,
      orders,
      country,
      code,
      state: selectedState,
      shipping_service: selectedShippingService?.id,
      since,
      until,
      distributor: selectedDistributor?.id,
    })
  }

  function onEditComments(order: OrderModel) {
    setOrder(order)
    setVisibleCommentsModal(true)
  }

  async function onSaveComments() {
    setVisibleCommentsModal(false)
    fetchOrders(false)
  }

  async function fetchShippingServices() {
    try {
      const { data } = await listShippingServices()
      setShippingServices(data)
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    fetchShippingServices()
  }, []);

  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'b2bOrders.title' })}</PageTitle>
      <div className='row'>
        <div className='col-12'>
          <OrderList
            displayBulkActions={true}
            orders={orders}
            products={products}
            loading={loading}
            page={page}
            count={count}
            onPageChange={setPage}
            onCreateOrder={onCreateOrder}
            onParseEdi={onParseEdi}
            onEditOrder={onEditOrder}
            onEditLines={onEditLines}
            onGenerateProforma={onGenerateProforma}
            onGenerateDeliveryNote={onGenerateDeliveryNote}
            onUpdateState={onUpdateState}
            onEditComments={onEditComments}
            onBulkGenerateProductsNecessitySheet={(orders) => onPerformBulkAction(BulkActionsEnum.GENERATE_PRODUCTS_NECESSITY_SHEET, orders)}
            onBulkGenerateWarehouseOperationsSheet={(orders) => onPerformBulkAction(BulkActionsEnum.GENERATE_WAREHOUSE_OPERATIONS_SHEET, orders)}
            onBulkPrintTags={(orders) => onPerformBulkAction(BulkActionsEnum.PRINT_TAGS, orders)}
            onBulkPrepareOrders={(orders) => onPerformBulkAction(BulkActionsEnum.PREPARE_ORDERS, orders)}
          >
            <main className='d-flex justify-content-start flex-wrap align-items-end mb-0 gy-0'>
              <div className='form-group w-300px d-inline-block me-4'>
                <label className='fw-bolder mb-1'>
                  {intl.formatMessage({ id: 'b2bOrders.filterByDistributor' })}
                </label>
                <Select
                  className="react-select-container"
                  classNamePrefix="react-select" 
                  placeholder={intl.formatMessage({ id: 'b2bOrders.anyDistributor' })}
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      height: '42px',
                    } as any),
                  }}
                  options={[
                    {
                      value: null,
                      label: intl.formatMessage({ id: 'b2bOrders.anyDistributor' }),
                    },
                    ...distributors.map((distributor) => ({
                      value: distributor.id,
                      label: distributor.name,
                    } as any)),
                  ]}
                  value={selectedDistributor ?
                    {
                      value: selectedDistributor?.id,
                      label: selectedDistributor?.name
                    } : null}
                  onChange={(option: any) => {
                    const distributor = distributors.find((d) => d.id == option.value)

                    setSelectedDistributor(distributor)
                  }}
                />
              </div>
              <div className='form-group w-300px d-inline-block me-4'>
                <label className='fw-bolder mb-1'>
                  {intl.formatMessage({ id: 'b2bOrders.filterByShippingService' })}
                </label>
                <Select 
                  className="react-select-container"
                  classNamePrefix="react-select" 
                  placeholder={intl.formatMessage({ id: 'b2bOrders.anyShippingService' })}
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      height: '42px',
                    } as any),
                  }}
                  options={[
                    {
                      value: ANY_SERVICE,
                      label: intl.formatMessage({ id: 'b2bOrders.anyShippingService' }),
                    },
                    ...shippingServices.map((service) => ({
                      value: service.id,
                      label: service.name,
                    })),
                  ]}
                  value={selectedShippingService? {
                    value: selectedShippingService?.id,
                    label: selectedShippingService?.name
                  } : null}
                  onChange={(option: any) => {
                    const shippingService = shippingServices.find((s) => s.id == option.value)

                    setSelectedShippingService(shippingService)
                  }}
                />
              </div>
              <div className='form-group w-180px d-inline-block me-4'>
                <label className='fw-bolder mb-1'>
                  {intl.formatMessage({ id: 'b2bOrders.filterByState' })}
                </label>
                <select
                  className='form-control form-select'
                  value={selectedState}
                  onChange={onStateChange}
                >
                  <option value={ANY_STATE}>
                    {intl.formatMessage({ id: 'b2bOrders.anyState' })}
                  </option>
                  {Object.values(OrderStateEnum).map((state) => (
                    <option key={state} value={state}>
                      {state}
                    </option>
                  ))}
                </select>
              </div>
              <div className='form-group w-180px d-inline-block me-4'>
                <label className='fw-bolder mb-1'>
                  {intl.formatMessage({ id: 'b2bOrders.filterByCountry' })}
                </label>
                <select
                  className='form-control form-select'
                  value={country}
                  onChange={(e) => setCountry(e.target.value || null)}
                >
                  <option value="">
                    {intl.formatMessage({ id: 'b2bOrders.anyCountry' })}
                  </option>
                  {COUNTRIES.map((country) => (
                    <option key={country} value={country}>
                      {COUNTRY_NAMES[country]}
                    </option>
                  ))}
                </select>
              </div>
              <div className='form-group w-180px d-inline-block me-4'>
                <label className='fw-bolder mb-1'>
                  {intl.formatMessage({ id: 'b2bOrders.filterByCode' })}
                </label>
                <input
                  type='text'
                  className='form-control'
                  placeholder={intl.formatMessage({ id: 'b2bOrders.codePlaceholder' })}
                  value={code || ''}
                  onChange={(e) => setCode(e.target.value)}
                />
              </div>
              <div className='form-group w-180px d-inline-block mt-8'>
                <DateRangeSelector
                  label={intl.formatMessage({ id: 'operationOrders.filterByPromiseDate' })}
                  onSelected={(since, until) => setPromiseDateRange({ since, until })}
                  initialSince={new Date()}
                  initialUntil={new Date()}
                />
              </div>
            </main>
          </OrderList>
        </div >
      </div >
      <ModalOrderForm
        visible={visibleOrderModal}
        onCancel={() => setVisibleOrderModal(false)}
        onSaveOrder={onSaveCreatedOrder}
        order={order}
        distributors={distributors}
      />
      <ModalOrderLines
        visible={visibleLinesModal}
        loading={loading}
        onClose={() => setVisibleLinesModal(false)}
        order={order}
        products={products}
        onAddLine={onAddLine}
        onRemoveLine={onRemoveLine}
      />
      <ModalInvoiceForm
        visible={visibleInvoiceModal}
        onClose={() => setVisibleInvoiceModal(false)}
        order={order}
        invoiceKind={InvoiceKind.Proforma}
      />
      <ModalDeliveryNoteForm
        visible={visibleDeliveryNoteModal}
        onClose={() => setVisibleDeliveryNoteModal(false)}
        onCreateDeliveryNote={onCreateDeliveryNote}
        order={order}
      />
      <ModalEdiForm
        visible={visibleParseEdiModal}
        onClose={() => setVisibleParseEdiModal(false)}
        onCreated={onCreatedOrder}
        distributors={distributors}
      />
      <ModalComments
        visible={visibleCommentsModal}
        onClose={() => setVisibleCommentsModal(false)}
        onSaved={onSaveComments}
        order={order}
      />
    </>
  )
}

export { B2BOrdersPage }
