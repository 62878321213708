import { useState } from "react";
import DateRangeSelector from "../../../components/DateRangeSelector";
import { useIntl } from "react-intl";
import moment from "moment";
import { sendSalesExcel } from "../services/DashboardCRUD";
import { toast } from "react-toastify";

export default function SalesReport() {
    const intl = useIntl()
    const initialSince = new Date(new Date().setDate(new Date().getDate() - new Date().getDay() + 1))
    const initialUntil = new Date(new Date().setDate(new Date().getDate() - new Date().getDay() + 7))
    const [since, setSince] = useState(initialSince)
    const [until, setUntil] = useState(initialUntil)
    const [generatingSalesReport, setGeneratingSalesReport] = useState(false)
    
    async function generateSalesReport() {
        const hasConfirmed = window.confirm(
            intl.formatMessage({ id: 'businessDashboard.confirmSalesReport' }, {
                since: moment(since).format('DD/MM/YYYY'),
                until: moment(until).format('DD/MM/YYYY'),
            }),
        )

        if (hasConfirmed) {
            try {
                setGeneratingSalesReport(true)
                await sendSalesExcel({
                    since: moment(since).format('YYYY-MM-DD'),
                    until: moment(until).format('YYYY-MM-DD'),
                })
                await new Promise((resolve) => setTimeout(resolve, 2000))
                toast.success(intl.formatMessage({ id: 'businessDashboard.salesReportGenerated' }))
            } catch (error) {
                toast.error(intl.formatMessage({ id: 'businessDashboard.salesReportError' }))
                console.warn(error)
            } finally {
                setGeneratingSalesReport(false)
            }
        }
    }

    return <>
        <h1 className="title mb-8">
            {intl.formatMessage({ id: 'businessDashboard.generateSalesReport' })}
        </h1>
        <div className="d-flex gap-4 align-items-end">
            <DateRangeSelector
                initialSince={initialSince}
                initialUntil={initialUntil}
                onSelected={(since, until) => {
                    setSince(since)
                    setUntil(until)
                }}
                disableFutureDates={false}
            />
            <button
                className='btn btn-primary mt-auto'
                disabled={generatingSalesReport}
                onClick={generateSalesReport}
            >
                {
                    generatingSalesReport ?
                        <span className='spinner-border spinner-border-sm me-3'></span>
                        : <span className='fas fa-file-excel me-3'></span>
                }
                {intl.formatMessage({ id: 'businessDashboard.generateSalesReport' })}
            </button>
        </div>
    </>
}