export function getStoreFlag(storeName: string) {
  switch (storeName) {
    case 'www.foodforjoe.fr':
      return '/media/flags/france.svg'
    case 'www.foodforjoe.de':
      return '/media/flags/germany.svg'
    case 'www.foodforjoe.it':
      return '/media/flags/italy.svg'
    case 'www.foodforjoe.pt':
      return '/media/flags/portugal.svg'
    case 'www.foodforjoe.es':
    default:
      return '/media/flags/spain.svg'
  }
}
