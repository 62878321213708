/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'

import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import {PlanCalculator} from '../../modules/plans'

export const PlanCalculatorPage: FC = () => {
  const intl = useIntl()

  return (
    <>
        <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'menu.planCalculator'})}</PageTitle>
        <div className='row'>
            <div className='col-12'>
              <PlanCalculator />
            </div>
        </div>
    </>
  )
}