import {Pagination} from '@mui/material'
import {ChangeEvent, useEffect, useState} from 'react'
import { shallowEqual, useDispatch, useSelector} from 'react-redux'
import {useParams} from 'react-router-dom'

import * as taskRedux from '../redux/TaskRedux'
import TaskTable from './TaskTable'
import { downloadPendingAgentTaskReport } from '../services/TaskCRUD'
import { toast } from 'react-toastify'
import { useIntl } from 'react-intl'

type RouteParams = {
  id: string
}

function PendingAgentTable() {
  const intl = useIntl()
  const dispatch = useDispatch()
  const tasks = useSelector(taskRedux.pendingAgentTasksSelector, shallowEqual)
  const status = useSelector(taskRedux.pendingAgentTasksStatusSelector)
  const page = useSelector(taskRedux.pendingAgentPageSelector)
  const taskProfile = useSelector(taskRedux.pendingAgentTaskProfileSelector)
  const taskResolution = useSelector(taskRedux.pendingAgentTaskResolutionSelector)
  const since = useSelector(taskRedux.pendingAgentSinceSelector)
  const until = useSelector(taskRedux.pendingAgentUntilSelector)
  const count = useSelector(taskRedux.pendingAgentCountSelector)
  const current_attempt_number = useSelector(taskRedux.pendingAgentCurrentAttemptNumberSelector)
  const order = useSelector(taskRedux.pendingAgentOrderSelector)
  const {id: idParam} = useParams<RouteParams>()
  const id = idParam ? parseInt(idParam, 10) : -1
  const [generatingReport, setGeneratingReport] = useState(false)

  useEffect(() => {
    dispatch(
      taskRedux.actions.retrievePendingAgentTasks({
        id: id,
        page: 1,
        taskProfile: null,
        taskResolution: null,
        since: null,
        until: null,
        order: '-created_at',
        current_attempt_number: null,
      })
    )
  }, [])

  function onPageChange(event: ChangeEvent<unknown>, page: number, taskProfile: number, taskResolution: number, since: string, until: string,  current_attempt_number?: number, order?: string) {
    event?.preventDefault()
    dispatch(
      taskRedux.actions.retrievePendingAgentTasks({
        id,
        page,
        taskProfile,
        taskResolution,
        since,
        until,
        order,
        current_attempt_number,
      })
    )
  }

  async function downloadReport(){
    try {
      setGeneratingReport(true)
      const response = await downloadPendingAgentTaskReport({
        agentId: id,
        taskProfile,
        taskResolution,
        since,
        until,
        current_attempt_number,
        order
      })
      // download response as a file
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'report.csv')
      document.body.appendChild(link)
      link.click()
      link.remove()

    } catch (error) {
      console.error(error)
      toast.error(intl.formatMessage({id: 'tasks.downloadReportError'}))
    } finally {
      setGeneratingReport(false)
    }
  }
  return (
    <div className='card w-100'>
      <div className='card-body position-relative'>
        <TaskTable
          tasks={tasks}
          displayOwner={false}
          loading={status === 'loading'}
          onRefresh={() => onPageChange(null, 1, taskProfile, taskResolution, since, until, current_attempt_number, order)}
          onTaskProfileSelected={(taskProfile) =>
            onPageChange(null, 1, taskProfile, taskResolution, since, until, current_attempt_number, order)
          }
          onTaskResolutionSelected={(taskResolution) =>
            onPageChange(null, 1, taskProfile, taskResolution, since, until, current_attempt_number, order)
          }
          onDateRangeSelected={(since, until) =>
            onPageChange(null, 1, taskProfile, taskResolution, since, until, current_attempt_number, order)
          }
          onOrderSelected={(order) =>
            onPageChange(null, 1, taskProfile, taskResolution, since, until, current_attempt_number, order)
          } 
          onCurrentAttemptNumberSelected={(current_attempt_number) =>
            onPageChange(null, 1, taskProfile, taskResolution, since, until, current_attempt_number, order)
          }
        >
         <button onClick={downloadReport} className='btn btn-primary mb-0' disabled={generatingReport}>

            {
              generatingReport ? 
                <>
                  <span className="spinner-border spinner-border-sm me-3" role="status" aria-hidden="true"></span>
                  {intl.formatMessage({id: 'tasks.generatingReport'})}
                </>
                : <>
                  <span className="me-3 fas fa-icon fa-file-alt"/>
                  {intl.formatMessage({id: 'tasks.downloadReport'})}
                </>
            
            }
          </button>
        </TaskTable>
        {count > 0 && (
          <Pagination
            className='mx-auto mt-4 d-flex justify-content-center'
            count={Math.ceil(count / taskRedux.TASKS_PER_PAGE)}
            page={page}
            onChange={(event, page) =>
              onPageChange(event, page, taskProfile, taskResolution, since, until, current_attempt_number, order)
            }
          />
        )}
      </div>
    </div>
  )
}

export default PendingAgentTable
