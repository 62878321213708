import { useSelector } from "react-redux"
import { currentUserSelector } from "../../user-profile"
import { useIntl } from "react-intl"
import { WhatsappLog } from "../models/WhatsappLog"
import { useEffect, useState } from "react"
import { ITEMS_PER_PAGE, getWhatsappLogs } from "../services/WhatsappLogsCRUD"
import clsx from "clsx"
import { Pagination } from "@mui/material"

export default function WhatsappLogs() {
    const currentUser = useSelector(currentUserSelector)
    const intl = useIntl()
    const [loading, setLoading] = useState(false);
    const [logs, setLogs] = useState<Array<WhatsappLog>>([]);
    const [page, setPage] = useState(1);
    const [count, setCount] = useState(0);

    async function fetchLogs() {
        setLoading(true);
        const { data: response } = await getWhatsappLogs(currentUser?.id, page);
        setCount(response.count);
        setLogs(response.results);
        setLoading(false);
    }

    useEffect(() => {
        if (currentUser?.id && page > 0) {
            fetchLogs();
        }
    }, [currentUser?.id, page])

    useEffect(() => {
        setPage(1);
    }, [currentUser?.id])

    function renderResult(log: WhatsappLog) {
            return <span className={clsx({
                'badge ms-3': true,
                'badge-success': log.result.includes('success'),
                'badge-danger': log.result.includes('error'),
            })}>
                {
                    log.result.includes('success')?
                        intl.formatMessage({ id: 'whatsappLogs.success' })
                        : intl.formatMessage({ id: 'whatsappLogs.error' })
                }
            </span>
    }

    return (
        <div className="card p-10">
            <h1 className="mb-10">
                {intl.formatMessage({ id: 'menu.whatsappLogs' })}
            </h1>
            {
                loading && <span className="spinner-border spinner-border-sm"></span>
            }
            {
                !loading && count > 0 && <span className="text-muted mb-5">
                    {intl.formatMessage({ id: 'whatsappLogs.count' }, { count })}
                </span>
            }
            {
                !loading && count === 0 && <span className="text-muted mb-5">
                    {intl.formatMessage({ id: 'whatsappLogs.empty' })}
                </span>
            }
            {
                !loading && logs.map((log, index) => (
                    <article key={index} className="card p-5 shadow mb-5 mw-500px">
                        <h2 className="d-flex align-items-center">
                            {log.template_name}
                            {renderResult(log)}
                        </h2>
                        <span className="text-muted">
                            {intl.formatMessage({ id: 'whatsappLogs.sent' })} {
                                (new Date(log.sent_at)).toLocaleString(
                                    intl.locale,
                                    {
                                        dateStyle: 'long',
                                        timeStyle: 'short',
                                    }
                                )
                            }
                        </span>
                        {
                            log.result.includes('error') && (
                                <code className="rounded bg-light-warning p-5 m-0 mt-3">
                                        {log.result}
                                </code>
                            )
                        }
                    </article>
                ))
            }
            {count > 0 && (
                <Pagination
                    className='mt-4 mx-auto'
                    count={Math.ceil(count / ITEMS_PER_PAGE)}
                    page={page}
                    onChange={(_, page) => setPage(page)}
                />
            )}
        </div>
    )
}