import {useEffect, useState} from 'react'
import {Button, Modal} from 'react-bootstrap'
import {FormattedMessage} from 'react-intl'

export default function PromiseDateModal({isOpen, onClose, onSubmit, initialValue}) {
  const [date, setDate] = useState(initialValue)

  useEffect(() => {
    setDate(initialValue)
  }, [initialValue])

  return (
    <Modal show={isOpen} onClose={onClose}>
      <Modal.Header>
        <h4>
          <FormattedMessage id='orders.changePromiseDate' />
        </h4>
      </Modal.Header>
      <Modal.Body>
        <label className='fw-bolder mb-2'>
          <FormattedMessage id='orders.promiseDateLabel' />
        </label>
        <div className='form-group'>
          <input
            type='date'
            className='form-control'
            value={date}
            onChange={(e) => {
              //  TODO: remove this after moving to new picking center
              if (e.target.value === '2024-09-06') {
                return
              }
              setDate(e.target.value)
            }}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className='d-flex justify-content-between w-100'>
          <Button variant='tertiary' onClick={onClose}>
            <FormattedMessage id='orders.cancel' />
          </Button>
          <Button variant='primary' onClick={() => onSubmit(date)}>
            <FormattedMessage id='orders.updatePromiseDate' />
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  )
}
