import {useIntl} from 'react-intl'

export default function ShopList({shops, loading = false, onAddShop, onEditShop, onRemoveShop}) {
  const intl = useIntl()

  return (
    <>
      <div className='d-flex justify-content-between align-items-center mb-8'>
        <h2 className=''>{intl.formatMessage({id: 'shops.listTitle'})}</h2>
        <button onClick={onAddShop} className='btn btn-primary'>
          <span className='fa fa-plus me-2' />
          {intl.formatMessage({id: 'shops.addNewShop'})}
        </button>
      </div>
      {loading ? (
        <div className='spinner-border text-primary' role='status' />
      ) : (
        <table className='table table-striped table-bordered table-hover'>
          <thead className='table-header sticky-top bg-white'>
            <tr className='text-left'>
              <th className='ps-2'>{intl.formatMessage({id: 'shops.id'})}</th>
              <th>{intl.formatMessage({id: 'shops.name'})}</th>
              <th>{intl.formatMessage({id: 'shops.location'})}</th>
              <th>{intl.formatMessage({id: 'shops.address'})}</th>
              <th>{intl.formatMessage({id: 'shops.latitude'})}</th>
              <th>{intl.formatMessage({id: 'shops.longitude'})}</th>
              <th>{intl.formatMessage({id: 'shops.edit'})}</th>
              <th className='pe-2'>{intl.formatMessage({id: 'shops.remove'})}</th>
            </tr>
          </thead>
          <tbody className='text-left'>
            {shops
              ?.sort((a, b) => b.id - a.id)
              ?.map((shop, index) => {
                return (
                  <tr key={index}>
                    <td className='ps-2'>{shop.id}</td>
                    <td>{shop.name}</td>
                    <td>{shop.location}</td>
                    <td>{shop.address}</td>
                    <td>{shop.lat}</td>
                    <td>{shop.long}</td>
                    <td>
                      <button
                        onClick={() => onEditShop(shop)}
                        className='btn btn-sm btn-tertiary fa fa-edit text-primary py-0'
                      />
                    </td>
                    <td className='pe-2'>
                      <button
                        onClick={() => onRemoveShop(shop)}
                        className='btn btn-sm btn-tertiary fa fa-trash text-danger py-0'
                      />
                    </td>
                  </tr>
                )
              })}
          </tbody>
        </table>
      )}
    </>
  )
}
