import axios from "axios"
import { LoyaltyLevel } from "../models/LoyaltyLevel"
import { LoyaltyReward } from "../models/LoyaltyReward"

const API_URL = process.env.REACT_APP_API_URL

export function listRewardsPerLevel(): Promise<{data: Array<LoyaltyLevel>}> {
    const endpoint = `${API_URL}/api/loyalty/rewards/`
  
    return axios.get(endpoint)
}

export function createReward(reward: LoyaltyReward): Promise<{data: LoyaltyReward}> {
    const endpoint = `${API_URL}/api/loyalty/rewards/`
  
    return axios.post(endpoint, reward)
}

export function updateReward(reward: LoyaltyReward): Promise<{data: LoyaltyReward}> {
    const endpoint = `${API_URL}/api/loyalty/rewards/`
  
    return axios.patch(endpoint, reward)
}

export function deleteRewards(rewardIds: Array<Number>): Promise<{data: LoyaltyLevel}> {
    const endpoint = `${API_URL}/api/loyalty/rewards/`
    
    return axios.delete(endpoint, {data: { reward_ids: rewardIds }})
}