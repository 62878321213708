import {useEffect, useState} from 'react'
import {AsideDefault} from './components/aside/AsideDefault'
import {Footer} from './components/Footer'
import {HeaderWrapper} from './components/header/HeaderWrapper'
import {ScrollTop} from './components/ScrollTop'
import {Content} from './components/Content'
import {PageDataProvider} from './core'
import {useLocation} from 'react-router-dom'
import {TaskDrawer} from '../../app/modules/tasks/'
import {MenuComponent} from '../assets/ts/components'
import {Toolbar} from './components/Toolbar'
import 'react-toastify/dist/ReactToastify.css'
import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'
import {ToastContainer} from 'react-toastify'
import {PhoneModal} from '../../app/components/PhoneModal'
import AircallContext from './AircallContext'

const MasterLayout = ({children}) => {
  const location = useLocation()
  const [modalVisible, setModalVisible] = useState(false)
  const [phoneNumber, setPhoneNumber] = useState('')

  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 500)
  }, [])

  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 500)
  }, [location.key])

  return (
    <>
      <AircallContext.Provider
        value={{
          modalVisible,
          setModalVisible,
          phoneNumber,
          setPhoneNumber,
        }}
      >
        <PageDataProvider>
          <div className='page d-flex flex-row flex-column-fluid'>
            <AsideDefault />
            <div className='wrapper d-flex flex-column flex-row-fluid' id='kt_wrapper'>
              <HeaderWrapper />
              <div id='kt_content' className='content d-flex flex-column flex-column-fluid'>
                <Toolbar />
                <div className='post d-flex flex-column-fluid' id='kt_post'>
                  <Content>{children}</Content>
                </div>
              </div>
              <Footer />
            </div>
          </div>
          <TaskDrawer />
          <ScrollTop />
          <ToastContainer
            position={'top-center'}
            autoClose={4000}
            hideProgressBar={false}
            closeOnClick={true}
            pauseOnHover={false}
            draggable={false}
          />
          <PhoneModal
            onHide={() => setModalVisible(false)}
            show={modalVisible}
            phoneNumber={phoneNumber}
          />
        </PageDataProvider>
      </AircallContext.Provider>
    </>
  )
}

export {MasterLayout}
