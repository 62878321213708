import {UserModel} from '../../user-profile/models/UserModel'
import {PendingsModel} from '../models/PendingsModel'
import {TaskGroupModel} from '../models/TaskGroupModel'
import {TaskListModel} from '../models/TaskListModel'
import {TaskModel} from '../models/TaskModel'
import {TaskStatus} from './TaskStatusType'
import {actionTypes} from './TaskActions'

interface ITaskState {
  pendingTaskFilters: {
    taskResolution?: number
    taskProfile?: number
  }
  pendingTask?: TaskModel
  pendingTasks?: Array<TaskModel>
  selectedTask?: TaskModel
  notReachableExpiredTasks: TaskListModel
  possibleTasks: TaskListModel
  pendingCustomerTasks: TaskListModel
  pendingAgentTasks: TaskListModel
  recoveryTasks: TaskListModel
  clientTasks: TaskListModel
  alarmTasks: TaskListModel
  groups?: Array<TaskGroupModel>
  staffUsers?: Array<UserModel>
  pendingsPerAgent?: Array<PendingsModel>
  pendingTaskError?: string
  selectedTaskError?: string
  pendingTasksStatus?: TaskStatus
  possibleTasksStatus?: TaskStatus
  pendingCustomerTasksStatus?: TaskStatus
  notReachableExpiredTasksStatus?: TaskStatus
  pendingAgentTasksStatus?: TaskStatus
  alarmTasksStatus?: TaskStatus
  pendingTaskStatus: TaskStatus
  selectedTaskStatus: TaskStatus
  pendingsPerAgentStatus?: TaskStatus
  recoveyTasksStatus?: TaskStatus
  unknownTasksStatus?: TaskStatus
  unknownTasks: TaskListModel
  newLeadTasks: TaskListModel
  newLeadTasksStatus?: TaskStatus
  noRecipesTasks: TaskListModel
  noRecipesTasksStatus?: TaskStatus
  leadCallRequestTasks: TaskListModel
  leadCallRequestTasksStatus?: TaskStatus
  noAnswerTasks: TaskListModel
  noAnswerTasksStatus?: TaskStatus
  clientTasksStatus?: TaskStatus
}

const initialState: ITaskState = {
  pendingTaskFilters: {
    taskResolution: undefined,
    taskProfile: undefined,
  },
  pendingTask: undefined,
  pendingTasks: [],
  selectedTask: undefined,
  notReachableExpiredTasks: {
    results: [],
    count: 0,
    next: null,
    previous: null,
    page: 0,
  },
  recoveryTasks: {
    results: [],
    count: 0,
    next: null,
    previous: null,
    page: 0,
  },
  possibleTasks: {
    results: [],
    count: 0,
    next: null,
    previous: null,
    page: 0,
  },
  pendingCustomerTasks: {
    results: [],
    count: 0,
    next: null,
    previous: null,
    page: 0,
  },
  pendingAgentTasks: {
    results: [],
    count: 0,
    next: null,
    previous: null,
    page: 0,
    taskProfile: null,
    taskResolution: null,
    currentAttemptNumber: null,
    order: '-created_at',
  },
  unknownTasks: {
    results: [],
    count: 0,
    next: null,
    previous: null,
    page: 0,
  },
  newLeadTasks: {
    results: [],
    count: 0,
    next: null,
    previous: null,
    page: 0,
  },
  noRecipesTasks: {
    results: [],
    count: 0,
    next: null,
    previous: null,
    page: 0,
  },
  leadCallRequestTasks: {
    results: [],
    count: 0,
    next: null,
    previous: null,
    page: 0,
  },
  noAnswerTasks: {
    results: [],
    count: 0,
    next: null,
    previous: null,
    page: 0,
  },
  clientTasks: {
    results: [],
    count: 0,
    next: null,
    previous: null,
    page: 0,
  },
  alarmTasks: {
    results: [],
    count: 0,
    next: null,
    previous: null,
    page: 0,
  },
  clientTasksStatus: 'loading',
  noAnswerTasksStatus: 'loading',
  noRecipesTasksStatus: 'loading',
  leadCallRequestTasksStatus: 'loading',
  newLeadTasksStatus: 'loading',
  unknownTasksStatus: 'loading',
  alarmTasksStatus: 'loading',
  groups: [],
  staffUsers: [],
  pendingTaskStatus: 'idle',
  selectedTaskStatus: 'idle',
  pendingTaskError: undefined,
  selectedTaskError: undefined,
  pendingTasksStatus: 'loading',
  possibleTasksStatus: 'loading',
  pendingCustomerTasksStatus: 'loading',
  notReachableExpiredTasksStatus: 'loading',
  pendingAgentTasksStatus: 'loading',
  pendingsPerAgentStatus: 'loading',
  pendingsPerAgent: [],
}

export const reducer = (state: ITaskState = initialState, action: any): ITaskState => {
  switch (action.type) {
    case actionTypes.SetPendingTaskFilters: {
      return {
        ...state,
        pendingTaskFilters: action.payload,
      }
    }
    case actionTypes.SetPendingTask: {
      return {...state, pendingTask: action.payload.task}
    }
    case actionTypes.SetPendingTasks: {
      return {...state, pendingTasks: action.payload.tasks}
    }
    case actionTypes.SetSelectedTask: {
      return {...state, selectedTask: action.payload.task}
    }
    case actionTypes.CommentPendingTask: {
      return {
        ...state,
        pendingTask: {
          ...state.pendingTask,
          comments: [...(state?.pendingTask?.comments || []), action.payload.taskComment],
        } as TaskModel,
      }
    }
    case actionTypes.CommentSelectedTask: {
      return {
        ...state,
        selectedTask: {
          ...state.selectedTask,
          comments: [...(state?.selectedTask?.comments || []), action.payload.taskComment],
        } as TaskModel,
      }
    }
    case actionTypes.SetNotReachableExpiredTasks: {
      return {...state, notReachableExpiredTasks: action.payload.tasks}
    }
    case actionTypes.SetPossibleTasks: {
      return {...state, possibleTasks: action.payload.tasks}
    }
    case actionTypes.SetPendingCustomerTasks: {
      return {...state, pendingCustomerTasks: action.payload.tasks}
    }
    case actionTypes.SetAlarmTasks: {
      return {...state, alarmTasks: action.payload.tasks}
    }
    case actionTypes.SetGroups: {
      return {...state, groups: action.payload?.groups || []}
    }
    case actionTypes.SetStaffUsers: {
      return {...state, staffUsers: action.payload?.staffUsers || []}
    }
    case actionTypes.SetPendingTaskError: {
      return {...state, pendingTaskError: action.payload?.error, pendingTaskStatus: 'failed'}
    }
    case actionTypes.SetSelectedTaskError: {
      return {...state, selectedTaskError: action.payload?.error, selectedTaskStatus: 'failed'}
    }
    case actionTypes.SetPendingTaskStatus: {
      return {...state, pendingTaskStatus: action.payload?.status}
    }
    case actionTypes.SetSelectedTaskStatus: {
      return {...state, selectedTaskStatus: action.payload?.status}
    }
    case actionTypes.SetPendingTasksStatus: {
      return {...state, pendingTasksStatus: action.payload?.status}
    }
    case actionTypes.SetPossibleTasksStatus: {
      return {...state, possibleTasksStatus: action.payload?.status}
    }
    case actionTypes.SetPendingCustomerTasksStatus: {
      return {...state, pendingCustomerTasksStatus: action.payload?.status}
    }
    case actionTypes.SetNotReachableExpiredTasksStatus: {
      return {...state, notReachableExpiredTasksStatus: action.payload?.status}
    }
    case actionTypes.SetAlarmTasksStatus: {
      return {...state, alarmTasksStatus: action.payload?.status}
    }
    case actionTypes.SetClientTasksStatus: {
      return {...state, clientTasksStatus: action.payload?.status}
    }
    case actionTypes.SetClientTasks: {
      return {...state, clientTasks: action.payload?.tasks}
    }
    case actionTypes.SetPendingsPerAgent: {
      return {...state, pendingsPerAgent: action.payload?.pendingsPerAgent}
    }
    case actionTypes.SetPendingsPerAgentStatus: {
      return {...state, pendingsPerAgentStatus: action.payload?.status}
    }
    case actionTypes.SetPendingAgentTasks: {
      return {...state, pendingAgentTasks: action.payload.tasks}
    }
    case actionTypes.SetPendingAgentTasksStatus: {
      return {...state, pendingAgentTasksStatus: action.payload?.status}
    }
    case actionTypes.SetRecoveryTasks: {
      return {...state, recoveryTasks: action.payload.tasks}
    }
    case actionTypes.SetRecoveryTasksStatus: {
      return {...state, recoveyTasksStatus: action.payload?.status}
    }
    case actionTypes.SetUnknownTasks: {
      return {...state, unknownTasks: action.payload.tasks}
    }
    case actionTypes.SetUnknownTasksStatus: {
      return {...state, unknownTasksStatus: action.payload?.status}
    }
    case actionTypes.SetNewLeadTasks: {
      return {...state, newLeadTasks: action.payload.tasks}
    }
    case actionTypes.SetNewLeadTasksStatus: {
      return {...state, newLeadTasksStatus: action.payload?.status}
    }
    case actionTypes.SetNoRecipesTasks: {
      return {...state, noRecipesTasks: action.payload.tasks}
    }
    case actionTypes.SetNoRecipesTasksStatus: {
      return {...state, noRecipesTasksStatus: action.payload?.status}
    }
    case actionTypes.SetLeadCallRequestTasks: {
      return {...state, leadCallRequestTasks: action.payload.tasks}
    }
    case actionTypes.SetLeadCallRequestTasksStatus: {
      return {...state, leadCallRequestTasksStatus: action.payload?.status}
    }
    case actionTypes.SetNoAnswerTasks: {
      return {...state, noAnswerTasks: action.payload.tasks}
    }
    case actionTypes.SetNoAnswerTasksStatus: {
      return {...state, noAnswerTasksStatus: action.payload?.status}
    }
    default:
      return state
  }
}
