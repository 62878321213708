import { ReactNode, useEffect, useState } from 'react'
import clsx from 'clsx'
import { Dropdown } from 'react-bootstrap'
import { useIntl } from 'react-intl'

import { LeadOrderModel, LeadOrderStateEnum } from '../../b2b-lead-orders'
import { LeadDistributorModel, listLeadDistributors } from '../../lead-distributors'
import { toast } from 'react-toastify'
import Pagination from '@mui/material/Pagination'
import { ORDERS_PER_PAGE } from '../../orders'

const NEUTRAL_STATES = [
  LeadOrderStateEnum.PROPOSAL,
  LeadOrderStateEnum.CONFIRMED,
  LeadOrderStateEnum.PROCESSING,
  LeadOrderStateEnum.LOCKED,
]
const SUCCESS_STATES = [LeadOrderStateEnum.SHIPPED, LeadOrderStateEnum.COMPLETED]
const FAIL_STATES = [LeadOrderStateEnum.CANCELLED, LeadOrderStateEnum.RETURNED, LeadOrderStateEnum.UNDELIVERED]
const STATE_TRANSITIONS = {
  [LeadOrderStateEnum.PROPOSAL]: [LeadOrderStateEnum.CANCELLED, LeadOrderStateEnum.CONFIRMED],
  [LeadOrderStateEnum.CONFIRMED]: [LeadOrderStateEnum.LOCKED, LeadOrderStateEnum.CANCELLED],
  [LeadOrderStateEnum.LOCKED]: [
    LeadOrderStateEnum.PROCESSING,
    LeadOrderStateEnum.CANCELLED,
    LeadOrderStateEnum.COMPLETED,
  ],
  [LeadOrderStateEnum.PROCESSING]: [
    LeadOrderStateEnum.COMPLETED,
    LeadOrderStateEnum.RETURNED,
    LeadOrderStateEnum.UNDELIVERED,
  ],
}
type BULK_STATE = 'loading' | 'idle'

type PropType = {
  onCreateOrder: () => void
  onEditOrder: (order: LeadOrderModel) => void
  onUpdateState: (order: LeadOrderModel, state: LeadOrderStateEnum) => void
  onBulkPrepareOrders: (orderIds: Array<number>) => void
  onBulkGeneratePickingSheet: (orderIds: Array<number>) => void
  onEditComments: (order: LeadOrderModel) => void
  onPageChange?: (page: number) => void
  page: number
  count: number
  orders: Array<LeadOrderModel>
  loading: boolean
  children?: ReactNode
  displayBulkActions?: boolean
}

export default function LeadOrderList({
  onCreateOrder,
  onEditOrder,
  onUpdateState,
  onBulkPrepareOrders,
  onBulkGeneratePickingSheet,
  onEditComments,
  onPageChange,
  orders,
  loading,
  children,
  count,
  page,
  displayBulkActions = false,
}: PropType) {
  const intl = useIntl()
  const [distributors, setDistributors] = useState<Array<LeadDistributorModel>>([])
  const [selectedOrders, setSelectedOrders] = useState([])
  const [bulkState, setBulkState] = useState<BULK_STATE>('idle')
  const hasSelectedAllOrders = selectedOrders?.length === orders?.length
  const selectedCount = hasSelectedAllOrders ? count : selectedOrders.length
  
  async function copyToClipboard(text) {
    try {
      if (navigator?.clipboard) {
        navigator.clipboard.writeText(text)
      } else {
        var textField = document.createElement('textarea')
        textField.textContent = text
        document.body.appendChild(textField)
        textField.select()
        document?.execCommand('copy')
        textField.remove()
      }
      toast.success(intl.formatMessage({ id: 'shopifyOrders.copiedToClipboard' }))
    } catch (error) {
      console.warn({ error })
      toast.error(intl.formatMessage({ id: 'shopifyOrders.copyToClipboardError' }))
    }
  }

  async function fetchDistributors() {
    try {
      const { data } = await listLeadDistributors()
      setDistributors(data)
    } catch (error) {
      console.warn(error)
    }
  }

  useEffect(() => {
    fetchDistributors()
  }, [])

  useEffect(() => {
    setSelectedOrders([])
  }, [orders])

  async function onPrepareOrders() {
    const hasConfirmed = window.confirm(
      intl.formatMessage(
        { id: 'operationOrders.bulkPrepareOrdersConfirmation' },
        { count: selectedCount }
      )
    )
    if (!hasConfirmed) {
      return
    }
    setBulkState('loading')
    try {
      if (hasSelectedAllOrders) {
        await onBulkPrepareOrders([])
      } else {
        await onBulkPrepareOrders(selectedOrders?.map((order) => order.id))
      }

      toast.success(
        intl.formatMessage(
          { id: 'operationOrders.bulkPrepareOrdersSuccess' },
          { count: selectedCount }
        )
      )
    } catch (error) {
      console.warn({ error })
      toast.error(intl.formatMessage({ id: 'operationOrders.bulkPrepareOrdersError' }))
    } finally {
      setBulkState('idle')
    }
  }

  async function onGeneratePickingSheet() {
    const hasConfirmed = window.confirm(
      intl.formatMessage(
        { id: 'operationOrders.bulkGeneratePickingSheetConfirmation' },
        { count: selectedCount }
      )
    )
    if (!hasConfirmed) {
      return
    }
    setBulkState('loading')
    try {
      if (hasSelectedAllOrders) {
        await onBulkGeneratePickingSheet([])
      } else {
        await onBulkGeneratePickingSheet(selectedOrders?.map((order) => order.id))
      }
      toast.success(
        intl.formatMessage(
          { id: 'operationOrders.bulkGeneratePickingSheetSuccess' },
          { count: selectedCount }
        )
      )
    } catch (error) {
      console.warn({ error })
      toast.error(intl.formatMessage({ id: 'operationOrders.bulkGeneratePickingSheetError' }))
    } finally {
      setBulkState('idle')
    }
  }

  return (
    <>
      <div className='card p-10 h-auto'>
        <div className='d-flex align-items-center justify-content-between mb-8'>
            <h2 className='card-title'>{intl.formatMessage({ id: 'b2bLeadOrders.title' })}</h2>
            <button className='btn btn-primary' onClick={onCreateOrder}>
              <span className='fa fa-plus me-3' />
              {intl.formatMessage({ id: 'b2bLeadOrders.createNewOrder' })}
            </button>
        </div>
        <div className='row mb-8'>{children}</div>
        {loading && <div className='spinner-border text-primary' role='status' />}
        {!loading && displayBulkActions && (
          <section className='d-flex px-0 gap-4 align-items-end mb-8'>
            <div className='me-4'>
              <label className={clsx('mb-2', !selectedCount && 'text-muted')}>
                {selectedCount > 0 ? (
                  <>
                    {intl.formatMessage(
                      { id: 'operationOrders.countSelectedOrders' },
                      { count: selectedCount }
                    )}
                  </>
                ) : (
                  <>{intl.formatMessage({ id: 'operationOrders.noSelectedOrders' })}</>
                )}
              </label>
              <Dropdown>
                <Dropdown.Toggle variant='primary' disabled={selectedCount < 1}>
                  <span className='me-4 fas w-15px d-inline-block fa-layer-group' />
                  {intl.formatMessage({ id: 'operationOrders.bulkActions' })}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item
                    className='py-3 ps-5 fs-6'
                    onClick={onGeneratePickingSheet}
                  >
                    <span className='me-4 fas w-15px d-inline-block fa-file-alt' />
                    {intl.formatMessage({ id: 'operationOrders.generatePickingSheet' })}
                  </Dropdown.Item>
                  <Dropdown.Item className='py-3 ps-5 fs-6 fw-bolder' onClick={onPrepareOrders}>
                    <span className='me-4 fas w-15px d-inline-block fa-magic' />
                    {intl.formatMessage({ id: 'operationOrders.prepareOrders' })}
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </section>
        )}
        {!loading && orders.length === 0 && (
          <p className='fw-bolder'>{intl.formatMessage({ id: 'b2bLeadOrders.noOrders' })}</p>
        )}
        {!loading && orders.length > 0 && (
          <div
            className='table-responsive'
            style={{
              height: 'calc(100vh - 420px)',
            }}
          >
            <p className='text-muted'>
              {
                intl.formatMessage(
                  { id: 'b2bLeadOrders.countOrders' },
                  { visible: orders.length, count }
                )
              }
            </p>
            <table className='table table-hover table-row-dashed table-row-gray-200 align-start gs-0 gy-4'>
              <thead className='table-header sticky-top bg-white'>
                <tr className='fw-bolder'>
                  {displayBulkActions && (
                    <th className='text-center ps-6 pe-4'>
                      <input
                        type='checkbox'
                        className='cursor-pointer form-check w-25px h-25px'
                        disabled={bulkState === 'loading'}
                        checked={selectedOrders.length === orders.length}
                        onChange={() => {
                          if (selectedOrders.length === orders.length) {
                            setSelectedOrders([])
                          } else {
                            setSelectedOrders(orders)
                          }
                        }}
                      />
                    </th>
                  )}
                  <th>{intl.formatMessage({ id: 'b2bLeadOrders.order' })}</th>
                  <th>{intl.formatMessage({ id: 'b2bLeadOrders.distributor' })}</th>
                  <th>{intl.formatMessage({ id: 'b2bLeadOrders.promiseDate' })}</th>
                  <th>{intl.formatMessage({ id: 'b2bLeadOrders.comments' })}</th>
                  <th>{intl.formatMessage({ id: 'b2bLeadOrders.trackingLink' })}</th>
                  <th>
                    {
                      // Empty th for the actions
                    }
                  </th>
                </tr>
              </thead>
              <tbody>
                {orders
                  ?.sort((a, b) => b.id - a.id)
                  ?.map((order) => (
                    <tr key={order.id}>
                      {displayBulkActions && (
                        <td className='text-center ps-6 pe-4'>
                          <input
                            type='checkbox'
                            className='cursor-pointer form-check w-25px h-25px'
                            checked={selectedOrders.includes(order)}
                            disabled={bulkState === 'loading'}
                            onChange={() => {
                              if (selectedOrders.includes(order)) {
                                setSelectedOrders(selectedOrders.filter((t) => t.id !== order.id))
                              } else {
                                setSelectedOrders([...selectedOrders, order])
                              }
                            }}
                          />
                        </td>
                      )}
                      <td className='text-nowrap'>
                        <div className='mb-1'>{order.code}</div>
                        {STATE_TRANSITIONS[order.state]?.length > 0 ? (
                          <Dropdown>
                            <Dropdown.Toggle
                              id='dropdown-create-b2b-order'
                              variant={clsx({
                                primary: NEUTRAL_STATES.includes(order.state),
                                success: SUCCESS_STATES.includes(order.state),
                                danger: FAIL_STATES.includes(order.state),
                              })}
                              className={clsx({
                                'badge badge-sm px-4 py-2 mb-1': true,
                              })}
                            >
                              {order.state}
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              {STATE_TRANSITIONS[order.state]?.map((state) => (
                                <Dropdown.Item
                                  className='p-4'
                                  key={state}
                                  onClick={() => onUpdateState(order, state)}
                                >
                                  {state}
                                </Dropdown.Item>
                              ))}
                            </Dropdown.Menu>
                          </Dropdown>
                        ) : (
                          <div
                            style={{
                              width: 'fit-content',
                            }}
                            className={clsx({
                              'badge badge-sm px-4 py-2 d-block mb-1': true,
                              'badge-success': SUCCESS_STATES.includes(order.state),
                              'badge-danger': FAIL_STATES.includes(order.state),
                            })}
                          >
                            {order.state}
                          </div>
                        )}
                      </td>
                      <td>
                        <a href={`/lead-distributor/${order.distributor}`}>
                          {distributors.find((d) => d.id === order.distributor)?.name || '-'}
                        </a>
                      </td>
                      <td className=''>
                        {order.promise_date
                          ? intl.formatDate(new Date(order.promise_date), {
                            day: '2-digit',
                            month: '2-digit',
                            year: 'numeric',
                          })
                          : '-'}
                      </td>
                      <td className='w-200px cursor-pointer' onClick={() => onEditComments(order)}>
                        {order?.comments?.length ? (
                          <p>{order?.comments}</p>
                        ) : (
                          <p className='text-muted'>-</p>
                        )}
                      </td>
                      <td>
                        <div className="w-150px text-start">
                          {
                            order?.tracking_link ?
                              <div className="d-flex flex-column gap-2">
                                <input
                                  onClick={(e) => {
                                    e.stopPropagation()
                                  }}
                                  type="text"
                                  value={order?.tracking_link}
                                  className="form-control form-control-solid fs-8 py-1 px-3 w-100 text-truncate"
                                  readOnly
                                />
                                <div className="d-flex gap-2 justify-content-between">
                                  <button
                                    className="btn btn-primary btn-sm flex-1 w-100 py-2 px-2 d-flex flex-nowrap align-items-center justify-content-center"
                                    onClick={(e) => {
                                      e.stopPropagation()
                                      copyToClipboard(order?.tracking_link)
                                    }}
                                  >
                                    <span className="fa fa-copy me-2"></span>
                                    {
                                      intl.formatMessage({ id: 'orderTrackingHistory.copy' })
                                    }
                                  </button>
                                  <a
                                    onClick={(e) => e.stopPropagation()}
                                    className="btn btn-primary btn-sm flex-1 w-100 py-2 px-2 d-flex flex-nowrap align-items-center justify-content-center"
                                    href={order?.tracking_link}
                                    target="_blank"
                                    rel="noreferrer">
                                    <span className="fa fa-external-link-alt me-2"></span>
                                    {
                                      intl.formatMessage({ id: 'orderTrackingHistory.open' })
                                    }
                                  </a>
                                </div>
                              </div>
                              : '-'
                          }
                        </div>
                      </td>
                      <td className='text-center'>
                        <button className='btn btn-textual text-primary p-4' onClick={() => onEditOrder(order)}>
                            <span className='fa fa-edit me-3'></span>
                            {intl.formatMessage({ id: 'b2bLeadOrders.edit' })}
                        </button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
            {count > 0 && (
            <>
              <Pagination
                className='mx-auto mt-4 d-flex justify-content-center'
                count={Math.ceil(count / ORDERS_PER_PAGE)}
                page={page}
                onChange={(_, page) => onPageChange(page)}
              />
            </>
          )}
          </div>
        )}
      </div>
    </>
  )
}
