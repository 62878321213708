import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { DeliveryChangeModel } from '../models/DeliveryChangeModel'
import { deleteDeliveryChange, listDeliveryChanges, updateDeliveryChange } from '../services/DeliveryChangesCRUD'
import { Link } from 'react-router-dom'
import { Dropdown } from 'react-bootstrap'
import clsx from 'clsx'
import { toast } from 'react-toastify'
import moment from 'moment'
import DateRangeSelector from '../../../components/DateRangeSelector'
import { useDispatch, useSelector } from 'react-redux'
import { selectedOrderSelector } from '../../orders'
import * as OrderRedux from '../../orders/redux/OrderRedux'
import { DeliveryChangeReasonEnum } from '../models/DeliveryChangeReasonEnum'

export default function DeliveryChangeList({ onlySelectedOrder = false }: { onlySelectedOrder?: boolean  }) {
  const intl = useIntl()
  const dispatch = useDispatch()
  const [deliveryChanges, setDeliveryChanges] = useState<Array<DeliveryChangeModel>>([])
  const [doneFilter, setDoneFilter] = useState<number>(0)
  const [loading, setLoading] = useState(false)
  const [since, setSince] = useState<Date>(new Date())
  const [until, setUntil] = useState<Date>(new Date())
  const selectedOrder = useSelector(selectedOrderSelector)
  const [deliveryChangeToDelete, setDeliveryChangeToDelete] = useState<DeliveryChangeModel | null>(null)
  const [updatingDeliveryChange, setUpdatingDeliveryChange] = useState<DeliveryChangeModel | null>(null)

  async function onDelete() {
    const hasConfirmed = window.confirm(intl.formatMessage({ id: 'deliveryChanges.deleteConfirm' }))
    if (hasConfirmed) {
      try {
        setUpdatingDeliveryChange(deliveryChangeToDelete)
        if (deliveryChangeToDelete) {
          await deleteDeliveryChange(deliveryChangeToDelete.id)
          toast.success(intl.formatMessage({ id: 'deliveryChanges.deleteSuccess' }))
          fetchDeliveryChanges(false)
        }
      } catch (error) {
        console.warn(error)
        toast.error(intl.formatMessage({ id: 'deliveryChanges.deleteError' }))
      } finally {
        setUpdatingDeliveryChange(null)
      }
    }
    setDeliveryChangeToDelete(null)
  }

  useEffect(() => {
    if (deliveryChangeToDelete) {
      onDelete();
    }
  }, [deliveryChangeToDelete])
    
  async function fetchDeliveryChanges(withLoading = false) {
    try {
      if (withLoading) {
        setLoading(true)
      }

      if (onlySelectedOrder) {
        const { delivery_changes } = selectedOrder
        
        setDeliveryChanges(delivery_changes?.sort((a, b) => b.id - a.id) || [])
      } else {
        const { data } = await listDeliveryChanges({
          done: doneFilter,
          promise_date__gte: moment(since).format('YYYY-MM-DD'),
          promise_date__lte: moment(until).format('YYYY-MM-DD'),
        })

        setDeliveryChanges(data?.sort((a, b) => b.id - a.id) || [])
      } 
    } catch (error) {
      console.warn(error)
    } finally {
      if (withLoading) {
        setLoading(false)
      }
    }
  }

  useEffect(() => {
    fetchDeliveryChanges(true)
  }, [selectedOrder, onlySelectedOrder, doneFilter, since, until])

  async function onDoneChange(deliveryChange: DeliveryChangeModel) {
    try {
      setUpdatingDeliveryChange(deliveryChange)
      setDeliveryChanges(
        deliveryChanges.map((item) => {
          if (item.id === deliveryChange.id) {
            return {
              ...item,
              done: !item.done,
            }
          }
          return item
        })
      )
      await updateDeliveryChange({
        ...deliveryChange,
        done: !deliveryChange.done,
      })
      toast.success(intl.formatMessage({ id: 'deliveryChanges.doneChangeSuccess' }))
      fetchDeliveryChanges(false)
      if (onlySelectedOrder) {
        dispatch(OrderRedux.actions.requestOrder(selectedOrder.id))
      }
    } catch (error) {
      console.warn(error)
      toast.error(intl.formatMessage({ id: 'deliveryChanges.doneChangeError' }))
    } finally {
      setUpdatingDeliveryChange(null)
    }
  }

  async function onReasonChange(deliveryChange: DeliveryChangeModel, reason: DeliveryChangeReasonEnum) {
    try {
      setUpdatingDeliveryChange(deliveryChange)
      setDeliveryChanges(
        deliveryChanges.map((item) => {
          if (item.id === deliveryChange.id) {
            return {
              ...item,
              reason,
            }
          }
          return item
        })
      )
      await updateDeliveryChange({
        ...deliveryChange,
        reason,
      })
      toast.success(intl.formatMessage({ id: 'deliveryChanges.reasonChangeSuccess' }))
      fetchDeliveryChanges(false)
      if (onlySelectedOrder) {
        dispatch(OrderRedux.actions.requestOrder(selectedOrder.id))
      }
    } catch (error) {
      console.warn(error)
      toast.error(intl.formatMessage({ id: 'deliveryChanges.reasonChangeError' }))
    } finally {
      setUpdatingDeliveryChange(null)
    }
  }

  async function onUpdateObservations(deliveryChange: DeliveryChangeModel, observations: string) {
    try {
      setUpdatingDeliveryChange(deliveryChange)
      await updateDeliveryChange({
        ...deliveryChange,
        observations,
      })
      toast.success(intl.formatMessage({ id: 'deliveryChanges.observationsChangeSuccess' }))
      fetchDeliveryChanges(false)
      if (onlySelectedOrder) {
        dispatch(OrderRedux.actions.requestOrder(selectedOrder.id))
      }
    } catch (error) {
      console.warn(error)
      toast.error(intl.formatMessage({ id: 'deliveryChanges.observationsChangeError' }))
    } finally {
      setUpdatingDeliveryChange(null)
    }
  }

  async function onUpdateIsRecurrent(deliveryChange: DeliveryChangeModel, isRecurrent: boolean) {
    try {
      setUpdatingDeliveryChange(deliveryChange)
      await updateDeliveryChange({
        ...deliveryChange,
        is_recurrent: isRecurrent,
      })
      toast.success(intl.formatMessage({ id: 'deliveryChanges.isRecurrentChangeSuccess' }))
      fetchDeliveryChanges(false)
      if (onlySelectedOrder) {
        dispatch(OrderRedux.actions.requestOrder(selectedOrder.id))
      }
    } catch (error) {
      console.warn(error)
      toast.error(intl.formatMessage({ id: 'deliveryChanges.isRecurrentChangeError' }))
    } finally {
      setUpdatingDeliveryChange(null)
    }
  }

  return <>
    <div className='card card-custom'>
      <div className='card-body'>
        <h2 className='mb-8'>{intl.formatMessage({ id: 'deliveryChanges.title' })}</h2>
        {!onlySelectedOrder && <>
          <div className='form-group w-200px d-inline-block me-4'>
            <label className='form-label fw-bolder'>
              {intl.formatMessage({ id: 'deliveryChanges.doneFilter' })}
            </label>
            <select
              className='form-select'
              name='doneFilter'
              required
              disabled={loading}
              onChange={(e) => setDoneFilter(e.target.value ? Number(e.target.value) : null)}
              value={doneFilter}
            >
              <option value='1'>{intl.formatMessage({ id: 'deliveryChanges.done' })}</option>
              <option value='0'>{intl.formatMessage({ id: 'deliveryChanges.pending' })}</option>
            </select>
          </div>
          <div className='form-group w-200px d-inline-block'>
            <DateRangeSelector
              initialSince={since}
              initialUntil={until}
              onSelected={(since, until) => {
                setSince(since)
                setUntil(until)
              }}
            />

          </div>
        </>}
        <div className='mt-8'>
          {loading && <span className='spinner-border text-primary mt-5'></span>}
          {!loading && deliveryChanges.length === 0 && (
            <p>{intl.formatMessage({ id: 'deliveryChanges.empty' })}</p>
          )}
          {!loading && deliveryChanges.length > 0 && (
            <table className='table table-hover table-row-dashed table-row-gray-200 align-start gs-0 gy-4'>
              <thead className='table-header'>
                <tr className='fw-bolder'>
                  <th>{intl.formatMessage({ id: 'deliveryChanges.id' })}</th>
                  <th>{intl.formatMessage({ id: 'deliveryChanges.order' })}</th>
                  <th>{intl.formatMessage({ id: 'deliveryChanges.reason' })}</th>
                  <th>{intl.formatMessage({ id: 'deliveryChanges.observations' })}</th>
                  <th>{intl.formatMessage({ id: 'deliveryChanges.isRecurrent'})}</th>
                  <th>{intl.formatMessage({ id: 'deliveryChanges.status' })}</th>
                  <th>{intl.formatMessage({ id: 'deliveryChanges.remove' })}</th>
                </tr>
              </thead>
              <tbody>
                {deliveryChanges.map((deliveryChange) => (
                  <tr key={deliveryChange.id} className='align-center'>
                    <td>{deliveryChange.id}</td>
                    <td className='text-nowrap'>
                      {deliveryChange.orders?.map((order) => (
                        <Link
                          to={`/user/${order.user_id}/order/${order.id}`}
                          key={order.id}
                          className='d-block mb-1'
                        >
                          {order.code}
                        </Link>
                      ))}
                    </td>
                    <td className='text-nowrap w-250px'>
                    <select 
                        disabled={updatingDeliveryChange?.id === deliveryChange.id}
                        className='form-select w-250px'
                        value={deliveryChange.reason}
                        onChange={(e) => onReasonChange(deliveryChange, e.target.value as DeliveryChangeReasonEnum)}
                      >
                        {
                          Object.values(DeliveryChangeReasonEnum)
                            .map((reason) => (
                              <option key={reason} value={reason}>
                                {intl.formatMessage({ id: `deliveryChangeModal.${reason}` })}
                              </option>
                          ))
                        }
                      </select>
                    </td>
                    <td>
                      <textarea
                        className='form-control'
                        rows={
                          deliveryChange.observations?.split('\n').length + 1 || 1
                        }
                        disabled={updatingDeliveryChange?.id === deliveryChange.id}
                        value={deliveryChange.observations || '-'}
                        onChange={(e) =>
                          setDeliveryChanges(
                            deliveryChanges.map((item) => {
                              if (item.id === deliveryChange.id) {
                                return {
                                  ...item,
                                  observations: e.target.value,
                                }
                              }
                              return item
                            })
                          )
                        }
                        onBlur={(e) =>
                          onUpdateObservations(deliveryChange, e.target.value)
                        }
                      ></textarea>
                    </td>
                    <td>
                      <div className='form-check form-switch'>
                        <input
                          disabled={updatingDeliveryChange?.id === deliveryChange.id}
                          className='form-check-input'
                          type='checkbox'
                          id={`delivery-change-list-${deliveryChange.id}`}
                          checked={deliveryChange.is_recurrent}
                          onChange={() => onUpdateIsRecurrent(deliveryChange, !deliveryChange.is_recurrent)}
                        />
                        <label
                          className='form-check-label'
                          htmlFor={`delivery-change-list-${deliveryChange.id}`}
                        >
                          {
                            deliveryChange.is_recurrent?
                              intl.formatMessage({ id: 'deliveryChanges.isRecurrentYes' })
                              : intl.formatMessage({ id: 'deliveryChanges.isRecurrentNo' })
                          }
                        </label>
                      </div>
                    </td>
                    <td>
                      <Dropdown>
                        <Dropdown.Toggle
                          id='dropdown-create-b2b-order'
                          variant={clsx({
                            primary: !deliveryChange.done,
                            success: deliveryChange.done,
                          })}
                          className={clsx({
                            'badge badge-sm px-4 py-2': true,
                          })}
                        >
                          {deliveryChange.done
                            ? intl.formatMessage({ id: 'deliveryChanges.done' })
                            : intl.formatMessage({ id: 'deliveryChanges.pending' })}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item
                            className='p-4'
                            onClick={() => onDoneChange(deliveryChange)}
                          >
                            {deliveryChange.done
                              ? intl.formatMessage({ id: 'deliveryChanges.pending' })
                              : intl.formatMessage({ id: 'deliveryChanges.done' })}
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </td>
                    <td className='text-start'>
                      <button type="button" 
                        disabled={updatingDeliveryChange?.id === deliveryChange.id}
                        className="btn pt-2 btn-tertiary fa fa-trash text-danger btn-sm" 
                        onClick={() => setDeliveryChangeToDelete(deliveryChange)}>
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </div>
  </>
}