import { useIntl } from "react-intl"
import DateRangeSelector from "../../../components/DateRangeSelector"
import { useEffect, useState } from "react"
import moment from "moment"
import LineChart from "../../dashboard/components/LineChart"
import { toast } from "react-toastify"
import { getLeadDistributorReport } from "../services/LeadDistributorCRUD"
import { getBillingReport, getBillingReportCsv, getEuroKg } from "../../b2b-orders"

export default function ModalDistributorReport() {
    const intl = useIntl()
    const [since, setSince] = useState(moment().subtract(30, 'days').toDate())
    const [until, setUntil] = useState(moment().subtract(0, 'days').toDate())
    const [loading, setLoading] = useState(true)
    const [data, setData] = useState({})
    const [report, setReport] = useState({
        "subtotal_by_days": {},
        "total_subtotal": 0.0
    })
    const [euroKg, setEuroKg] = useState(0.0)

    function onDateRangeSelected(since, until) {
        setSince(since)
        setUntil(until)
    }

    async function fetchReport() {
        try {
            setLoading(true)
            const response = await getLeadDistributorReport({
                since: moment(since).format('YYYY-MM-DD'),
                until: moment(until).format('YYYY-MM-DD'),
            })
            setData(response.data)
            const reportResponse = await getBillingReport({
                since: moment(since).format('YYYY-MM-DD'),
                until: moment(until).format('YYYY-MM-DD'),
            })
            setReport(reportResponse.data)
            const euroKgResopnse = await getEuroKg({
                since: moment(since).format('YYYY-MM-DD'),
                until: moment(until).format('YYYY-MM-DD'),
            })
            setEuroKg(euroKgResopnse.data.total_euro_kg)
        } catch (error) {
            console.error(error)
            toast.error(intl.formatMessage({id: 'leadDistributorReport.error'}))
        } finally {
            setLoading(false)
        }
    }

    function getData() {
        let completeData = []

        let i = 0;
        for (let agent in data) {
            if (data.hasOwnProperty(agent)) {
                const days = []
                for (let date = since; date <= until; date = moment(date).add(1, 'days').toDate()) {
                    days.push(moment(date).format('YYYY-MM-DD'))
                }

                const serie = days.map((date) => {
                    i++;
                    const value = data[agent][date] || 0
                    return {
                        date,
                        value,
                        id: i,
                        agent,
                    }
                })

                completeData = [
                    ...completeData,
                    ...serie,
                ]
            }
        }

        return completeData;
    }

    function getReportData() {
        let completeData = [];
        
        for (let key of Object.keys(report.subtotal_by_days)) {
            if (report.subtotal_by_days.hasOwnProperty(key)) {
                completeData.push({
                    date: key,
                    value: report.subtotal_by_days[key],
                    id: key,
                    serie: 'Subtotal',
                })
            }
        }
        const days = []
        for (let date = since; date <= until; date = moment(date).add(1, 'days').toDate()) {
            days.push(moment(date).format('YYYY-MM-DD'))
        }
        for (let date of days) {
            if (!completeData.find((item) => item.date === date)) {
                completeData.push({
                    date,
                    value: 0,
                    id: date,
                    serie: 'Subtotal',
                })
            }
        }
        return completeData;
    }

    async function downloadExcel() {
        try {
            const hasConfirmed = window.confirm(intl.formatMessage({id: 'leadDistributorReport.confirmExcel'}))
            if (!hasConfirmed) {
                return
            }
            await getBillingReportCsv({
                since: moment(since).format('YYYY-MM-DD'),
                until: moment(until).format('YYYY-MM-DD'),
            })
            toast.success(intl.formatMessage({id: 'leadDistributorReport.excelSuccess'}))
        } catch (error) {
            console.error(error)
            toast.error(intl.formatMessage({id: 'leadDistributorReport.excelError'}))
        }
    }

    useEffect(() => {
        fetchReport()
    }, [since, until])

    return <>
        <div className="w-100 mb-8">
            <DateRangeSelector
              initialSince={since}
              initialUntil={until}
              onSelected={onDateRangeSelected}
              disableFutureDates={false}
            />
        </div>
        <h1>
            {intl.formatMessage({id: 'leadDistributorReport.totalReport'})}:&nbsp; {
                report.total_subtotal ? 
                    new Intl.NumberFormat('es-ES', { style: 'currency', currency: 'EUR' }).format(report.total_subtotal)
                    : '0,00€'
            }
        </h1>
        <h1  className="mb-8">
            {
                intl.formatMessage({id: 'leadDistributorReport.euroKg'})
            }
            {
                euroKg && euroKg > 0 ?
                    new Intl.NumberFormat('es-ES', { style: 'currency', currency: 'EUR', minimumFractionDigits: 4 }).format(euroKg)
                    : '0,0000€'
            }
        </h1>
        <LineChart
            title={ intl.formatMessage({id: 'leadDistributorReport.billingReportTitle'}) }
            data={getReportData()}
            loading={loading}
            serieField='serie'
        />
        <button className="btn btn-primary d-block me-auto mb-10" onClick={downloadExcel}>
            <span className="fas fa-file-excel me-3"></span>
            { intl.formatMessage({id: 'leadDistributorReport.downloadExcel'})}
        </button>
        <LineChart
            title={ intl.formatMessage({id: 'leadDistributorReport.chartTitle'}) }
            data={getData()}
            loading={loading}
            serieField='agent'
        />
    </>
}