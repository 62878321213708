/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useReducer, useState } from 'react'
import { useIntl } from 'react-intl'
import { toast } from 'react-toastify'

import { PageTitle } from '../../../_metronic/layout/core'
import {
  OrderStateEnum,
  PetKindEnum,
  performBulkAction,
  listOrders,
  BulkActionsEnum,
  listShippingServices,
} from '../../modules/orders'
import { OrderTable } from '../../modules/orders'
import { OrderListModel } from '../../modules/orders/models/OrderListModel'
import DateRangeSelector from '../../components/DateRangeSelector'

const ANY_STATE = 'ANY_STATE'

function reducer(state: OrderListModel, action: any) {
  switch (action.type) {
    case 'setState':
      return {
        ...action.payload,
      }
    default:
      return state
  }
}

const OperationOrdersPage: FC = () => {
  const intl = useIntl()
  const [state, dispatch] = useReducer(reducer, {
    results: [],
    next: null,
    page: 1,
    count: 0,
    previous: null,
  })
  const [loading, setLoading] = useState(false)
  const [selectedState, setSelectedState] = useState<OrderStateEnum | string>(OrderStateEnum.LOCKED)
  const [selectedStore, setSelectedStore] = useState<number>(1)
  const [isTrial, setIsTrial] = useState<boolean | null>(null)
  const [promiseDateRange, setPromiseDateRange] = useState<{ since: Date; until: Date }>({
    since: new Date(new Date().getTime() + 24 * 60 * 60 * 1000),
    until: new Date(new Date().getTime() + 48 * 60 * 60 * 1000),
  })
  const [petKind, setPetKind] = useState<number | null>(null)
  const [code, setCode] = useState('')
  const [shippingService, setShippingService] = useState(null)
  const [shippingServices, setShippingServices] = useState([])

  async function fetchShippingServices() {
    try {
      const { data } = await listShippingServices()
      setShippingServices(data?.sort((a, b) => a.name.localeCompare(b.name)))
    } catch (error) {
      console.warn(error)
    }
  }

  useEffect(() => {
    fetchShippingServices()
  }, []);

  async function fetchOrders(withLoader = true, page = 1) {
    const now = new Date()
    try {
      if (withLoader) {
        setLoading(true)
      }
      const since = promiseDateRange.since.toLocaleDateString('en-CA', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      });
      const until = promiseDateRange.until.toLocaleDateString('en-CA', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      });
      const { data } = await listOrders({
        code,
        page: page > 0 ? page : 1,
        state: selectedState === ANY_STATE ? null : selectedState,
        store: selectedStore,
        is_trial: isTrial,
        promise_date__gte: since,
        promise_date__lte: until,
        sortByWeight: true,
        pet__pet_kind: petKind,
        shipping_service: shippingService,
      })
      dispatch({
        type: 'setState',
        payload: {
          ...data,
          page,
        },
      })
    } catch (error) {
      toast.error(intl.formatMessage({ id: 'operationOrders.fetchError' }))
      dispatch({ type: 'setState', payload: { results: [] } })
      console.warn(error)
    } finally {
      if (withLoader) {
        setLoading(false)
      }
    }
  }

  useEffect(() => {
    fetchOrders(true, state?.page)
  }, [selectedState, selectedStore, promiseDateRange, isTrial, petKind, code, shippingService])

  async function onPerformBulkAction(action: BulkActionsEnum, orders: number[]) {
    const since = promiseDateRange.since.toLocaleDateString('en-CA', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    });
    const until = promiseDateRange.until.toLocaleDateString('en-CA', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    });

    return performBulkAction({
      action,
      orders,
      promise_date__gte: since,
      promise_date__lte: until,
      pet__pet_kind: petKind,
      is_trial: isTrial,
      store: selectedStore,
      state: selectedState,
      code,
      shipping_service: shippingService,
    })
  }

  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'operationOrders.title' })}</PageTitle>
      <div className='row'>
        <div className='col-12'>
          <div className='card card-custom'>
            <div className='card-body'>
              <OrderTable
                orders={state.results}
                loading={loading}
                page={state?.page}
                count={state.count}
                emptyMessage={intl.formatMessage({ id: 'operationOrders.noOrders' })}
                displayPetName={false}
                displayBulkActions={true}
                onPageChange={(page) => fetchOrders(true, page)}
                onRefresh={() => fetchOrders(true, state?.page)}
                displayResultsCount={true}
                onBulkMarkAsReadyToShip={(orders) => onPerformBulkAction(BulkActionsEnum.SET_READY_TO_SHIP, orders)}
                onBulkPrintInstructions={(orders) => onPerformBulkAction(BulkActionsEnum.PRINT_INSTRUCTIONS_PDF, orders)}
                onBulkPrintTags={(orders) => onPerformBulkAction(BulkActionsEnum.PRINT_TAGS, orders)}
                onBulkSetToProcessing={(orders) => onPerformBulkAction(BulkActionsEnum.SET_PROCESSING, orders)}
                onBulkGeneratePickingSheet={(orders) => onPerformBulkAction(BulkActionsEnum.GENERATE_PICKING_SHEET, orders)}
                onBulkGenerateWarehouseOperationsSheet={(orders) => onPerformBulkAction(BulkActionsEnum.GENERATE_WAREHOUSE_OPERATIONS_SHEET, orders)}
                onBulkGenerateProductsNecessitySheet={(orders) => onPerformBulkAction(BulkActionsEnum.GENERATE_PRODUCTS_NECESSITY_SHEET, orders)}
              >
                <div className='d-flex flex-wrap align-items-baseline'>
                  <div className='form-group w-150px d-inline-block me-4'>
                    <label className='fw-bolder mb-2'>
                      {intl.formatMessage({ id: 'operationOrders.filterByStore' })}
                    </label>
                    <select
                      className='form-control form-select'
                      value={selectedStore}
                      onChange={(event) => {
                        setSelectedStore(parseInt(event.target.value))
                      }}
                    >
                      {
                        // TODO: get stores from API ??
                      }
                      <option value={1}>{intl.formatMessage({ id: 'operationOrders.es' })}</option>
                      <option value={6}>{intl.formatMessage({ id: 'operationOrders.fr' })}</option>
                      <option value={8}>{intl.formatMessage({ id: 'operationOrders.pt' })}</option>
                      <option value={9}>{intl.formatMessage({ id: 'operationOrders.it' })}</option>
                    </select>
                  </div>
                  <div className='form-group d-inline-block me-4'>
                    <label className='fw-bolder mb-2'>
                      {intl.formatMessage({ id: 'operationOrders.filterByState' })}
                    </label>
                    <select
                      className='form-control form-select'
                      value={selectedState}
                      onChange={(event) => {
                        if (event.target.value === ANY_STATE) {
                          setSelectedState(ANY_STATE)
                        } else {
                          setSelectedState(event.target.value as OrderStateEnum)
                        }
                      }}
                    >
                      <option value={ANY_STATE}>
                        {intl.formatMessage({ id: 'operationOrders.anyState' })}
                      </option>
                      {Object.values(OrderStateEnum).map((state) => (
                        <option key={state} value={state}>
                          {state}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className='form-group d-inline-block me-4'>
                    <label className='fw-bolder mb-2'>
                      {intl.formatMessage({ id: 'operationOrders.filterByTrial' })}
                    </label>
                    <select
                      value={isTrial?.toString() || ''}
                      className='form-control form-select'
                      onChange={(event) => {
                        if (event.target.value === '') {
                          setIsTrial(null)
                        } else {
                          setIsTrial(event.target.value === 'true')
                        }
                      }}
                    >
                      <option value=''>
                        {intl.formatMessage({ id: 'operationOrders.anyKind' })}
                      </option>
                      <option value='true'>
                        {intl.formatMessage({ id: 'operationOrders.trial' })}
                      </option>
                      <option value='false'>
                        {intl.formatMessage({ id: 'operationOrders.subscription' })}
                      </option>
                    </select>
                  </div>
                  <div className='form-group d-inline-block me-4'>
                    <label className='fw-bolder mb-2'>
                      {intl.formatMessage({ id: 'operationOrders.filterByPetKind' })}
                    </label>
                    <select
                      value={petKind?.toString() || ''}
                      className='form-select w-150px'
                      onChange={(event) => {
                        if (event.target.value === '') {
                          setPetKind(null)
                        } else {
                          setPetKind(parseInt(event.target.value, 10))
                        }
                      }}
                    >
                      <option value=''>
                        {intl.formatMessage({ id: 'operationOrders.allPetKinds' })}
                      </option>
                      {Object.keys(PetKindEnum)
                        .filter((key) => !isNaN(Number(PetKindEnum[key])))
                        .map((key) => (
                          <option key={key} value={PetKindEnum[key]}>
                            {intl.formatMessage({ id: `operationOrders.${key.toLowerCase()}` })}
                          </option>
                        ))}
                    </select>
                  </div>
                  <div className="form d-inline-block me-4">
                    <div className="form-group">
                      <label className="fw-bolder mb-2">
                        {intl.formatMessage({ id: 'orders.filterBySo' })}
                      </label>
                      <div className="d-flex align-items-center">
                        <input
                          type="text"
                          className="form-control px-10 w-150px"
                          placeholder={intl.formatMessage({ id: 'orders.searchPlaceholder' })}
                          value={code}
                          onChange={(e) => setCode(e.target.value)}
                        />
                        <span className="fas fa-search text-muted position-absolute px-4" />
                      </div>
                    </div>
                  </div>
                  <div className="form d-inline-block me-4">
                    <div className="form-group">
                    <label className="fw-bolder mb-2">
                        {intl.formatMessage({ id: 'orders.filterByShippingService' })}
                      </label>
                      <select
                        value={shippingService?.toString() || ''}
                        className='form-select w-200px'
                        onChange={(event) => {
                          if (event.target.value === '') {
                            setShippingService(null)
                          } else {
                            setShippingService(parseInt(event.target.value, 10))
                          }
                        }}
                      >
                        <option value=''>
                          {intl.formatMessage({ id: 'orders.allShippingServices' })}
                        </option>
                        {shippingServices.map((shippingService) => (
                          <option key={shippingService.id} value={shippingService.id}>
                            {shippingService.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className='form-group w-180px d-inline-block me-4 mt-8'>
                    <DateRangeSelector
                      label={intl.formatMessage({ id: 'operationOrders.filterByPromiseDate' })}
                      onSelected={(since, until) => setPromiseDateRange({ since, until })}
                      initialSince={new Date()}
                      initialUntil={new Date()}
                    />
                  </div>
                </div>
              </OrderTable>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export { OperationOrdersPage }
