/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {useIntl} from 'react-intl'
import {useDispatch, useSelector} from 'react-redux'
import {PageTitle} from '../../../_metronic/layout/core'
import {
  actions,
  selectedTaskErrorSelector,
  selectedTaskSelector,
  selectedTaskStatusSelector,
} from '../../modules/tasks'
import {TaskManager} from '../../modules/tasks'
import {usePathProps} from '../../modules/user-profile/hooks/usePathProps'

const TaskDetailPage: FC = () => {
  usePathProps()
  const intl = useIntl()
  const dispatch = useDispatch()
  const onAddComment = (comment) => dispatch(actions.commentSelectedTask(comment))
  const onResolve = (taskSetResolution, replaceAgent, isAlarm) =>
    dispatch(actions.resolveSelectedTask(taskSetResolution, replaceAgent, isAlarm))
  const status = useSelector(selectedTaskStatusSelector)
  const error = useSelector(selectedTaskErrorSelector)
  const task = useSelector(selectedTaskSelector)

  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'menu.tasks'})}</PageTitle>
      <div style={{height: '100%', overflowY: 'auto'}}>
        <div className='mx-auto mw-550px'>
          <div className='card p-10 bg-light position-relative overflow-x-hidden'>
            <h1 className='mb-8'>{intl.formatMessage({id: 'tasks.task'}, {taskId: task?.id})}</h1>
            {!!task && (
              <TaskManager
                task={task}
                onAddComment={onAddComment}
                onResolve={onResolve}
                status={status}
                error={error}
                disableTransition={true}
                displayOwner={true}
              />
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export {TaskDetailPage}
