import { useEffect, useState } from 'react'
import { Modal, Spinner } from 'react-bootstrap'
import { useIntl } from 'react-intl'
import { createInvoicePreview, confirmInvoicePreview, discardInvoicePreview } from '../services/InvoiceCRUD'
import { toast } from 'react-toastify'

type PropsType = {
    order: any
    visible: boolean
    onClose: () => void
    onSubmit: () => void
}

export default function ModalInvoiceForm({
    order,
    visible,
    onClose,
    onSubmit,
}: PropsType) {
    const intl = useIntl()
    const [loading, setLoading] = useState(true);
    const [preview, setPreview] = useState(null);
   
    async function onCancel() {
        try {
            setLoading(true);
            await discardInvoicePreview({ invoiceId: preview.id });
            toast.success(intl.formatMessage({ id: 'invoices.discardPreviewSuccess' }));
            onClose();
        } catch (error) {
            toast.error(intl.formatMessage({ id: 'invoices.discardPreviewError' }));
            console.warn(error);
        } finally {
            setLoading(false);
        }
    }

    async function onConfirm() {
        try {
            setLoading(true);
            await confirmInvoicePreview({ invoiceId: preview.id });
            toast.success(intl.formatMessage({ id: 'invoices.confirmPreviewSuccess' }));
            onSubmit();
        } catch (error) {
            toast.error(intl.formatMessage({ id: 'invoices.confirmPreviewError' }));
            console.warn(error);
        } finally {
            setLoading(false);
        }
    }

    async function createPreview({ orderId }) {
        try {
            setLoading(true);
            const { data } = await createInvoicePreview({ orderId })
            setPreview(data);
        } catch (error) {
            toast.error(intl.formatMessage({ id: 'invoices.createPreviewError' }))
            onClose();
            console.warn(error)
        } finally {
            setLoading(false);
        }   
    }

    useEffect(function resetModal() {
        if (visible && order?.id) {
            setLoading(true);
            setPreview(null);
            createPreview({ orderId: order.id })
        }
    }, [visible, order?.id]);

    return (
        <Modal
        closeButton
            show={visible}
            onHide={onClose}
            aria-labelledby='contained-modal-title-vcenter'
            centered
            size='lg'
            backdrop='static'

        >
            <Modal.Header>
                <Modal.Title id='contained-modal-title-vcenter'>
                    {intl.formatMessage({ id: 'invoices.invoicePreview' })}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {
                    loading ?
                        <div className='d-flex justify-content-center'>
                            <Spinner animation='border' />
                        </div>
                        :
                        <iframe
                            src={preview?.pdf_file_url}
                            style={{  
                                margin: '0 auto',
                                height: 'calc(100vh - 400px)',
                                width: '100%', 
                                aspectRatio: '9/16'
                            }}
                        />
                }
            </Modal.Body>
            <Modal.Footer>
                <div className='d-flex gap-4 justify-content-center w-100'>
                    <button
                        className='btn btn-secondary'
                        onClick={() => onCancel()}
                    >
                        {intl.formatMessage({ id: 'invoices.cancel' })}
                    </button>
                    <button
                        className='btn btn-primary'
                        onClick={() => onConfirm()}
                    >
                        {intl.formatMessage({ id: 'invoices.confirmInvoicePreview' })}
                    </button>
                </div>
            </Modal.Footer>
        </Modal>
    )
}
