import {persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import {put, takeLatest} from 'redux-saga/effects'
import {RootState, ActionWithPayload} from '../../../../setup'

import {UserModel} from '../models/UserModel'
import {getMe} from '../services/AuthCRUD'

export const actionTypes = {
  SetToken: 'AUTH_REDUX_SET_TOKEN',
  Login: 'AUTH_REDUX_LOGIN',
  Logout: 'AUTH_REDUX_LOGOUT',
  UserRequested: 'AUTH_REDUX_USER_REQUESTED',
  SetUser: 'AUTH_REDUX_SET_USER',
}

export const authUserSelector = (state: RootState) => state.auth.user
export const accessTokenSelector = (state: RootState) => state.auth.accessToken

const initialAuthState: IAuthState = {
  user: undefined,
  accessToken: undefined,
}

export interface IAuthState {
  user?: UserModel
  accessToken?: string
}

export const reducer = persistReducer(
  {storage, key: 'v100-demo1-auth', whitelist: ['user', 'accessToken']},
  (state: IAuthState = initialAuthState, action: ActionWithPayload<IAuthState>) => {
    switch (action.type) {
      case actionTypes.SetToken: {
        const accessToken = action.payload?.accessToken
        return {accessToken, user: undefined}
      }

      case actionTypes.Logout: {
        return initialAuthState
      }

      case actionTypes.Login:
      case actionTypes.SetUser: {
        const user = action.payload?.user
        return {...state, user}
      }

      default:
        return state
    }
  }
)

export const actions = {
  login: (user: UserModel) => ({type: actionTypes.Login, payload: {user}}),
  logout: () => ({type: actionTypes.Logout}),
  requestUser: () => ({
    type: actionTypes.UserRequested,
  }),
  setUser: (user: UserModel) => ({type: actionTypes.SetUser, payload: {user}}),
  setToken: (accessToken: String) => ({type: actionTypes.SetToken, payload: {accessToken}}),
  store: () => ({type: 'def'}),
}

export function* saga() {
  yield takeLatest(actionTypes.SetToken, function* tokenChangedEffect() {
    yield put(actions.requestUser())
  })

  yield takeLatest(actionTypes.UserRequested, function* userRequestedEffect() {
    try {
      const {data: user} = yield getMe()
      yield put(actions.setUser(user))
    } catch (err) {
      console.warn({err})
    }
  })
}
