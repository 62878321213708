import { useIntl } from "react-intl";
import { FormField, GenericForm } from "../../../components/GenericForm";
import { useEffect, useState } from "react";
import { listMeals, listStores, Meal, PlanCalculatorRequest, PlanCalculatorResponse, calculatePlan } from "../services/PlanCRUD";
import { toast } from "react-toastify";
import clsx from "clsx";
import { ActivityLevelModel } from "../../pets/models/ActivityLevelModel";
import { BodyConditionModel } from "../../pets/models/BodyConditionModel";
import { listActivityLevels, listBodyConditions } from "../../pets";

export default function PlanCalculator() {
    const intl = useIntl();
    const [loading, setLoading] = useState(false);
    const [meals, setMeals] = useState<Array<Meal>>([]);
    const [stores, setStores] = useState<Array<any>>([]);
    const [activityLevels, setActivityLevels] = useState<Array<ActivityLevelModel>>([]);
    const [bodyConditions, setBodyConditions] = useState<Array<BodyConditionModel>>([]);
    const initialState: PlanCalculatorRequest = {
        store: 1,
        pet_kind: 1,
        is_neutered: true,
        age_years: 1,
        age_months: 0,
        weight_in_kg: 15,
        activity_level: 3,
        body_condition: 2,
        combine: false,
        meals: []
    };
    const [formState, setFormState] = useState<PlanCalculatorRequest>(initialState);
    const [response, setResponse] = useState<PlanCalculatorResponse | undefined>();

    function getMealOptions() {
        return meals
            ?.filter((meal: Meal) => 
                meal.pet_kinds.some((petKind) => 
                    petKind.id?.toString() === formState?.pet_kind?.toString()
                )
            )?.map((meal: Meal) => ({
                label: `${meal.name}`,
                value: meal.id.toString()
            }));
    }

    useEffect(function resetMealsOnPetKindChange() {
        setFormState({
            ...formState,
            meals: getMealOptions()?.map((meal) => parseInt(meal.value, 10)) || []
        });
    }, [formState?.pet_kind, meals]);

    const requestFields: Array<FormField<PlanCalculatorRequest>> = [
        {
            id: 'pet_kind',
            inputType: 'select',
            label: intl.formatMessage({id: 'planCalculator.petKind'}),
            options: [
                { label: intl.formatMessage({id: 'planCalculator.dog'}), value: 1 },
                { label: intl.formatMessage({id: 'planCalculator.cat'}), value: 2 }
            ],
            setter: (state: PlanCalculatorRequest, value: string) => ({
                ...state,
                pet_kind: parseInt(value, 10)
            }),
        },
        {
            id: 'weight_in_kg',
            inputType: 'number',
            label: intl.formatMessage({id: 'planCalculator.weight'}),
            setter: (state: PlanCalculatorRequest, value: string) => ({
                ...state,
                weight_in_kg: parseFloat(value)
            }),
        },
        {
            id: 'age_years',
            inputType: 'number',
            label: intl.formatMessage({id: 'planCalculator.ageYears'}),
        },
        {
            id: 'age_months',
            inputType: 'number',
            label: intl.formatMessage({id: 'planCalculator.ageMonths'}),
        },
        {
            id: 'activity_level',
            inputType: 'select',
            label: intl.formatMessage({id: 'planCalculator.activityLevel'}),
            options: activityLevels?.map((activityLevel) => ({
                value: activityLevel.id,
                label: intl.formatMessage({id: activityLevel.name})
            })),
            setter: (state: PlanCalculatorRequest, value: string) => ({
                ...state,
                activity_level: parseInt(value, 10)
            }),
        },
        {
            id: 'body_condition',
            inputType: 'select',
            label: intl.formatMessage({id: 'planCalculator.bodyCondition'}),
            options: bodyConditions?.map((bodyCondition) => ({
                value: bodyCondition.id,
                label: intl.formatMessage({id: bodyCondition.name})
            })),
            setter: (state: PlanCalculatorRequest, value: string) => ({
                ...state,
                body_condition: parseInt(value, 10)
            }),
        },
        {
            id: 'is_neutered',
            inputType: 'checkbox',
            label: intl.formatMessage({id: 'planCalculator.isNeutered'}),
        },
        {
            id: 'combine',
            inputType: 'checkbox',
            label: intl.formatMessage({id: 'planCalculator.combine'}),
        },
        {
            id: 'store',
            inputType: 'select',
            label: intl.formatMessage({id: 'planCalculator.store'}),
            options: stores?.map((store) => ({
                value: store.id,
                label: (new Intl.DisplayNames([intl.locale], {type: 'region'}))
                    .of(store.country)
            })),
            setter: (state: PlanCalculatorRequest, value: string) => ({
                ...state,
                store: parseInt(value, 10)
            }),
        },
        {
            id: 'meals',
            inputType: 'multiple',
            label: intl.formatMessage({id: 'planCalculator.meals'}),
            options: getMealOptions(),
            getter: (state: PlanCalculatorRequest) => state.meals?.join(','),
            setter: (state: PlanCalculatorRequest, value: string) => ({
                ...state,
                meals: value.split(',')?.map(Number)
            }),
        }
    ];
    
    const responseFields: Array<FormField<PlanCalculatorResponse>> = [
        {
            id: 'max_days_in_freezer',
            inputType: 'number',
            label: intl.formatMessage({id: 'planCalculator.maxDaysInFreezer'}),
        },
        {
            id: 'average_grams_per_day',
            inputType: 'number',
            label: intl.formatMessage({id: 'planCalculator.averageGramsPerDay'}),
        },
        {
            id: 'daily_calorie_intake',
            inputType: 'number',
            label: intl.formatMessage({id: 'planCalculator.dailyCalorieIntake'}),
        },
        {
            id: 'daily_protein_intake',
            inputType: 'number',
            label: intl.formatMessage({id: 'planCalculator.dailyProteinIntake'}),
        },
        {
            id: 'default_meal_gross_margin',
            inputType: 'number',
            label: intl.formatMessage({id: 'planCalculator.defaultMealGrossMargin'}),
            getter: (state: PlanCalculatorResponse) => `${Math.floor(state.default_meal_gross_margin * 100)}%`,
        },
        {
            id: 'trial_price',
            inputType: 'number',
            label: intl.formatMessage({id: 'planCalculator.trialPrice'}),
        },
        {
            id: 'regular_price',
            inputType: 'number',
            label: intl.formatMessage({id: 'planCalculator.regularPrice'}),
        },
        {
            id: 'meal_price_per_day',
            inputType: 'number',
            label: intl.formatMessage({id: 'planCalculator.mealPricePerDay'}),
            getter: (state: PlanCalculatorResponse) => `${(state.regular_price / 28).toFixed(2)} €`,
        },
    ];

    async function onSubmit() {
        try {
            setLoading(true);
            const { data} = await calculatePlan(formState)
            setResponse(data);
        } catch (err) {
            console.warn(err)
            toast.error(err.message);
        } finally {
            setLoading(false);
        }
    }

    async function fetchMeals() {
        const { data } = await listMeals();
        
        setMeals(data || []);
    }

    async function fetchStores() {
        const { data } = await listStores();

        setStores(data || []);
    }

    async function fetchActivityLevels() {
        const { data } = await listActivityLevels({ 
            pet_kind: formState.pet_kind,
        });
        
        setActivityLevels(data || []);
    }

    async function fetchBodyConditions() {
        const { data } = await listBodyConditions({ 
            pet_kind: formState.pet_kind,
        });
        
        setBodyConditions(data || []);
    }

    useEffect(() => {
        fetchActivityLevels();
        fetchBodyConditions();
    }, [formState.pet_kind]);
    
    useEffect(() => {
        fetchMeals();
        fetchStores();
    }, []);

    return <>
        <div className={clsx({
                "card p-10 mw-600px": true,
                "d-none": response,
            })}>
            <GenericForm
                state={formState}
                setState={setFormState}
                columns={2}
                title={intl.formatMessage({id: 'menu.planCalculator'})}
                fields={requestFields}
                initialState={initialState}
                ctaLabel={intl.formatMessage({id: 'planCalculator.calculate'})}
                onSubmit={onSubmit}
                toggleEdit={null}
                editing={true}
                submitting={loading}
                submittingLabel={intl.formatMessage({id: 'planCalculator.calculating'})}
            /> 
        </div>
        <section className={clsx({
                "mt-5 card bg-light-primary border border-2 border-primary mw-600px": true,
                "d-none": !response,
            })}>
            <div className="card-body">
                <GenericForm
                    state={response}
                    setState={() => {}}
                    columns={2}
                    title={intl.formatMessage({id: 'planCalculator.results'})}
                    fields={responseFields}
                    editable={false}
                    submitting={false}
                    editing={false}
                    ctaLabel={intl.formatMessage({id: 'planCalculator.calculate'})}
                    onSubmit={() => {}}
                    toggleEdit={null}
                    submittingLabel=""
                    initialState={response}
                /> 
                    <button onClick={() => setResponse(undefined)} className="btn btn-primary mt-5 d-block ms-auto">
                    <span className="fas fa-icon fa-arrow-left me-2"></span>
                    {intl.formatMessage({id: 'planCalculator.calculateAgain'})}
                </button>
            </div>
        </section>
    </>
}