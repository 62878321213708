import {ResponsiveLine} from '@nivo/line'
import moment from 'moment'
import {useIntl} from 'react-intl'
import {Modal} from 'react-bootstrap'
import {useState} from 'react'
import KpiDetail from './KpiDetail'
import {toast} from 'react-toastify'

interface Props {
  title?: string
  data?: any[]
  loading?: boolean
  onPointClick?: (point) => void
  serieField?: string
  visibleSeries?: string[]
}

export default function LineChart({
  title = '',
  data = [],
  loading = false,
  onPointClick,
  serieField = '',
  visibleSeries = undefined,
}: Props) {
  const intl = useIntl()
  const [selectedPoint, setSelectedPoint] = useState(null)

  function getDataSeries() {
    let series = []

    data?.forEach((item) => {
      let serie = series.find((serie) => serie.id === item[serieField])
      if (!serie) {
        serie = {
          id: item[serieField],
          data: [
            {
              x: item.date,
              y: item.value,
              has_details: item.has_details,
              id: item.id,
            },
          ],
        }
        series.push(serie)
      } else {
        serie.data.push({
          x: item.date,
          y: item.value,
          has_details: item.has_details,
          id: item.id,
        })
      }
    })

    series.forEach(function fillHolesWithZeros(serie) {
      const firstDate = moment.min(series.map((serie) => moment(serie.data[0].x)))
      const lastDate = moment.max(
        series.map((serie) => moment(serie.data[serie.data.length - 1].x))
      )

      const days = lastDate.diff(firstDate, 'days')
      const dates = []
      for (let i = 0; i < days; i++) {
        dates.push(firstDate.clone().add(i, 'days').format('YYYY-MM-DD'))
      }
      const missingDates = dates.filter((date) => !serie.data.find((item) => item.x === date))
      missingDates.forEach((date) => {
        serie.data.push({
          x: date,
          y: 0,
          has_details: false,
          id: null,
        })
      })
      serie.data.sort((a, b) => moment(a.x).diff(moment(b.x)))
    })

    if (typeof visibleSeries !== 'undefined') {
      return series.filter((serie) => visibleSeries.includes(serie.id))
    }
    return series
  }

  function handlePointClick(point) {
    if (onPointClick) {
      onPointClick(point)
    }
    if (point?.data?.has_details) {
      setSelectedPoint(point)
    } else {
      setSelectedPoint(null)
      toast(intl.formatMessage({id: 'businessDashboard.noDetails'}), {
        type: 'warning',
      })
    }
  }

  return (
    <>
      <h2>{title}</h2>
      <div style={{height: '400px'}} className='mb-20'>
        {loading && (
          <div
            className='d-flex justify-content-center align-items-center'
            style={{height: '100%'}}
          >
            <span className='spinner-border text-primary'></span>
          </div>
        )}
        {!loading && !getDataSeries()?.length && (
          <p>{intl.formatMessage({id: 'businessDashboard.noData'})}</p>
        )}
        {!loading && getDataSeries()?.length > 0 && (
          <ResponsiveLine
            data={getDataSeries()}
            margin={{top: 40, right: 200, bottom: 20, left: 40}}
            xScale={{
              type: 'time',
              format: '%Y-%m-%d',
              useUTC: false,
              precision: 'day',
            }}
            xFormat='time:%d/%m/%Y'
            axisBottom={{
              format: '%d/%m/%Y',
              tickValues: `every ${
                getDataSeries()?.[0]?.data?.length > 12
                  ? Math.ceil(getDataSeries()?.[0]?.data?.length / 12)
                  : 1
              } days`,
            }}
            yScale={{
              type: 'linear',
              min: 0,
              max:
                getDataSeries()?.length > 0
                  ? Math.max(
                      ...[]
                        .concat(...getDataSeries().map((serie) => serie.data))
                        .map((point) => point.y)
                    ) * 1.2
                  : 0,
              stacked: false,
            }}
            pointLabelYOffset={-200}
            pointSize={10}
            pointColor='white'
            pointBorderWidth={2}
            pointBorderColor={{from: 'serieColor'}}
            legends={[
              {
                toggleSerie: true,
                anchor: 'right',
                direction: 'column',
                justify: false,
                translateX: 200,
                translateY: 0,
                itemsSpacing: 0,
                itemDirection: 'left-to-right',
                itemWidth: 160,
                itemHeight: 20,
                itemOpacity: 0.75,
                symbolSize: 12,
                symbolShape: 'circle',
                symbolBorderWidth: 2,
                effects: [
                  {
                    on: 'hover',
                    style: {
                      itemBackground: 'rgba(0, 0, 0, .03)',
                      itemOpacity: 1,
                    },
                  },
                ],
              },
            ]}
            tooltip={({point}) => {
              return (
                <div className='card p-3 shadow'>
                  <div className='d-flex align-items-center'>
                    <span className='fas fa-circle me-3' style={{color: point.serieColor}} />
                    <span className='fw-bolder'>{point.serieId}</span>
                  </div>
                  <div className='d-flex align-items-center mt-3'>
                    <span className='fas fa-calendar-alt me-3 text-muted' />
                    <span className='text-muted'>{moment(point.data.x).format('DD/MM/YYYY')}</span>
                  </div>
                  <div className='d-flex align-items-center mt-3'>
                    <span className='fas fa-paw me-3 text-muted' />
                    <span className='text-muted'>{point.data.yFormatted}</span>
                  </div>
                </div>
              )
            }}
            onClick={handlePointClick}
            isInteractive={true}
            useMesh={true}
          />
        )}
      </div>
      <Modal
        show={!!selectedPoint}
        onHide={() => setSelectedPoint(null)}
        size='lg'
        centered
        scrollable={true}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <div className='mb-1'>
              {title}{' '}
              {intl.formatMessage({
                id: 'businessDashboard.fromTheDate',
              })}{' '}
              {selectedPoint?.data?.xFormatted}
            </div>
            <div className='fs-6 text-muted mb-0'>{selectedPoint?.serieId}</div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <KpiDetail id={selectedPoint?.data?.id} />
        </Modal.Body>
      </Modal>
    </>
  )
}
