import axios from 'axios'
import {DeliveryChangeModel} from '../models/DeliveryChangeModel'

const API_URL = process.env.REACT_APP_API_URL
const ORDERS_DELIVERY_CHANGES_URL = `${API_URL}/api/orders/delivery-changes/`
const DELIVERY_CHANGES_URL = `${API_URL}/api/delivery-changes/`

type Props = {
  done?: number
  promise_date__gte?: string
  promise_date__lte?: string
}

export function listDeliveryChanges({
  done,
  promise_date__gte,
  promise_date__lte,
}: Props): Promise<{data: Array<DeliveryChangeModel>}> {
  return axios.get(ORDERS_DELIVERY_CHANGES_URL, {
    params: {
      done,
      promise_date__gte,
      promise_date__lte,
    },
  })
}

export function updateDeliveryChange({
  id,
  done,
  observations,
  reason,
  is_recurrent,
}: DeliveryChangeModel ): Promise<{data: DeliveryChangeModel}> {
  return axios.patch(`${DELIVERY_CHANGES_URL}${id}/`, {
    done,
    observations,
    reason,
    is_recurrent,
  })
}

export function deleteDeliveryChange(id: number): Promise<{data: DeliveryChangeModel}> {
  return axios.delete(`${DELIVERY_CHANGES_URL}${id}/`)
}
