import {useIntl} from 'react-intl'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {default as OrderTable} from './OrderTable'
import {useEffect} from 'react'
import {actions, orderStatusSelector, ordersSelector} from '../redux/OrderRedux'
import {currentUserSelector} from '../../user-profile'
import {OrderListModel} from '../models/OrderListModel'
import { downloadInvoices } from '../services/OrderCRUD'

export default function OrderList() {
  const intl = useIntl()
  const dispatch = useDispatch()
  const currentUser = useSelector(currentUserSelector, shallowEqual)
  const orders: OrderListModel = useSelector(ordersSelector, shallowEqual)
  const status = useSelector(orderStatusSelector, shallowEqual)

  useEffect(() => {
    dispatch(actions.listOrders(currentUser?.id, 1))
  }, [dispatch, currentUser?.id])

  const onBulkDownloadInvoices = (orderIds: number[]) => {
    try {
      downloadInvoices({ 
        orderIds,
        userId: currentUser?.id
      })
    } catch (error) {
      console.warn(error)
    } finally {
    }

  }

  return (
    <main className='card p-10'>
      <h1 className='text-2xl font-bold mb-8'>{intl.formatMessage({id: 'orders.title'})}</h1>
      <OrderTable
        orders={orders?.results}
        page={orders?.page}
        count={orders?.count}
        loading={status === 'loading_list_orders'}
        displayPetName={true}
        onRefresh={() => dispatch(actions.listOrders(currentUser.id, orders.page))}
        onPageChange={(page) => dispatch(actions.listOrders(currentUser?.id, page))}
        emptyMessage={intl.formatMessage({id: 'orders.noOrders'})}
        onBulkDownloadInvoices={onBulkDownloadInvoices}
        displayBulkActions={true}
      />
    </main>
  )
}
