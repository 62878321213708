import {useEffect, useState} from 'react'
import {getKpiDetails} from '../services/DashboardCRUD'
import {useIntl} from 'react-intl'
import {Link} from 'react-router-dom'

interface Props {
  id?: number
}

export default function KpiDetail({id = null}: Props) {
  const [details, setDetails] = useState([])
  const [loading, setLoading] = useState(false)
  const intl = useIntl()

  async function fetchKpiDetails(id) {
    try {
      setLoading(true)
      const {data} = await getKpiDetails({id})
      setDetails(data)
    } catch (err) {
      console.warn(err)
      setDetails([])
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (id) {
      fetchKpiDetails(id)
    } else {
      setDetails([])
    }
  }, [id])

  function getInfoFields() {
    const keys = details.map((detail) => Object.keys(detail?.info))
    const uniqueKeys = Array.from(new Set(keys.flat())).filter(
      (key) => key.includes('task_id') || !key.includes('_id')
    )
    return uniqueKeys
  }

  function getLink(detail, key) {
    if (!detail?.info?.user_id) {
      return '#'
    }
    switch (key) {
      case 'pet':
        return `/user/${detail?.info?.user_id}/pet/${detail?.info?.pet_id}`
      case 'plan':
        return `/user/${detail?.info?.user_id}/plan/${detail?.info?.plan_id}`
      case 'sale_order':
        return `/user/${detail?.info?.user_id}/sale-order/${detail?.info?.sale_order_id}`
      case 'task_id':
        return `/user/${detail?.info?.user_id}/task/${detail?.info?.task_id}`
      case 'user_email':
        return `/user/${detail?.info?.user_id}/profile`
      default:
        return '#'
    }
  }

  return (
    <>
      {loading && (
        <div className='d-flex justify-content-center'>
          <div className='spinner-border text-primary' role='status'>
            <span className='visually-hidden'>Loading...</span>
          </div>
        </div>
      )}
      {!loading && details?.length > 0 && (
        <table className='table table-hover table-row-dashed table-row-gray-200 align-middle gs-0 gy-4'>
          <thead className='table-header'>
            <tr className='fw-bolder'>
              {getInfoFields().map((key) => (
                <th key={key}>
                  {key
                    .split('_')
                    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                    .join(' ')}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {details.map((detail) => (
              <tr key={detail.id}>
                {getInfoFields().map((key) => (
                  <td key={detail.id + key}>
                    <Link to={getLink(detail, key)}>{detail?.info[key] || ''}</Link>
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      )}
      {!loading && !details?.length && (
        <p>{intl.formatMessage({id: 'businessDashboard.noDetailsThisDay'})}</p>
      )}
    </>
  )
}
