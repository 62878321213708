/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {shallowEqual, useSelector} from 'react-redux'
import {useIntl} from 'react-intl'

import {UserModel} from '../../../../app/modules/auth/models/UserModel'
import {Languages} from './Languages'
import * as auth from '../../../../app/modules/auth/redux/AuthRedux'
import {useDispatch} from 'react-redux'
import {toAbsoluteUrl} from '../../../helpers'
import {Link} from 'react-router-dom'
import superUsers from '../../../../app/modules/tasks/constants/superUsers'
import groupSupervisors from '../../../../app/modules/tasks/constants/groupSupervisors'
import {
  authorizedBusinessDashboardUsers,
  authorizedAtcDashboardUsers,
} from '../../../../app/modules/dashboard'

const HeaderUserMenu: FC = () => {
  const intl = useIntl()
  const user: UserModel = useSelector(auth.authUserSelector, shallowEqual) as UserModel
  const isB2BUser = !!user?.groups?.find((aGroup) => aGroup.name === 'B2B')
  const dispatch = useDispatch()
  const logout = () => {
    dispatch(auth.actions.logout())
  }

  function getBadge() {
    if (superUsers.includes(user?.email)) {
      intl.formatMessage({id: 'app.superuser'})
    }
    if (groupSupervisors.includes(user?.email)) {
      intl.formatMessage({id: 'app.supervisor'})
    }
    return intl.formatMessage({id: 'app.staff'})
  }

  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px'
      data-kt-menu='true'
    >
      <div className='menu-item px-3'>
        <div className='menu-content d-flex align-items-center px-3'>
          <div className='symbol symbol-50px me-5'>
            <img alt='' src={toAbsoluteUrl('/media/avatars/300-1.jpg')} />
          </div>
          <div className='d-flex flex-column'>
            <div className='fw-bolder d-flex align-items-center fs-5'>
              <span style={{maxWidth: '130px'}} className='text-truncate'>
                {user?.name} {user?.last_name}
              </span>
              <span className='badge badge-primary fw-bolder fs-8 px-2 py-1 ms-2'>
                {getBadge()}
              </span>
            </div>
            <span
              style={{maxWidth: '170px'}}
              className='fw-bold text-muted text-truncate fs-7 mt-1'
            >
              {user?.email}
            </span>
          </div>
        </div>
      </div>
      <div className='separator my-2'></div>
      <div className='menu-item px-5'>
        <Link to='/tasks-per-agent' className='menu-link px-5'>
          <span className='fas fa-eye me-4'></span>
          {intl.formatMessage({id: 'app.tasksPerAgent'})}
        </Link>
      </div>
      {superUsers.includes(user?.email) && (
        <div className='menu-item px-5'>
          <Link to='/agent-performance' className='menu-link px-5'>
            <span className='fas fa-headset me-4'></span>
            {intl.formatMessage({id: 'app.agentPerformance'})}
          </Link>
        </div>
      )}
      {authorizedBusinessDashboardUsers.includes(user?.email) && (
        <div className='menu-item px-5'>
          <Link to='/influencer-stats' className='menu-link px-5'>
            <span className='fas fa-poll me-4'></span>
            {intl.formatMessage({id: 'app.influencerStats'})}
          </Link>
        </div>
      )}
      {
        isB2BUser && <div className='menu-item px-5'>
          <Link to='/lead-distributor-report' className='menu-link px-5'>
            <span className='fas fa-chart-line me-4'></span>
            {intl.formatMessage({id: 'app.leadDistributorReport'})}
          </Link>
        </div>
      }
      {authorizedBusinessDashboardUsers.includes(user?.email) && (
        <div className='menu-item px-5'>
          <Link to='/business-dashboard' className='menu-link px-5'>
            <span className='fas fa-chart-bar me-4'></span>
            {intl.formatMessage({id: 'app.businessDashboard'})}
          </Link>
        </div>
      )}
      {authorizedBusinessDashboardUsers.includes(user?.email) && (
        <div className='menu-item px-5'>
          <Link to='/segmented-report' className='menu-link px-5'>
            <span className='fas fa-chart-pie me-4'></span>
            {intl.formatMessage({id: 'app.segmentedReport'})}
          </Link>
        </div>
      )}
      {authorizedBusinessDashboardUsers.includes(user?.email) && (
        <div className='menu-item px-5'>
          <Link to='/loyalty-rewards' className='menu-link px-5'>
            <span className='fas fa-trophy me-4'></span>
            {intl.formatMessage({id: 'app.loyaltyRewards'})}
          </Link>
        </div>
      )}
      {
        authorizedBusinessDashboardUsers.includes(user?.email) && (<>
          <div className='menu-item px-5'>
            <Link to='/meal-pricing' className='menu-link px-5'>
              <span className='fas fa-dollar-sign me-4'></span>
              {intl.formatMessage({id: 'app.mealPricing'})}
            </Link>
          </div>
          <div className='menu-item px-5'>
            <Link to='/shopify-import' className='menu-link px-5'>
              <span className='fas fa-download me-4'></span>
              {intl.formatMessage({id: 'app.shopifyImport'})}
            </Link>
          </div>
        </>)
      }
      {authorizedAtcDashboardUsers.includes(user?.email) && (
        <div className='menu-item px-5'>
          <Link to='/atc-dashboard' className='menu-link px-5'>
            <span className='fas fa-chart-area me-4'></span>
            {intl.formatMessage({id: 'app.atcDashboard'})}
          </Link>
        </div>
      )}
      <Languages />
      <div className='menu-item px-5'>
        <a onClick={logout} className='menu-link px-5'>
          <span className='fas fa-sign-out-alt me-4'></span>
          {intl.formatMessage({id: 'app.signOut'})}
        </a>
      </div>
    </div>
  )
}

export {HeaderUserMenu}
