import axios from 'axios'
import MealPricingModel from '../models/MealPricingModel'

const API_URL = process.env.REACT_APP_API_URL
export function listMealPricing() {
    const endpoint = `${API_URL}/api/b2c/meal-pricing/`

    return axios.get<Array<MealPricingModel>>(endpoint)
}

export function updateMealPricing(mealPricing: MealPricingModel) {
    const endpoint = `${API_URL}/api/b2c/meal-pricing/${mealPricing.id}/`

    return axios.patch<MealPricingModel>(endpoint, {
        pvp: mealPricing.pvp,
        cogs_cost: mealPricing.cogs_cost,
    })
}