import {useIntl} from 'react-intl'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'

import {FormField, GenericForm} from '../../../components/GenericForm'
import {OrderModel} from '../models/OrderModel'
import {actions, orderErrorSelector, orderStatusSelector, selectedOrderSelector} from '../redux/OrderRedux'
import { currentUserSelector } from '../../user-profile'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { listShippingServices } from '..'
import isEditable from '../services/isEditable'

export default function OrderDetail() {
  const intl = useIntl()
  const dispatch = useDispatch()
  const [isEditing, setIsEditing] = useState(false)
  const [shippingServices, setShippingServices] = useState([])
  const selectedOrder = useSelector(selectedOrderSelector, shallowEqual)
  const currentUser = useSelector(currentUserSelector, shallowEqual)
  const status = useSelector(orderStatusSelector, shallowEqual)
  const error = useSelector(orderErrorSelector, shallowEqual)

  const fields: Array<FormField<OrderModel>> = [
    {
      id: 'code',
      label: intl.formatMessage({id: 'orderDetail.code'}),
      inputType: 'text',
      readonly: true,
    },
    {
      id: 'kind',
      label: intl.formatMessage({id: 'orderDetail.kind'}),
      inputType: 'text',
      readonly: true,
    },
    {
      id: 'state',
      label: intl.formatMessage({id: 'orderDetail.state'}),
      inputType: 'text',
      readonly: true,
    },
    {
      id: 'days',
      label: intl.formatMessage({id: 'orderDetail.days'}),
      inputType: 'text',
      readonly: true,
    },
    {
      id: 'combine',
      label: intl.formatMessage({id: 'orderDetail.combine'}),
      inputType: 'checkbox',
      readonly: true,
    },
    {
      id: 'is_trial',
      label: intl.formatMessage({id: 'orderDetail.isTrial'}),
      inputType: 'checkbox',
      readonly: true,
    },
    {
      id: 'total_price_inc_tax',
      label: intl.formatMessage({id: 'orderDetail.totalPrice'}),
      inputType: 'text',
      readonly: true,
      units: '€',
    },
    {
      id: 'total_weight_in_grs',
      label: intl.formatMessage({id: 'orderDetail.totalWeight'}),
      inputType: 'text',
      readonly: true,
      units: 'gr',
    },
    {
      id: 'gross_margin_ratio',
      label: intl.formatMessage({id: 'orderDetail.grossMargin'}),
      inputType: 'number',
      readonly: true,
      getter: (anOrder: OrderModel) =>
         typeof anOrder?.gross_margin_ratio  === 'number'? (anOrder?.gross_margin_ratio * 100).toFixed(2) : 0,
      units: '%',
    },
    {
      id: 'net_shipping_inc_tax',
      label: intl.formatMessage({id: 'orderDetail.shippingPrice'}),
      inputType: 'text',
      readonly: true,
      units: '€',
    },
    isEditing ?
    {
      id: 'shipping_address',
      label: intl.formatMessage({id: 'orderDetail.shippingAddress'}),
      inputType: 'select',
      options: currentUser?.shipping_addresses?.map((address) => ({
        label: `${address?.street}, ${address?.postcode} ${address?.city}, ${address?.country}`,
        value: address?.id?.toString(),
      })),
      getter: (anOrder: OrderModel) => anOrder?.shipping_address?.id?.toString() || '',
      setter: (anOrder: OrderModel, value: string) => {
        const address = currentUser?.shipping_addresses?.find((anAddress) => anAddress?.id?.toString() === value)
        return {...anOrder, shipping_address: address}
      }
    } : {
      id: 'shipping_address',
      label: intl.formatMessage({id: 'orderDetail.shippingAddress'}),
      inputType: 'text',
      readonly: true,
      getter: (anOrder: OrderModel) => `${anOrder?.shipping_address?.street}, ${anOrder?.shipping_address?.postcode} ${anOrder?.shipping_address?.city}, ${anOrder?.shipping_address?.country}`,
    }, 
    {
      id: 'shipping_service',
      label: intl.formatMessage({id: 'orderDetail.shippingService'}),
      inputType: 'select',
      options: shippingServices
        ?.sort((a, b) => b?.priority - a?.priority)
        ?.map((service) => ({
          label: service?.name,
          value: service?.id?.toString(),
        })),
      getter: (anOrder: OrderModel) => anOrder?.shipping_service?.toString() || '',
      setter: (anOrder: OrderModel, value: string) => {
        return {...anOrder, shipping_service: parseInt(value, 10)}
      },
    },
    {
      id: 'tracking_links',
      label: intl.formatMessage({id: 'orderDetail.trackingLinks'}),
      inputType: 'text',
      readonly: true,
      embedHtml: true,
      getter: (anOrder: OrderModel) =>
        anOrder?.tracking_links
          ?.map(
            (link: string, i) => `<a href="${link}" target="_blank">
          ${intl.formatMessage({id: 'orderDetail.trackingLink'})} #${i + 1}
        </a>`
          )
          .join('<br>') || '-',
    },
    {
      id: 'label_links',
      label: intl.formatMessage({id: 'orderDetail.labelLinks'}),
      inputType: 'text',
      readonly: true,
      embedHtml: true,
      getter: (anOrder: OrderModel) =>
        anOrder?.label_links
          ?.map(
            (link: string, i) => `<a href="${link}" target="_blank">
          ${intl.formatMessage({id: 'orderDetail.labelLink'})} #${i + 1}
        </a>`
          )
          .join('<br>') || '-',
    },
    {
      id: 'is_merged',
      label: intl.formatMessage({id: 'orderDetail.isMerged'}),
      inputType: 'checkbox',
      readonly: true,
    },
    {
      id: 'parent_sale_order',
      label: intl.formatMessage({id: 'orderDetail.parentSaleOrder'}),
      inputType: 'text',
      readonly: true,
      getter: (anOrder: OrderModel) => anOrder?.parent_sale_order?.code? <a href={`/user/${currentUser?.id}/order/${anOrder?.parent_sale_order?.id}`}>
        {anOrder?.parent_sale_order?.code}
      </a> : '-',
    },
    {
      id: 'is_merged_parent',
      label: intl.formatMessage({id: 'orderDetail.isMergedParent'}),
      inputType: 'checkbox',
      readonly: true,
    },

    {
      id: 'is_reship',
      label: intl.formatMessage({id: 'orderDetail.isReship'}),
      inputType: 'checkbox',
      readonly: true,
    },
    {
      id: 'reship_reason',
      label: intl.formatMessage({id: 'orderDetail.reshipReason'}),
      inputType: 'text',
      readonly: true,
    },
  ]

  function onSubmit(editedOrder: OrderModel) {
    const hasConfirmed = window.confirm(intl.formatMessage({id: 'orderDetail.confirmEdit'}))
    const isValidAddress = currentUser?.shipping_addresses?.find((address) => address?.id === editedOrder?.shipping_address?.id)
    const hasModifiedAddress = selectedOrder?.shipping_address?.id !== editedOrder?.shipping_address?.id
    const hasModifiedShippingService = selectedOrder?.shipping_service !== editedOrder?.shipping_service

    if (hasConfirmed && hasModifiedAddress && isValidAddress) {
      dispatch(actions.updateShippingAddress(editedOrder))
    }

    if (hasConfirmed && hasModifiedShippingService) {
      dispatch(actions.updateShippingService(editedOrder))
    }

    if (hasConfirmed && (hasModifiedAddress || hasModifiedShippingService)) {
      toast.success(intl.formatMessage({id: 'orderDetail.success'}))
    }

    setIsEditing(false)
  }

  async function fetchShippingServices() {
    const { data: services } = await listShippingServices()
    setShippingServices(services)
  }

  useEffect(() => {
    fetchShippingServices()
  }, [])

  return (
    <section className='card p-10'>
      <GenericForm
        editable={isEditable({ order: selectedOrder, authUser: currentUser })}
        error={error}
        columns={3}
        title={intl.formatMessage({id: 'orderDetail.basicInfo'})}
        fields={fields}
        initialState={selectedOrder}
        ctaLabel={intl.formatMessage({id: 'orderDetail.ctaLabel'})}
        submitting={status === 'loading_update_selected_order'}
        toggleEdit={() => setIsEditing(!isEditing)}
        onSubmit={onSubmit}
        editing={isEditing}
        loading={status === 'loading_selected_order'}
        submittingLabel={intl.formatMessage({id: 'orderDetail.loading'})}
      />
    </section>
  )
}
