import moment from 'moment'
import {useState} from 'react'
import {useIntl} from 'react-intl'
import {DateRange} from 'react-date-range'
import {es} from 'react-date-range/dist/locale'
import {KTSVG} from '../../_metronic/helpers'

interface Props {
  initialSince: Date
  initialUntil: Date
  onSelected: (since: Date, until: Date) => void
  disableFutureDates?: boolean
  label?: string
}

export default function DateRangeSelector({
  initialSince,
  initialUntil,
  onSelected,
  disableFutureDates = false,
  label = '',
}: Props) {
  const intl = useIntl()
  const [since, setSince] = useState<Date>(initialSince)
  const [until, setUntil] = useState<Date>(initialUntil)
  const [calendarVisible, setCalendarVisible] = useState(false)

  const selectionRange = {
    startDate: since,
    endDate: until,
    key: 'selection',
  }

  function onDateRangeChange(ranges) {
    setSince(ranges?.selection?.startDate)
    setUntil(ranges?.selection?.endDate)
  }

  function onAcceptDateRange() {
    setCalendarVisible(false)
    onSelected(since, until)
  }

  return (
    <div style={{width: '230px'}}>
      <label className='form-label'>
        {label || intl.formatMessage({id: 'dateRangeSelector.selectDateRange'})}
      </label>
      <div
        className='d-flex align-items-center rounded px-4 pt-4 pb-3 border border-1 border-secondary cursor-pointer'
        onClick={() => setCalendarVisible(!calendarVisible)}
      >
        <span className='fas fa-calendar-alt me-3 mb-1 text-muted' />
        {since && until ? (
          <>
            {moment(since).format('DD/MM/YYYY')} - {moment(until).format('DD/MM/YYYY')}
          </>
        ) : (
          <span className='text-muted'>
            {intl.formatMessage({id: 'dateRangeSelector.placeholder'})}
          </span>
        )}
        <span className='fas fa-chevron-down ms-auto text-muted' />
      </div>
      {calendarVisible && (
        <div style={{
          zIndex: 2,
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          background: 'rgba(0, 0, 0, 0.3)',
          backdropFilter: 'blur(5px)',
        }}>
          <div 
            className='position-fixed card p-3 shadow' 
            style={{
              zIndex: 3,
              top: 140,
              left: 100,
            }}
          >
            <button
              type='button'
              className='position-absolute top-0 end-0 m-2 btn btn-sm btn-icon btn-active-light-primary'
              onClick={() => setCalendarVisible(false)}
            >
              <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
            </button>
            <DateRange
              locale={es}
              disabledDay={(date) =>
                disableFutureDates ? date > moment().subtract(1, 'days').toDate() : false
              }
              showSelectionPreview={false}
              showMonthAndYearPickers={false}
              showMonthArrow={false}
              showDateDisplay={false}
              showPreview={false}
              weekStartsOn={1}
              scroll={{enabled: true}}
              staticRanges={[]}
              hasCustomRendering={true}
              ranges={[selectionRange]}
              defaultRanges={[]}
              onChange={onDateRangeChange}
              placeholder={intl.formatMessage({id: 'dateRangeSelector.dateRangePlaceholder'})}
              calendarIcon={<span className='fas fa-calendar-alt' />}
            />
            <button className='btn btn-primary mt-5' onClick={onAcceptDateRange}>
              {intl.formatMessage({id: 'dateRangeSelector.filterByDateRange'})}
            </button>
          </div>
        </div>
      )}
    </div>
  )
}
