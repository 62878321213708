/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {useIntl} from 'react-intl'

import {usePathProps} from '../../modules/user-profile/hooks/usePathProps'
import {PageTitle} from '../../../_metronic/layout/core'
import {LeadCallRequestsTable} from '../../modules/tasks'

const LeadCallRequestsComponent: FC = () => {
  return (
    <>
      <LeadCallRequestsTable />
    </>
  )
}

const LeadCallRequestsPage: FC = () => {
  const intl = useIntl()
  usePathProps()

  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'leadCallRequests.title'})}</PageTitle>
      <LeadCallRequestsComponent />
    </>
  )
}

export {LeadCallRequestsPage}
