import { InvoiceStateEnum } from '../models/InvoiceModel';
import axios from 'axios'
import {InvoiceModel} from '../models/InvoiceModel'

const API_URL = process.env.REACT_APP_API_URL
const INVOICES_URL = `${API_URL}/api/b2b/invoices/`
export const INVOICES_PER_PAGE = 20

interface ListInvoicesResponse {
  results: Array<InvoiceModel>,
  count: number,
}

interface ListInvoicesProps {
  distributorId: number,
  page: number,
  state: InvoiceStateEnum | null,
  isProforma: boolean | null, 
  isCredit: boolean | null,
}

export function listInvoices({
  distributorId, 
  page = 1,
  state = null,
  isProforma = null,
  isCredit = null,
}: ListInvoicesProps): Promise<{data: ListInvoicesResponse}> {
  return axios.get(INVOICES_URL, {
    params: {
      distributor: distributorId,
      offset: (page - 1) * INVOICES_PER_PAGE,
      limit: INVOICES_PER_PAGE,
      state,
      is_proforma: isProforma,
      is_credit: isCredit,
    },
  })
}

export function getInvoiceById(id: number): Promise<{data: InvoiceModel}> {
  const endpoint = `${INVOICES_URL}${id}/`
  return axios.get(endpoint)
}

export function createInvoice({orderId, date, isProforma = false}) {
  return axios.post(INVOICES_URL, {
    order: orderId,
    date,
    is_proforma: isProforma,
    is_credit: false,
  })
}

export function updateInvoice(invoice: InvoiceModel) {
  const endpoint = `${INVOICES_URL}${invoice.id}/`
  return axios.patch(endpoint, {...invoice})
}

export function generateEdiFile(invoiceId: number) {
  const endpoint = `${INVOICES_URL}${invoiceId}/generate-edi-file/`
  return axios.post(endpoint)
}

export function generatePdfFile(invoiceId: number) {
  const endpoint = `${INVOICES_URL}${invoiceId}/generate-pdf/`
  return axios.post(endpoint)
}

export function updateInvoiceNumber(invoiceNumber: string) {
  const endpoint = `${INVOICES_URL}invoice-number/`
  return axios.patch(endpoint, {next_num: invoiceNumber})
}

export function createInvoicePreview({ orderId }) {
  const endpoint = INVOICES_URL;

  return axios.post(endpoint, {
    order: orderId,
    is_preview: true,
  });
}

export function confirmInvoicePreview({ invoiceId }) {
  const endpoint = `${INVOICES_URL}invoice-preview-decision/`;

  return axios.post(endpoint, {
    invoice: invoiceId,
    accept: true,
  });
}

export function discardInvoicePreview({ invoiceId }) {
  const endpoint = `${INVOICES_URL}invoice-preview-decision/`;

  return axios.post(endpoint, {
    invoice: invoiceId,
    accept: false,
  });
}