/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'

import {PageTitle} from '../../../_metronic/layout/core'
import {
  ShopList,
  ModalShopForm,
  ShopModel,
  listShops,
  createShop,
  updateShop,
  deleteShop,
} from '../../modules/shops'
import {toast} from 'react-toastify'

const ShopsPage: FC = () => {
  const intl = useIntl()
  const [shops, setShops] = useState([])
  const [loading, setLoading] = useState(false)
  const [modalFormVisible, setModalFormVisible] = useState(false)
  const [editingShop, setEditingShop] = useState<ShopModel | undefined>(undefined)

  const fetchShops = async () => {
    try {
      setLoading(true)
      const {data} = await listShops()
      setShops(data)
    } catch (error) {
      console.warn(error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchShops()
  }, [])

  function onAddShop() {
    setEditingShop(undefined)
    setModalFormVisible(true)
  }

  async function onSaveShop(shop: ShopModel) {
    setModalFormVisible(false)
    setLoading(true)
    try {
      if (shop.id) {
        const response = await updateShop(shop)
        setShops([...shops.filter((s) => s.id !== shop.id), response.data])
      } else {
        const response = await createShop(shop)
        setShops([...shops, response.data])
      }
      fetchShops()
      toast.success(intl.formatMessage({id: 'shops.saved'}))
    } catch (error) {
      console.warn(error)
      toast.error(intl.formatMessage({id: 'shops.error'}))
    } finally {
      setLoading(false)
    }
  }

  async function onRemoveShop(shop: ShopModel) {
    const hasConfirmed = window.confirm(intl.formatMessage({id: 'shops.confirmRemove'}))
    if (!hasConfirmed) {
      return
    }

    setLoading(true)
    try {
      await deleteShop(shop.id)
      setShops(shops.filter((s) => s.id !== shop.id))
      fetchShops()
      toast.success(intl.formatMessage({id: 'shops.removed'}))
    } catch (error) {
      console.warn(error)
    } finally {
      setLoading(false)
    }
  }

  function onEditShop(shop: ShopModel) {
    setEditingShop(shop)
    setModalFormVisible(true)
  }

  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'menu.shops'})}</PageTitle>
      <div className='row'>
        <div className='col-12'>
          <div className='card p-10'>
            <ShopList
              shops={shops}
              loading={loading}
              onAddShop={onAddShop}
              onEditShop={onEditShop}
              onRemoveShop={onRemoveShop}
            />
            <ModalShopForm
              shop={editingShop}
              visible={modalFormVisible}
              onCancel={() => setModalFormVisible(false)}
              onSaveShop={onSaveShop}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export {ShopsPage}
