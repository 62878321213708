import ReactDOM from 'react-dom'
// Redux
// https://github.com/rt2zz/redux-persist
import {PersistGate} from 'redux-persist/integration/react'
import {Provider} from 'react-redux'
import axios from 'axios'
import {Chart, registerables} from 'chart.js'

import * as _redux from './setup'
import store, {persistor} from './setup/redux/Store'
import {App} from './app/App'
import {MetronicI18nProvider} from './_metronic/i18n/Metronici18n'

/**
 * TIP: Replace this style import with dark styles to enable dark mode
 *
 * import './_metronic/assets/sass/style.dark.scss'
 *
 * TIP: Replace this style import with rtl styles to enable rtl mode
 *
 * import './_metronic/assets/css/style.rtl.css'
 **/
import './_metronic/assets/sass/style.scss'
import './_metronic/assets/sass/style.react.scss'
/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const {PUBLIC_URL} = process.env

/**
 * Inject Metronic interceptors for axios.
 *
 * @see https://github.com/axios/axios#interceptors
 */
_redux.setupAxios(axios, store)

Chart.register(...registerables)

const providerProps = {store}

ReactDOM.render( 
    <MetronicI18nProvider>
       <Provider {...providerProps}>
        <PersistGate
          persistor={persistor}
          loading={<img src='/media/gif/spinner.gif' alt='Loading...' />}
        >
          <App basename={PUBLIC_URL} />
        </PersistGate>
      </Provider>
    </MetronicI18nProvider>
  ,
  document.getElementById('root')
)
