import {useIntl} from 'react-intl'
import {DeliveryNoteModel} from '../models/DeliveryNoteModel'
import {Modal} from 'react-bootstrap'
import {useEffect, useState} from 'react'
import clsx from 'clsx'
import { UpdateDeliveryNoteProps } from '../services/DeliveryNoteCRUD'
import { UpdateDeliveryNoteRequestModel } from '../models/UpdateDeliveryNoteRequestModel'

type PropTypes = {
  visible?: boolean
  onClose?: () => void
  onUpdateDeliveryNote?: ({
    deliveryNoteId,
    request,
  }: UpdateDeliveryNoteProps) => void
  deliveryNote?: DeliveryNoteModel
  loading?: boolean
}

export default function ModalDeliveryNoteForm({
  visible = false,
  onClose = () => {},
  deliveryNote,
  loading = false,
  onUpdateDeliveryNote = () => {},
}: PropTypes) {
  const intl = useIntl()
  const [confirmationVisible, setConfirmationVisible] = useState(false)
  const [confirmationText, setConfirmationText] = useState('')
  const [request, setRequest] = useState<UpdateDeliveryNoteRequestModel>({
    delivery_note_lines: [],
    promise_date: '',
  })
  
  useEffect(function updateRequestState() {
    setRequest((currentRequest) => ({
      ...currentRequest,
      delivery_note_lines: deliveryNote
        ?.delivery_note_lines
          ?.map(({ id, served_units }) => ({
            id,
            served_units,
          })) || [],
    }))
  }, [deliveryNote]);

  useEffect(function resetPromiseDate() {
    setRequest((currentRequest) => ({
      ...currentRequest,
      promise_date: '',
    }))

  }, [visible])

  function isValid() {    
    if (!request?.promise_date) {
      return false;
    }
    
    let hasChanges = false;
    for (let requestLine of request?.delivery_note_lines) {
      const originalLine = deliveryNote?.delivery_note_lines?.find((line) => line.id === requestLine.id);
      
      if (originalLine.served_units !== requestLine.served_units) {
        hasChanges = true;
      }
    }

    return hasChanges;
  }

  function getMissingUnits() {
    let missingUnits = 0;
    for (let requestLine of request?.delivery_note_lines || []) {
      const originalLine = deliveryNote?.delivery_note_lines?.find((line) => line.id === requestLine.id);
      
      if (originalLine?.served_units !== requestLine?.served_units) {
        missingUnits += originalLine?.served_units - requestLine?.served_units;
      }
    }

    return missingUnits;
  }

  function canConfirm() {
    const text = confirmationText.trim().toLowerCase();
    return text === 'entiendo';
  }

  useEffect(function resetConfirmationText() {
    setConfirmationText('')
  }, [confirmationVisible])

  return (<>
    <Modal show={visible && !confirmationVisible} onHide={onClose} size='lg'>
      <Modal.Header closeButton>
        <Modal.Title>
          {intl.formatMessage({id: 'modalDeliveryNoteEditable.title'}, {code: deliveryNote?.code})}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <table className='table table-hover table-row-dashed table-row-gray-200 align-middle gs-0 gy-4'>
          <thead className='table-header'>
            <tr className='fw-bolder'>
              <th className='w-auto ps-4'>
                {intl.formatMessage({id: 'modalDeliveryNoteEditable.product'})}
              </th>
              <th className='w-100px'>
                {intl.formatMessage({id: 'modalDeliveryNoteEditable.servedUnits'})}
              </th>
              <th className='w-150px'>
                {intl.formatMessage({id: 'modalDeliveryNoteEditable.availableUnits'})}
              </th>
              <th className='w-100px'>
                {intl.formatMessage({id: 'modalDeliveryNoteEditable.remainingUnits'})}
              </th>
            </tr>
          </thead>
          <tbody>
            { 
              deliveryNote?.delivery_note_lines?.map((deliveryNoteLine, index) => {
                const product = deliveryNoteLine.order_line.name;
                const servedUnits = deliveryNoteLine.served_units;
                const availableUnits = request?.delivery_note_lines?.find((aLine) => 
                  aLine.id === deliveryNoteLine.id
                )?.served_units || 0;
                
                return (
                  <tr key={index} className='align-middle'>
                    <td className='ps-4'>{product}</td>
                    <td className=''>{servedUnits}</td>
                    <td>
                      <input
                        type='number'
                        className={clsx('form-control', availableUnits > servedUnits && 'is-invalid')}
                        value={availableUnits}
                        min={0}
                        max={servedUnits}
                        onChange={(e) => {
                          const newValue = parseInt(e.target.value, 10)

                          setRequest((currentRequest) => ({
                            ...currentRequest,
                            delivery_note_lines: currentRequest.delivery_note_lines.map(
                              (aLine) =>
                                aLine.id === deliveryNoteLine.id
                                  ? {
                                      ...aLine,
                                      served_units: newValue || 0,
                                    }
                                    : aLine
                              ),
                            }))
                          }}
                      />
                    </td>
                    <td className=''>
                      {servedUnits - availableUnits}
                    </td>
                  </tr>
                )
              })
            }
          </tbody>
        </table>
        <div className='form-group ms-auto mt-8 mb-2 w-300px'>
          <label htmlFor='date' className='mb-1 fw-bolder'>
            {intl.formatMessage({id: 'modalDeliveryNoteEditable.promiseDate'})}
          </label>
          <input
            required
            type='date'
            className='form-control'
            id='date'
            value={request?.promise_date}
            onChange={(e) => setRequest({...request, promise_date: e.target.value})}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button className='btn btn-tertiary' onClick={onClose}>
          {intl.formatMessage({id: 'modalDeliveryNoteEditable.cancel'})}
        </button>
        <button
          disabled={!isValid() || loading}
          className='btn btn-primary'
          onClick={() => setConfirmationVisible(true)}
        >
          {intl.formatMessage({id: 'modalDeliveryNoteEditable.confirm'})}
        </button>
      </Modal.Footer>
    </Modal>
    <Modal 
      show={confirmationVisible} 
      onHide={() => setConfirmationVisible(false)}  
    >
      <form onSubmit={(e) => {
        e.preventDefault()
        onUpdateDeliveryNote({
          deliveryNoteId: deliveryNote?.id,
          request,
        })
        setConfirmationVisible(false)
      }}>
        <Modal.Header closeButton>
          <Modal.Title>
            {intl.formatMessage({id: 'modalDeliveryNoteEditable.confirmationTitle'})}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            {
              intl.formatMessage(
                {
                  id: 'modalDeliveryNoteEditable.confirmationMessage'
                }, 
                {
                  promiseDate: request?.promise_date?.split('-').reverse().join('/'),
                  missingUnits: getMissingUnits() || 0,
                  strong: str => <b>{str}</b>,
                  br:  _str => <br />
                }
              )
            }
          </p>
          <input
            type='text'
            className='form-control'
            placeholder={intl.formatMessage({id: 'modalDeliveryNoteEditable.confirmationPlaceholder'})}
            value={confirmationText}
            onChange={(e) => setConfirmationText(e.target.value)}
            autoFocus={true}
          />
        </Modal.Body>
        <Modal.Footer>
          <button className='btn btn-tertiary' onClick={() => setConfirmationVisible(false)}>
            {intl.formatMessage({id: 'modalDeliveryNoteEditable.cancel'})}
          </button>
          <button
            disabled={!canConfirm() || loading }
            className='btn btn-primary'
            type='submit'
          >
            {intl.formatMessage({id: 'modalDeliveryNoteEditable.confirm'})}
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  </>)
}
