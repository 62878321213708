import clsx from 'clsx'
import { useContext, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { shallowEqual, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import Select from 'react-select'
import { toast } from 'react-toastify'
import AircallContext from '../../../../_metronic/layout/AircallContext'
import { authUserSelector } from '../../auth'
import { UserModel } from '../../auth/models/UserModel'
import superUsers from '../constants/superUsers'
import { TaskProfileActionModel } from '../models/TaskProfileActionModel'
import { TaskSetResolutionModel } from '../models/TaskSetResolutionModel'
import { launchAction } from '../services/TaskCRUD'
import { solvableStates } from './Task'
import TaskComments from './TaskComments'
import SPECIAL_RESOLUTION_CODES from '../constants/specialResolutionCodes'
import moment from 'moment'
import { staffUsersSelector } from '../redux/TaskSelectors'
import { Source } from '../models/TaskSetResolutionModel'

export default function TaskInfo({ task, displayOwner, onAddComment, onResolve }) {
  const intl = useIntl()
  const user: UserModel = useSelector(authUserSelector, shallowEqual) as UserModel
  const aircallContext = useContext(AircallContext)
  const [completeActions, setCompleteActions] = useState<Set<number>>(new Set())
  const [selectedResolution, setSelectedResolution] = useState<string>('')
  const [selectedChildResolution, setSelectedChildResolution] = useState<string>('')
  const [selectedSubChildResolution, setSelectedSubChildResolution] = useState<string>('')
  const [isFromCompetitor, setIsFromCompetitor] = useState<boolean>(false)
  const [source, setSource] = useState<Source>()
  const [competitor, setCompetitor] = useState<string>()
  const staffUsers = useSelector(staffUsersSelector, shallowEqual) as UserModel[]
  const LastContactContaniner =
    task?.user?.id && task?.user_last_interaction?.task ? Link : (props: any) => <div {...props} />

  function getStaffUserName(id) {
    const user = staffUsers.find((user) => user.id === id)
    return user?.name
  }

  useEffect(() => {
    if (task?.id) {
      setSelectedResolution('')
      setCompleteActions(new Set())
      setIsFromCompetitor(false)
      setSource(undefined)
      setCompetitor(undefined)
    }
  }, [task?.id])

  useEffect(() => {
    setSelectedChildResolution(null)
  }, [selectedResolution])

  useEffect(() => {
    setSelectedSubChildResolution(null)
  }, [selectedChildResolution])

  function isThereAnUnsentComment() {
    const commentInput = document.getElementById('task-comment-input') as HTMLInputElement
    return commentInput?.value?.trim() !== '';
  }

  const handleSetResolution = (e?: any) => {
    if (isThereAnUnsentComment()) {
      alert(intl.formatMessage({ id: 'tasks.commentWillBeLost' }))
      return;
    }

    let task_resolution

    if (selectedSubChildResolution) {
      task_resolution = parseInt(selectedSubChildResolution, 10)
    } else if (selectedChildResolution) {
      task_resolution = parseInt(selectedChildResolution, 10)
    } else {
      task_resolution = parseInt(selectedResolution, 10)
    }

    const taskSetResolution: TaskSetResolutionModel = {
      task_resolution,
      source,
      is_from_competitor: isFromCompetitor,
      competitor,
    }
    const resolutionObject = task.resolutions.find(
      (resolution) => resolution.id === task_resolution
    )
    const taskType = task.task_profile.code

    if (taskType === 'new-lead' && resolutionObject.name === 'Managed') {
      const hasConfirmed = window.confirm(intl.formatMessage({ id: 'tasks.confirmManagedLead' }))
      if (!hasConfirmed) {
        return
      }
    }

    onResolve(taskSetResolution, false, undefined)
  }

  function getActionIcon(action: TaskProfileActionModel) {
    switch (action.medium[0].code) {
      case 'whatsapp': {
        return 'fab fa-whatsapp me-3'
      }
      case 'email': {
        return 'fas fa-envelope me-3'
      }
      default: {
        return ''
      }
    }
  }

  function onActionClick(action: TaskProfileActionModel) {
    launchAction(action.id, task.id, action?.medium[0]?.code || 'email')
      .then(() => {
        setCompleteActions(new Set(completeActions).add(action.id))
        toast.success(intl.formatMessage({ id: 'tasks.actionSuccess' }))
      })
      .catch(() => toast.error(intl.formatMessage({ id: 'tasks.taskActionFailedToLaunch' })))
  }

  function onCall() {
    aircallContext.setPhoneNumber(
      task?.call_request?.phone || task?.user?.standard_phone || task?.phone || ''
    )
    aircallContext.setModalVisible(true)
  }

  return (
    <>
      <div
        className={clsx(
          'card px-10 py-5 mb-4',
          task?.current_attempt_number >= task?.current_task_profile_settings?.total_attempts - 1
        )}
      >
        <table width='100%' className='align-top'>
          <tbody>
            <tr>
              <th className='text-nowrap align-top'>{intl.formatMessage({ id: 'tasks.id' })}</th>
              <td className='ps-4'>{task.id}</td>
            </tr>
            <tr>
              <th className='text-nowrap align-top'>
                {intl.formatMessage({ id: 'tasks.taskProfile.name' })}
              </th>
              <td className='ps-4'>{task.task_profile?.name}</td>
            </tr>
            {!!task.task_profile?.objective && (
              <tr>
                <th className='text-nowrap align-top'>
                  {intl.formatMessage({ id: 'tasks.taskProfile.objective' })}
                </th>
                <td className='ps-4'>{task.task_profile?.objective}</td>
              </tr>
            )}
            <tr>
              <th className='text-nowrap align-top'>{intl.formatMessage({ id: 'tasks.state' })}</th>
              <td className='ps-4'>
                <span
                  className={clsx(
                    'badge fw-bolder',
                    solvableStates.includes(task.state) ? 'badge-primary' : 'badge-secondary'
                  )}
                >
                  {task.state}
                </span>
              </td>
            </tr>
            {displayOwner && !!task?.owner?.name && (
              <tr>
                <th className='text-nowrap align-top'>{intl.formatMessage({ id: 'tasks.owner' })}</th>
                <td className='ps-4 text-capitalize'>{task.owner?.name}</td>
              </tr>
            )}
            {!!task?.reporter?.name && (
              <tr>
                <th className='text-nowrap align-top'>
                  {intl.formatMessage({ id: 'tasks.reporter' })}
                </th>
                <td className='ps-4 text-capitalize'>{task.reporter?.name}</td>
              </tr>
            )}
            {!!task.context && (
              <tr>
                <th className='text-nowrap align-top'>
                  {intl.formatMessage({ id: 'tasks.context' })}
                </th>
                <td className='ps-4'>{task.context}</td>
              </tr>
            )}
            <tr>
              <th className='text-nowrap align-top'>
                {intl.formatMessage({ id: 'tasks.currentAttemptNumber' })}
              </th>
              <td className='ps-4'>
                {task.current_attempt_number + 1}/
                {task.current_task_profile_settings?.total_attempts || 1}
              </td>
            </tr>
            {!!task.additional_info && (
              <tr>
                <th className='text-nowrap align-top'>
                  {intl.formatMessage({ id: 'tasks.additionalInfo' })}
                </th>
                <td className='ps-4'>{task.additional_info}</td>
              </tr>
            )}
            <tr>
              <th className='text-nowrap align-top'>
                {intl.formatMessage({ id: 'tasks.hasAlarm' })}
              </th>
              <td className='ps-4'>
                {task?.is_alarm
                  ? intl.formatMessage({ id: 'genericForm.yes' })
                  : intl.formatMessage({ id: 'genericForm.no' })}
              </td>
            </tr>
            <tr>
              <th className='text-nowrap align-top'>{intl.formatMessage({ id: 'tasks.startAt' })}</th>
              <td className='ps-4'>{new Date(Date.parse(task.dispatch_after)).toLocaleString()}</td>
            </tr>
            <tr>
              <th className='text-nowrap align-top'>
                {intl.formatMessage({ id: 'tasks.finishBefore' })}
              </th>
              <td className='ps-4'>{new Date(Date.parse(task.finish_before)).toLocaleString()}</td>
            </tr>
            <tr>
              <th className='text-nowrap align-top'>
                {intl.formatMessage({ id: 'tasks.createdAt' })}
              </th>
              <td className='ps-4'>{new Date(Date.parse(task.created_at)).toLocaleString()}</td>
            </tr>
            <tr>
              <th className='text-nowrap align-top'>{intl.formatMessage({ id: 'tasks.phone' })}</th>
              <td className='ps-4'>
                {task?.call_request?.phone || task?.user?.standard_phone || task?.phone || '-'}
              </td>
            </tr>
            <tr>
              <th className='text-nowrap align-top'>
                {intl.formatMessage({ id: 'tasks.username' })}
              </th>
              <td className='ps-4'>
                {task?.user?.id > 0 ? (
                  <>
                    <Link to={`/user/${task?.user?.id}/profile`}>
                      {task?.call_request?.name || task.user?.name || '-'}
                    </Link>
                  </>
                ) : (
                  task?.call_request?.name || task.user?.name || '-'
                )}
              </td>
            </tr>
            <tr>
              <th className='text-nowrap align-top'>{intl.formatMessage({ id: 'tasks.email' })}</th>
              <td className='ps-4'>
                {task?.user?.id > 0 ? (
                  <Link to={`/user/${task?.user?.id}/profile`}>
                    {task.user?.email || '-'}
                  </Link>
                ) : (
                  task.email || '-'
                )}
              </td>
            </tr>
            <tr>
              <th className='text-nowrap align-top'>
                {intl.formatMessage({ id: 'tasks.clientTasks' })}
              </th>
              <td className='ps-4'>
                {task?.user?.id > 0 ? (
                  <>
                    <Link to={`/user/${task?.user?.id}/tasks`}>
                      {intl.formatMessage({ id: 'tasks.seeClientTasks' })}
                    </Link>
                  </>
                ) : (
                  '-'
                )}
              </td>
            </tr>
            <tr>
              <th className='text-nowrap align-top'>
                {intl.formatMessage({ id: 'tasks.clientComments' })}
              </th>
              <td className='ps-4'>
                {task?.user?.id > 0 ? (
                  <>
                    <Link to={`/user/${task?.user?.id}/comments`}>
                      {intl.formatMessage({ id: 'tasks.seeClientComments' })}
                    </Link>
                  </>
                ) : (
                  '-'
                )}
              </td>
            </tr>
            {task.pet && (
              <tr>
                <th className='text-nowrap align-top'>{intl.formatMessage({ id: 'tasks.pet' })}</th>
                <td className='ps-4'>
                  <Link to={task?.user?.id ? `/user/${task?.user?.id}/pet/${task.pet?.id}` : ''}>
                    {task.pet?.name}
                  </Link>
                </td>
              </tr>
            )}
            {task.plan && (
              <tr>
                <th className='text-nowrap align-top'>{intl.formatMessage({ id: 'tasks.plan' })}</th>
                <td className='ps-4'>
                  <Link to={task?.user?.id ? `/user/${task?.user?.id}/plan/${task.plan?.id}` : ''}>
                    {task.plan?.code}
                  </Link>
                </td>
              </tr>
            )}
            {task.sale_order && (
              <tr>
                <th className='text-nowrap align-top'>
                  {intl.formatMessage({ id: 'tasks.saleOrder' })}
                </th>
                <td className='ps-4'>
                  <Link
                    to={
                      task?.user?.id ? `/user/${task?.user?.id}/order/${task.sale_order?.id}` : ''
                    }
                  >
                    {task.sale_order?.code}
                  </Link>
                </td>
              </tr>
            )}
          </tbody>
        </table>
        <LastContactContaniner
          className='px-3'
          to={
            task?.user?.id && task?.user_last_interaction?.task
              ? `/user/${task?.user?.id}/task/${task.user_last_interaction?.task}/`
              : '#'
          }
        >
          <div
            className={clsx(
              'rounded mt-4 px-4 pt-4 row align-items-center',
              task?.user?.id && task?.user_last_interaction?.task ? 'bg-light-primary' : 'bg-light'
            )}
          >
            <div className='col-1'>
              <i
                className={clsx(
                  'h1 fas fa-comment mb-4',
                  task?.user?.id && task?.user_last_interaction?.task
                    ? 'text-primary'
                    : 'text-muted'
                )}
              ></i>
            </div>
            <div
              className={clsx(
                'col-11',
                task?.user?.id && task?.user_last_interaction?.task ? 'text-primary' : 'text-muted'
              )}
            >
              <p className='fw-bolder mb-0'>{intl.formatMessage({ id: 'tasks.lastInteraction' })}</p>
              {task?.user_last_interaction?.owner ? (
                <p className='mt-0'>
                  <span className='me-1'>
                    {getStaffUserName(task?.user_last_interaction?.owner)}
                  </span>
                  {moment(task.user_last_interaction?.date).isSame(new Date(), 'day')
                    ? moment(task.user_last_interaction?.date).fromNow()
                    : moment(task.user_last_interaction?.date).format('DD/MM/YYYY HH:mm')}
                </p>
              ) : (
                <p>{intl.formatMessage({ id: 'tasks.noInteraction' })}</p>
              )}
            </div>
          </div>
        </LastContactContaniner>
        {!!task?.call_request?.phone || !!task?.user?.standard_phone || !!task?.phone ? (
          <button className='btn btn-light-primary w-100 mt-4' onClick={onCall}>
            <i className='fa fa-phone-alt me-2 text-primary fw-bolder'></i>
            {intl.formatMessage({ id: 'tasks.callViaAircall' })}
          </button>
        ) : (
          <>
            <p className='text-warning fw-bolder mt-4'>
              {intl.formatMessage({ id: 'tasks.notValidPhone' })}
            </p>
          </>
        )}
      </div>
      <TaskComments task={task} onAddComment={onAddComment} />
      {((task.owner?.id === user.id && task.specific_actions?.length > 0) ||
        (solvableStates.includes(task?.state) &&
          (
            task?.owner?.id === user?.id ||
            superUsers.includes(user?.email) ||
            !!user?.cs_groups.find((aGroup) => aGroup.name === 'ATC - Subscription')
          ))) && (
          <div className='card mt-4 mb-20'>
            <div className='card-body pt-5 '>
              {task.owner?.id === user.id && task.specific_actions?.length > 0 && (
                <>
                  <p className='fw-bolder'>{intl.formatMessage({ id: 'tasks.specificActions' })}</p>
                  <div className='d-flex gap-3 mb-10 '>
                    {task.specific_actions?.map((action: TaskProfileActionModel) => (
                      <button
                        onClick={() => onActionClick(action)}
                        key={action.id}
                        disabled={Array.from(completeActions).includes(action.id)}
                        className='btn btn-secondary mb-2 col'
                      >
                        {Array.from(completeActions).includes(action.id) ? (
                          <>
                            <span className='fa fa-check me-3' />
                            {intl.formatMessage({ id: 'tasks.actionCompleted' })}
                          </>
                        ) : (
                          <>
                            <span className={getActionIcon(action)} />
                            {action.name}
                          </>
                        )}
                      </button>
                    ))}
                  </div>
                </>
              )}
              {solvableStates.includes(task?.state) &&
                (
                  task?.owner?.id === user?.id ||
                  superUsers.includes(user?.email) ||
                  !!user?.cs_groups.find((aGroup) => aGroup.name === 'ATC - Subscription')
                ) && (
                  <>
                    {task.resolutions?.filter(
                      (resolution) => !SPECIAL_RESOLUTION_CODES.includes(resolution.code)
                    )?.length > 0 && (
                        <p className='fw-bolder mb-2'>
                          {intl.formatMessage({ id: 'tasks.resolveTask' })}
                        </p>
                      )}
                    <Select
                      className="react-select-container"
                      classNamePrefix="react-select"
                      placeholder={intl.formatMessage({ id: 'tasks.selectResolution' })}
                      onChange={(e: any) => setSelectedResolution(e.value)}
                      options={
                        task.resolutions
                          ?.filter((resolution) => !resolution.parent)
                          ?.filter(
                            (resolution) => !SPECIAL_RESOLUTION_CODES.includes(resolution.code)
                          )
                          ?.map((resolution) => ({
                            value: `${resolution.id}`,
                            label: resolution.name,
                          })) || []
                      }
                    />
                    {task.resolutions?.filter(
                      (resolution) => resolution.parent === parseInt(selectedResolution)
                    )?.length > 0 && (
                        <>
                          <label className='fw-bolder mt-4 mb-2'>
                            {intl.formatMessage({ id: 'tasks.selectChildResolution' })}
                          </label>
                          <Select
                            className="react-select-container"
                            classNamePrefix="react-select"
                            placeholder={intl.formatMessage({ id: 'tasks.selectResolution' })}
                            onChange={(e: any) => setSelectedChildResolution(e.value)}
                            options={
                              task.resolutions
                                ?.filter(
                                  (resolution) => resolution.parent === parseInt(selectedResolution)
                                )
                                ?.map((resolution) => ({
                                  value: `${resolution.id}`,
                                  label: resolution.name,
                                })) || []
                            }
                          />
                        </>
                      )}
                    {task.resolutions?.filter(
                      (resolution) => resolution.parent === parseInt(selectedChildResolution)
                    )?.length > 0 && (
                        <>
                          <label className='fw-bolder mt-4 mb-2'>
                            {intl.formatMessage({ id: 'tasks.selectSubResolution' })}
                          </label>
                          <Select
                            className="react-select-container"
                            classNamePrefix="react-select"
                            placeholder={intl.formatMessage({ id: 'tasks.selectResolution' })}
                            onChange={(e: any) => setSelectedSubChildResolution(e.value)}
                            options={
                              task.resolutions
                                ?.filter(
                                  (resolution) =>
                                    resolution.parent === parseInt(selectedChildResolution)
                                )
                                ?.map((resolution) => ({
                                  value: `${resolution.id}`,
                                  label: resolution.name,
                                })) || []
                            }
                          />
                        </>
                      )}
                    <div className='mb-4'>
                      <label className='fw-bolder mb-2'>{intl.formatMessage({ id: 'tasks.setSource' })}</label>
                      <Select
                        className='react-select-container'
                        classNamePrefix='react-select'
                        placeholder={intl.formatMessage({ id: 'tasks.selectSource' })}
                        options={Object.values(Source).map((source) => ({
                          value: source.toString(),
                          label: intl.formatMessage({ id: `tasks.${source}` }),
                        }))}
                        onChange={(e: any) => setSource(e.value)}
                        value={source? {
                          value: source?.toString(),
                          label: intl.formatMessage({ id: `tasks.${source}` }),
                        } : null}
                      />
                    </div>
                    <div className='mb-4'>
                      <label className='fw-bolder mb-2'>{intl.formatMessage({ id: 'tasks.isFromCompetitor' })}</label>
                      <div className='d-flex gap-4'>
                        <div className='d-flex gap-1'>
                          <input
                            type='radio'
                            id='no'
                            name='isFromCompetitor'
                            value='no'
                            onChange={() => setIsFromCompetitor(false)}
                            checked={!isFromCompetitor}
                          />
                          <label
                            htmlFor='no'>{intl.formatMessage({ id: 'genericForm.no' })}</label>
                        </div>
                        <div className='d-flex gap-1'>
                          <input
                            type='radio'
                            id='yes'
                            name='isFromCompetitor'
                            value='yes'
                            onChange={() => setIsFromCompetitor(true)}
                            checked={isFromCompetitor}
                          />
                          <label htmlFor='yes'>{intl.formatMessage({ id: 'genericForm.yes' })}</label>
                        </div>
                      </div>
                      {
                        isFromCompetitor && (
                          <div className='mt-4'>
                            <label className='fw-bolder mb-2'>{intl.formatMessage({ id: 'tasks.competitor' })}</label>
                            <input
                              maxLength={50}
                              type='text'
                              className='form-control'
                              onChange={(e) => setCompetitor(e?.target?.value)}
                              value={competitor}
                              placeholder={intl.formatMessage({ id: 'tasks.competitorPlaceholder' })}
                            />
                          </div>
                        )
                      }
                    </div>
                    <button
                      className='btn btn-primary w-100 mt-4'
                      onClick={handleSetResolution}
                      disabled={
                        !selectedResolution ||
                        (task.resolutions?.filter(
                          (resolution) => resolution.parent === parseInt(selectedResolution)
                        )?.length > 0 &&
                          !selectedChildResolution) ||
                        (task.resolutions?.filter(
                          (resolution) => resolution.parent === parseInt(selectedChildResolution)
                        )?.length > 0 &&
                          !selectedSubChildResolution)
                      }
                    >
                      {intl.formatMessage({ id: 'tasks.setResolution' })}
                    </button>
                  </>
                )}
            </div>
          </div>
        )}
    </>
  )
}
