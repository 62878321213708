import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { 
    calculateSegment, 
    generateSegmentedReport,
    SegmentCalculationRequest, 
    SegmentCalculationResponse, 
    UserSegmentationRequest,
    PlanSegmentationRequest,
    PetSegmentationRequest,
    SaleOrderSegmentationRequest,
} from "../services/DashboardCRUD";
import { FormField, GenericForm } from "../../../components/GenericForm";
import { toast } from "react-toastify";
import { Modal } from 'react-bootstrap'
import { authUserSelector } from "../../auth";
import { useSelector } from "react-redux";
import { listStores, PlanStateEnum, Store } from "../../plans";
import { listActivityLevels, listBodyConditions, listBreeds, listCurrentFoodTypes } from "../../pets";
import { ActivityLevelModel } from "../../pets/models/ActivityLevelModel";
import { BodyConditionModel } from "../../pets/models/BodyConditionModel";
import { BreedModel } from "../../pets/models/BreedModel";
import { CurrentFoodTypeModel } from "../../pets/models/CurrentFoodTypeModel";
import { OrderStateEnum } from "../../orders";

enum FORM_STATES {
    IDLE,
    CALCULATING,
    GENERATING,
};
const INITIAL_USER_SEGMENTATION_REQUEST: UserSegmentationRequest = {
    status: [],
    date_joined__gte: '',
    date_joined__lte: '',
    date_first_order__gte: '',
    date_first_order__lte: ''
};
const INITIAL_PLAN_SEGMENTATION_REQUEST: PlanSegmentationRequest = {
    created_at__gte: '',
    created_at__lte: '',
    state: [],
    meal_kind: [],
    kind: [],
}
const INITIAL_PET_KIND_SEGMENTATION_REQUEST: Array<number> = [
];
const INITIAL_PET_SEGMENTATION_REQUEST: PetSegmentationRequest = {
    breed: [],
    body_condition: [],
    gender: [],
    activity_level: [],
    current_food_type: []
}
const INITIAL_SALE_ORDER_SEGMENTATION_REQUEST: SaleOrderSegmentationRequest = {
    promise_date__gte: '',
    promise_date__lte: '',
    charge_date__gte: '',
    charge_date__lte: '',
    state: [],
    postcode: [],
}
const INITIAL_STORE_SEGMENTATION_REQUEST: Array<number> = [];

export default function SegmentedReport() {
    const intl = useIntl();
    const [breeds, setBreeds] = useState<Array<BreedModel>>([])
    const [bodyConditions, setBodyConditions] = useState<Array<BodyConditionModel>>([])
    const [activityLevels, setActivityLevels] = useState<Array<ActivityLevelModel>>([])
    const [currentFoodTypes, setCurrentFoodTypes] = useState<Array<CurrentFoodTypeModel>>([])
    const [stores, setStores] = useState<Array<Store>>([])
    const authUser = useSelector(authUserSelector)
    const [formStage, setFormStage] = useState(FORM_STATES.IDLE);
    const [request, setRequest] = useState<SegmentCalculationRequest>({});
    const [editingState, setEditingState] = useState({
        store: false,
        user: false,
        pet_kind: false,
        pet: false,
        plan: false,
        sale_order: false,
    });
    const [calculationResult, setCalculationResult] = useState<SegmentCalculationResponse | null>(null);
    const storeFormFields: Array<FormField<SegmentCalculationRequest>> = [
        {
            id: 'store',
            label: intl.formatMessage({ id: 'businessDashboard.store' }),
            inputType: 'multiple',
            options: stores.map((store) => ({
                value: store.id.toString(),
                label: store.name
            })),
            getter: (requestState) => requestState.store.join(','),
            setter: (requestState, value) => ({
                ...requestState,
                store: value.split(',').filter(Boolean).map(Number)
            })
        }
    ];
    const userFormFields: Array<FormField<UserSegmentationRequest>> = [
        {
            id: 'date_joined__gte',
            label: intl.formatMessage({ id: 'businessDashboard.dateJoinedFrom' }),
            inputType: 'date'
        },
        {
            id: 'date_joined__lte',
            label: intl.formatMessage({ id: 'businessDashboard.dateJoinedTo' }),
            inputType: 'date'
        },
        {
            id: 'date_first_order__gte',
            label: intl.formatMessage({ id: 'businessDashboard.dateFirstOrderFrom' }),
            inputType: 'date'
        },
        {
            id: 'date_first_order__lte',
            label: intl.formatMessage({ id: 'businessDashboard.dateFirstOrderTo' }),
            inputType: 'date'
        },
        {
            id: 'status',
            label: intl.formatMessage({ id: 'businessDashboard.status' }),
            inputType: 'multiple',
            options: [
                {
                    value: 'active',
                    label: intl.formatMessage({ id: 'businessDashboard.active' })
                },
                {
                    value: 'proposal',
                    label: intl.formatMessage({ id: 'businessDashboard.proposal' })
                },
                {
                    value: 'paused',
                    label: intl.formatMessage({ id: 'businessDashboard.paused' })
                }
            ],
            getter: (user) => user.status.join(','),
            setter: (user, value) => ({
                ...user,
                status: value.split(',').filter(Boolean)
            })
        },
    ]
    const planFormFields: Array<FormField<PlanSegmentationRequest>> = [
        {
            id: 'created_at__gte',
            label: intl.formatMessage({ id: 'businessDashboard.createdAtFrom' }),
            inputType: 'date'
        },
        {
            id: 'created_at__lte',
            label: intl.formatMessage({ id: 'businessDashboard.createdAtTo' }),
            inputType: 'date'
        },
        {
            id: 'state',
            label: intl.formatMessage({ id: 'businessDashboard.state' }),
            inputType: 'multiple',
            options: Object.values(PlanStateEnum).map((state) => ({
                value: state,
                label: state,
            })),    

            getter: (plan) => plan.state.join(','),
            setter: (plan, value) => ({
                ...plan,
                state: value.split(',').filter(Boolean)
            })
        },
        {
            id: 'meal_kind',
            label: intl.formatMessage({ id: 'businessDashboard.mealKind' }),
            inputType: 'multiple',
            options: [
                {
                    value: 'original',
                    label: intl.formatMessage({ id: 'businessDashboard.original' })
                },
                {
                    value: 'special-care',
                    label: intl.formatMessage({ id: 'businessDashboard.specialCare' })
                },
            ],
            getter: (plan) => plan.meal_kind.join(','),
            setter: (plan, value) => ({
                ...plan,
                meal_kind: value.split(',').filter(Boolean)
            })
        },
        {
            id: 'kind',
            label: intl.formatMessage({ id: 'businessDashboard.kind' }),
            inputType: 'multiple',
            options: [
                {
                    value: 'custom',
                    label: intl.formatMessage({ id: 'businessDashboard.custom' })
                },
                {
                    value: 'auto',
                    label: intl.formatMessage({ id: 'businessDashboard.auto' })
                },
            ],
            getter: (plan) => plan.kind.join(','),
            setter: (plan, value) => ({
                ...plan,
                kind: value.split(',').filter(Boolean)
            })
        },
    ];
    const petKindFormFields: Array<FormField<SegmentCalculationRequest>> = [
        {
            id: 'pet_kind',
            label: intl.formatMessage({ id: 'businessDashboard.petKind' }),
            inputType: 'multiple',
            options: [
                {
                    value: '1',
                    label: intl.formatMessage({ id: 'businessDashboard.dog' })
                },
                {
                    value: '2',
                    label: intl.formatMessage({ id: 'businessDashboard.cat' })
                },
            ],
            getter: (requestState) => requestState.pet_kind.join(','),
            setter: (requestState, value) => ({
                ...requestState,
                pet_kind: value.split(',').filter(Boolean).map(Number)
            })
        }
    ];
    const petFormFields: Array<FormField<PetSegmentationRequest>> = [
        {
            id: 'age_years__gte',
            label: intl.formatMessage({ id: 'businessDashboard.ageYearsFrom' }),
            inputType: 'number'
        },
        {
            id: 'age_years__lte',
            label: intl.formatMessage({ id: 'businessDashboard.ageYearsTo' }),
            inputType: 'number'
        },
        {
            id: 'age_months__gte',
            label: intl.formatMessage({ id: 'businessDashboard.ageMonthsFrom' }),
            inputType: 'number'
        },
        {
            id: 'age_months__lte',
            label: intl.formatMessage({ id: 'businessDashboard.ageMonthsTo' }),
            inputType: 'number'
        },
        {
            id: 'weight_in_kg__gte',
            label: intl.formatMessage({ id: 'businessDashboard.weightKgFrom' }),
            inputType: 'number'
        },
        {
            id: 'weight_in_kg__lte',
            label: intl.formatMessage({ id: 'businessDashboard.weightKgTo' }),
            inputType: 'number'
        },
        {
            id: 'is_neutered',
            label: intl.formatMessage({ id: 'businessDashboard.isNeutered' }),
            inputType: 'checkbox',
        },
        {
            id: 'gender',
            label: intl.formatMessage({ id: 'businessDashboard.gender' }),
            inputType: 'multiple',
            options: [
                {
                    label: intl.formatMessage({ id: 'businessDashboard.male' }),
                    value: 'male'
                },
                {
                    label: intl.formatMessage({ id: 'businessDashboard.female' }),
                    value: 'female'
                },
            ],
            getter: (pet) => pet.gender.join(','),
            setter: (pet, value) => ({
                ...pet,
                gender: value.split(',').filter(Boolean)
            })
        },
        {
            id: 'body_condition',
            label: intl.formatMessage({ id: 'businessDashboard.bodyCondition' }),
            inputType: 'multiple',
            options: bodyConditions.map((bodyCondition) => ({
                value: bodyCondition.id.toString(),
                label: bodyCondition.name,
            })),
            getter: (pet) => pet.body_condition.join(','),
            setter: (pet, value) => ({
                ...pet,
                body_condition: value.split(',').filter(Boolean)
            })
        },
        {
            id: 'activity_level',
            label: intl.formatMessage({ id: 'businessDashboard.activityLevel' }),
            inputType: 'multiple',
            options: activityLevels.map((activityLevel) => ({
                value: activityLevel.id.toString(),
                label: activityLevel.name,
            })),
            getter: (pet) => pet.activity_level.join(','),
            setter: (pet, value) => ({
                ...pet,
                activity_level: value.split(',').filter(Boolean)
            })
        },
        {
            id: 'current_food_type',
            label: intl.formatMessage({ id: 'businessDashboard.currentFoodType' }),
            inputType: 'multiple',
            options: currentFoodTypes.map((currentFoodType) => ({
                value: currentFoodType.id.toString(),
                label: currentFoodType.name,
            })),
            getter: (pet) => pet.current_food_type.join(','),
            setter: (pet, value) => ({
                ...pet,
                current_food_type: value.split(',').filter(Boolean)
            })
        },
        {
            id: 'breed',
            label: intl.formatMessage({ id: 'businessDashboard.breed' }),
            inputType: 'multiple',
            options: breeds.map((breed) => ({
                value: breed.id.toString(),
                label: breed.name,
            })),
            getter: (pet) => pet.breed.join(','),
            setter: (pet, value) => ({
                ...pet,
                breed: value.split(',').filter(Boolean)
            })
        },
    ]; 
    const saleOrderFormFields: Array<FormField<SaleOrderSegmentationRequest>> = [
        {
            id: 'promise_date__gte',
            label: intl.formatMessage({ id: 'businessDashboard.promiseDateFrom' }),
            inputType: 'date'
        },
        {
            id: 'promise_date__lte',
            label: intl.formatMessage({ id: 'businessDashboard.promiseDateTo' }),
            inputType: 'date'
        },
        {
            id: 'charge_date__gte',
            label: intl.formatMessage({ id: 'businessDashboard.chargeDateFrom' }),
            inputType: 'date'
        },
        {
            id: 'charge_date__lte',
            label: intl.formatMessage({ id: 'businessDashboard.chargeDateTo' }),
            inputType: 'date'
        },
        {
            id: 'total_price_inc_tax__gte',
            label: intl.formatMessage({ id: 'businessDashboard.totalPriceFrom' }),
            inputType: 'number'
        },
        {
            id: 'total_price_inc_tax__lte',
            label: intl.formatMessage({ id: 'businessDashboard.totalPriceTo' }),
            inputType: 'number'
        },
        {
            id: 'gross_margin_percentage__gte',
            label: intl.formatMessage({ id: 'businessDashboard.grossMarginPercentageFrom' }),
            inputType: 'number'
        },
        {
            id: 'gross_margin_percentage__lte',
            label: intl.formatMessage({ id: 'businessDashboard.grossMarginPercentageTo' }),
            inputType: 'number'
        },
        {
            id: 'state',
            label: intl.formatMessage({ id: 'businessDashboard.state' }),
            inputType: 'multiple',
            options: Object.values(OrderStateEnum).map((aState) => ({
                value: aState,
                label: aState,
            })),
            getter: (saleOrder) => saleOrder.state.join(','),
            setter: (saleOrder, value) => ({
                ...saleOrder,
                state: value.split(',').filter(Boolean)
            })
        },
        /*{
            id: 'postcode',
            label: intl.formatMessage({ id: 'businessDashboard.postcode' }),
            inputType: 'text',
        }*/
    ];
    const entities = [
        {
            key: 'store',
            keyName: intl.formatMessage({ id: 'businessDashboard.storeKeyName' }),
            formFields: storeFormFields,
            initialValue: INITIAL_STORE_SEGMENTATION_REQUEST,
            isArray: true,
        },
        {
            key: 'user',
            keyName: intl.formatMessage({ id: 'businessDashboard.userKeyName' }),
            formFields: userFormFields,
            initialValue: INITIAL_USER_SEGMENTATION_REQUEST,
        },
        {
            key: 'pet_kind',
            keyName: intl.formatMessage({ id: 'businessDashboard.petKindKeyName' }),
            formFields: petKindFormFields,
            initialValue: INITIAL_PET_KIND_SEGMENTATION_REQUEST,
            isArray: true,
        },
        {
            key: 'pet',
            keyName: intl.formatMessage({ id: 'businessDashboard.petKeyName' }),
            formFields: petFormFields,
            initialValue: INITIAL_PET_SEGMENTATION_REQUEST,
        },
        {
            key: 'plan',
            keyName: intl.formatMessage({ id: 'businessDashboard.planKeyName' }),
            formFields: planFormFields,
            initialValue: INITIAL_PLAN_SEGMENTATION_REQUEST,
        },
        {
            key: 'sale_order',
            keyName: intl.formatMessage({ id: 'businessDashboard.orderKeyName' }),
            formFields: saleOrderFormFields,
            initialValue: INITIAL_SALE_ORDER_SEGMENTATION_REQUEST,
        },
    ]

    function getFieldFilterCount(field) {
        if (Array.isArray(request[field])) {
            return request[field].length > 0? 1 : 0;
        }
        return Object.keys(request[field] || {}).filter((key) => {
            const value = request[field][key];
            return value && (
                Array.isArray(value) ? value.length : true
            )
        }).length;
    }

    function getTotalFilterCount() {
        return Object.keys(request).reduce((acc, key) => {
            return acc + getFieldFilterCount(key);
        }, 0)
    }

    async function onCalculateSegment() {
        try {
            setCalculationResult(undefined);
            setFormStage(FORM_STATES.CALCULATING);
            console.log({request})
            const { data } = await calculateSegment(request);
            if (data?.count > 0) {
                setCalculationResult(data);
            } else {
                toast.warn(intl.formatMessage({ id: 'businessDashboard.noResults' }));
                setCalculationResult(undefined);
            }
        } catch (error) {
            console.error(error);
            toast.error(intl.formatMessage({ id: 'businessDashboard.errorCalculatingSegment' }));
            setCalculationResult(undefined);
        } finally {
            setFormStage(FORM_STATES.IDLE);
        }
    }

    async function onGenerateCsv() {
        try {
            setFormStage(FORM_STATES.GENERATING);
            await generateSegmentedReport(request);
            setCalculationResult(null);
            toast.success(intl.formatMessage({ id: 'businessDashboard.csvGenerated' }));
        } catch (error) {
            console.error(error);
            toast.error(intl.formatMessage({ id: 'businessDashboard.errorGeneratingCsv' }));
        } finally {
            setFormStage(FORM_STATES.IDLE);
        }
    }

    function isEditing() {
        return Object.values(editingState).some(Boolean);
    }

    async function fetchBreeds(petKinds) {
        const breedsPromises = petKinds.map((petKind) => listBreeds({pet_kind: petKind}))
        const breeds = await Promise.all(breedsPromises)
        setBreeds(breeds.map((breed) => breed.data).flat())
    }

    async function fetchBodyConditions(petKinds) {
        const bodyConditionsPromises = petKinds.map((petKind) => listBodyConditions({pet_kind: petKind}))
        const bodyConditions = await Promise.all(bodyConditionsPromises)

        setBodyConditions(
            bodyConditions
                .map((bodyCondition) => bodyCondition.data)
                .flat()
                .filter((bodyCondition, index, self) => 
                    index === self.findIndex((t) => (
                        t.id === bodyCondition.id
                    ))
                )
        )
    }

    async function fetchActivityLevels(petKinds) {
        const activityLevelsPromises = petKinds.map((petKind) => listActivityLevels({pet_kind: petKind}))
        const activityLevels = await Promise.all(activityLevelsPromises)

        setActivityLevels(
            activityLevels
                .map((activityLevel) => activityLevel.data)
                .flat()
                .filter((activityLevel, index, self) => 
                    index === self.findIndex((t) => (
                        t.id === activityLevel.id
                    )
                )
            )
        )
    }

    async function fetchCurrentFoodTypes(petKinds) {
        const currentFoodTypesPromises = petKinds.map((petKind) => listCurrentFoodTypes({pet_kind: petKind}))
        const currentFoodTypes = await Promise.all(currentFoodTypesPromises)
        setCurrentFoodTypes(
            currentFoodTypes
                .map((currentFoodType) => currentFoodType.data)
                .flat()
                .filter((currentFoodType, index, self) =>
                    index === self.findIndex((t) => (
                        t.id === currentFoodType.id
                    ))
                )
        )
    }

    async function fetchStores() {
        const { data } = await listStores()
        setStores(data)
    }

    function fetchFormOptions(petKinds: Array<number>) {
        try {
            fetchStores()
            fetchBreeds(petKinds)
            fetchBodyConditions(petKinds)
            fetchActivityLevels(petKinds)
            fetchCurrentFoodTypes(petKinds)
        } catch (error) {
            console.error(error);
            toast.error(intl.formatMessage({ id: 'businessDashboard.errorFetchingOptions' }));
        }
    }

    useEffect(() => {
        fetchFormOptions([1, 2])
    }, [])

    useEffect(function updateEditingStateOnRequestChange() {
        entities.forEach((entity) => {
            if (!request[entity.key]) {
                setEditingState((currentEditingState) => ({
                    ...currentEditingState,
                    [entity.key]: false
                }))
            }
        })
    }, [request])
 
    return (<>
        <section>
            <h1 className="card-title mb-8">
                {intl.formatMessage({ id: 'businessDashboard.segmentedReport' })}
            </h1>
            <p className="text-muted mb-4">
                {intl.formatMessage({ id: 'businessDashboard.segmentedReportDescription' })}
            </p>
            <section className="d-flex flex-column gap-4 mb-8">
                {
                    entities.map((entity) => (
                        <div className="card border shadow p-8" key={entity.key}>
                            <div className="form-check form-switch d-flex gap-4 align-items-center justify-start">
                                <input
                                    style={{
                                        zIndex: 1
                                    }}
                                    id={`${entity.key}-segment`}
                                    type="checkbox"
                                    className="form-check-input"
                                    onChange={() => {
                                        setRequest((currentRequest) => ({
                                            ...currentRequest,
                                            [entity.key]: 
                                                currentRequest[entity.key] ? 
                                                    undefined 
                                                    : entity.initialValue
                                        }))
                                        setEditingState((currentEditingState) => ({
                                            ...currentEditingState,
                                            [entity.key]: !!!currentEditingState[entity.key]
                                        }))
                                    }}
                                />
                                <label 
                                    className="form-label mb-0"
                                    htmlFor={`${entity.key}-segment`}
                                    style={{
                                        zIndex: 1
                                    }}>
                                    { intl.formatMessage({ id: 'businessDashboard.segmentBy' }, { 
                                        keyName: entity.keyName,
                                        filterCount: getFieldFilterCount(entity.key)
                                    }) }
                                </label>
                            </div>
                            {
                                request[entity.key] && (<div style={{ marginTop: -32 }}>
                                    <GenericForm                                
                                        columns={2}
                                        fields={entity.formFields}
                                        initialState={
                                            entity.isArray ?
                                                request
                                                : request[entity.key]
                                        }
                                        editable={true}
                                        editing={editingState[entity.key]}
                                        title=" "
                                        toggleEdit={() => {
                                            setEditingState((currentEditingState) => ({
                                                ...currentEditingState,
                                                [entity.key]: !currentEditingState[entity.key]
                                            }))
                                        }}
                                        ctaLabel={<>
                                            <i className="fas fa-check me-2"></i>
                                            {intl.formatMessage({ id: 'businessDashboard.applyFilters' })}
                                        </> as any}
                                        onSubmit={(newState) => {
                                            setRequest((currentRequest) => entity.isArray?
                                            ({
                                                ...newState
                                            })
                                            : ({
                                                ...currentRequest,
                                                [entity.key]: newState
                                            }))
                                            setEditingState((currentEditingState) => ({
                                                ...currentEditingState,
                                                [entity.key]: false
                                            }))
                                        }}
                                        submitting={false}
                                        submittingLabel={intl.formatMessage({ id: 'businessDashboard.applyingFilters' })}
                                    />
                                </div>)
                            }
                        </div>
                    ))
                }
            </section>
            {
                isEditing() && <p className="text-muted text-end mb-4">
                    {intl.formatMessage({ id: 'businessDashboard.editing' })}
                </p>
            }
            <button
                onClick={onCalculateSegment}
                disabled={!getTotalFilterCount() || formStage === FORM_STATES.CALCULATING || isEditing()}
                className="btn btn-primary d-block ms-auto">
                    {formStage === FORM_STATES.CALCULATING && (
                        <span className="spinner-border spinner-border-sm me-2"></span>
                    )}
                    {intl.formatMessage(
                        { id: 'businessDashboard.calculateSegment' },
                        { filterCount: getTotalFilterCount() }
                    )}
            </button>
        </section>
        <Modal 
            show={calculationResult?.count > 0} 
            onHide={() => {
                setCalculationResult(null)
            }}
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    {intl.formatMessage({ id: 'businessDashboard.generateCsv' })}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>
                    {intl.formatMessage({ id: 'businessDashboard.generateCsvDescription' }, {
                        filterCount: getTotalFilterCount(),
                        resultsCount: calculationResult?.count || 0,
                        email: authUser?.email,
                        strong: str => <strong>{str}</strong>,
                        br: _str => <br />
                    })}
                </p>
                <div className="d-flex gap-4 justify-content-end">
                    <button className="btn btn-secondary" onClick={() => setCalculationResult(null)}>
                        {intl.formatMessage({
                            id: 'businessDashboard.cancel'
                        })}
                    </button>
                    <button className="btn btn-primary" onClick={onGenerateCsv} disabled={formStage === FORM_STATES.GENERATING}>
                        { 
                            formStage === FORM_STATES.GENERATING ?
                            <span className="spinner-border spinner-border-sm me-2"></span>
                            : <span className="fas fa-file-csv me-2"></span> 
                        }
                        {intl.formatMessage({
                            id: 'businessDashboard.generateCta'
                        })}
                    </button>
                </div>
            </Modal.Body>
        </Modal>
    </>);
}