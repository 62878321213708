export enum DeliveryChangeReasonEnum {
  CUSTOMER_REQUEST = 'customer-request',
  DAMAGED_FOOD = 'damaged-food',
  B2B = 'B2B',
  INFLUENCER = 'influencer',
  SPECIFICATION = 'specification',
  WAREHOUSE_ERROR = 'warehouse-error',
  ATC_ERROR = 'atc-error',
  DELIVERY_ERROR = 'delivery-error',
  CRM_ERROR = 'crm-error',
  DEFROSTED = 'defrosted',
}
