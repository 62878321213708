import {useIntl} from 'react-intl'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {actions} from '../redux/OrderRedux'
import {OrderModel} from '../models/OrderModel'
import {orderStatusSelector, selectedOrderSelector} from '../redux/OrderRedux'
import {useEffect, useState} from 'react'
import AddProductModal from './AddProductModal'


export default function OrderLines({editEnabled = false}) {
  const intl = useIntl()
  const dispatch = useDispatch()
  const selectedOrder: OrderModel = useSelector(selectedOrderSelector, shallowEqual)
  const status = useSelector(orderStatusSelector, shallowEqual)
  const loadingSelectedOrder = status === 'loading_selected_order'
  const loadingRemoveProduct = status === 'loading_remove_product_from_selected_order'
  const loadingAddProduct = status === 'loading_add_product_to_selected_order'
  const [additionalProductModalVisible, setAdditionalProductModalVisible] = useState(false)
  const [removingProduct, setRemovingProduct] = useState(null)
  const [increasingProduct, setIncreasingProduct] = useState(null)
  const [decreasingProduct, setDecreasingProduct] = useState(null)

  function onAddAdditionalProduct() {
    setAdditionalProductModalVisible(true)
  }

  function onIncreaseUnits(line) {
    setIncreasingProduct(line.product)
    dispatch(
      actions.setProductUnitsInSelectedOrder({
        productId: line.product.id,
        units: line.units + 1,
        isFree: line.fixed_unit_price_inc_tax === 0
      })
    )
  }

  function onDecreaseUnits(line) {
    setDecreasingProduct(line.product)
    dispatch(
      actions.setProductUnitsInSelectedOrder({
        productId: line.product.id,
        units: line.units > 0 ? line.units - 1 : 0,
        isFree: line.fixed_unit_price_inc_tax === 0
      })
    )
  }

  function onRemove(line) {
    setRemovingProduct(line.product)
    dispatch(
      actions.setProductUnitsInSelectedOrder({
        productId: line.product.id,
        units: 0,
        isFree: line.fixed_unit_price_inc_tax === 0
      })
    )
  }

  useEffect(() => {
    if (!loadingRemoveProduct) {
      setRemovingProduct(null)
    }
  }, [loadingRemoveProduct])

  useEffect(() => {
    if (!loadingAddProduct) {
      setIncreasingProduct(null)
      setDecreasingProduct(null)
    }
  }, [loadingAddProduct])

  function isLoading(line) {
    return (
      line.product.id === removingProduct?.id ||
      line.product.id === increasingProduct?.id ||
      line.product.id === decreasingProduct?.id
    )
  }

  function isChangingUnits(line) {
    return line.product.id === increasingProduct?.id || line.product.id === decreasingProduct?.id
  }

  return (
    <>
      <section className='card p-10'>
        <h1 className='mb-12'>
          {intl.formatMessage({id: 'orderDetail.orderLines'}, {code: selectedOrder?.code})}
        </h1>
        {loadingSelectedOrder && <div className='spinner-border text-primary' role='status' />}

        {!loadingAddProduct &&
          !loadingSelectedOrder &&
          selectedOrder?.lines?.length === 0 && (
            <p>{intl.formatMessage({id: 'orderDetail.noNonMealLines'})}</p>
          )
        }
        {!loadingSelectedOrder && selectedOrder?.lines?.length > 0 && (
          <div className='card bg-light p-8 mb-4'>
            <div className='table-responsive table-scrollable m-0'>
              <table className='align-start table table-hover table-row-dashed table-row-gray-200 gs-0 gy-4 overflow-hidden table-hover m-0'>
                <thead className='table-header'>
                  <tr>
                    <th>{intl.formatMessage({id: 'orderDetail.lineName'})}</th>
                    <th className='mw-50px text-center'>
                      {intl.formatMessage({id: 'orderDetail.units'})}
                    </th>
                    <th className='mw-50px text-center'>
                      {intl.formatMessage({id: 'orderDetail.unitWeight'})}
                    </th>
                    <th className='mw-50px text-center'>
                      {intl.formatMessage({id: 'orderDetail.totalWeight'})}
                    </th>
                    <th className='mw-80px text-end pe-4'>
                      {intl.formatMessage({id: 'orderDetail.unitPriceIncTax'})}
                    </th>
                    <th className='mw-80px text-end pe-4'>
                      {intl.formatMessage({id: 'orderDetail.totalPriceIncTax'})}
                    </th>
                    <th className='mw-80px text-end pe-4'>
                      {intl.formatMessage({id: 'orderDetail.netTotalPriceIncTax'})}
                    </th>

                    {editEnabled && (
                      // empty column for remove button
                      <th></th>
                    )}
                  </tr>
                </thead>
                <tbody>
                  {[
                    ...selectedOrder?.lines
                      ?.filter((aLine) => aLine.kind === 'product' || aLine.kind === 'extra'),
                  ]?.map((aLine, index) => (
                    <tr key={index} className=''>
                      <td className='mw-150px pe-2'>
                        {
                          aLine.product?.product?.related_meal?.name || 
                          aLine.product?.product?.name ||
                          aLine?.name ||
                          '-'
                        }
                      </td>
                      <td className='text-center'>
                        <div className='d-flex justify-content-center align-items-center'>
                          {editEnabled && (
                            <button
                              disabled={isLoading(aLine)}
                              type='button'
                              className='btn btn-icon btn-light btn-sm'
                              onClick={() => onDecreaseUnits(aLine)}
                            >
                              <i className='fas fa-minus-circle' />
                            </button>
                          )}
                          <span>
                            {isChangingUnits(aLine) ? (
                              <div
                                className='spinner-border spinner-border-sm text-muted m-0 p-0 h-10px w-10px'
                                role='status'
                              />
                            ) : (
                              aLine.units
                            )}
                          </span>
                          {editEnabled && (
                            <button
                              disabled={isLoading(aLine)}
                              type='button'
                              className='btn btn-icon btn-light btn-sm'
                              onClick={() => onIncreaseUnits(aLine)}
                            >
                              <i className='fas fa-plus-circle' />
                            </button>
                          )}
                        </div>
                      </td>
                      <td className='text-center'>
                        {aLine.product.product?.related_meal ? aLine.product?.traits : '-'}
                      </td>
                      <td className='text-center'>
                        {aLine.product.product?.related_meal
                          ? `${
                              parseInt(aLine.product.traits.replace(' gr', ''), 10) * aLine.units
                            } gr`
                          : '-'}
                      </td>
                      <td className='text-end pe-4'>
                        {intl.formatNumber(aLine.unit_price_inc_tax, {
                          style: 'currency',
                          currency: 'EUR',
                        })}
                      </td>
                      <td className='text-end pe-4'>
                        {intl.formatNumber(aLine.total_price_inc_tax, {
                          style: 'currency',
                          currency: 'EUR',
                        })}
                      </td>
                      <td className='text-end pe-4'>
                        {intl.formatNumber(aLine.net_total_price_inc_tax, {
                          style: 'currency',
                          currency: 'EUR',
                        })}
                      </td>
                      {editEnabled && (
                        <td className='text-center'>
                          {loadingRemoveProduct && removingProduct === aLine.product.id ? (
                            <div className='spinner-border spinner-border-sm' role='status' />
                          ) : (
                            <button
                              disabled={isLoading(aLine)}
                              onClick={() => onRemove(aLine)}
                              className='px-2 cursor-pointer text-muted border-0 bg-transparent fa fa-trash'
                            />
                          )}
                        </td>
                      )}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )}
        <button
          className='btn btn-primary ms-auto mt-4'
          style={{width: 'fit-content'}}
          onClick={onAddAdditionalProduct}
        >
          <span className='fa fa-plus me-2' />
          {intl.formatMessage({id: 'orderDetail.addAdditionalProduct'})}
        </button>
      </section>
      <AddProductModal
        visible={additionalProductModalVisible}
        onClose={() => setAdditionalProductModalVisible(false)}
      />
    </>
  )
}
