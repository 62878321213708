
import {useEffect, useState} from 'react'
import {Modal} from 'react-bootstrap'
import {useIntl} from 'react-intl'

type InvoiceNumberModalProps = {
    currentNumber: string;
    visible: boolean;
    onClose: () => void;
    onSave: (invoiceNumber: string) => void;
}

export default function InvoiceNumberModal({
    currentNumber,
    visible,
    onClose,
    onSave,
}: InvoiceNumberModalProps) {
  const intl = useIntl()
  const [invoiceNumbers, setInvoiceNumbers] = useState<(number | undefined)[]>([])
  
  useEffect(() => { 
    if (currentNumber) {
      setInvoiceNumbers([
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
      ]);
    }
  }, [currentNumber, visible])

  function isValid() {
    return invoiceNumbers?.every((invoiceNumber, i) => {
      if (i === 0) {
        return true
      }

      return invoiceNumber === invoiceNumbers[0] && typeof invoiceNumbers[0] !== 'undefined'
    })
  }

  return (
    <Modal
      show={visible}
      onHide={onClose}
      aria-labelledby='contained-modal-title-vcenter'
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id='contained-modal-title-vcenter'>
          {intl.formatMessage({id: 'invoices.chageNumber'})}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
            {intl.formatMessage({id: 'invoices.changeNumberDescription'})}
        </p>
        {
            invoiceNumbers?.map((invoiceNumber, i) => (
                <div className='form-group mb-3' key={i}>
                    <label className='fw-bolder mb-1' htmlFor={`invoiceNumber${invoiceNumber}`}>
                        {intl.formatMessage({id: 'invoices.nextNumber'})}
                        {
                            i > 0 && invoiceNumber !== invoiceNumbers[0] && (
                                <span className='badge bg-danger ms-2'>
                                    {intl.formatMessage({id: 'invoices.invalidNumber'})}
                                </span>
                            )
                        }
                    </label>
                    <input
                        className='form-control'
                        type='number'
                        id={`invoiceNumber${invoiceNumber}`}
                        value={invoiceNumber}
                        onChange={(e) => setInvoiceNumbers([
                            ...invoiceNumbers.slice(0, i),
                            parseInt(e.target.value, 10),
                            ...invoiceNumbers.slice(i + 1),
                        ])}
                        placeholder={'Ej: 00001'}
                        onPaste={(e)=>{
                            e.preventDefault()
                            return false;
                          }} 
                          onCopy={(e)=>{
                            e.preventDefault()
                            return false;
                          }} 
                          onCut={(e)=>{
                            e.preventDefault()
                            return false;
                        }}
                    />
                </div>
            ))
        }
      </Modal.Body>
      <Modal.Footer>
        <button
          className='btn btn-primary'
          onClick={() => onSave(invoiceNumbers[0].toString())}
          disabled={!isValid()}
          
        >
          {intl.formatMessage({id: 'invoices.save'})}
        </button>
      </Modal.Footer>
    </Modal>
  )
}
