import axios from 'axios'
import {DeliveryNoteLineModel} from '../models/DeliveryNoteLineModel'

const API_URL = process.env.REACT_APP_API_URL
const DELIVERY_NOTES_URL = `${API_URL}/api/b2b/delivery-note-lines/`

export function listDeliveryNoteLines(
  deliveryNoteId
): Promise<{data: Array<DeliveryNoteLineModel>}> {
  return axios.get(DELIVERY_NOTES_URL, {
    params: {
      delivery_note: deliveryNoteId,
    },
  })
}

export function getDeliveryNoteLineById(id: number): Promise<{data: DeliveryNoteLineModel}> {
  const endpoint = `${DELIVERY_NOTES_URL}${id}/`
  return axios.get(endpoint)
}

export function createDeliveryNoteLine(
  deliveryNoteId: number,
  orderLineId: number,
  servedUnits: number
) {
  return axios.post(DELIVERY_NOTES_URL, {
    delivery_note: deliveryNoteId,
    order_line: orderLineId,
    served_units: servedUnits,
  })
}

export function updateDeliveryNoteLine(deliveryNoteLine: DeliveryNoteLineModel) {
  const endpoint = `${DELIVERY_NOTES_URL}${deliveryNoteLine.id}/`
  return axios.patch(endpoint, {...deliveryNoteLine})
}
