import { useSelector } from "react-redux"
import { currentUserSelector } from "../../user-profile"
import { useEffect, useState } from "react"
import { Paginated } from "../../../models/Paginated"
import { CallLog } from "../models/CallLog"
import { CALLS_PER_PAGE, listUserCallLogs } from "../services/CallLogCRUD"
import { CallLogCard } from "./CallLogCard"
import { Pagination } from "@mui/material"
import { useIntl } from "react-intl"


export default function CallLogList() {
    const [page, setPage] = useState(1)
    const [callLogResponse, setCallLogResponse] = useState<Paginated<CallLog> | null>(null)
    const currentUser = useSelector(currentUserSelector)
    const intl = useIntl()
    
    async function fetchCallLogs({ userId, page }) {
        try {
            const { data } = await listUserCallLogs({ userId, page })
            setCallLogResponse(data)
            console.log({data})
        } catch (error) {
            console.error(error)
        }
    }

    useEffect(() => {
        if (currentUser?.id) {
            fetchCallLogs({ userId: currentUser?.id, page })
        }
    }, [currentUser?.id, page])

    useEffect(() => {
        setPage(1)
    }, [currentUser?.id])

    return <section className="card p-10 w-900px">
        <h2 className="mb-8">
            {
                intl.formatMessage({ id: 'callLogs.callLogsOf' })
            } {currentUser?.email}
        </h2>
        <div className="d-flex flex-column gap-4 w-100">
            {
                callLogResponse?.count === 0 && <p className="text-muted">
                    {
                        intl.formatMessage({ id: 'callLogs.noResults' })
                    }
                </p>
            }
        {
            callLogResponse?.results.map(callLog => <CallLogCard 
                key={callLog.id}
                callLog={callLog}
            />)
        }
        </div>
        {callLogResponse?.count > 0 && <Pagination
            className='d-flex justify-content-center mt-8'
            count={Math.ceil(callLogResponse?.count / CALLS_PER_PAGE)}
            page={page}
            onChange={(_e, page) => {
                setPage(page)
            }}
        />}
    </section>
}