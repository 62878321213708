import clsx from 'clsx'
import {useIntl} from 'react-intl'
import {Link, useHistory} from 'react-router-dom'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'

import './PlanTable.css'
import * as planRedux from '../redux/PlanRedux'
import {PlanListingModel} from '../models/PlanListingModel'
import * as userRedux from './../../user-profile/redux/UserRedux'
import * as petRedux from '../../pets/redux/PetRedux'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { getAggregatePricing, PricingResponse } from '..'
import { Modal } from 'react-bootstrap'

type Props = {
  selectedPetOnly?: boolean
}

type BULK_STATE = 'loading' | 'idle'

export default function PlanTable({selectedPetOnly = false}: Props) {
  const displayPetName = !selectedPetOnly
  const intl = useIntl()
  const petPlans = useSelector(planRedux.petPlansSelector, shallowEqual)
  const plans = useSelector(planRedux.plansSelector, shallowEqual)
  const pets = useSelector(petRedux.petsSelector)
  const history = useHistory()
  const currentUser = useSelector(userRedux.currentUserSelector, shallowEqual)
  const petError = useSelector(petRedux.petErrorSelector)
  const planError = useSelector(planRedux.planErrorSelector)
  const dispatch = useDispatch()
  const [selectedPlans, setSelectedPlans] = useState([])
  const [bulkState, setBulkState] = useState<BULK_STATE>('idle')
  const selectedCount = selectedPlans.length
  const [estimation, setEstimation] = useState<PricingResponse>(null)

  function setSelectedPlan(plan: PlanListingModel) {
    history.push(`/user/${currentUser.id}/plan/${plan?.id}`)
  }

  function getPlanList() {
    return selectedPetOnly ? petPlans : plans
  }

  async function onPriorityChange(petId: number) {
    const pet = pets.find((pet) => pet.id === petId)

    dispatch(petRedux.actions.setSelectedPet(pet))
    dispatch(petRedux.actions.setSelectedPetAsPriorityPet())
  }

  async function estimateAggregatePrice() {
    setBulkState('loading')
    try {
      const { data: estimation } = await getAggregatePricing({ planIds: selectedPlans.map((plan) => plan.id) })
      setEstimation(estimation)
    } catch (error) {
      console.log({error})
      if (Array.isArray(error?.response?.data) && error?.response?.data.length > 0 && typeof error?.response.data[0] === 'string') {
        toast.error(error.response.data[0])
      } else {
        toast.error(intl.formatMessage({id: 'plans.estimateAggregatePriceError'}))
      }
      console.error(error)
    } finally {
      setBulkState('idle')
    }
  }

  useEffect(() => {
    if (petError?.length > 0 || planError?.length > 0) {
      toast.error(petError?.[0] || planError?.[0])
    }
  }, [petError, planError])

  useEffect(() => {
    setSelectedPlans([])
  }, [plans])
  
  if (!getPlanList().length && selectedPetOnly) {
    return <p>{intl.formatMessage({id: 'plans.noPlansForSelectedPet'})}</p>
  }
  if (!getPlanList().length && !selectedPetOnly) {
    return <p>{intl.formatMessage({id: 'plans.noPlans'})}</p>
  }
  return (<>
    { !selectedPetOnly && <div className='mb-4'>
      <label className={clsx('mb-2', !selectedCount && 'text-muted')}>
        {selectedCount > 0 ? (
          <>
            {intl.formatMessage(
              {id: 'plans.countSelectedPlans'},
              {count: selectedCount}
            )}
          </>
        ) : (
          <>{intl.formatMessage({id: 'plans.noSelectedPlans'})}</>
        )}
      </label>
      <button 
        className='btn btn-primary d-block mb-2' 
        disabled={selectedPlans.length < 2 || bulkState === 'loading'}
        onClick={estimateAggregatePrice}>
        {
          bulkState === 'loading' ? 
            <span className='spinner-border spinner-border-sm me-4' role='status' aria-hidden='true'></span>
            : <span className='fa fa-calculator me-4'></span>
        }
        {intl.formatMessage({id: 'plans.estimateAggregatePrice'})}
      </button>
    </div> }
    <div className='table-responsive'>
      <table className='table  table-row-dashed table-row-gray-200 align-start gs-0 gy-4'>
        <thead className='table-header sticky-top bg-white'>
          <tr className='fw-bold'>
            { !selectedPetOnly && <th>
              <input
                type='checkbox'
                className='cursor-pointer form-check w-25px h-25px'
                disabled={bulkState === 'loading'}
                checked={selectedPlans.length === plans.length}
                onChange={() => {
                  if (selectedPlans.length === plans.length) {
                    setSelectedPlans([])
                  } else {
                    setSelectedPlans(plans)
                  }
                }}
              />
            </th> }
            <th className='w-90px'>{intl.formatMessage({id: 'plans.code'})}</th>
            {displayPetName && <th className='w-80px'>{intl.formatMessage({id: 'plans.pet'})}</th>}
            <th className=''>{intl.formatMessage({id: 'plans.hasPriority'})}</th>
            <th className=''>{intl.formatMessage({id: 'plans.pricingModel'})}</th>
            <th className=''>{intl.formatMessage({id: 'plans.defaultMealPrice'})}</th>
            <th className=''>{intl.formatMessage({id: 'plans.summary'})}</th>
            <th className='text-center'>{intl.formatMessage({id: 'plans.isComplementary'})}</th>
            <th className='text-center'>{intl.formatMessage({id: 'plans.days'})}</th>
            <th className='text-center'>{intl.formatMessage({id: 'plans.date'})}</th>
            <th className=''>{intl.formatMessage({id: 'plans.defaultGM'})}</th>
          </tr>
        </thead>
        <tbody>
          {getPlanList().map((plan: PlanListingModel) => (
            <tr key={plan.id} className='text-nowrap'>
              {!selectedPetOnly && (
                <td className='text-center'>
                  <input
                    type='checkbox'
                    className='cursor-pointer form-check w-25px h-25px'
                    checked={selectedPlans.includes(plan)}
                    disabled={bulkState === 'loading'}
                    onChange={() => {
                      if (selectedPlans.includes(plan)) {
                        setSelectedPlans(selectedPlans.filter((t) => t.id !== plan.id))
                      } else {
                        setSelectedPlans([...selectedPlans, plan])
                      }
                    }}
                  />
                </td>
              )}
              <td>
                <a
                  onClick={() => setSelectedPlan(plan)}
                  className='d-flex flex-column link cursor-pointer position-relative'
                >
                  {plan?.from_ftk && (
                    <img
                      src='/media/logos/frankie.jpg'
                      alt='Frankie the King'
                      className='h-20px w-20px rounded-2 place-self-center me-1 mb-1'
                    />
                  )}
                  {plan?.code}
                  <span
                    className={clsx(
                      'badge mt-1',
                      plan.state === 'active' && 'badge-success',
                      plan.state === 'proposal' && 'badge-warning text-dark',
                      plan.state !== 'active' && plan.state !== 'proposal' && 'badge-danger'
                    )}
                  >
                    {plan.state}
                  </span>
                </a>
              </td>
              {displayPetName && (
                <td className='text-nowrap'>
                  <Link
                    to={`/user/${currentUser?.id}/pet/${
                      pets.find((pet) => pet.id === plan.pet_id)?.id
                    }`}
                    className='link cursor-pointer'
                  >
                    {pets.find((pet) => pet.id === plan.pet_id)?.name}
                  </Link>
                </td>
              )}
              <td className=''>
                { !!pets.find((pet) => pet.id === plan.pet_id) && <label className='form-check form-switch form-check-custom form-check-solid'>
                  <input
                    id={pets.find((pet) => pet.id === plan.pet_id)?.id?.toString()}
                    type='checkbox'
                    className='form-check-input'
                    onChange={() => onPriorityChange(plan.pet_id)}
                    checked={
                      pets.find((pet) => pet.id === plan.pet_id)?.is_priority_pet || false
                    }
                    disabled={pets?.length <= 1 || pets.find((pet) => pet.id === plan.pet_id)?.is_priority_pet}
                  />
                  <span className='form-check-label'></span>
                </label> }
              </td>
              <td className='text-center'>
                <span className={clsx('badge badge-primary', plan.pricing_model === 'old' && 'badge-info')}>
                  {plan.pricing_model === 'old' && intl.formatMessage({id: 'plans.old'})}
                  {plan.pricing_model === 'new-2023' && intl.formatMessage({id: 'plans.new'})}
                </span>
              </td>
              <td className='pe-8 text-end'>
                {intl.formatNumber(plan.default_meal_price, {
                  style: 'currency',
                  currency: 'EUR',
                })}
              </td>
              <td className='pt-0 pb-1 text-nowrap summary'>
                <div className='py-4' dangerouslySetInnerHTML={{__html: plan.summary}}></div>
              </td>
              <td className='text-center'>
                {plan.combine
                  ? intl.formatMessage({id: 'plans.yes'})
                  : intl.formatMessage({id: 'plans.no'})}
              </td>
              <td className='text-center'>{plan.period.period}</td>
              <td className='text-center'>
                {new Date(plan.created_at).toLocaleDateString()}
              </td>
              <td className='pe-4'>
                {plan?.default_meal_gross_margin > 0
                  ? intl.formatNumber(plan?.default_meal_gross_margin * 100, {
                      maximumFractionDigits: 2,
                    })
                  : '0'}
                %
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      { !!estimation && <Modal show={!!estimation} onHide={() => setEstimation(null)} size='lg'>
        <Modal.Header closeButton>
          <Modal.Title>{intl.formatMessage({id: 'plans.estimatedPrice'})}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='w-fit-content'>
            <table className='table'>
              <thead className='table-header'>
                <tr>
                  <th>
                    {
                      // blank
                    }
                  </th>
                  <th colSpan={2} className='text-center fw-bolder bg-light-success text-success'>
                    {intl.formatMessage({id: 'plans.trial'})}
                  </th>
                  <th colSpan={2} className='text-center fw-bolder bg-light-primary text-primary'>
                    {intl.formatMessage({id: 'plans.regular'})}
                  </th>
                </tr>
                <tr className='text-end'>
                  <th>
                    {
                      // blank
                    }
                  </th>
                  <th className='pe-4 text-end bg-light-success text-success'>
                    {intl.formatMessage({id: 'plans.shipping'})}
                  </th>
                  <th className='pe-4 text-end bg-light-success text-success'>
                    {intl.formatMessage({id: 'plans.total'})}
                  </th>
                  <th className='pe-4 text-end bg-light-primary text-primary'>
                    {intl.formatMessage({id: 'plans.shipping'})}
                  </th>
                  <th className='pe-4 text-end bg-light-primary text-primary'>
                    {intl.formatMessage({id: 'plans.total'})}
                  </th>
                </tr>
              </thead>
              <tbody className='table-body'>
                {
                  estimation?.plans && Object.keys(estimation.plans).map((planId) => {
                    const plan = plans.find((plan) => plan.id === parseInt(planId))
                    return (
                      <tr key={planId} className='text-end'>
                        <td>
                          {pets.find((pet) => pet.id === plan.pet_id)?.name}
                        </td>
                        <td className='pe-4 bg-light-success '>
                          {intl.formatNumber(estimation.plans[planId].trial.shipping_fee, {
                            style: 'currency',
                            currency: 'EUR',
                          })}
                        </td>
                        <td className='pe-4 bg-light-success '>
                          {intl.formatNumber(estimation.plans[planId].trial.total_price_inc_tax, {
                            style: 'currency',
                            currency: 'EUR',
                          })}
                        </td>
                        <td className='pe-4 bg-light-primary '>
                          {intl.formatNumber(estimation.plans[planId].regular.shipping_fee, {
                            style: 'currency',
                            currency: 'EUR',
                          })}
                        </td>
                        <td className='pe-4 bg-light-primary '>
                          {intl.formatNumber(estimation.plans[planId].regular.total_price_inc_tax, {
                            style: 'currency',
                            currency: 'EUR',
                          })}
                        </td>
                      </tr>
                    )
                  })
                }
                  <tr className='fw-bold fs-5 text-end'>
                    <td>
                      {intl.formatMessage({id: 'plans.aggregate'})}
                    </td>
                    <td className='pe-4 bg-light-success '>
                      {intl.formatNumber(estimation.totals.trial.shipping_fee, {
                        style: 'currency',
                        currency: 'EUR',
                      })}
                    </td>
                    <td className='pe-4 bg-light-success '>
                      {intl.formatNumber(estimation.totals.trial.total_price_inc_tax, {
                        style: 'currency',
                        currency: 'EUR',
                      })}
                    </td>
                    <td className='pe-4 bg-light-primary '>
                      {intl.formatNumber(estimation.totals.regular.shipping_fee, {
                        style: 'currency',
                        currency: 'EUR',
                      })}
                    </td>
                    <td className='pe-4 bg-light-primary '>
                      {intl.formatNumber(estimation.totals.regular.total_price_inc_tax, {
                        style: 'currency',
                        currency: 'EUR',
                      })}
                    </td>
                  </tr>
              </tbody>
            </table>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className='btn btn-primary' onClick={() => setEstimation(null)}>
            {intl.formatMessage({id: 'plans.ok'})}
          </button>
        </Modal.Footer>
      </Modal>}
    </div>
  </>)
}
