/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {useIntl} from 'react-intl'
import {useParams} from 'react-router-dom'

import {PageTitle} from '../../../_metronic/layout/core'
import LeadDistributorDetail from '../../modules/lead-distributors/components/LeadDistributorDetail'
import LeadDistributorAddresses from '../../modules/lead-distributors/components/LeadDistributorAddresses'

type RouteParams = {
  leadDistributorId: string
}

const LeadDistributorDetailPage: FC = () => {
  const intl = useIntl()
  const {leadDistributorId} = useParams<RouteParams>()

  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'leadDistributorDetail.title'})}</PageTitle>
      <div className='row'>
        <div className='col-6'>
          <LeadDistributorDetail id={leadDistributorId} />
        </div>
        <div className='col-6'>
          <LeadDistributorAddresses id={leadDistributorId} />
        </div>
      </div>
    </>
  )
}

export {LeadDistributorDetailPage}
