import axios from 'axios'
import {OrderModel, OrderStateEnum} from '../models/OrderModel'

const API_URL = process.env.REACT_APP_API_URL
const ORDERS_URL = `${API_URL}/api/b2b/orders/`
export const ORDERS_PER_PAGE = 20

interface ListOrdersProps {
  state?: OrderStateEnum
  distributorId?: number
  page?: number
  country?: string
  since?: string
  until?: string
  code?: string
  shipping_service?: string
}

interface OrderListResponse {
  count: number;
  next: string;
  previous: string;
  results: Array<OrderModel>;
}
export function listOrders({
  state,
  distributorId,
  page,
  country,
  since,
  until,
  code,
  shipping_service,
}: ListOrdersProps): Promise<{data: OrderListResponse}> {
  const limit = ORDERS_PER_PAGE
  const offset = (page - 1) * limit
  const params: any = {
    distributor: distributorId,
    limit,
    offset,
    country,
    promise_date__gte: since,
    promise_date__lte: until,
    code,
    shipping_service,
  }
  if (state) {
    params.state = state
  }
  return axios.get(ORDERS_URL, {params})
}

export function retrieveOrder(id: number): Promise<{data: OrderModel}> {
  return axios.get(`${ORDERS_URL}${id}/`)
}

export function createOrder(order: OrderModel): Promise<{data: OrderModel}> {
  return axios.post(ORDERS_URL, order)
}

export function createOrderFromEdi(
  distributorId: number,
  edi: String
): Promise<{data: OrderModel}> {
  return axios.post(`${ORDERS_URL}process-edi/`, {
    content: edi,
    distributor: distributorId,
  })
}

export function updateOrder(order: OrderModel): Promise<{data: OrderModel}> {
  const {
    distributor,
    promise_date,
    shipping_fee,
    total_discounts,
    shipping_address,
    billing_address,
    state,
    comments,
    external_reference,
  } = order

  return axios.patch(`${ORDERS_URL}${order.id}/`, {
    distributor,
    promise_date,
    shipping_fee,
    total_discounts,
    shipping_address,
    billing_address,
    state,
    comments,
    external_reference,
  })
}

export enum BulkActionsEnum {
  GENERATE_WAREHOUSE_OPERATIONS_SHEET = 'generate_warehouse_operations_sheet',
  PRINT_TAGS = 'print_tags',
  PREPARE_ORDERS = 'prepare_orders',
  GENERATE_PRODUCTS_NECESSITY_SHEET = 'generate_products_necessity_sheet', 
}

interface BulkActionProps {
  orders: Array<number>
  action: BulkActionsEnum
  country?: string
  since?: string
  until?: string
  code?: string
  distributor?: number
  state?: string
  shipping_service?: string
}

export function performBulkAction({
  orders,
  action,
  country,
  since,
  until,
  code,
  distributor,
  state,
  shipping_service,
}: BulkActionProps) {
  const endpoint = `${API_URL}/api/b2b/orders/massive-actions/`

  return axios.post(endpoint, {
    action,
    orders,
    country,
    promise_date__gte: since,
    promise_date__lte: until,
    code,
    distributor,
    state,
    shipping_service,
  })
}

interface BillingReport {
  subtotal_by_days: any;
  total_subtotal: number;
}
interface GetBillingReportProps {
  since: string
  until: string
}
export function getBillingReport({
  since,
  until,
}: GetBillingReportProps): Promise<{data: BillingReport}> {
  const endpoint = `${API_URL}/api/b2b/orders/reports/billing/`
  const params = {  
    since_data: since,
    until_data: until,
  }

  return axios.get(endpoint, {params})
}

export function getBillingReportCsv({
  since,
  until,
}: GetBillingReportProps): Promise<any> {
  const endpoint = `${API_URL}/api/b2b/orders/reports/csv-billing/`
  const params = {  
    since_data: since,
    until_data: until,
  }

  return axios.get(endpoint, {params})
}

interface EuroKgResponse {
  total_euro_kg: number;
}
export function getEuroKg({
  since,
  until,
}: GetBillingReportProps): Promise<{data: EuroKgResponse}> {
  const endpoint = `${API_URL}/api/b2b/orders/reports/euro-kg/`
  const params = {  
    since_data: since,
    until_data: until,
  }
  return axios.get(endpoint, {params})
}

