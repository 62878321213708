import {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {useDispatch, useSelector} from 'react-redux'
import Select from 'react-select'
import {toast} from 'react-toastify'
import {Button, Modal} from 'react-bootstrap'
import {MultiSelect} from 'react-multi-select-component'

import {authUserSelector} from '../../auth'
import {ordersSelector} from '../../orders'
import {plansSelector} from '../../plans'
import {currentUserSelector} from '../../user-profile'

import {MediumModel} from '../models/MediumModel'
import {EventModel} from '../models/EventModel'
import {EventProfileModel} from '../models/EventProfileModel'

import {addEvent, retrieveEventProfiles, retrieveMediums} from '../services/TaskCRUD'
import {petsSelector} from '../../pets'
import clsx from 'clsx'
import {staffUsersSelector} from '../redux/TaskRedux'
import {
  ACQUISITION_GROUP_ID,
  SUBSCRIPTION_GROUP_ID,
  PET_CARE_GROUP_ID,
  GROUPS_PER_EVENT_PROFILE,
} from '../redux/TaskRedux'
import * as userRedux from '../../user-profile/redux/UserRedux'
import * as taskRedux from '../redux/TaskRedux'

const MISSED_CALL_EVENT_PROFILE_CODE = 'event-missed-call'
const PET_MODEL = 'pet'
const PLAN_MODEL = 'plan'
const ORDER_MODEL = 'saleorder'

type PropsAddModal = {
  onHide: () => void
  setShowModal: any
  show: boolean
  reportMissedCall?: boolean
}

type Option = {
  value: number
  label: any
}

export const AddTaskModal: React.FC<PropsAddModal> = ({
  onHide,
  setShowModal,
  show,
  reportMissedCall = false,
}) => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const authUser = useSelector(authUserSelector)
  const [eventMediums, setEventMediums] = useState<any>()
  const [eventProfiles, setEventProfiles] = useState<any>()
  const [selectedMedium, setSelectedMedium] = useState<number>()
  const [orderOptions, setOrderOptions] = useState<Array<Option>>([])
  const [petOptions, setPetOptions] = useState<Array<Option>>([])
  const [planOptions, setPlanOptions] = useState<Array<Option>>([])
  const [selectedEventProfile, setSelectedEventProfile] = useState<number>()
  const [selectedAppliesAt, setSelectedAppliesAt] = useState<string>()

  const [selectedPlans, setSelectedPlans] = useState<Array<number>>([])
  const [selectedOrders, setSelectedOrders] = useState<Array<number>>([])
  const [selectedPets, setSelectedPets] = useState<Array<number>>([])

  const [selectedStaff, setSelectedStaff] = useState<number>()
  const [replaceStaff, setReplaceStaff] = useState<boolean>(false)
  const [additionalInfo, setAdditionalInfo] = useState<string>('')
  const [isAlarm, setIsAlarm] = useState<boolean>(false)
  const [markAsManaged, setMarkAsManaged] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [startAt, setStartAt] = useState<string>()
  const [email, setEmail] = useState<string>()
  const [phone, setPhone] = useState<string>()
  const currentUser = useSelector(currentUserSelector)
  const staffUserOptions = useSelector(staffUsersSelector)
    ?.filter((user) => user?.cs_groups?.find((aGroup) => aGroup?.name?.includes('ATC')))
    ?.map((user) => ({
      value: user.id,
      label: user.name || user.email,
    }))
  const plans = useSelector(plansSelector)
  const orders = useSelector(ordersSelector)
  const pets = useSelector(petsSelector)
  const staffUser = useSelector(authUserSelector)
  const appliesAtOptions =
    eventProfiles
      ?.find((profile) => profile.id === selectedEventProfile)
      ?.applies_at?.map((app: any) => ({
        value: app.model,
        label: app.name,
      })) || []
  const multiSelectStrings = {
    allItemsAreSelected: intl.formatMessage({id: 'tasks.allOptionsSelected'}),
    clearSearch: intl.formatMessage({id: 'tasks.clearSearch'}),
    clearSelected: intl.formatMessage({id: 'tasks.clearSelected'}),
    noOptions: intl.formatMessage({id: 'tasks.noOptions'}),
    search: intl.formatMessage({id: 'tasks.search'}),
    selectAll: intl.formatMessage({id: 'tasks.selectAll'}),
    selectAllFiltered: intl.formatMessage({id: 'tasks.selectAllFiltered'}),
    selectSomeItems: intl.formatMessage({id: 'tasks.selectSomeItems'}),
    create: intl.formatMessage({id: 'tasks.create'}),
  }

  useEffect(() => {
    if (!eventMediums) {
      let mediums = retrieveMediums() as any
      mediums.then((mediums) => {
        mediums = mediums?.data?.map((medium: MediumModel) => ({
          value: medium.id,
          label: medium.code,
        }))
        setEventMediums(mediums)
      })
    }
    if (!eventProfiles) {
      let profiles = retrieveEventProfiles() as any
      profiles.then((profiles) => setEventProfiles(profiles.data))
    }
  }, [eventMediums, eventProfiles])

  useEffect(
    function resetForm() {
      if (show) {
        setSelectedEventProfile(undefined)
        setEmail('')
        setPhone('')
        setAdditionalInfo('')
        setReplaceStaff(false)
        setIsAlarm(false)
        setMarkAsManaged(false)
        if (!startAt) {
          const today = new Date()
          const tomorrow = new Date(today)
          tomorrow.setDate(tomorrow.getDate() + 1)
          tomorrow.setHours(11)
          tomorrow.setMinutes(0)
          tomorrow.setMilliseconds(0)
          tomorrow.setSeconds(0)
          setStartAt(tomorrow.toISOString().split('Z')[0])
        }
      }
    },
    [show]
  )

  function updateSelectedStaff(selectedEventProfile) {
    let staffId = null
    const eventProfile = eventProfiles?.find((profile) => profile.id === selectedEventProfile)
    const csGroup = GROUPS_PER_EVENT_PROFILE[eventProfile.code]

    switch (csGroup) {
      case ACQUISITION_GROUP_ID:
        staffId = currentUser?.cs_acquisition_agent
        break
      case SUBSCRIPTION_GROUP_ID:
        staffId = currentUser?.cs_subscription_agent
        break
      case PET_CARE_GROUP_ID:
        staffId = currentUser?.cs_pet_care_agent
        break
      default:
        break
    }
    if (staffId) {
      setSelectedStaff(staffId)
    } else {
      setSelectedStaff(authUser?.id)
    }
  }

  useEffect(
    function resetProfileRelatedFields() {
      if (selectedEventProfile) {
        setSelectedMedium(undefined)
        setSelectedAppliesAt(undefined)
        setSelectedPets([])
        setSelectedPlans([])
        setSelectedOrders([])
        updateSelectedStaff(selectedEventProfile)
      }
    },
    [selectedEventProfile]
  )

  useEffect(
    function resetPetRelatedFields() {
      setSelectedPets([])
      setSelectedPlans([])
      setSelectedOrders([])
    },
    [selectedAppliesAt]
  )

  useEffect(
    function updateSelectOptions() {
      const newPetOptions = pets?.map((pet) => ({value: pet.id, label: pet.name}))
      const newPlanOptions = plans?.map((plan) => ({
        value: plan.id,
        label: (
          <div className='d-flex justify-content-between align-items-center'>
            {plan.code}
            {pets?.find((pet) => pet.id === plan.pet_id)?.name &&
              ` (${pets?.find((pet) => pet.id === plan.pet_id)?.name})`}
            <span
              className={clsx(
                'badge ms-1',
                plan.state === 'active' && 'badge-success',
                plan.state === 'proposal' && 'badge-warning',
                plan.state !== 'active' && plan.state !== 'proposal' && 'badge-danger'
              )}
            >
              {plan.state}
            </span>
          </div>
        ),
      }))

      // TODO: get all order pages if requested by ATC (usually first page will be enough for reporting incidents)
      const newOrderOptions = orders?.results?.map((order) => ({
        value: order.id,
        label: (
          <div className='d-flex justify-content-between align-items-center'>
            {order.code}
            {pets?.find((pet) => pet.id === order.pet_id)?.name &&
              ` (${pets?.find((pet) => pet.id === order.pet_id)?.name})`}
            <span
              className={clsx(
                'badge ms-1',
                ['completed'].includes(order.state || '') && 'badge-success',
                ['locked', 'confirmed'].includes(order.state || '') && 'badge-primary',
                order.state === 'proposal' && 'badge-warning',
                !['confirmed', 'locked', 'completed', 'proposal'].includes(order.state || '') &&
                  'badge-danger'
              )}
            >
              {order.state}
            </span>
          </div>
        ),
      }))

      setPetOptions(newPetOptions)
      setPlanOptions(newPlanOptions)
      setOrderOptions(newOrderOptions)
    },
    [pets, plans, orders]
  )

  useEffect(() => {
    if (appliesAtOptions?.length === 1) {
      setSelectedAppliesAt(appliesAtOptions[0].value)
    }
  }, [appliesAtOptions])

  useEffect(
    function selectOnlyOptions() {
      if (selectedAppliesAt === PET_MODEL && petOptions?.length === 1) {
        setSelectedPets([petOptions[0].value])
      }
      if (selectedAppliesAt === PLAN_MODEL && planOptions?.length === 1) {
        setSelectedPlans([planOptions[0].value])
      }
      if (selectedAppliesAt === ORDER_MODEL && orderOptions?.length === 1) {
        setSelectedOrders([orderOptions[0].value])
      }
    },
    [petOptions, planOptions, orderOptions, selectedAppliesAt]
  )

  const handleAddEvent = () => {
    const userId = currentUser?.id
    const event: EventModel = reportMissedCall
      ? {
          eventProfileId: eventProfiles?.find(
            (profile) => profile.code === MISSED_CALL_EVENT_PROFILE_CODE
          )?.id,
          reporterUserId: staffUser.id,
          mediumId: eventMediums?.find((medium) => medium.label === 'phone')?.value,
          dispatchAfter: startAt,
          isAlarm: isAlarm,
          ownerId: selectedStaff,
          email,
          phone,
          resolutionId: markAsManaged ? 6 : undefined,
          replaceDefaultAgent: replaceStaff,
          additionalInfo,
        }
      : {
          eventProfileId: selectedEventProfile,
          reporterUserId: staffUser.id,
          userId: userId,
          mediumId: selectedMedium,
          petIds: selectedPets,
          planIds: selectedPlans,
          saleOrderIds: selectedOrders,
          dispatchAfter: startAt,
          replaceDefaultAgent: replaceStaff,
          isAlarm: isAlarm,
          ownerId: selectedStaff,
          resolutionId: markAsManaged ? 6 : undefined,
          additionalInfo,
        }

    if (!reportMissedCall && !selectedEventProfile) {
      return toast.error(intl.formatMessage({id: 'tasks.eventProfileRequired'}))
    }

    if (!reportMissedCall && !selectedMedium) {
      return toast.error(intl.formatMessage({id: 'tasks.mediumRequired'}))
    }

    if (appliesAtOptions?.length > 0 && !selectedAppliesAt) {
      return toast.error(intl.formatMessage({id: 'tasks.appliesAtRequired'}))
    }

    if (selectedAppliesAt === PET_MODEL && !selectedPets && petOptions?.length > 0) {
      return toast.error(intl.formatMessage({id: 'tasks.petRequired'}))
    }

    if (selectedAppliesAt === PLAN_MODEL && !selectedPlans && planOptions?.length > 0) {
      return toast.error(intl.formatMessage({id: 'tasks.planRequired'}))
    }

    if (selectedAppliesAt === ORDER_MODEL && !selectedOrders && orderOptions?.length > 0) {
      return toast.error(intl.formatMessage({id: 'tasks.orderRequired'}))
    }

    if (reportMissedCall && !email && !phone) {
      return toast.error(intl.formatMessage({id: 'tasks.missedCallInfoRequired'}))
    }
    setLoading(true)
    addEvent(event)
      .then(() => {
        toast.success(intl.formatMessage({id: 'tasks.taskAddedSuccessfully'}))
        setShowModal(false)
        onHide()
        setSelectedEventProfile(undefined)
        setLoading(false)
        dispatch(userRedux.actions.requestUser(currentUser?.id))
        dispatch(taskRedux.actions.retrieveAlarmTasks())
      })
      .catch((res) => {
        const responseData = res?.response?.data

        if (responseData?.event_id) {
          toast.error(intl.formatMessage({id: 'tasks.eventProfileRequired'}))
        }
        if (responseData?.medium_id) {
          toast.error(intl.formatMessage({id: 'tasks.mediumRequired'}))
        }
        setLoading(false)
      })
  }

  function renderOptions() {
    if (!!selectedEventProfile && !reportMissedCall) {
      const appliesAt = eventProfiles
        ?.find((profile) => profile.id === selectedEventProfile)
        ?.applies_at?.find((appliesAt) => appliesAt.model === selectedAppliesAt)
      if (appliesAt) {
        switch (appliesAt.model) {
          case PET_MODEL:
            let petsHtml
            if (petOptions?.length > 0) {
              petsHtml = (
                <MultiSelect
                  disabled={petOptions?.length === 1}
                  className='multi-select'
                  overrideStrings={multiSelectStrings}
                  labelledBy='Select'
                  disableSearch={true}
                  options={petOptions}
                  value={
                    petOptions?.filter((anOption) => selectedPets?.includes(anOption.value)) || []
                  }
                  onChange={(pets: any) => setSelectedPets(pets?.map((aPet: any) => aPet.value))}
                  valueRenderer={(selected) =>
                    selected.length
                      ? selected
                          ?.map(({value}) => pets?.find((aPet) => aPet.id === value).name)
                          .join(', ')
                      : ''
                  }
                />
              )
            } else {
              petsHtml = <>{intl.formatMessage({id: 'tasks.noPet'})}</>
            }
            return (
              <div className='row align-items-center mb-5'>
                <div className='col-4 fw-bold text-end'>
                  {intl.formatMessage({id: 'tasks.pet'})}
                </div>
                <div className='col-8'>{petsHtml}</div>
              </div>
            )
          case PLAN_MODEL:
            let plansHtml
            if (planOptions?.length > 0) {
              plansHtml = (
                <MultiSelect
                  disabled={planOptions?.length === 1}
                  className='multi-select'
                  overrideStrings={multiSelectStrings}
                  labelledBy='Select'
                  disableSearch={true}
                  options={planOptions}
                  value={
                    planOptions?.filter((anOption) => selectedPlans?.includes(anOption.value)) || []
                  }
                  onChange={(plans: any) =>
                    setSelectedPlans(plans?.map((aPlan: any) => aPlan.value))
                  }
                  valueRenderer={(selected) =>
                    selected.length
                      ? selected
                          ?.map(({value}) => plans?.find((aPlan) => aPlan.id === value).code)
                          .join(', ')
                      : ''
                  }
                />
              )
            } else {
              plansHtml = <>{intl.formatMessage({id: 'tasks.noPlan'})}</>
            }
            return (
              <div className='row align-items-center mb-5'>
                <div className='col-4 fw-bold text-end'>
                  {intl.formatMessage({id: 'tasks.plan'})}
                </div>
                <div className='col-8'>{plansHtml}</div>
              </div>
            )
          case ORDER_MODEL:
            let orderHtml
            if (orderOptions?.length > 0) {
              orderHtml = (
                <MultiSelect
                  disabled={orderOptions?.length === 1}
                  className='multi-select'
                  overrideStrings={multiSelectStrings}
                  labelledBy='Select'
                  disableSearch={true}
                  options={orderOptions}
                  value={
                    orderOptions?.filter((anOption) => selectedOrders?.includes(anOption.value)) ||
                    []
                  }
                  onChange={(someOrders: any) =>
                    setSelectedOrders(someOrders?.map((anOrder: any) => anOrder.value))
                  }
                  valueRenderer={(selected) =>
                    selected.length
                      ? selected
                          ?.map(
                            ({value}) =>
                              orders?.results?.find((anOrder) => anOrder.id === value).code
                          )
                          .join(', ')
                      : ''
                  }
                />
              )
            } else {
              orderHtml = <>{intl.formatMessage({id: 'tasks.noOrder'})}</>
            }
            return (
              <div className='row align-items-center mb-5'>
                <div className='col-4 fw-bold text-end'>
                  {intl.formatMessage({id: 'tasks.order'})}
                </div>
                <div className='col-8'>{orderHtml}</div>
              </div>
            )
        }
      } else {
        return []
      }
    }
    return []
  }

  return (
    <Modal
      show={show}
      onHide={onHide}
      width='500'
      aria-labelledby='contained-modal-title-vcenter'
      centered
    >
      <Modal.Header>
        <Modal.Title id='contained-modal-title-center'>
          {reportMissedCall
            ? intl.formatMessage({id: 'tasks.reportMissedCall'})
            : intl.formatMessage(
                {id: 'tasks.addClientTask'},
                {client: currentUser?.name || intl.formatMessage({id: 'tasks.theSelectedClient'})}
              )}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='container'>
          {reportMissedCall && (
            <>
              <div className='row align-items-center mb-5'>
                <div className='col-4 fw-bold text-end'>
                  {intl.formatMessage({id: 'tasks.phone'})}
                </div>
                <div className='col-8'>
                  <input
                    type='tel'
                    className='form-control'
                    placeholder={intl.formatMessage({id: 'tasks.phone'})}
                    onChange={(e) => setPhone(e.target.value)}
                    value={phone}
                    maxLength={14}
                  />
                </div>
              </div>
              <div className='row align-items-center mb-5'>
                <div className='col-4 fw-bold text-end'>
                  {intl.formatMessage({id: 'tasks.leadEmail'})}
                </div>
                <div className='col-8'>
                  <input
                    type='email'
                    className='form-control'
                    placeholder={intl.formatMessage({id: 'tasks.leadEmail'})}
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                  />
                </div>
              </div>
            </>
          )}
          {!reportMissedCall && (
            <div className='row align-items-center mb-5'>
              <div className='col-4 fw-bold text-end'>
                {intl.formatMessage({id: 'tasks.profile'})}
              </div>
              <div className='col-8'>
                <Select 
                  className="react-select-container"
                  classNamePrefix="react-select" 
                  placeholder={intl.formatMessage({id: 'genericForm.selectPlaceholder'})}
                  onChange={(e: any) => setSelectedEventProfile(e.value)}
                  options={eventProfiles?.map((profile: EventProfileModel) => ({
                    value: profile.id,
                    label: profile.name,
                  }))}
                />
              </div>
            </div>
          )}
          {!!selectedEventProfile && (
            <div className='row align-items-center mb-5'>
              <div className='col-4 fw-bold text-end'>
                {intl.formatMessage({id: 'tasks.channel'})}
              </div>
              <div className='col-8'>
                <Select 
                  className="react-select-container"
                  classNamePrefix="react-select" 
                  value={eventMediums.find((aMedium) => aMedium.value === selectedMedium) || false}
                  placeholder={intl.formatMessage({id: 'genericForm.selectPlaceholder'})}
                  onChange={(e: any) => setSelectedMedium(e.value)}
                  options={eventMediums}
                />
              </div>
            </div>
          )}
          {!!selectedEventProfile && appliesAtOptions?.length > 1 && (
            <div className='row align-items-center mb-5'>
              <div className='col-4 fw-bold text-end'>
                {intl.formatMessage({id: 'tasks.appliesAt'})}
              </div>
              <div className='col-8'>
                <Select 
                  className="react-select-container"
                  classNamePrefix="react-select" 
                  value={
                    appliesAtOptions.find((anOption) => anOption.value === selectedAppliesAt) ||
                    false
                  }
                  placeholder={intl.formatMessage({id: 'genericForm.selectPlaceholder'})}
                  onChange={(e: any) => setSelectedAppliesAt(e.value)}
                  options={appliesAtOptions}
                />
              </div>
            </div>
          )}
          {!!selectedAppliesAt && renderOptions()}

          <div className='row align-items-center mb-5'>
            <div className='col-4 fw-bold text-end'>
              {intl.formatMessage({id: 'tasks.startDate'})}
            </div>
            <div className='col-8'>
              <input
                type='datetime-local'
                className='calendar form-control'
                value={startAt}
                onChange={(e) => setStartAt(e.target.value)}
              />
            </div>
          </div>
          <div className='row align-items-center mb-5'>
            <div className='col-4 fw-bold text-end'>{intl.formatMessage({id: 'tasks.owner'})}</div>
            <div className='col-8'>
              <Select 
                className="react-select-container"
                classNamePrefix="react-select" 
                value={
                  staffUserOptions.find((anOption) => anOption.value === selectedStaff) || false
                }
                placeholder={intl.formatMessage({id: 'genericForm.selectPlaceholder'})}
                onChange={(e: any) => setSelectedStaff(e.value)}
                options={staffUserOptions}
              />
            </div>
          </div>
          <div className='row align-items-center mb-5'>
            <div className='col-4 fw-bold text-end'>
              {intl.formatMessage({id: 'tasks.additionalInfo'})}
            </div>
            <div className='col-8'>
              <textarea
                className='form-control'
                placeholder={intl.formatMessage({id: 'tasks.additionalInfo'})}
                onChange={(e) => setAdditionalInfo(e.target.value)}
                value={additionalInfo}
              />
            </div>
          </div>
          {!reportMissedCall && (
            <div className='row align-items-center mb-5'>
              <div className='col-4 fw-bold text-end'>
                {intl.formatMessage({id: 'tasks.replaceStaff'})}
              </div>
              <div className='col-8'>
                <label className='form-check form-switch form-switch-md form-check-custom form-check-solid mt-3'>
                  <input
                    className='form-check-input me-4'
                    id='replace-staff'
                    type='checkbox'
                    checked={replaceStaff}
                    onChange={(e) => setReplaceStaff(e.target.checked)}
                  />
                </label>
              </div>
            </div>
          )}
          <div className='row align-items-center mb-5'>
            <div className='col-4 fw-bold text-end'>
              {intl.formatMessage({id: 'tasks.isAlarm'})}
            </div>
            <div className='col-8'>
              <label className='form-check form-switch form-switch-md form-check-custom form-check-solid mt-3'>
                <input
                  className='form-check-input me-4'
                  id='is-alarm'
                  type='checkbox'
                  checked={isAlarm}
                  onChange={(e) => setIsAlarm(e.target.checked)}
                />
              </label>
            </div>
          </div>
          <div className='row align-items-center mb-5'>
            <div className='col-4 fw-bold text-end'>
              {intl.formatMessage({id: 'tasks.markAsResolved'})}
            </div>
            <div className='col-8'>
              <label className='form-check form-switch form-switch-md form-check-custom form-check-solid mt-3'>
                <input
                  className='form-check-input me-4'
                  id='mark-as-managed'
                  type='checkbox'
                  checked={markAsManaged}
                  onChange={(e) => setMarkAsManaged(e.target.checked)}
                />
              </label>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='tertiary' onClick={onHide}>
          {intl.formatMessage({id: 'tasks.cancel'})}
        </Button>
        <Button variant='primary' className='ms-10' onClick={handleAddEvent} disabled={loading}>
          {reportMissedCall
            ? intl.formatMessage({id: 'tasks.reportCall'})
            : intl.formatMessage({id: 'tasks.addEvent'})}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
