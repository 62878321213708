import {FC, useState, useEffect} from 'react'
import {useIntl} from 'react-intl'
import {Link} from 'react-router-dom'
import {toast} from 'react-toastify'

import {WorkLogModel} from '../models/WorkLogModel'
import {listResolutions, listWorkLogs} from '../services/TaskCRUD'
import Select from 'react-select'
import {useSelector} from 'react-redux'
import {staffUsersSelector} from '../redux/TaskSelectors'
import xlsx from 'json-as-xlsx'

const AgentPerformanceTable: FC = () => {
  const intl = useIntl()
  const staffUsers = useSelector(staffUsersSelector)?.filter((user) =>
    user?.cs_groups?.find((aGroup) => aGroup?.name?.includes('ATC'))
  )
  const [workLogs, setWorkLogs] = useState<WorkLogModel[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [date, setDate] = useState<Date>(new Date())
  const [selectedStaffUser, setSelectedStaffUser] = useState<any>(null)
  const [resolutions, setResolutions] = useState<any>([])
  const sheetName = `Rendimiento ${selectedStaffUser?.label?.slice(0, 5)} - ${date
    ?.toISOString()
    .slice(0, 10)}`
  const fileName = `Rendimiento ${selectedStaffUser?.label} - ${date?.toISOString().slice(0, 10)}`

  async function fetchResolutions() {
    try {
      const {data} = await listResolutions()
      setResolutions(data)
    } catch (error) {
      console.warn(error)
    }
  }
  useEffect(() => {
    fetchResolutions()
  }, [])

  function onRefresh() {
    setLoading(true)

    listWorkLogs(selectedStaffUser?.value, date.toISOString().slice(0, 10))
      .then((response) => {
        setWorkLogs(response.data)
      })
      .catch((error) => {
        toast.error(error.message)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    if (date && selectedStaffUser) {
      onRefresh()
    }
  }, [date, selectedStaffUser])

  function formatTimeDifference(start, finish) {
    if (!start && !finish) {
      return '-'
    }
    if (areTheSameDatetime(new Date(start), new Date(finish))) {
      return (
        <span className='badge bg-light-dark text-dark fs-7 rounded-pill rounded-circle fw-bolder'>
          ?
        </span>
      )
    }
    if (new Date(finish).getTime() - new Date(start).getTime() < 60000) {
      return `${Math.ceil((new Date(finish).getTime() - new Date(start).getTime()) / 1000)} s`
    }
    if (new Date(finish).getTime() - new Date(start).getTime() < 3600000) {
      return `${Math.ceil((new Date(finish).getTime() - new Date(start).getTime()) / 60000)} m`
    }
    if (new Date(finish).getTime() - new Date(start).getTime() < 86400000) {
      return `${Math.ceil((new Date(finish).getTime() - new Date(start).getTime()) / 3600000)} h`
    }
    return `${Math.ceil((new Date(finish).getTime() - new Date(start).getTime()) / 86400000)} d`
  }

  function calculateAverageTimeDifference() {
    const relevantWorkLogs = workLogs?.filter(
      (workLog) =>
        workLog.start_datetime !== null &&
        workLog.end_datetime !== null &&
        !areTheSameDatetime(new Date(workLog.start_datetime), new Date(workLog.end_datetime))
    )
    const totalSeconds = relevantWorkLogs?.reduce((total, workLog) => {
      return (
        total +
        (new Date(workLog.end_datetime).getTime() - new Date(workLog.start_datetime).getTime())
      )
    }, 0)
    const averageSeconds = totalSeconds / relevantWorkLogs?.length
    if (averageSeconds > 0) {
      return formatTimeDifference(new Date(), new Date(new Date().getTime() + averageSeconds))
    } else {
      return '-'
    }
  }

  function areTheSameDatetime(a, b) {
    return (
      a.getFullYear() === b.getFullYear() &&
      a.getMonth() === b.getMonth() &&
      a.getDate() === b.getDate() &&
      a.getHours() === b.getHours() &&
      a.getMinutes() === b.getMinutes() &&
      a.getSeconds() === b.getSeconds()
    )
  }

  function getDataSheet() {
    return {
      sheet: sheetName,
      columns: [
        {
          label: intl.formatMessage({id: 'tasks.taskId'}),
          value: (row: any) => row.task_id,
        },
        {
          label: intl.formatMessage({id: 'tasks.solvedViaFocus'}),
          value: (row: any) =>
            !areTheSameDatetime(new Date(row?.end_datetime), new Date(row?.start_datetime))
              ? 'Sí'
              : 'No',
        },
        {
          label: intl.formatMessage({id: 'tasks.dispatchDate'}),
          value: (row: any) =>
            row.start_datetime
              ? new Date(row.start_datetime).toLocaleString('es-ES', {
                  year: 'numeric',
                  month: '2-digit',
                  day: '2-digit',
                  hour: '2-digit',
                  minute: '2-digit',
                  second: '2-digit',
                })
              : '-',
        },
        {
          label: intl.formatMessage({id: 'tasks.resolutionDate'}),
          value: (row: any) =>
            row.end_datetime
              ? new Date(row.end_datetime).toLocaleString('es-ES', {
                  year: 'numeric',
                  month: '2-digit',
                  day: '2-digit',
                  hour: '2-digit',
                  minute: '2-digit',
                  second: '2-digit',
                })
              : '-',
        },
        {
          label: intl.formatMessage({id: 'tasks.resolutionTime'}),
          value: (row: any) => formatTimeDifference(row.start_datetime, row.end_datetime),
        },
        {
          label: intl.formatMessage({id: 'tasks.initialResolution'}),
          value: (row: any) =>
            resolutions?.find((resolution) => resolution.id === row.start_resolution_id)?.name ||
            row.start_resolution_id ||
            '-',
        },
        {
          label: intl.formatMessage({id: 'tasks.finalResolution'}),
          value: (row: any) =>
            resolutions?.find((resolution) => resolution.id === row.end_resolution_id)?.name ||
            row.end_resolution_id ||
            '-',
        },
      ],
      content: workLogs,
    }
  }

  function exportToCsv() {
    xlsx([getDataSheet()] as any, {
      fileName: fileName,
      extraLength: 0,
      writeMode: 'writeFile',
      writeOptions: {},
    })
  }

  return (
    <div className='card p-10'>
      <menu className='d-flex px-0 gap-4 align-items-end justify-content-start flex-wrap'>
        <div className='d-flex flex-column'>
          <label className='form-label mb-0'>{intl.formatMessage({id: 'tasks.selectDate'})}</label>
          <input
            type='date'
            style={{
              height: '38px',
              borderColor: 'rgb(204, 204, 204)',
              borderRadius: '4px',
            }}
            className='form-control'
            value={date.toISOString().slice(0, 10)}
            onChange={(e) => {
              setDate(new Date(e.target.value))
            }}
          />
        </div>
        <div className='d-flex flex-column'>
          <label className='form-label mb-0'>
            {intl.formatMessage({id: 'tasks.selectStaffUser'})}
          </label>
          <Select 
            className="react-select-container w-250px"
            classNamePrefix="react-select"
            options={staffUsers
              ?.map((staffUser) => ({
                value: staffUser.id,
                label: staffUser.name,
              }))
              ?.filter((staffUser) => !!staffUser.label)
              ?.sort((a, b) => a.label.localeCompare(b.label))}
            placeholder={intl.formatMessage({id: 'tasks.select'})}
            onChange={(e) => {
              setSelectedStaffUser(e)
            }}
            value={selectedStaffUser}
          />
        </div>
        {workLogs && selectedStaffUser && (
          <div className='d-flex gap-3'>
            <span className='rounded p-3 bg-light-primary text-primary fw-bold'>
              {workLogs?.filter((workLog) => workLog.end_datetime !== null).length}{' '}
              {intl.formatMessage({id: 'tasks.resolvedTasks'})}
            </span>
            {calculateAverageTimeDifference() !== '-' &&
              workLogs?.filter((workLog) => workLog.end_datetime !== null)?.length > 0 && (
                <span className='rounded p-3 bg-light-primary text-primary fw-bold'>
                  {intl.formatMessage({id: 'tasks.averageResolutionTime'})}:{' '}
                  {calculateAverageTimeDifference()}
                </span>
              )}
          </div>
        )}
        {workLogs?.length > 0 && (
          <button className='btn btn-primary ms-auto' onClick={exportToCsv}>
            <i className='fa fa-file-csv me-3' />
            {intl.formatMessage({id: 'tasks.exportToCsv'})}
          </button>
        )}
      </menu>
      {loading ? (
        <div className='d-flex h-100px mt-10 w-100 justify-content-center align-items-center'>
          <div className='d-block spinner-border mx-auto text-primary' role='status' />
        </div>
      ) : (
        <div className='table-responsive'>
          {!selectedStaffUser && (
            <p className='mt-6'>{intl.formatMessage({id: 'tasks.selectStaffUserToStart'})}</p>
          )}
          {selectedStaffUser && workLogs?.length === 0 && (
            <p className='mt-6'>{intl.formatMessage({id: 'tasks.noTasks'})}</p>
          )}
          {workLogs?.length > 0 && (
            <>
              <table className='table table-hover table-row-dashed table-row-gray-200 align-middle gs-0 gy-4'>
                <thead className='table-header sticky-top bg-white'>
                  <tr className='fw-bold'>
                    <th className='text-start ps-3'>{intl.formatMessage({id: 'tasks.taskId'})}</th>
                    <th className='text-center'>
                      {intl.formatMessage({id: 'tasks.solvedViaFocus'})}
                    </th>
                    <th className='text-center'>
                      {intl.formatMessage({id: 'tasks.dispatchDate'})}
                    </th>
                    <th className='text-center'>
                      {intl.formatMessage({id: 'tasks.resolutionDate'})}
                    </th>
                    <th className='text-center'>
                      {intl.formatMessage({id: 'tasks.resolutionTime'})}
                    </th>
                    <th className='text-center'>
                      {intl.formatMessage({id: 'tasks.initialResolution'})}
                    </th>
                    <th className='text-center'>
                      {intl.formatMessage({id: 'tasks.finalResolution'})}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {workLogs?.map((workLog: WorkLogModel) => (
                    <tr key={workLog.id} className='hover:bg-gray-100'>
                      <td className='text-start ps-3'>
                        <Link
                          to={
                            workLog?.user_id
                              ? `/user/${workLog.user_id}/task/${workLog.task_id}`
                              : `/task/${workLog.task_id}`
                          }
                        >
                          {workLog.task_id}
                        </Link>
                      </td>
                      <td className='text-center'>
                        {!areTheSameDatetime(
                          new Date(workLog?.end_datetime),
                          new Date(workLog?.start_datetime)
                        ) ? (
                          <span className='badge badge-light-success rounded-pill p-0 w-25px h-25px align-items-center d-flex justify-content-center m-auto text-center rounded-circle'>
                            <span className='fa fa-check'></span>
                          </span>
                        ) : (
                          <span className='badge badge-light-danger rounded-pill p-0 w-25px h-25px align-items-center d-flex justify-content-center m-auto text-centerrounded-circle'>
                            <span className='fa fa-times'></span>
                          </span>
                        )}
                      </td>
                      <td className='text-center'>
                        {workLog.start_datetime
                          ? new Date(workLog.start_datetime).toLocaleString('es-ES', {
                              year: 'numeric',
                              month: '2-digit',
                              day: '2-digit',
                              hour: '2-digit',
                              minute: '2-digit',
                              second: '2-digit',
                            })
                          : '-'}
                      </td>
                      <td className='text-center'>
                        {workLog.end_datetime
                          ? new Date(workLog.end_datetime).toLocaleString('es-ES', {
                              year: 'numeric',
                              month: '2-digit',
                              day: '2-digit',
                              hour: '2-digit',
                              minute: '2-digit',
                              second: '2-digit',
                            })
                          : '-'}
                      </td>
                      <td className='text-center'>
                        {workLog?.end_datetime && workLog?.start_datetime
                          ? formatTimeDifference(workLog.start_datetime, workLog.end_datetime)
                          : '-'}
                      </td>
                      <td className='text-center'>
                        {resolutions?.find(
                          (resolution) => resolution.id === workLog.start_resolution_id
                        )?.name ||
                          workLog.start_resolution_id ||
                          '-'}
                      </td>
                      <td className='text-center'>
                        {resolutions?.find(
                          (resolution) => resolution.id === workLog.end_resolution_id
                        )?.name ||
                          workLog.end_resolution_id ||
                          '-'}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </>
          )}
        </div>
      )}
    </div>
  )
}

export default AgentPerformanceTable
