import {Pagination} from '@mui/material'
import {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'

import * as taskRedux from '../redux/TaskRedux'
import TaskTable from './TaskTable'

function LeadCallRequestsTable() {
  const dispatch = useDispatch()
  const tasks = useSelector(taskRedux.leadCallRequestTasksSelector)
  const status = useSelector(taskRedux.leadCallRequestTasksStatusSelector)
  const page = useSelector(taskRedux.leadCallRequestTasksPageSelector)
  const count = useSelector(taskRedux.leadCallRequestTasksCountSelector)

  useEffect(() => {
    dispatch(taskRedux.actions.retrieveLeadCallRequestTasks())
  }, [])

  function onPageChange(event, page) {
    dispatch(taskRedux.actions.retrieveLeadCallRequestTasks(page))
  }

  return (
    <div className='card w-100'>
      <div className='card-body position-relative' id='kt_not_reachable_body'>
        <TaskTable
          tasks={tasks}
          displayOwner={false}
          onRefresh={() => dispatch(taskRedux.actions.retrieveLeadCallRequestTasks())}
          loading={status === 'loading'}
        />
        {count > 0 && (
          <Pagination
            className='mx-auto mt-4 d-flex justify-content-center'
            count={Math.ceil(count / taskRedux.TASKS_PER_PAGE)}
            page={page}
            onChange={onPageChange}
          />
        )}
      </div>
    </div>
  )
}

export default LeadCallRequestsTable
