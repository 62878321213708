import {useEffect} from 'react'
import Pagination from '@mui/material/Pagination'
import {useDispatch, useSelector} from 'react-redux'

import * as taskRedux from '../redux/TaskRedux'
import TaskTable from './TaskTable'

function PendingCustomerTable() {
  const dispatch = useDispatch()
  const tasks = useSelector(taskRedux.pendingCustomerTasksSelector)
  const status = useSelector(taskRedux.pendingCustomerTasksStatusSelector)
  const page = useSelector(taskRedux.pendingCustomerPageSelector)
  const count = useSelector(taskRedux.pendingCustomerCountSelector)

  useEffect(() => {
    dispatch(taskRedux.actions.retrievePendingCustomerTasks())
  }, [])

  function onPageChange(event, page) {
    dispatch(taskRedux.actions.retrievePendingCustomerTasks(page))
  }

  return (
    <div className='card w-100'>
      <div className='card-body position-relative' id='kt_not_reachable_body'>
        <TaskTable
          tasks={tasks}
          displayOwner={false}
          onRefresh={() => dispatch(taskRedux.actions.retrievePendingCustomerTasks(page))}
          loading={status === 'loading'}
        />
        {count > 0 && (
          <Pagination
            className='mx-auto mt-4 d-flex justify-content-center'
            count={Math.ceil(count / taskRedux.TASKS_PER_PAGE)}
            page={page}
            onChange={onPageChange}
          />
        )}
      </div>
    </div>
  )
}

export default PendingCustomerTable
