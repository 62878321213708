/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import {OrderTrackingHistoryList} from '../../modules/orders'

const OrderTrackingHistoryPage: FC = () => {
  const intl = useIntl()
 
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'menu.orderTrackingHistory'})}</PageTitle>
      <OrderTrackingHistoryList />
    </>
  )
}

export {OrderTrackingHistoryPage}
