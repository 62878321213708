import {useEffect, useState} from 'react'
import {Button, Modal} from 'react-bootstrap'
import {useIntl} from 'react-intl'
import Select from 'react-select'
import {toast} from 'react-toastify'
import {TaskModel} from '..'
import {UserModel} from '../../user-profile/models/UserModel'

import {TaskGroupModel} from '../models/TaskGroupModel'
import {TaskResolutionModel} from '../models/TaskResolutionModel'
import {TaskSetResolutionModel} from '../models/TaskSetResolutionModel'

type PropsModal = {
  onHide: () => void
  onResolve: (
    taskResolution: TaskSetResolutionModel,
    replaceAgent: boolean,
    isAlarm: boolean
  ) => void
  resolutions: Array<TaskResolutionModel> | undefined
  resolutionCode: string
  show: boolean
  staffUsers: Array<UserModel>
  expertStaffUsers: Array<UserModel>
  groups: Array<TaskGroupModel>
  task: TaskModel
  disableReplaceAgent?: boolean
}

const resolutionTargetCodes = ['pending-customer', 'possible']

export const TaskResolutionModal: React.FC<PropsModal> = ({
  onHide,
  onResolve,
  resolutionCode,
  resolutions,
  show,
  staffUsers,
  expertStaffUsers,
  groups,
  task,
  disableReplaceAgent = false,
}) => {
  const intl = useIntl()
  const [textValue, setTextValue] = useState<string | undefined>(undefined)
  const [selectValue, setSelectValue] = useState<string | undefined>(undefined)
  const [selectedStaffUser, setSelectedStaffUser] = useState<string | undefined>(undefined)
  const [replaceAgent, setReplaceAgent] = useState<boolean>(false)
  const [resolutionTarget, setResolutionTarget] = useState<number | undefined>(-1)
  const [isAlarm, setIsAlarm] = useState<boolean>(true)

  const resolutionTargets =
    resolutions?.length > 0
      ? [
          ...resolutions
            ?.filter((aResolution) => resolutionTargetCodes.includes(aResolution.code))
            ?.map((aResolutionTarget) => ({
              value: aResolutionTarget.id,
              label: aResolutionTarget.name,
            })),
          {
            value: -1,
            label: intl.formatMessage({id: 'tasks.defaultResolutionTarget'}),
          },
        ]
      : []

  let options = []
  if (resolutionCode === 'reassign-owner') {
    options = expertStaffUsers?.map((user: UserModel) => ({value: user.id, label: user.email}))
  } else if (resolutionCode === 'reassign-group' || resolutionCode === 'reassign-owner-and-group') {
    options = groups?.map((group: TaskGroupModel) => ({value: group.id, label: group.name}))
  }

  useEffect(() => {
    setTextValue(undefined)
    setSelectValue(undefined)
    setSelectedStaffUser(undefined)
    setReplaceAgent(false)
    setResolutionTarget(-1)
  }, [show])

  useEffect(() => {
    if (resolutionCode === 'postpone' && !textValue) {
      const today = new Date()
      const tomorrow = new Date(today)
      tomorrow.setDate(tomorrow.getDate() + 1)
      tomorrow.setHours(10)
      tomorrow.setMinutes(0)
      tomorrow.setMilliseconds(0)
      tomorrow.setSeconds(0)
      setTextValue(tomorrow.toISOString().split('Z')[0]);
    } else {
      setTextValue(undefined)
    }
  }, [resolutionCode])

  const handleSetResolution = async (e) => {
    e.preventDefault()
    let checkPassed = false
    if (
      resolutionCode === 'reassign-owner' ||
      resolutionCode === 'reassign-group' ||
      resolutionCode === 'reassign-owner-and-group'
    ) {
      if (selectValue !== undefined) {
        checkPassed = true
      } else {
        toast.error(intl.formatMessage({id: 'tasks.fieldNewOwnerIsRequired'}))
      }
    } else if (resolutionCode === 'postpone') {
      if (textValue !== undefined && textValue.trim() !== '') {
        checkPassed = true
      } else {
        toast.error(intl.formatMessage({id: 'tasks.fieldNewDateIsRequired'}))
      }
    } else if (resolutionCode === 'incidence') {
      if (selectValue !== undefined) {
        checkPassed = true
      } else {
        toast.error(intl.formatMessage({id: 'tasks.selectAnIncidenceTypeIsRequired'}))
      }
      if (textValue !== undefined && textValue.trim() !== '') {
        checkPassed = true && checkPassed
      } else {
        checkPassed = false
        toast.error(intl.formatMessage({id: 'tasks.fieldAdditionalInfoRequired'}))
      }
    }

    if (!checkPassed) {
      return
    }

    let resolutionId = undefined

    if (resolutionCode === 'incidence') {
      resolutionCode = selectValue as string
    }

    resolutions?.every((resolution) => {
      if (resolution.code === resolutionCode) {
        resolutionId = resolution.id
      }
      return true
    })

    if (resolutionId !== undefined) {
      const taskSetResolution: TaskSetResolutionModel = {
        task_resolution: resolutionId,
      }

      let resolveWithAlarm = undefined

      if (resolutionCode === 'reassign-owner') {
        taskSetResolution['owner'] = parseInt(selectValue as string)
      } else if (resolutionCode === 'reassign-group') {
        taskSetResolution['group'] = parseInt(selectValue as string)
      } else if (resolutionCode === 'reassign-owner-and-group') {
        taskSetResolution['group'] = parseInt(selectValue as string)
        taskSetResolution['owner'] = parseInt(selectedStaffUser as string)
      } else if (resolutionCode === 'postpone') {
        taskSetResolution['dispatch_after'] = new Date(textValue).toISOString()
        resolveWithAlarm = isAlarm
      } else if (resolutionCode.indexOf('-incidence') > 0) {
        taskSetResolution['additional_info'] = textValue
      }
      if (resolutionTarget !== -1) {
        taskSetResolution['task_resolution_target'] = resolutionTarget
      }

      onResolve(taskSetResolution, replaceAgent, resolveWithAlarm)
    }
  }

  return (
    <Modal show={show} onHide={onHide} aria-labelledby='contained-modal-title-vcenter' centered>
      <form onSubmit={handleSetResolution}>
        <Modal.Header>
          {resolutionCode && <Modal.Title id='contained-modal-title-vcenter'>
            {intl.formatMessage({id: `tasks.${resolutionCode}`})}
          </Modal.Title>}
        </Modal.Header>
        <Modal.Body>
          <div className='container'>
            <div className='row'>
              {(resolutionCode === 'reassign-owner' ||
                resolutionCode === 'reassign-group' ||
                resolutionCode === 'reassign-owner-and-group') && (
                <div className='row mb-3'>
                  <label className='form-label'>
                    {resolutionCode === 'reassign-owner'
                      ? intl.formatMessage({id: `tasks.agent`})
                      : intl.formatMessage({id: 'tasks.group'})}
                  </label>
                  <Select 
                    className="react-select-container"
                    classNamePrefix="react-select" 
                    placeholder={intl.formatMessage({id: 'genericForm.selectPlaceholder'})}
                    onChange={(e: any) => setSelectValue(e.value)}
                    options={options}
                  />
                </div>
              )}
              {resolutionCode === 'reassign-owner-and-group' && (
                <div className='row mb-3'>
                  <label className='form-label'>{intl.formatMessage({id: `tasks.owner`})}</label>
                  <Select 
                    className="react-select-container"
                    classNamePrefix="react-select" 
                    placeholder={intl.formatMessage({id: 'genericForm.selectPlaceholder'})}
                    onChange={(e: any) => setSelectedStaffUser(e.value)}
                    options={staffUsers
                      ?.filter((user: UserModel) =>
                        user.cs_groups?.find(
                          (group: TaskGroupModel) => group.id === parseInt(selectValue as string)
                        )
                      )
                      ?.map((user: UserModel) => ({value: user.id, label: user.email}))}
                  />
                </div>
              )}
              {!disableReplaceAgent &&
                (resolutionCode === 'reassign-owner' ||
                  resolutionCode === 'reassign-owner-and-group') && (
                  <div className='col-12 d-flex align-items-center'>
                    <input
                      checked={replaceAgent}
                      onChange={() => setReplaceAgent((value) => !value)}
                      className='my-4 me-2 form-checkbox'
                      type='checkbox'
                      id='replace-agent'
                      name='reassign-owner-notify'
                    />
                    <label className='' htmlFor='replace-agent'>
                      {intl.formatMessage({id: 'tasks.replaceAgent'})}
                    </label>
                  </div>
                )}
              {resolutionCode === 'postpone' && (
                <div className='col'>
                  <label className='form-label'>
                    {intl.formatMessage({id: 'tasks.startDate'})}
                  </label>
                  <input
                    step='60'
                    className='form-control form-control-solid mb-4'
                    onChange={(e) => {
                      setTextValue(e.target.value)
                    }}
                    type='datetime-local'
                    value={textValue}
                  />
                  <label className='form-label mt-4'>
                    {intl.formatMessage({id: `tasks.markAs`})}
                  </label>
                  <Select 
                    className="react-select-container mb-4"
                    classNamePrefix="react-select"
                    placeholder={intl.formatMessage({id: 'genericForm.auto'})}
                    options={resolutionTargets}
                    onChange={(e: any) => setResolutionTarget(e.value)}
                    value={resolutionTargets.find((target) => target.value === resolutionTarget)}
                  />
                  <label className='form-label mt-4'>
                    {intl.formatMessage({id: `tasks.toggleAlarm`})}
                  </label>
                  <label className='form-check form-switch form-switch-sm form-check-custom form-check-solid d-flex align-items-center mb-3'>
                    <input
                      checked={isAlarm}
                      onChange={() => setIsAlarm((value) => !value)}
                      className='me-2 form-check-input'
                      type='checkbox'
                      id='toggle-alarm'
                      name='toggle-alarm'
                    />
                    <span className=''>
                      {isAlarm
                        ? intl.formatMessage({id: 'tasks.alarmOn'})
                        : intl.formatMessage({id: 'tasks.alarmOff'})}
                    </span>
                  </label>
                  {!isAlarm && (
                    <section className='alert alert-warning mb-0'>
                      <p className='fw-bolder mb-1'>
                        <span className='fa fa-exclamation-triangle me-2'></span>
                        {intl.formatMessage({id: 'tasks.alarmWarningTitle'})}
                      </p>
                      <p
                        className='mb-0'
                        dangerouslySetInnerHTML={{
                          __html: intl.formatMessage({id: 'tasks.alarmWarningText'}),
                        }}
                      />
                    </section>
                  )}
                </div>
              )}
              {resolutionCode === 'incidence' && (
                <div className='col'>
                  <Select 
                    className="react-select-container"
                    classNamePrefix="react-select" 
                    placeholder={intl.formatMessage({id: 'genericForm.selectPlaceholder'})}
                    onChange={(e: any) => setSelectValue(e.value)}
                    options={options}
                  />
                  <textarea
                    placeholder={intl.formatMessage({id: 'tasks.additionalInfo'})}
                    className='form-control mt-5'
                    maxLength={160}
                    onChange={(e) => setTextValue(e.target.value)}
                    rows={5}
                    value={textValue}
                    required
                  ></textarea>
                </div>
              )}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='tertiary' onClick={onHide}>
            {intl.formatMessage({id: 'tasks.cancel'})}
          </Button>
          {resolutionCode && <Button variant='primary' className='ms-10' type='submit'>
            {intl.formatMessage({id: `tasks.${resolutionCode}`})}
          </Button>}
        </Modal.Footer>
      </form>
    </Modal>
  )
}
