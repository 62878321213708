import {put, takeEvery, select, delay} from 'redux-saga/effects'

import {UserModel} from '../models/UserModel'
import {CouponModel} from '../models/CouponModel'
import {BankcardModel} from '../models/BankcardModel'

import {
  getUserById,
  updateUser,
  listCoupons,
  addCoupon,
  removeCoupon,
  removeBankcard,
  updateBankcard,
  addShippingAddress,
  updateShippingAddress,
  updateBillingAddress,
  removeShippingAddress,
  removeBillingAddress,
  addBillingAddress,
  unsubscribeFromNewsletter,
} from '../services/UserCRUD'
import {actions as petActions} from '../../pets/redux/PetRedux'
import {actions as planActions} from '../../plans/redux/PlanRedux'
import {actions as orderActions} from '../../orders/redux/OrderRedux'
import {RootState, ActionWithPayload} from '../../../../setup'
import {ShippingAddressModel} from '../models/ShippingAddressModel'
import {BillingAddressModel} from '../models/BillingAddressModel'

export const actionTypes = {
  UserRequested: 'USER_REDUX_USER_REQUESTED',
  SetUser: 'USER_REDUX_SET_USER',
  ResetUser: 'USER_REDUX_RESET_USER',
  UpdateUser: 'USER_REDUX_UPDATE_USER',
  UpdateAgents: 'USER_REDUX_UPDATE_AGENTS',
  SetStatus: 'USER_REDUX_SET_STATUS',
  SetError: 'USER_REDUX_SET_ERROR',
  SetCoupons: 'USER_REDUX_SET_COUPONS',
  RemoveCoupon: 'USER_REDUX_REMOVE_COUPON',
  AddCoupon: 'USER_REDUX_ADD_COUPON',
  RemoveBankcard: 'USER_REDUX_REMOVE_BANKCARD',
  MakeDefaultBankcard: 'USER_REDUX_MAKE_DEFAULT_BANKCARD',
  SetBankcards: 'USER_REDUX_SET_BANKCARDS',
  AddShippingAddress: 'USER_REDUX_ADD_SHIPPING_ADDRESS',
  AddBillingAddress: 'USER_REDUX_ADD_BILLING_ADDRESS',
  UpdateShippingAddress: 'USER_REDUX_UPDATE_SHIPPING_ADDRESS',
  UpdateBillingAddress: 'USER_REDUX_UPDATE_BILLING_ADDRESS',
  RemoveShippingAddress: 'USER_REDUX_REMOVE_SHIPPING_ADDRESS',
  RemoveBillingAddress: 'USER_REDUX_REMOVE_BILLING_ADDRESS',
  UnsubscribeFromNewsletter: 'USER_REDUX_UNSUBSCRIBE_FROM_NEWSLETTER',
}

type Status =
  | 'idle'
  | 'loading_request_user'
  | 'error_request_user'
  | 'success_request_user'
  | 'loading_update_user'
  | 'error_update_user'
  | 'success_update_user'
  | 'loading_update_agents'
  | 'error_update_agents'
  | 'success_update_agents'
  | 'loading_add_coupon'
  | 'error_add_coupon'
  | 'success_add_coupon'
  | 'loading_remove_coupon'
  | 'success_remove_coupon'
  | 'error_remove_coupon'
  | 'loading_remove_bankcard'
  | 'success_remove_bankcard'
  | 'error_remove_bankcard'
  | 'loading_make_default_bankcard'
  | 'success_make_default_bankcard'
  | 'error_make_default_bankcard'
  | 'loading_add_shipping_address'
  | 'success_add_shipping_address'
  | 'error_add_shipping_address'
  | 'loading_update_shipping_address'
  | 'success_update_shipping_address'
  | 'error_update_shipping_address'
  | 'loading_remove_shipping_address'
  | 'success_remove_shipping_address'
  | 'error_remove_shipping_address'
  | 'loading_add_billing_address'
  | 'success_add_billing_address'
  | 'error_add_billing_address'
  | 'loading_update_billing_address'
  | 'success_update_billing_address'
  | 'error_update_billing_address'
  | 'loading_remove_billing_address'
  | 'success_remove_billing_address'
  | 'error_remove_billing_address'
  | 'loading_unsubscribe_from_newsletter'
  | 'success_unsubscribe_from_newsletter'
  | 'error_unsubscribe_from_newsletter'

export interface IUserState {
  user: UserModel | undefined
  coupons: Array<CouponModel>
  status: Status
  error?: string
}

const initialState: IUserState = {
  user: undefined,
  coupons: [],
  status: 'idle',
  error: undefined,
}

export const currentUserSelector = (state: RootState) => state.user.user
export const userStatusSelector = (state: RootState) => state.user.status
export const userErrorSelector = (state: RootState) => state.user.error

export const reducer = (
  state: IUserState = initialState,
  action: ActionWithPayload<any>
): IUserState => {
  switch (action.type) {
    case actionTypes.SetUser: {
      const user = action.payload?.user
      return {
        ...state,
        user,
        status: 'idle',
        coupons: [],
      }
    }

    case actionTypes.SetCoupons: {
      const coupons = action.payload?.coupons as Array<CouponModel>

      return {
        ...state,
        coupons,
      }
    }

    case actionTypes.SetBankcards: {
      const bankcards = action.payload?.bankcards as Array<BankcardModel>

      return {
        ...state,
        user: {
          ...state.user,
          bankcards,
        } as UserModel,
      }
    }

    case actionTypes.SetStatus: {
      const status = action.payload?.status || 'idle'
      return {...state, status}
    }

    case actionTypes.SetError: {
      const error = action.payload?.error || ''
      return {...state, error}
    }

    case actionTypes.ResetUser: {
      return initialState
    }

    default:
      return state
  }
}

export const actions = {
  setUser: (user: UserModel) => ({type: actionTypes.SetUser, payload: {user}}),
  requestUser: (id: number) => ({type: actionTypes.UserRequested, payload: {id}}),
  updateUser: ({id, props}: any) => ({type: actionTypes.UpdateUser, payload: {id, props}}),
  updateAgents: ({id, props}: any) => ({type: actionTypes.UpdateAgents, payload: {id, props}}),
  resetUser: () => ({type: actionTypes.ResetUser}),
  setStatus: (status: Status) => ({type: actionTypes.SetStatus, payload: {status}}),
  setError: (error: string) => ({type: actionTypes.SetError, payload: {error}}),
  setCoupons: (coupons: Array<CouponModel>) => ({type: actionTypes.SetCoupons, payload: {coupons}}),
  removeCoupon: (coupon: CouponModel) => ({type: actionTypes.RemoveCoupon, payload: {coupon}}),
  addCoupon: (code: string) => ({type: actionTypes.AddCoupon, payload: {code}}),
  removeBankcard: (bankcard: BankcardModel) => ({
    type: actionTypes.RemoveBankcard,
    payload: {bankcard},
  }),
  makeDefaultBankcard: (bankcard: BankcardModel) => ({
    type: actionTypes.MakeDefaultBankcard,
    payload: {bankcard},
  }),
  setBankcards: (bankcards: Array<BankcardModel>) => ({
    type: actionTypes.SetBankcards,
    payload: {bankcards},
  }),
  addShippingAddress: (address: ShippingAddressModel) => ({
    type: actionTypes.AddShippingAddress,
    payload: {address},
  }),
  addBillingAddress: (address: BillingAddressModel) => ({
    type: actionTypes.AddBillingAddress,
    payload: {address},
  }),
  updateShippingAddress: (address: ShippingAddressModel) => ({
    type: actionTypes.UpdateShippingAddress,
    payload: {address},
  }),
  updateBillingAddress: (address: BillingAddressModel) => ({
    type: actionTypes.UpdateBillingAddress,
    payload: {address},
  }),
  removeShippingAddress: (addressId: number) => ({
    type: actionTypes.RemoveShippingAddress,
    payload: {addressId},
  }),
  removeBillingAddress: (addressId: number) => ({
    type: actionTypes.RemoveBillingAddress,
    payload: {addressId},
  }),
  unsubscribeFromNewsletter: (email: string) => ({
    type: actionTypes.UnsubscribeFromNewsletter,
    payload: {email},
  }),
  store: () => ({type: 'def'}),
}

export function* saga() {
  yield takeEvery(
    actionTypes.UnsubscribeFromNewsletter,
    function* unsubscribeFromNewsletterEffect(action) {
      const currentUser = yield select(currentUserSelector)
      const email = currentUser?.email
      if (email) {
        try {
          yield put(actions.setStatus('loading_unsubscribe_from_newsletter'))
          yield unsubscribeFromNewsletter(email)
          yield put(actions.requestUser(currentUser.id))
          yield put(actions.setStatus('success_unsubscribe_from_newsletter'))
        } catch (error) {
          yield put(actions.setStatus('error_unsubscribe_from_newsletter'))
          yield put(actions.setError(error.message))
        }
      }
    }
  )

  yield takeEvery(
    actionTypes.UserRequested,
    function* userRequestedEffect(action: ActionWithPayload<{id: number}>) {
      const id = action?.payload?.id
      if (id) {
        try {
          yield put(actions.setStatus('loading_request_user'))
          const {data: user} = yield getUserById(id)
          yield put(
            actions.setUser({
              // @ts-ignore
              id: action.payload.id,
              ...user,
            })
          )
          yield put(actions.setStatus('success_request_user'))
          const {data: coupons} = yield listCoupons(id)
          yield put(actions.setCoupons(coupons))
          yield put(petActions.listPets(id))
          yield put(planActions.listPlans(id))
          yield put(orderActions.listOrders(id, 1))
        } catch (e) {
          console.warn(e)
          yield put(actions.setStatus('error_request_user'))
        } finally {
          yield delay(0)
          yield put(actions.setStatus('idle'))
        }
      }
    }
  )

  yield takeEvery(actionTypes.UpdateUser, function* updateUserEffect(action) {
    try {
      yield put(actions.setStatus('loading_update_user'))
      // @ts-ignore
      const {data: user} = yield updateUser({id: action.payload.id, ...action.payload.props})
      yield put(
        actions.setUser({
          // @ts-ignore
          id: action.payload.id,
          ...user,
        })
      )
      yield put(actions.setStatus('success_update_user'))
    } catch (error: any) {
      console.warn(error)
      if (error?.length > 0) {
        yield put(actions.setError(error[0]))
      }
      yield put(actions.setStatus('error_update_user'))
    } finally {
      yield delay(0)
      yield put(actions.setStatus('idle'))
    }
  })

  yield takeEvery(actionTypes.UpdateAgents, function* UpdateAgentsEffect(action) {
    try {
      yield put(actions.setStatus('loading_update_agents'))
      // @ts-ignore
      const {data: user} = yield updateUser({id: action.payload.id, ...action.payload.props})
      yield put(
        actions.setUser({
          // @ts-ignore
          id: action.payload.id,
          ...user,
        })
      )
      yield put(actions.setStatus('success_update_agents'))
    } catch (error: any) {
      console.warn(error)
      if (error?.length > 0) {
        yield put(actions.setError(error[0]))
      }
      yield put(actions.setStatus('error_update_agents'))
    } finally {
      yield delay(0)
      yield put(actions.setStatus('idle'))
    }
  })

  const getUserId = (state: RootState): number | undefined => state.user.user?.id

  yield takeEvery(
    actionTypes.AddCoupon,
    function* addCouponEffect(action: ActionWithPayload<{code: string}>) {
      try {
        const userId: number | undefined = yield select(getUserId)
        const code = action.payload?.code
        yield put(actions.setStatus('loading_add_coupon'))
        if (userId && code) {
          yield addCoupon(code, userId)
          const {data: coupons} = yield listCoupons(userId)
          yield put(actions.setCoupons(coupons))
        }
        yield put(actions.setStatus('success_add_coupon'))
      } catch (error: any) {
        console.warn(error)
        yield put(actions.setStatus('error_add_coupon'))
      } finally {
        yield delay(0)
        yield put(actions.setStatus('idle'))
      }
    }
  )

  const getCoupons = (state: RootState): Array<CouponModel> => state.user.coupons

  yield takeEvery(
    actionTypes.RemoveCoupon,
    function* removeCouponEffect(action: ActionWithPayload<{coupon: CouponModel}>) {
      try {
        const userId: number | undefined = yield select(getUserId)
        const code = action.payload?.coupon.code
        const coupons: Array<CouponModel> = yield select(getCoupons)
        yield put(actions.setStatus('loading_remove_coupon'))
        if (userId && code) {
          yield removeCoupon(code, userId)
          yield put(
            actions.setCoupons(
              coupons?.filter((coupon) => coupon.code !== code) as Array<CouponModel>
            )
          )
          yield put(actions.setStatus('success_remove_coupon'))
        }
      } catch (error: any) {
        console.warn(error)
        yield put(actions.setStatus('error_remove_coupon'))
      } finally {
        yield delay(0)
        yield put(actions.setStatus('idle'))
      }
    }
  )

  const getBankcards = (state: RootState): Array<BankcardModel> => state.user.user?.bankcards || []

  yield takeEvery(
    actionTypes.RemoveBankcard,
    function* removeBankcardEffect(action: ActionWithPayload<{bankcard: BankcardModel}>) {
      try {
        const userId: number | undefined = yield select(getUserId)
        const bankcard = action.payload?.bankcard
        const bankcards: Array<BankcardModel> = yield select(getBankcards)

        yield put(actions.setStatus('loading_remove_bankcard'))
        if (userId && bankcard) {
          yield removeBankcard(bankcard.id)
          yield put(
            actions.setBankcards(
              bankcards?.filter((b) => b.id !== bankcard.id) as Array<BankcardModel>
            )
          )
          yield put(actions.setStatus('success_remove_bankcard'))
        }
      } catch (error: any) {
        console.warn(error)
        yield put(actions.setStatus('error_remove_bankcard'))
      } finally {
        yield delay(0)
        yield put(actions.setStatus('idle'))
      }
    }
  )

  yield takeEvery(
    actionTypes.MakeDefaultBankcard,
    function* makeDefaultBankcardEffect(action: ActionWithPayload<{bankcard: BankcardModel}>) {
      try {
        const bankcard = action.payload?.bankcard
        const bankcards: Array<BankcardModel> = yield select(getBankcards)

        yield put(actions.setStatus('loading_make_default_bankcard'))
        if (bankcard) {
          yield updateBankcard(bankcard.id, {is_default: true})
          yield put(
            actions.setBankcards(
              bankcards?.map((b) => {
                if (b.id === bankcard.id) {
                  return {...b, is_default: true}
                }
                return {...b, is_default: false}
              }) as Array<BankcardModel>
            )
          )
          yield put(actions.setStatus('success_make_default_bankcard'))
        }
      } catch (error: any) {
        console.warn(error)
        yield put(actions.setStatus('error_make_default_bankcard'))
      } finally {
        yield delay(0)
        yield put(actions.setStatus('idle'))
      }
    }
  )

  yield takeEvery(
    actionTypes.AddShippingAddress,
    function* AddShippingAddressEffect(action: ActionWithPayload<{address: ShippingAddressModel}>) {
      try {
        const address = action.payload?.address
        const userId: number | undefined = yield select(getUserId)

        yield put(actions.setStatus('loading_add_shipping_address'))
        if (address && userId) {
          yield addShippingAddress(userId, address)
          yield put(actions.requestUser(userId))
          yield put(actions.setStatus('success_add_shipping_address'))
        }
      } catch (error: any) {
        console.warn(error)
        yield put(actions.setStatus('error_add_shipping_address'))
      } finally {
        yield delay(0)
        yield put(actions.setStatus('idle'))
      }
    }
  )

  yield takeEvery(
    actionTypes.AddBillingAddress,
    function* AddBillingAddressEffect(action: ActionWithPayload<{address: BillingAddressModel}>) {
      try {
        const address = action.payload?.address
        const userId: number | undefined = yield select(getUserId)

        yield put(actions.setStatus('loading_add_billing_address'))
        if (address && userId) {
          yield addBillingAddress(userId, address)
          yield put(actions.requestUser(userId))
          yield put(actions.setStatus('success_add_billing_address'))
        }
      } catch (error: any) {
        console.warn(error)
        yield put(actions.setStatus('error_add_billing_address'))
      } finally {
        yield delay(0)
        yield put(actions.setStatus('idle'))
      }
    }
  )

  yield takeEvery(
    actionTypes.UpdateShippingAddress,
    function* UpdateShippingAddressEffect(
      action: ActionWithPayload<{address: ShippingAddressModel}>
    ) {
      try {
        const address = action.payload?.address
        const userId: number | undefined = yield select(getUserId)

        yield put(actions.setStatus('loading_update_shipping_address'))
        if (address && userId) {
          yield updateShippingAddress(address)
          yield put(actions.requestUser(userId))
          yield put(actions.setStatus('success_update_shipping_address'))
        }
      } catch (error: any) {
        console.warn(error)
        yield put(actions.setStatus('error_update_shipping_address'))
      } finally {
        yield delay(0)
        yield put(actions.setStatus('idle'))
      }
    }
  )

  yield takeEvery(
    actionTypes.UpdateBillingAddress,
    function* UpdateBillingAddressEffect(
      action: ActionWithPayload<{address: BillingAddressModel}>
    ) {
      try {
        const address = action.payload?.address
        const userId: number | undefined = yield select(getUserId)

        yield put(actions.setStatus('loading_update_billing_address'))
        if (address && userId) {
          yield updateBillingAddress(address)
          yield put(actions.requestUser(userId))
          yield put(actions.setStatus('success_update_billing_address'))
        }
      } catch (error: any) {
        console.warn(error)
        yield put(actions.setStatus('error_update_billing_address'))
      } finally {
        yield delay(0)
        yield put(actions.setStatus('idle'))
      }
    }
  )

  yield takeEvery(
    actionTypes.RemoveShippingAddress,
    function* RemoveShippingAddressEffect(action: ActionWithPayload<{addressId: number}>) {
      try {
        const addressId = action.payload?.addressId
        const userId: number | undefined = yield select(getUserId)

        yield put(actions.setStatus('loading_remove_shipping_address'))
        if (addressId && userId) {
          yield removeShippingAddress(addressId)
          yield put(actions.requestUser(userId))
          yield put(actions.setStatus('success_remove_shipping_address'))
        }
      } catch (error: any) {
        console.warn(error)
        yield put(actions.setStatus('error_remove_shipping_address'))
      } finally {
        yield delay(0)
        yield put(actions.setStatus('idle'))
      }
    }
  )

  yield takeEvery(
    actionTypes.RemoveBillingAddress,
    function* RemoveBillingAddressEffect(action: ActionWithPayload<{addressId: number}>) {
      try {
        const addressId = action.payload?.addressId
        const userId: number | undefined = yield select(getUserId)

        yield put(actions.setStatus('loading_remove_billing_address'))
        if (addressId && userId) {
          yield removeBillingAddress(addressId)
          yield put(actions.requestUser(userId))
          yield put(actions.setStatus('success_remove_billing_address'))
        }
      } catch (error: any) {
        console.warn(error)
        yield put(actions.setStatus('error_remove_billing_address'))
      } finally {
        yield delay(0)
        yield put(actions.setStatus('idle'))
      }
    }
  )
}
