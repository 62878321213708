import {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {useDispatch, useSelector} from 'react-redux'
import {toast} from 'react-toastify'
import {useHistory} from 'react-router-dom'
import * as planRedux from '../redux/PlanRedux'
import {currentUserSelector} from '../../user-profile/redux/UserRedux'
import PlanKoModal from './PlanKoModal'

const PLAN_ACTIONS = [
  'pause',
  'resume',
  'cancel',
  'confirm',
  'discard',
  'recalculate',
  'reactivate',
  'detain',
  'repropose',
  'refresh',
  'generate',
  'apply',
]
const REGENERABLE_STATES = [
  'active',
  'proposal',
  'payment-failed',
  'paused',
  'reactivating',
  'detained',
]
const PAUSABLE_STATES = [
  'active',
  'payment-failed',
  'not-payment-method',
  'proposal',
  'reactivating',
  'detained',
]
const REACTIVABLE_STATES = ['payment-failed', 'cancelled', 'no-payment-method', 'reactivating']

export default function PlanActions() {
  const intl = useIntl()
  const history = useHistory()
  const dispatch = useDispatch()
  const status = useSelector(planRedux.planStatusSelector)
  const selectedPlan = useSelector(planRedux.selectedPlanSelector)
  const currentUser = useSelector(currentUserSelector)
  const pauseLoading = status === 'loading_pause_plan'
  const resumeLoading = status === 'loading_resume_plan'
  const confirmLoading = status === 'loading_confirm_plan'
  const recalculateLoading = status === 'loading_recalculate_plan'
  const reactivateLoading = status === 'loading_reactivate_plan'
  const reproposeLoading = status === 'loading_repropose_plan'
  const refreshPricesLoading = status === 'loading_refresh_plan'
  const generateOrdersLoading = status === 'loading_generate_orders'
  const isLoading = status.includes('loading_')
  const [visibleKoModal, setVisibleKoModal] = useState(false)
  const [koAction, setKoAction] = useState('')

  function onPause() {
    setKoAction('pause')
    setVisibleKoModal(true)
  }

  useEffect(() => {
    const action = status?.split('_')[1]

    if (status.includes('error_') && PLAN_ACTIONS.includes(action)) {
      toast.error(intl.formatMessage({id: `planDetail.${action}Error`}))
    }
    if (status.includes('success_') && PLAN_ACTIONS.includes(action)) {
      toast.success(intl.formatMessage({id: `planDetail.${action}Success`}))
    }
    if (status === 'success_cancel_plan') {
      if (currentUser) {
        history.push(`/user/${currentUser.id}/plans`)
      } else {
        history.push('/dashboard')
      }
    }
  }, [status])

  function onKoSubmit(koReason) {
    if (koAction === 'pause') {
      dispatch(planRedux.actions.pausePlan(selectedPlan.id, koReason))
    }
    setVisibleKoModal(false)
  }

  return (
    <>
      <section className='card p-10'>
        <h1 className='mb-8'>{intl.formatMessage({id: 'planDetail.actionsTitle'})}</h1>
        <div className='d-flex flex-column gap-4'>
          {selectedPlan?.state !== 'proposal' && (
            <button
              className='btn btn-outline-warning border border-warning d-inline-block w-auto'
              onClick={() => dispatch(planRedux.actions.reproposePlan(selectedPlan.id))}
              disabled={isLoading}
            >
              {reproposeLoading ? (
                <span className='indicator-progress d-block'>
                  <span className='spinner-border spinner-border-sm align-middle me-4'></span>
                  {intl.formatMessage({id: 'planDetail.pleaseWait'})}
                </span>
              ) : (
                <span className='indicator-label'>
                  <span className='fas fa-fast-backward me-3' />
                  {intl.formatMessage({id: 'planDetail.reproposePlan'})}
                </span>
              )}
            </button>
          )}
          {PAUSABLE_STATES.includes(selectedPlan?.state) && (
            <button
              className='btn btn-outline-warning border border-warning'
              onClick={onPause}
              disabled={isLoading}
            >
              {pauseLoading ? (
                <span className='indicator-progress d-block'>
                  <span className='spinner-border spinner-border-sm align-middle me-4'></span>
                  {intl.formatMessage({id: 'planDetail.pleaseWait'})}
                </span>
              ) : (
                <span className='indicator-label'>
                  <span className='fas fa-pause  me-4' />
                  {intl.formatMessage({id: 'planDetail.pausePlan'})}
                </span>
              )}
            </button>
          )}
          {REACTIVABLE_STATES.includes(selectedPlan?.state) && (
            <button
              className='btn btn-outline-primary border border-primary'
              onClick={() => dispatch(planRedux.actions.reactivatePlan(selectedPlan.id))}
              disabled={isLoading}
            >
              {reactivateLoading ? (
                <span className='indicator-progress d-block'>
                  <span className='spinner-border spinner-border-sm align-middle me-4'></span>
                  {intl.formatMessage({id: 'planDetail.pleaseWait'})}
                </span>
              ) : (
                <span className='indicator-label'>
                  <span className='fas fa-undo me-4' />
                  {intl.formatMessage({id: 'planDetail.reactivatePlan'})}
                </span>
              )}
            </button>
          )}
          {REGENERABLE_STATES.includes(selectedPlan?.state) && (
            <button
              className='btn btn-outline-warning border border-warning'
              onClick={() => dispatch(planRedux.actions.generateOrders(selectedPlan.id))}
              disabled={isLoading}
            >
              {generateOrdersLoading ? (
                <span className='indicator-progress d-block'>
                  <span className='spinner-border spinner-border-sm align-middle me-4'></span>
                  {intl.formatMessage({id: 'planDetail.pleaseWait'})}
                </span>
              ) : (
                <span className='indicator-label'>
                  <span className='fas fa-box me-4' />
                  {intl.formatMessage({id: 'planDetail.generateOrders'})}
                </span>
              )}
            </button>
          )}
          {selectedPlan?.checkout_url?.length > 1 && (
            <a
              style={{pointerEvents: isLoading ? 'none' : 'initial'}}
              className={`btn btn-secondary btn-border ${isLoading ? 'disabled' : ''}`}
              href={selectedPlan?.checkout_url}
              target='_blank'
            >
              <span className='fas fa-shopping-cart me-4' />
              {intl.formatMessage({id: 'planDetail.goToCheckout'})}
            </a>
          )}
          {selectedPlan?.trial_instructions_url?.length > 1 && (
            <a
              style={{pointerEvents: isLoading ? 'none' : 'initial'}}
              className={`btn btn-secondary btn-border ${isLoading ? 'disabled' : ''}`}
              href={selectedPlan?.trial_instructions_url}
              target='_blank'
            >
              <span className='fas fa-table me-4' />
              {intl.formatMessage({id: 'planDetail.trialInstructions'})}
            </a>
          )}
          {selectedPlan?.regular_instructions_url?.length > 1 && (
            <a
              style={{pointerEvents: isLoading ? 'none' : 'initial'}}
              className={`btn btn-secondary btn-border ${isLoading ? 'disabled' : ''}`}
              href={selectedPlan?.regular_instructions_url}
              target='_blank'
            >
              <span className='fas fa-file me-4' />
              {intl.formatMessage({id: 'planDetail.regularInstructions'})}
            </a>
          )}
          {currentUser?.id && selectedPlan?.id && (
            <a
              href={`/user/${currentUser?.id}/plan/${selectedPlan?.id}/logs`}
              className='btn btn-secondary'
            >
              <span className='fas fa-history me-3' />
              {intl.formatMessage({id: 'logs.seeLogs'})}
            </a>
          )}
          {
            <button
              className='btn btn-secondary'
              onClick={() => dispatch(planRedux.actions.recalculatePlan(selectedPlan.id))}
              disabled={isLoading}
            >
              {recalculateLoading ? (
                <span className='indicator-progress d-block'>
                  <span className='spinner-border spinner-border-sm align-middle me-4'></span>
                  {intl.formatMessage({id: 'planDetail.pleaseWait'})}
                </span>
              ) : (
                <span className='indicator-label'>
                  <span className='fas fa-sync-alt me-3' />
                  {intl.formatMessage({id: 'planDetail.recalculatePlan'})}
                </span>
              )}
            </button>
          }
          {selectedPlan?.state === 'active' && (
            <button
              className='btn btn-secondary'
              onClick={() => dispatch(planRedux.actions.refreshPrices(selectedPlan.id))}
              disabled={isLoading}
            >
              {refreshPricesLoading ? (
                <span className='indicator-progress d-block'>
                  <span className='spinner-border spinner-border-sm align-middle me-4'></span>
                  {intl.formatMessage({id: 'planDetail.pleaseWait'})}
                </span>
              ) : (
                <span className='indicator-label'>
                  <span className='fas fa-money-bill me-3' />
                  {intl.formatMessage({id: 'planDetail.refreshPrices'})}
                </span>
              )}
            </button>
          )}
          {selectedPlan?.state === 'proposal' && (
            <button
              className='btn btn-primary'
              onClick={() => dispatch(planRedux.actions.confirmPlan(selectedPlan.id))}
              disabled={isLoading}
            >
              {confirmLoading ? (
                <span className='indicator-progress d-block'>
                  <span className='spinner-border spinner-border-sm align-middle me-4'></span>
                  {intl.formatMessage({id: 'planDetail.pleaseWait'})}
                </span>
              ) : (
                <span className='indicator-label'>
                  <span className='fas fa-check me-3' />
                  {intl.formatMessage({id: 'planDetail.confirmPlan'})}
                </span>
              )}
            </button>
          )}
          {selectedPlan?.state === 'paused' && (
            <button
              className='btn btn-primary'
              onClick={() => dispatch(planRedux.actions.resumePlan(selectedPlan.id))}
              disabled={isLoading}
            >
              {resumeLoading ? (
                <span className='indicator-progress d-block'>
                  <span className='spinner-border spinner-border-sm align-middle me-4'></span>
                  {intl.formatMessage({id: 'planDetail.pleaseWait'})}
                </span>
              ) : (
                <span className='indicator-label'>
                  <span className='fas fa-play me-4' />
                  {intl.formatMessage({id: 'planDetail.resumePlan'})}
                </span>
              )}
            </button>
          )}
        </div>
      </section>
      <PlanKoModal
        visible={visibleKoModal}
        loading={isLoading}
        onClose={() => setVisibleKoModal(false)}
        onSubmit={onKoSubmit}
        plan={selectedPlan}
      />
    </>
  )
}
