/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'

import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import {usePathProps} from '../../modules/user-profile/hooks/usePathProps'
import {PetList} from '../../modules/pets'

const PetsComponent: FC = () => {
  return (
    <>
      <div className='row'>
        <div className='col-12'>
          <PetList />
        </div>
      </div>
    </>
  )
}

const PetsPage: FC = () => {
  const intl = useIntl()
  usePathProps()

  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'menu.pets'})}</PageTitle>
      <PetsComponent />
    </>
  )
}

export {PetsPage}
