import {all} from 'redux-saga/effects'
import {combineReducers} from 'redux'
import {Action} from '@reduxjs/toolkit'

import * as auth from '../../app/modules/auth'
import * as user from '../../app/modules/user-profile'
import * as pet from '../../app/modules/pets'
import * as plan from '../../app/modules/plans'
import * as order from '../../app/modules/orders'
import * as task from '../../app/modules/tasks'

export const rootReducer = combineReducers({
  auth: auth.reducer,
  user: user.reducer,
  pet: pet.reducer,
  task: task.reducer,
  plan: plan.reducer,
  order: order.reducer,
})

export type RootState = ReturnType<typeof rootReducer>

export function* rootSaga() {
  yield all([pet.saga(), auth.saga(), user.saga(), task.saga(), plan.saga(), order.saga()])
}

export interface ActionWithPayload<T> extends Action {
  payload?: T
}
