import axios from 'axios'
import {LogModel} from '../models/LogModel'

const API_URL = process.env.REACT_APP_API_URL

export function getUserLogs(id: number) {
  return axios.get<Array<LogModel>>(`${API_URL}/api/users/${id}/actions/`)
}

export function getPetLogs(id: number) {
  return axios.get<Array<LogModel>>(`${API_URL}/api/pets/${id}/actions/`)
}

export function getPlanLogs(id: number) {
  return axios.get<Array<LogModel>>(`${API_URL}/api/plans/${id}/actions/`)
}

export function getOrderLogs(id: number) {
  return axios.get<Array<LogModel>>(`${API_URL}/api/orders/${id}/actions/`)
}
