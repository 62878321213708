/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react'
import { useIntl } from 'react-intl'
import { PageTitle } from '../../../_metronic/layout/core'
import { InvoiceList } from '../../modules/invoices'

export const UserInvoicesPage: FC = () => {
  const intl = useIntl()

  return (
    <>
      <PageTitle breadcrumbs={[]}>
        {intl.formatMessage({ id: 'menu.invoices' })}
      </PageTitle>
      <div className='row'>
        <div className='col-12'>
          <InvoiceList/>
        </div>
      </div>
    </>
  )
}