import axios from 'axios'
import {ShopModel} from '../models/ShopModel'

const API_URL = process.env.REACT_APP_API_URL
const SHOPS_URL = `${API_URL}/api/shops/`

export function listShops(): Promise<{data: Array<ShopModel>}> {
  return axios.get(SHOPS_URL)
}

export function retrieveShop(id: number): Promise<{data: ShopModel}> {
  return axios.get(`${SHOPS_URL}${id}/`)
}

export function createShop(shop: ShopModel): Promise<{data: ShopModel}> {
  return axios.post(SHOPS_URL, shop)
}

export function updateShop(shop: ShopModel): Promise<{data: ShopModel}> {
  return axios.put(`${SHOPS_URL}${shop.id}/`, shop)
}

export function deleteShop(id: number): Promise<{data: ShopModel}> {
  return axios.delete(`${SHOPS_URL}${id}/`)
}
