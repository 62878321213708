import {useIntl} from 'react-intl'

export function OrdersChart() {
  const intl = useIntl()

  return (
    <>
      <h1 className='mb-8'>{intl.formatMessage({id: 'dashboard.lastDays'})}</h1>
      <div
        style={{
          width: '100%',
          position: 'relative',
          overflow: 'hidden',
          paddingTop: '67.28%',
        }}
      >
        <iframe
          scrolling='no'
          className='col-12 overflow-hidden'
          src='https://datastudio.google.com/embed/reporting/f35e5585-1712-40f0-ab02-e65f01bdb7ef/page/hiFnC'
          frameBorder={0}
          width='600'
          style={{
            position: 'absolute',
            border: 0,
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            width: '100%',
            height: '100%',
          }}
        ></iframe>
      </div>
    </>
  )
}
