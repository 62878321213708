import axios from 'axios'
import { WhatsappLog } from '../models/WhatsappLog'

const API_URL = process.env.REACT_APP_API_URL
export const ITEMS_PER_PAGE = 20;

interface PaginatedWhatsappLogs {
    count: number
    next: string
    previous: string
    results: Array<WhatsappLog>
}
export function getWhatsappLogs(id: number, page: number): Promise<{data: PaginatedWhatsappLogs}> {
    const USERS_URL = `${API_URL}/api/crm/whatsapp-logs/`

    const params = {
        user: id,
        offset: (page - 1) * ITEMS_PER_PAGE,
        count: ITEMS_PER_PAGE
    }
    
    return axios.get(USERS_URL, { params})
}
