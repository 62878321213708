import {useEffect} from 'react'
import {useIntl} from 'react-intl'
import {useDispatch, useSelector} from 'react-redux'
import {Link} from 'react-router-dom'
import xlsx, {IJsonSheet} from 'json-as-xlsx'

import {actions} from '../redux/TaskRedux'
import {
  pendingsPerAgentSelector,
  pendingsPerAgentStatusSelector,
  staffUsersSelector,
} from '../redux/TaskSelectors'
import groupSupervisors from '../constants/groupSupervisors'
import superUsers from '../constants/superUsers'
import {authUserSelector} from '../../auth'
import moment from 'moment'

const TasksPerAgent = () => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const authUser = useSelector(authUserSelector)
  const tasks = useSelector(pendingsPerAgentSelector)
  const staffUsers = useSelector(staffUsersSelector)
  const status = useSelector(pendingsPerAgentStatusSelector)
  const total = tasks.reduce((acc, task) => acc + (task?.total?.n || 0), 0)
  const totalToday = tasks.reduce((acc, task) => acc + (task?.today?.n || 0), 0)
  const totalNow = tasks.reduce((acc, task) => acc + (task?.now?.n || 0), 0)
  const totalDoneToday = tasks.reduce((acc, task) => acc + (task?.done_today?.n || 0), 0)

  useEffect(() => {
    if (groupSupervisors.includes(authUser?.email) || superUsers.includes(authUser?.email)) {
      dispatch(actions.retrievePendingsPerAgent())
    } else {
      const ownerId = authUser?.id
      dispatch(actions.retrievePendingsPerAgent(ownerId))
    }
  }, [])

  function getRelevantTasks() {
    if (groupSupervisors.includes(authUser?.email)) {
      const groupId = authUser?.cs_groups[0]?.id
      const groupUsers = staffUsers.filter(
        (staffUser) => !!staffUser.cs_groups.find((aCsGroup) => aCsGroup.id === groupId)
      )
      const groupUserIds = groupUsers.map((aUser) => aUser.id)

      return tasks?.filter((task) => !task.id || groupUserIds.includes(task.id))
    }
    return tasks
  }

  function getAgentName(agent) {
    if (!agent?.id) {
      return intl.formatMessage({id: 'tasks.unassigned'})
    }

    const firstName = agent?.name.split(' ')[0]
    const lastName = agent?.name.split(' ')[1]
    return lastName?.length ? `${firstName} ${lastName?.charAt(0)}.` : firstName
  }

  function getTotalsSheet() {
    return {
      sheet: 'Totales',
      columns: [
        {
          label: intl.formatMessage({id: 'tasks.agent'}),
          value: (row: any) => row?.name || '-',
        },
        {
          label: intl.formatMessage({id: 'tasks.totalPending'}),
          value: (row: any) => row?.total?.n || 0,
        },
        {
          label: intl.formatMessage({id: 'tasks.pendingForToday'}),
          value: (row: any) => row?.today?.n || 0,
        },
        {
          label: intl.formatMessage({id: 'tasks.pendingRightNow'}),
          value: (row: any) => row?.now?.n || 0,
        },
        {
          label: intl.formatMessage({id: 'tasks.doneToday'}),
          value: (row: any) => row?.done_today?.n || 0,
        },
      ],
      content: getRelevantTasks(),
    }
  }

  function getAgentByTaskProfileSheet(agent) {
    const uniqueTaskProfiles = []
    const rows = []
    ;[
      ...(agent?.total?.task_profiles || []),
      ...(agent?.today?.task_profiles || []),
      ...(agent?.now?.task_profiles || []),
      ...(agent?.done_today?.task_profiles || []),
    ].forEach((taskProfile) => {
      if (!uniqueTaskProfiles.find((aTaskProfile) => aTaskProfile === taskProfile.name)) {
        uniqueTaskProfiles.push(taskProfile.name)
      }
    })

    uniqueTaskProfiles.forEach((taskProfileName) => {
      const row = {
        name: taskProfileName,
        total:
          agent?.total?.task_profiles?.find((aTaskProfile) => aTaskProfile.name === taskProfileName)
            ?.n || 0,
        today:
          agent?.today?.task_profiles?.find((aTaskProfile) => aTaskProfile.name === taskProfileName)
            ?.n || 0,
        now:
          agent?.now?.task_profiles?.find((aTaskProfile) => aTaskProfile.name === taskProfileName)
            ?.n || 0,
        done_today:
          agent?.done_today?.task_profiles?.find(
            (aTaskProfile) => aTaskProfile.name === taskProfileName
          )?.n || 0,
      }
      rows.push(row)
    })

    return {
      sheet: getAgentName(agent) + ' por Tipo',
      columns: [
        {
          label: intl.formatMessage({id: 'tasks.taskProfile'}),
          value: (row: any) => row?.name || '-',
        },
        {
          label: intl.formatMessage({id: 'tasks.totalPending'}),
          value: (row: any) => row?.total || 0,
        },
        {
          label: intl.formatMessage({id: 'tasks.pendingForToday'}),
          value: (row: any) => row?.today || 0,
        },
        {
          label: intl.formatMessage({id: 'tasks.pendingRightNow'}),
          value: (row: any) => row?.now | 0,
        },
        {
          label: intl.formatMessage({id: 'tasks.doneToday'}),
          value: (row: any) => row?.done_today || 0,
        },
      ],
      content: rows,
    }
  }

  function getAgentByTaskResolutionSheet(agent) {
    const uniqueTaskResolutions = []
    const rows = []
    ;[...(agent?.done_today?.task_resolutions || [])].forEach((taskResolution) => {
      if (
        !uniqueTaskResolutions.find((aTaskResolution) => aTaskResolution === taskResolution.name)
      ) {
        uniqueTaskResolutions.push(taskResolution.name)
      }
    })

    uniqueTaskResolutions.forEach((taskResolutionName) => {
      const row = {
        name: taskResolutionName,
        done_today:
          agent?.done_today?.task_resolutions?.find(
            (aTaskResolution) => aTaskResolution.name === taskResolutionName
          )?.n || 0,
      }
      rows.push(row)
    })

    return {
      sheet: getAgentName(agent) + ' Resueltas',
      columns: [
        {
          label: intl.formatMessage({id: 'tasks.taskResolution'}),
          value: (row: any) => row?.name || '-',
        },
        {
          label: intl.formatMessage({id: 'tasks.doneToday'}),
          value: (row: any) => row?.done_today || 0,
        },
      ],
      content: rows,
    }
  }

  function exportToCsv() {
    const data = [getTotalsSheet()]

    getRelevantTasks().forEach((agent) => {
      data.push(getAgentByTaskProfileSheet(agent))
      data.push(getAgentByTaskResolutionSheet(agent))
    })

    let settings = {
      fileName: intl.formatMessage(
        {
          id: 'tasks.tasksPerAgentCsvFilename',
        },
        {
          datetime: moment().format('YYYY-MM-DD-HH-mm-ss'),
        }
      ),
      extraLength: 3,
      writeMode: 'writeFile',
      writeOptions: {},
    }

    xlsx(data as any, settings)
  }

  return (
    <div className='card p-10'>
      {status === 'loading' && <div className='spinner-border text-primary' role='status' />}
      {status === 'idle' && (
        <>
          {getRelevantTasks()?.length > 0 && (
            <button className='btn btn-primary ms-auto mb-8' onClick={exportToCsv}>
              <i className='fa fa-file-csv me-3' />
              {intl.formatMessage({id: 'tasks.exportToCsv'})}
            </button>
          )}
          <div className='table-responsive overflow-scroll scroll-y'>
            {getRelevantTasks()?.length === 0 && <p>{intl.formatMessage({id: 'tasks.noTasks'})}</p>}
            {getRelevantTasks()?.length > 0 && (
              <table className='table table-hover table-row-dashed table-row-gray-200 align-top gs-0 gy-4'>
                <thead className='table-header sticky-top bg-white'>
                  <tr className='fw-bolder'>
                    <td>{intl.formatMessage({id: 'tasks.agent'})}</td>
                    <td>{intl.formatMessage({id: 'tasks.totalPending'})}</td>
                    <td>{intl.formatMessage({id: 'tasks.pendingForToday'})}</td>
                    <td>{intl.formatMessage({id: 'tasks.pendingRightNow'})}</td>
                    <td>{intl.formatMessage({id: 'tasks.doneToday'})}</td>
                  </tr>
                </thead>
                <tbody>
                  {getRelevantTasks()?.map((task, index) => (
                    <tr key={index}>
                      <td>
                        {
                          <Link
                            to={task.id ? `/agent-tasks/${task.id}` : '/unassigned-tasks'}
                            className='link mb-1 fs-6'
                          >
                            {task.name || '-'}
                          </Link>
                        }
                      </td>
                      <td>
                        <strong className='d-block mb-2'>
                          {task.total?.n || 0} {intl.formatMessage({id: 'tasks.tasks'})}
                        </strong>
                        <div className='d-flex flex-column gap-1'>
                          {task?.total?.task_profiles &&
                            task.total.task_profiles.map((task_profile, index) => (
                              <div key={index} className='d-flex gap-2'>
                                <div className='badge badge-circle badge-primary badge-sm border border-primary overflow-hidden'>
                                  {task_profile?.n}
                                </div>
                                <div className='text-nowrap'>{task_profile.name}</div>
                              </div>
                            ))}
                        </div>
                      </td>
                      <td>
                        <strong className='d-block mb-2'>
                          {task.today?.n || 0} {intl.formatMessage({id: 'tasks.tasks'})}
                        </strong>
                        <div className='d-flex flex-column gap-1'>
                          {task?.today?.task_profiles &&
                            task.today.task_profiles.map((task_profiles, index) => (
                              <div key={index} className='d-flex gap-2'>
                                <div className='badge badge-circle badge-primary badge-sm border border-primary overflow-hidden'>
                                  {task_profiles?.n}
                                </div>
                                <div className='text-nowrap'>{task_profiles.name}</div>
                              </div>
                            ))}
                        </div>
                      </td>
                      <td>
                        <strong className='d-block mb-2'>
                          {task.now?.n || 0} {intl.formatMessage({id: 'tasks.tasks'})}
                        </strong>
                        <div className='d-flex flex-column gap-1'>
                          {task?.now?.task_profiles &&
                            task.now.task_profiles.map((task_profile, index) => (
                              <div key={index} className='d-flex gap-2'>
                                <div className='badge badge-circle badge-primary badge-sm border border-primary overflow-hidden'>
                                  {task_profile?.n}
                                </div>
                                <div className='text-nowrap'>{task_profile.name}</div>
                              </div>
                            ))}
                        </div>
                      </td>
                      <td>
                        <strong className='d-block mb-1'>
                          {task?.done_today?.n || 0} {intl.formatMessage({id: 'tasks.tasks'})}
                        </strong>
                        <div className='d-flex flex-column gap-1'>
                          {task?.done_today?.task_resolutions && (
                            <>
                              <strong className='d-block mb-1'>
                                {intl.formatMessage({id: 'tasks.doneTodayPerResolution'})}
                              </strong>
                              {task.done_today.task_resolutions.map((task_resolution, index) => (
                                <div key={index} className='d-flex gap-2'>
                                  <div className='badge badge-circle badge-primary badge-sm border border-primary overflow-hidden'>
                                    {task_resolution.n}
                                  </div>
                                  <div className='text-nowrap'>{task_resolution.name}</div>
                                </div>
                              ))}
                            </>
                          )}
                        </div>
                        <div className='d-flex flex-column gap-1'>
                          {task?.done_today?.task_profiles && (
                            <>
                              <strong className='d-block mt-2 mb-1'>
                                {intl.formatMessage({id: 'tasks.doneTodayPerKind'})}
                              </strong>
                              {task.done_today.task_profiles.map((task_profile, index) => (
                                <div key={index} className='d-flex gap-2'>
                                  <div className='badge badge-circle badge-primary badge-sm border border-primary overflow-hidden'>
                                    {task_profile.n}
                                  </div>
                                  <div className='text-nowrap'>{task_profile.name}</div>
                                </div>
                              ))}
                            </>
                          )}
                        </div>
                      </td>
                    </tr>
                  ))}
                  <tr className='fw-bolder'>
                    <td>{intl.formatMessage({id: 'tasks.total'})}</td>
                    <td>
                      {total} {intl.formatMessage({id: 'tasks.tasks'})}
                    </td>
                    <td>
                      {totalToday} {intl.formatMessage({id: 'tasks.tasks'})}
                    </td>
                    <td>
                      {totalNow} {intl.formatMessage({id: 'tasks.tasks'})}
                    </td>
                    <td>
                      {totalDoneToday} {intl.formatMessage({id: 'tasks.tasks'})}
                    </td>
                  </tr>
                </tbody>
              </table>
            )}
          </div>
        </>
      )}
    </div>
  )
}

export default TasksPerAgent
