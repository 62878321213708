import {useIntl} from 'react-intl'
import {useSelector} from 'react-redux'
import {currentUserSelector} from '../../user-profile'
import clsx from 'clsx'

export default function TaskComments({loading = false, tasks = []}) {
  const intl = useIntl()
  const currentUser = useSelector(currentUserSelector)

  return (
    <>
      <h1 className='mb-4'>{intl.formatMessage({id: 'comments.taskComments'})}</h1>
      {loading && <div className='spinner-border text-primary' role='status' />}
      {!loading && tasks.length === 0 && (
        <p className='text-muted mb-0'>{intl.formatMessage({id: 'comments.noTaskComments'})}</p>
      )}
      {!loading && tasks.length > 0 && (
        <section className='timeline'>
          {tasks?.map((task) => {
            return (
              <div className='timeline-item'>
                <div className='timeline-line w-40px'></div>
                <div className='timeline-icon symbol symbol-circle symbol-40px me-4'>
                  <div className='symbol-label bg-light'>
                    <span className='svg-icon svg-icon-2 svg-icon-gray-500'>
                      <svg
                        width='24'
                        height='24'
                        viewBox='0 0 24 24'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          opacity='0.3'
                          d='M2 4V16C2 16.6 2.4 17 3 17H13L16.6 20.6C17.1 21.1 18 20.8 18 20V17H21C21.6 17 22 16.6 22 16V4C22 3.4 21.6 3 21 3H3C2.4 3 2 3.4 2 4Z'
                          fill='currentColor'
                        ></path>
                        <path
                          d='M18 9H6C5.4 9 5 8.6 5 8C5 7.4 5.4 7 6 7H18C18.6 7 19 7.4 19 8C19 8.6 18.6 9 18 9ZM16 12C16 11.4 15.6 11 15 11H6C5.4 11 5 11.4 5 12C5 12.6 5.4 13 6 13H15C15.6 13 16 12.6 16 12Z'
                          fill='currentColor'
                        ></path>
                      </svg>
                    </span>
                  </div>
                </div>
                <div className='timeline-content mb-1 mt-n1'>
                  <div className='pe-3 mt-2 mb-2'>
                    <a className='fw-bolder' href={`/user/${currentUser?.id}/task/${task.id}`}>
                      {task.name} ·{' '}
                      {new Date(task.created_at).toLocaleString('es-ES', {
                        day: '2-digit',
                        month: '2-digit',
                        year: '2-digit',
                      })}
                    </a>
                  </div>
                  {!task?.comments?.length ? (
                    <div className='rounded border border-dashed p-4 mb-2 border-muted'>
                      <p className='text-muted mb-0'>
                        {intl.formatMessage({id: 'comments.noTaskComments'})}
                      </p>
                    </div>
                  ) : (
                    task.comments.map((comment) => (
                      <div className='mb-3'>
                        <div className='bg-light-primary rounded border-primary border border-dashed p-4 mb-2'>
                          {comment.comment}
                        </div>
                        <div className='w-100 d-flex text-end mt-1 text-muted align-items-center justify'>
                          <span className='fs-8 me-1 d-inline-block'>
                            {new Date(comment.created_at).toLocaleString('es-ES', {
                              day: '2-digit',
                              month: '2-digit',
                              year: '2-digit',
                              hour: '2-digit',
                              minute: '2-digit',
                            })}{' '}
                            ·
                          </span>
                          <div className='fs-8 d-inline-block'>
                            {comment.user.name}
                            <span
                              className={clsx(
                                'badge badge-sm ms-1 fs-9',
                                comment?.user?.cs_groups?.[0]?.name?.includes('Subscription') &&
                                  'badge-light-info',
                                comment?.user?.cs_groups?.[0]?.name?.includes('Acquisition') &&
                                  'badge-light-success',
                                comment?.user?.cs_groups?.[0]?.name?.includes('Pet care') &&
                                  'badge-light-dark'
                              )}
                            >
                              {comment?.user?.cs_groups?.[0]?.name?.replace('ATC - ', '')}
                            </span>
                          </div>
                        </div>
                      </div>
                    ))
                  )}
                </div>
              </div>
            )
          })}
        </section>
      )}
    </>
  )
}
