import {useRef, useEffect, useState} from 'react'
import {shallowEqual, useSelector, connect, useDispatch, ConnectedProps} from 'react-redux'
import {LayoutSplashScreen} from '../../../../_metronic/layout/core'
import * as auth from './AuthRedux'
import {getToken} from '../services/AuthCRUD'
import {RootState} from '../../../../setup'

const mapState = (state: RootState) => ({auth: state.auth})
const connector = connect(mapState, auth.actions)
type PropsFromRedux = ConnectedProps<typeof connector> & {children: any}

const AuthInit = (props: PropsFromRedux) => {
  const didRequest = useRef(false)
  const dispatch = useDispatch()
  const [showSplashScreen, setShowSplashScreen] = useState(true)
  const accessToken = useSelector(auth.accessTokenSelector, shallowEqual)

  // We should request token before rendering the application
  useEffect(() => {
    const requestToken = async () => {
      try {
        const {data} = await getToken()
        const {token} = data
        dispatch(props.setToken(token))
      } catch (error) {
        console.error(error)
        if (!didRequest.current) {
          dispatch(props.logout())
        }
      }
    }

    if (accessToken) {
      setShowSplashScreen(false)
    } else {
      requestToken()
    }

    return () => {
      didRequest.current = true
    }

    // eslint-disable-next-line
  }, [accessToken])

  return showSplashScreen ? <LayoutSplashScreen /> : <>{props.children}</>
}

export default connector(AuthInit)
