import {useEffect, useState} from 'react'
import {LogModel} from '../models/LogModel'
import {getOrderLogs, getPetLogs, getPlanLogs, getUserLogs} from '../services/LogsCRUD'
import {useIntl} from 'react-intl'
import {useSelector} from 'react-redux'
import {staffUsersSelector} from '../../tasks'
import {currentUserSelector, userStatusSelector} from '../../user-profile'
import {planStatusSelector, selectedPlanSelector} from '../../plans'
import {petStatusSelector, selectedPetSelector} from '../../pets'
import {orderStatusSelector, selectedOrderSelector} from '../../orders'

type Props = {
  kind: 'user' | 'pet' | 'plan' | 'order'
  id: number
}
export default function LogTimeline({kind, id}: Props) {
  const intl = useIntl()
  const [logs, setLogs] = useState<Array<LogModel>>([])
  const [loading, setLoading] = useState<boolean>(false)
  const staffUsers = useSelector(staffUsersSelector)
  const currentUser = useSelector(currentUserSelector)
  const selectedPet = useSelector(selectedPetSelector)
  const selectedPlan = useSelector(selectedPlanSelector)
  const selectedOrder = useSelector(selectedOrderSelector)
  const currentUserStatus = useSelector(userStatusSelector)
  const selectedPetStatus = useSelector(petStatusSelector)
  const selectedPlansStatus = useSelector(planStatusSelector)
  const selectedOrderStatus = useSelector(orderStatusSelector)
  const isLoading =
    loading ||
    currentUserStatus !== 'idle' ||
    selectedPetStatus !== 'idle' ||
    selectedPlansStatus !== 'idle' ||
    selectedOrderStatus !== 'idle'

  async function fetchLogs() {
    try {
      let response
      setLogs([])
      setLoading(true)
      switch (kind) {
        case 'user':
          response = await getUserLogs(id)
          break
        case 'pet':
          response = await getPetLogs(id)
          break
        case 'plan':
          response = await getPlanLogs(id)
          break
        case 'order':
          response = await getOrderLogs(id)
          break
      }
      const {data} = response
      setLogs(data)
    } catch (error) {
      console.warn(error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (kind && id) {
      fetchLogs()
    }
  }, [kind, id])

  function getEntityName() {
    switch (kind) {
      case 'user':
        return currentUser?.name || currentUser?.email
      case 'pet':
        return selectedPet?.name
      case 'plan':
        return selectedPlan?.code
      case 'order':
        return selectedOrder?.code
      default:
        return '-'
    }
  }

  function getSortedLogs() {
    return logs.sort((a, b) => {
      return new Date(b.performed_at).getTime() - new Date(a.performed_at).getTime()
    })
  }

  function getLogIcon(log: LogModel) {
    switch (log.kind) {
      case 'creation':
        return <i className='bi bi-plus-circle fs-3'></i>
      case 'update':
        return <i className='bi bi-pencil-square fs-3'></i>
      case 'deletion':
        return <i className='bi bi-trash fs-3'></i>
      default:
        return <i className='bi bi-question-circle fs-3'></i>
    }
  }

  function getUserName(userId) {
    if (!userId) {
      return <span className='text-primary'>🤖 {intl.formatMessage({id: 'logs.theSystem'})}</span>
    }
    const staffUserName = staffUsers.find((user) => user?.id === userId)?.name
    if (staffUserName) {
      return (
        <a
          title='Staff'
          href={`/user/${userId}/profile`}
          className='text-primary d-inline-flex align-items-baseline'
        >
          {staffUserName[0].toUpperCase() + staffUserName.slice(1)}
          <span className='badge badge-primary fw-bolder ms-1 me-1 px-2 py-1'>Staff</span>
        </a>
      )
    }
    if (userId === currentUser?.id) {
      const userName = currentUser?.name || currentUser?.email
      return (
        <a title='Customer' href={`/user/${userId}/profile`} className='text-primary'>
          {userName[0].toUpperCase() + userName.slice(1)}
        </a>
      )
    }

    return `${intl.formatMessage({id: 'logs.theUser'})} ${userId}`
  }

  function getLogAction(kind) {
    switch (kind) {
      case 'creation':
        return intl.formatMessage({id: 'logs.created'})
      case 'update':
        return intl.formatMessage({id: 'logs.updated'})
      case 'deletion':
        return intl.formatMessage({id: 'logs.deleted'})
      default:
        return intl.formatMessage({id: 'logs.unknownAction'})
    }
  }

  return (
    <>
      <div className='card card-custom gutter-b'>
        <div className='card-header border-0'>
          <h1 className='card-title fw-bolder text-dark'>
            {isLoading
              ? intl.formatMessage({id: 'logs.loading'})
              : intl.formatMessage({id: 'logs.entityTitle'}, {entity: getEntityName()})}
          </h1>
        </div>
        <div className='card-body pt-0'>
          {isLoading && <div className='spinner-border text-primary' role='status' />}
          {!isLoading && logs.length === 0 && (
            <p className='text-muted mb-0'>{intl.formatMessage({id: 'logs.noLogs'})}</p>
          )}
          {!isLoading && logs.length > 0 && (
            <section className='timeline'>
              {getSortedLogs()?.map((log) => {
                return (
                  <div className='timeline-item'>
                    <div className='timeline-line w-40px'></div>
                    <div className='timeline-icon symbol symbol-circle symbol-40px me-4'>
                      <div className='symbol-label bg-light'>
                        <span className='svg-icon svg-icon-2 svg-icon-gray-500'>
                          {getLogIcon(log)}
                        </span>
                      </div>
                    </div>
                    <div className='timeline-content mb-1 mt-n1'>
                      <div className='pe-3 mt-2 mb-2'>
                        <p className='fw-bolder mb-0'>
                          {getUserName(log?.performed_by)} {getLogAction(log?.kind)} "
                          {log.reference_name}"
                        </p>
                        <p className='text-muted mb-0'>
                          {intl.formatDate(new Date(log.performed_at), {
                            year: 'numeric',
                            month: 'long',
                            day: 'numeric',
                            hour: 'numeric',
                            minute: 'numeric',
                          })}
                        </p>
                      </div>
                      {log?.changes && (
                        <div className='mb-1'>
                          <div className='bg-light-primary rounded border-primary border border-dashed p-4 mb-4 d-inline-block'>
                            {Object.entries(log?.changes)?.map(([key, value]) => (
                              <div key={key} className='d-flex align-items-center mb-0'>
                                <span className='fw-bolder me-2'>{key}:</span>
                                <span>
                                  {typeof value?.new !== 'undefined' &&
                                    typeof value?.old !== 'undefined' && (
                                      <>
                                        <span className='text-decoration-line-through me-2 text-muted'>
                                          {key === 'documents'
                                            ? JSON.stringify(value?.old?.map((doc) => doc?.name))
                                            : JSON.stringify(value?.old)}
                                        </span>
                                        <span>
                                          {key === 'documents'
                                            ? JSON.stringify(value?.new?.map((doc) => doc?.name))
                                            : JSON.stringify(value?.new)}
                                        </span>
                                      </>
                                    )}
                                  {(typeof value === 'string' || typeof value === 'number') &&
                                    value}
                                </span>
                              </div>
                            ))}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                )
              })}
            </section>
          )}
        </div>
      </div>
    </>
  )
}
