import {Modal} from 'react-bootstrap'
import {useIntl} from 'react-intl'
import {FC, useEffect, useState} from 'react'
import Select from 'react-select'
import {PlanModel} from '../models/PlanModel'

type PropTypes = {
  visible: boolean
  loading: boolean
  onClose: () => void
  onSubmit: (option: any) => void
  plan: PlanModel
}

const PlanKoModal: FC<PropTypes> = ({
  visible = false,
  loading = false,
  onClose,
  onSubmit,
  plan,
}) => {
  const intl = useIntl()
  const [selectedOption, setSelectedOption] = useState()
  const [selectedSubOption, setSelectedSubOption] = useState()

  const options = plan?.available_ko_reasons
    ?.filter((reason) => reason.parent === null)
    ?.map((reason) => ({
      value: reason.code,
      label: reason.name,
    }))

  const subOptions = plan?.available_ko_reasons
    ?.filter((reason) => reason.parent === selectedOption)
    ?.map((reason) => ({
      value: reason.code,
      label: reason.name,
    }))

  useEffect(() => {
    if (visible) {
      setSelectedOption(undefined)
      setSelectedSubOption(undefined)
    }
  }, [visible])

  useEffect(() => {
    if (selectedOption) {
      setSelectedSubOption(undefined)
    }
  }, [selectedOption])

  function handleSubmit(event) {
    event?.preventDefault()

    onSubmit(selectedSubOption || selectedOption)
  }

  return (
    <Modal show={visible} centered onHide={onClose}>
      <Modal.Header>
        <Modal.Title>{intl.formatMessage({id: 'planDetail.enterKoReason'})}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Select 
          className="react-select-container mb-5"
          classNamePrefix="react-select"
          placeholder={intl.formatMessage({id: 'genericForm.selectPlaceholder'})}
          value={
            selectedOption ? options?.find((option) => option.value === selectedOption) : false
          }
          options={options}
          onChange={(e: any) => setSelectedOption(e?.value || '')}
        />
        {subOptions?.length > 0 && (
          <Select className="react-select-container"
  classNamePrefix="react-select" 
            placeholder={intl.formatMessage({id: 'planDetail.selectSuboptionPlaceholder'})}
            value={
              selectedSubOption
                ? subOptions?.find((option) => option.value === selectedSubOption)
                : false
            }
            options={subOptions}
            onChange={(e: any) => setSelectedSubOption(e?.value || '')}
          />
        )}
      </Modal.Body>
      <Modal.Footer className='d-flex justify-content-end'>
        <button className='btn btn-tertiary' onClick={onClose}>
          {intl.formatMessage({id: 'planDetail.cancel'})}
        </button>
        <button
          onClick={handleSubmit}
          type='button'
          disabled={
            !selectedOption || (selectedOption && subOptions?.length > 0 && !selectedSubOption)
          }
          className='px-10 btn btn-primary d-block'
          data-kt-users-modal-action='submit'
          data-kt-indicator={loading ? 'on' : 'off'}
        >
          <span className='indicator-label'></span>
          <span className='indicator-progress'>
            <span className='spinner-border spinner-border-sm align-middle me-4'></span>
          </span>
          {intl.formatMessage({id: 'planDetail.confirmKo'})}
        </button>
      </Modal.Footer>
    </Modal>
  )
}

export default PlanKoModal
