import {useIntl} from 'react-intl'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {RootState} from '../../../../setup'
import {toast} from 'react-toastify'

import {PlanModel} from '../models/PlanModel'
import {planStatusSelector} from '../redux/PlanRedux'
import {useEffect, useState} from 'react'
import CustomMealModal from './CustomMealModal'
import * as planRedux from '../redux/PlanRedux'
import clsx from 'clsx'

export default function CustomMealList() {
  const intl = useIntl()
  const dispatch = useDispatch()
  const selectedPlan: PlanModel | undefined = useSelector(
    (state: RootState) => state.plan.selectedPlan,
    shallowEqual
  )
  const status: string = useSelector(planStatusSelector, shallowEqual)
  const loadingSelectedPlan = status === 'loading_selected_plan'
  const loadingAddCustomMeal = status === 'loading_create_custom_meal'
  const loadingUpdateCustomMeal = status === 'loading_update_custom_meal'
  const loadingDeleteCustomMeal = status === 'loading_delete_custom_meal'
  const [displayModal, setDisplayModal] = useState(false)
  const [editingCustomMeal, setEditingCustomMeal] = useState(null)
  const [removingCustomMeal, setRemovingCustomMeal] = useState(null)
  const [changingUnitsCustomMeal, setChangingUnitsCustomMeal] = useState(null)
  const [changingSizeCustomMeal, setChangingSizeCustomMeal] = useState(null)

  function getMeal(mealPlan) {
    return selectedPlan?.eligible_meals.find((eligibleMeal) => eligibleMeal.id === mealPlan.meal_id)
  }

  function onSubmitCustomMeal(event, id, mealId, sizeId, units) {
    const planId = selectedPlan?.id
    event.preventDefault()

    if (editingCustomMeal?.id) {
      dispatch(planRedux.actions.updateCustomMeal(planId, id, mealId, sizeId, units))
    } else {
      dispatch(planRedux.actions.addCustomMeal(planId, mealId, sizeId, units))
    }
  }

  function onEditCustomMeal(customMealId) {
    const newCustomPlan = selectedPlan?.meal_custom_plans.find(
      (mealCustomPlan) => mealCustomPlan.id === customMealId
    )
    if (newCustomPlan) {
      setEditingCustomMeal({
        id: newCustomPlan.id,
        meal: newCustomPlan.meal_id,
        size: newCustomPlan.meal_size.id,
        units: newCustomPlan.units,
      })
    } else {
      setEditingCustomMeal(null)
    }
    setDisplayModal(true)
  }

  function isChangingUnits(mealCustomPlan) {
    return changingUnitsCustomMeal?.id === mealCustomPlan.id
  }

  function isLoading(mealCustomPlan) {
    return isChangingUnits(mealCustomPlan) || removingCustomMeal === mealCustomPlan.id
  }

  function onIncreaseUnits(mealCustomPlan) {
    setChangingUnitsCustomMeal(mealCustomPlan)
    dispatch(
      planRedux.actions.updateCustomMeal(
        selectedPlan?.id,
        mealCustomPlan.id,
        mealCustomPlan.meal_id,
        mealCustomPlan.meal_size.id,
        mealCustomPlan.units + 1
      )
    )
  }

  function onDecreaseUnits(mealCustomPlan) {
    setChangingUnitsCustomMeal(mealCustomPlan)
    dispatch(
      planRedux.actions.updateCustomMeal(
        selectedPlan?.id,
        mealCustomPlan.id,
        mealCustomPlan.meal_id,
        mealCustomPlan.meal_size.id,
        mealCustomPlan.units > 0 ? mealCustomPlan.units - 1 : 0
      )
    )
  }

  function onDeleteCustomMeal(customMealId) {
    const planId = selectedPlan?.id
    setRemovingCustomMeal(customMealId)
    dispatch(planRedux.actions.deleteCustomMeal(planId, customMealId))
  }

  function onAddMeal() {
    setEditingCustomMeal(null)
    setDisplayModal(true)
  }

  useEffect(() => {
    switch (status) {
      case 'success_create_custom_meal':
        setDisplayModal(false)
        setChangingUnitsCustomMeal(null)
        setChangingSizeCustomMeal(null)
        toast.success(intl.formatMessage({id: 'planDetail.customMealAdded'}))
        break
      case 'success_update_custom_meal':
        setDisplayModal(false)
        setChangingUnitsCustomMeal(null)
        setChangingSizeCustomMeal(null)
        toast.success(intl.formatMessage({id: 'planDetail.customMealUpdated'}))
        break
      case 'success_delete_custom_meal':
        setRemovingCustomMeal(null)
        toast.success(intl.formatMessage({id: 'planDetail.customMealRemoved'}))
        break
      case 'error_create_custom_meal':
        setDisplayModal(false)
        toast.error(intl.formatMessage({id: 'planDetail.customMealAddFailed'}))
        break
      case 'error_update_custom_meal':
        setDisplayModal(false)
        setChangingUnitsCustomMeal(null)
        setChangingSizeCustomMeal(null)
        toast.error(intl.formatMessage({id: 'planDetail.customMealUpdateFailed'}))
        break
    }
  }, [status])

  function getSizes(mealId) {
    const sizes = selectedPlan?.eligible_meals
      ?.find((aMeal) => aMeal.id === mealId)
      ?.sizes?.map((aSize) => ({label: `${aSize.weight_in_gr} gr`, value: aSize.id}))
    return sizes
  }

  function onMealSizeChange(e, mealCustomPlan) {
    const newSize = parseInt(e.target.value, 10)

    setChangingSizeCustomMeal(mealCustomPlan)
    dispatch(
      planRedux.actions.updateCustomMeal(
        selectedPlan?.id,
        mealCustomPlan.id,
        mealCustomPlan.meal_id,
        newSize,
        mealCustomPlan.units
      )
    )
  }

  if (!loadingSelectedPlan && selectedPlan?.kind !== 'custom') {
    return <></>
  }

  return (
    <>
      <section className='card p-10'>
        <h1 className='mb-8'>{intl.formatMessage({id: 'planDetail.customMealPlans'})}</h1>
        {loadingSelectedPlan && (
          <div>
            <div className='spinner-border text-primary' role='status' />
          </div>
        )}
        {!loadingSelectedPlan && !selectedPlan?.meal_custom_plans.length && (
          <>
            <p>{intl.formatMessage({id: 'planDetail.noMealCustomPlans'})}</p>
          </>
        )}
        {!loadingSelectedPlan && selectedPlan?.meal_custom_plans.length > 0 && (
          <div className='overflow-scroll'>
            <table className='table table-hover table-row-dashed table-row-gray-200 align-middle gs-0 gy-0'>
              <thead className='table-header'>
                <tr className=''>
                  <th className=''>{intl.formatMessage({id: 'planDetail.meal'})}</th>
                  <th className='text-center'>{intl.formatMessage({id: 'planDetail.mealSize'})}</th>
                  <th className='text-center'>{intl.formatMessage({id: 'planDetail.units'})}</th>
                  <th className='text-center'>{intl.formatMessage({id: 'planDetail.weight'})}</th>
                  <th className='text-center'>{intl.formatMessage({id: 'planDetail.edit'})}</th>
                  <th className='text-center'>{intl.formatMessage({id: 'planDetail.remove'})}</th>
                </tr>
              </thead>
              <tbody className=''>
                {selectedPlan?.meal_custom_plans
                  ?.sort((a, b) => (a.id > b.id ? 1 : -1))
                  ?.map((mealCustomPlan) => (
                    <tr
                      key={mealCustomPlan.id}
                      className={clsx(!getMeal(mealCustomPlan) && 'text-danger')}
                    >
                      <td>
                        {getMeal(mealCustomPlan)?.name || (
                          <span className='text-danger fw-bold'>
                            {intl.formatMessage({id: 'planDetail.nonEligibleMeal'})}
                          </span>
                        )}
                      </td>
                      <td className='text-center'>
                        {changingSizeCustomMeal?.id === mealCustomPlan.id ? (
                          <div
                            className='spinner-border spinner-border-sm text-muted m-0 p-0 h-10px w-10px'
                            role='status'
                          />
                        ) : (
                          <select
                            className='form-select w-100px m-auto border-transparent py-1'
                            disabled={!getMeal(mealCustomPlan)}
                            value={mealCustomPlan?.meal_size?.id}
                            onChange={(e) => onMealSizeChange(e, mealCustomPlan)}
                          >
                            {getSizes(mealCustomPlan?.meal_id)?.map((size) => (
                              <option key={size.value} value={size.value}>
                                {size.label}
                              </option>
                            ))}
                          </select>
                        )}
                      </td>
                      <td className='text-center'>
                        <div className='d-flex justify-content-center align-items-center'>
                          <button
                            disabled={isLoading(mealCustomPlan)}
                            type='button'
                            className='btn btn-icon btn-white btn-sm'
                            onClick={() => onDecreaseUnits(mealCustomPlan)}
                          >
                            <i className='fas fa-minus-circle' />
                          </button>
                          <span style={{minWidth: '18px'}}>
                            {isChangingUnits(mealCustomPlan) ? (
                              <div
                                className='spinner-border spinner-border-sm text-muted m-0 p-0 h-10px w-10px'
                                role='status'
                              />
                            ) : (
                              mealCustomPlan?.units
                            )}
                          </span>
                          <button
                            disabled={isLoading(mealCustomPlan)}
                            type='button'
                            className='btn btn-icon btn-white btn-sm'
                            onClick={() => onIncreaseUnits(mealCustomPlan)}
                          >
                            <i className='fas fa-plus-circle' />
                          </button>
                        </div>
                      </td>
                      <td className='text-center'>
                        {mealCustomPlan.meal_size.weight_in_gr * mealCustomPlan.units} gr
                      </td>
                      <td className='text-center'>
                        <button
                          className='btn btn-small btn-outline-secondary'
                          onClick={() => onEditCustomMeal(mealCustomPlan.id)}
                          disabled={!getMeal(mealCustomPlan)}
                        >
                          <span className='fa fa-edit'></span>
                        </button>
                      </td>
                      <td className='text-center'>
                        <button
                          disabled={loadingDeleteCustomMeal}
                          className='btn btn-small btn-outline-secondary'
                          onClick={() => onDeleteCustomMeal(mealCustomPlan.id)}
                          data-kt-users-modal-action='submit'
                          data-kt-indicator={
                            loadingDeleteCustomMeal && removingCustomMeal === mealCustomPlan.id
                              ? 'on'
                              : 'off'
                          }
                        >
                          <span className='indicator-label fa fa-trash' />
                          <span
                            className='indicator-progress spinner-border spinner-border-sm align-middle'
                            role='status'
                          />
                        </button>
                      </td>
                    </tr>
                  ))}
                  <tr>
                    <td colSpan={1}></td>
                    <td colSpan={2} className='text-end pe-2'>
                      <strong className='text-primary d-block mt-4 fs-5'>
                        {intl.formatMessage({id: 'planDetail.total'})}
                      </strong>
                    </td>
                    <td>
                      <strong className='text-primary d-block mt-4 fs-5 pe-3'>
                        {
                          selectedPlan?.meal_custom_plans.reduce((acc, mealCustomPlan) => {
                            return acc + mealCustomPlan.meal_size.weight_in_gr * mealCustomPlan.units
                          }, 0)
                        } gr
                      </strong>
                    </td>
                  </tr>
              </tbody>
            </table>
          </div>
        )}
        {!loadingSelectedPlan && (
          <button className='btn btn-primary w-200px ms-auto' onClick={onAddMeal}>
            <span className='fa fa-plus me-4'></span>
            {intl.formatMessage({id: 'planDetail.addMeaCustomlPlan'})}
          </button>
        )}
      </section>
      <CustomMealModal
        loading={loadingAddCustomMeal || loadingUpdateCustomMeal}
        visible={displayModal}
        onClose={() => setDisplayModal(false)}
        onSubmitCustomMeal={onSubmitCustomMeal}
        initialCustomMeal={editingCustomMeal}
      />
    </>
  )
}
