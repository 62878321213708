import { Modal } from "react-bootstrap";
import { LoyaltyReward } from "../models/LoyaltyReward";
import { useEffect, useState } from "react";
import { useIntl } from "react-intl";

const NON_EDITABLE_FIELDS = ['id', 'level', 'order', 'created_at', 'updated_at']

export function RewardModal({
    visible,
    onClose,
    reward,
    onSubmit,
}: {
    visible: boolean
    reward: LoyaltyReward | null
    onClose: () => void
    onSubmit: (reward: LoyaltyReward) => void
}) {
    const intl = useIntl()
    const [rewardState, setRewardState] = useState<LoyaltyReward | null>(reward)

    useEffect(() => {
        setRewardState(reward)
    }, [reward])

    function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault()
        onSubmit(rewardState)
    }

    function isValid() {
        return rewardState? 
            Object.keys(rewardState)
                ?.filter((field) => !NON_EDITABLE_FIELDS.includes(field))
                ?.every((field) => rewardState?.[field]?.length > 0)
            : false
    }

    return <Modal show={visible} onHide={onClose}>
        <Modal.Header>
            <Modal.Title>
                {
                    reward?.id > -1 ? 
                        intl.formatMessage({ id: 'loyaltyRewards.editReward' })
                        : intl.formatMessage({ id: 'loyaltyRewards.createReward' })
                }
            </Modal.Title>
        </Modal.Header>
        <form onSubmit={handleSubmit}>
            <Modal.Body className="d-flex gap-4 flex-wrap">
                {
                    reward && Object.keys(reward)
                        ?.filter((field) => !NON_EDITABLE_FIELDS.includes(field))
                        ?.sort((a, b) => a?.slice(-2).localeCompare(b?.slice(-2)))
                        ?.map((field) => {
                        return <div key={field} className="form-group" style={{ flex: '1 1 45%' }}>
                            <label 
                                className="form-label"
                                htmlFor={field}>
                                    {field}
                                </label>
                            <input
                                className="form-control"
                                required
                                placeholder={field}
                                type="text"
                                id={field}
                                value={rewardState?.[field]}
                                onChange={(event) => {
                                    setRewardState({
                                        ...rewardState,
                                        [field]: event.target.value
                                    })
                                }}
                            />
                        </div>
                    })
                }
            </Modal.Body>
            <Modal.Footer>
                <button type="button" className="btn btn-secondary" onClick={onClose}>
                    {intl.formatMessage({ id: 'loyaltyRewards.cancel' })}
                </button>
                <button type="submit" className="btn btn-primary" disabled={!isValid()}>
                    {intl.formatMessage({ id: 'loyaltyRewards.save' })}
                </button>
            </Modal.Footer>
        </form>
    </Modal>
}