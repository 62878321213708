/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {useIntl} from 'react-intl'

import {PageTitle} from '../../../_metronic/layout/core'
import DeliveryChangeList from '../../modules/delivery-changes/components/DeliveryChangeList'

const DeliveryChangesPage: FC = () => {
  const intl = useIntl()

  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'menu.deliveryChanges'})}</PageTitle>
      <div className='row'>
        <div className='col-12'>
          <DeliveryChangeList />
        </div>
      </div>
    </>
  )
}

export default DeliveryChangesPage
