import {useEffect, useState} from 'react'
import {Modal} from 'react-bootstrap'
import {useIntl} from 'react-intl'
import moment from 'moment'
import { createInvoice } from '../services/InvoiceCRUD'
import { toast } from 'react-toastify'
import InvoicePreviewModal from './InvoicePreviewModal'

export enum InvoiceKind {
  Proforma = 'proforma',
  Invoice = 'invoice',
}
  
type PropsType = {
  order: any
  visible: boolean
  invoiceKind: InvoiceKind
  onClose: () => void
}

export default function ModalInvoiceForm({
  order, 
  visible,
  invoiceKind = InvoiceKind.Invoice,
  onClose, 
}: PropsType) {
  const intl = useIntl()
  const [date, setDate] = useState('')
  const [previewModalVisible, setPreviewModalVisible] = useState(false)

  async function onCreateInvoice() {
    if (invoiceKind === InvoiceKind.Invoice) {
      setPreviewModalVisible(true)
      return
    }
    
    try {

      const { data: invoice } = await createInvoice({
        orderId: order.id, 
        date, 
        isProforma: invoiceKind === InvoiceKind.Proforma 
      })
      onClose()

      toast.success(
        intl.formatMessage(
          { id: 'b2bOrders.createDocumentSuccess' },
          {
            code: invoice.code,
          }
        )
      )
    } catch (error) {
      if (error?.response?.data?.non_field_errors?.[0]?.includes('deben formar un conjunto único')) {
        toast.error(intl.formatMessage({ id: 'b2bOrders.createDocumentErrorExisting' }))
      } else {
        toast.error(intl.formatMessage({ id: 'b2bOrders.createDocumentError' }))
      }
      console.warn(error)
    }
  }

  useEffect(() => {
    if (visible) {
      setDate(
        moment().format('YYYY-MM-DD')
      )
    }
  }, [visible])

  if (!order) {
    return null
  }

  return (<>
    <Modal
      show={visible && !previewModalVisible}
      onHide={onClose}
      aria-labelledby='contained-modal-title-vcenter'
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id='contained-modal-title-vcenter'>
          {
            invoiceKind === InvoiceKind.Proforma?
              intl.formatMessage({id: 'invoices.createProforma'}, {code: order.code})
              : intl.formatMessage({id: 'invoices.createInvoice'}, {code: order.code})
          }
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className='text-center mb-0'>
          {intl.formatMessage({id: 'invoices.createInvoiceDescription'}, { 
            date: <strong>{(new Date()).toLocaleDateString('es-ES', {
              year: 'numeric',
              month: 'long',
              day: 'numeric'
            })}</strong>,
          })}
        </p>
      </Modal.Body>
      <Modal.Footer>
        <div className='d-flex gap-4 justify-content-center w-100'>
          <button
            className='btn btn-primary'
            onClick={() => onCreateInvoice()}
          >
            { 
              invoiceKind === InvoiceKind.Proforma?
                intl.formatMessage({id: 'invoices.createNewProforma'}) 
                : intl.formatMessage({id: 'invoices.createNewInvoice'})
            }
          </button>
        </div>
      </Modal.Footer>
    </Modal>
    <InvoicePreviewModal
      visible={previewModalVisible}
      onClose={() => setPreviewModalVisible(false)}
      onSubmit={() => { 
        setPreviewModalVisible(false)
        onClose()
      }}
      order={order}
    />
  </>)
}
