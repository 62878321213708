import axios from 'axios'
import { LeadDistributorModel } from '../models/LeadDistributorModel'
import { Place } from '../models/Place'

const API_URL = process.env.REACT_APP_API_URL
const LEAD_DISTRIBUTORS_URL = `${API_URL}/api/b2b/lead-distributor/`

export function listLeadDistributors(): Promise<{ data: Array<LeadDistributorModel> }> {
    return axios.get(`${LEAD_DISTRIBUTORS_URL}`)
}

export function createLeadDistributor(
    distributor: LeadDistributorModel
): Promise<{ data: LeadDistributorModel }> {
    return axios.post(LEAD_DISTRIBUTORS_URL, distributor)
}

export function updateLeadDistributor(
    distributor: LeadDistributorModel
): Promise<{ data: LeadDistributorModel }> {
    return axios.patch(`${LEAD_DISTRIBUTORS_URL}${distributor.id}/`, distributor)
}

export function retrieveLeadDistributor(
    id: number
): Promise<{ data: LeadDistributorModel }> {
    return axios.get(`${LEAD_DISTRIBUTORS_URL}${id}/`)
}

export function getLeadDistributorReport({ since, until }): Promise<{ data: any }> {
    const endpoint = `${API_URL}/api/b2b/lead-distributor/report/`
  
    const params = {
      since_data: since,
      until_data: until,
    }
  
    return axios.get(endpoint, {params})
}
  
interface SearchPlacesResponse {
    results: Array<Place>,
    next_page_token?: string,
}
export function searchPlaces(query: string, nextPageToken?: string): Promise<{ data: SearchPlacesResponse }> {
    const endpoint = `${API_URL}/api/b2b/lead-distributor/google-places/`
  
    const params = {
        query: query,
        next_page_token: nextPageToken,
    }
  
    return axios.get(endpoint, {params})
}