import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { toast } from 'react-toastify'
import Select from 'react-select'
import { PageTitle } from '../../../_metronic/layout/core'
import { DistributorModel, listDistributors } from '../../modules/distributors'
import {
  BillingStatusEnum,
  BulkActionsEnum,
  DeliveryNoteList,
  DeliveryNoteModel,
  DeliveryNoteStateEnum,
  listDeliveryNotes,
  performBulkAction,
  updateDeliveryNote,
  updateDeliveryNoteState,
} from '../../modules/delivery-notes'
import { InvoiceKind, ModalInvoiceForm } from '../../modules/b2b-invoices'
import ModalDeliveryNoteEditable from '../../modules/delivery-notes/components/ModalDeliveryNoteEditable'

export function DeliveryNotesPage() {
  const intl = useIntl()
  const [distributors, setDistributors] = useState<Array<DistributorModel>>([])
  const [deliveryNotes, setDeliveryNotes] = useState<Array<DeliveryNoteModel>>([])
  const [loading, setLoading] = useState(false)
  const [selectedDistributor, setSelectedDistributor] = useState<DistributorModel | null>(null)
  const [billingStatus, setBillingStatus] = useState<BillingStatusEnum | null>(null)
  const [page, setPage] = useState(1)
  const [count, setCount] = useState(0)
  const [generateInvoiceModalVisible, setGenerateInvoiceModalVisible] = useState(false)
  const [deliveryNoteForInvoice, setDeliveryNoteForInvoice] = useState<DeliveryNoteModel | null>(null)
  const [editModalVisible, setEditModalVisible] = useState(false)
  const [editingDeliveryNote, setEditingDeliveryNote] = useState<DeliveryNoteModel | null>(null)
  const billingStatusOptions = [
    {
      value: null,
      label: intl.formatMessage({ id: 'deliveryNotes.anyBillingStatus' }).toString(),
    },
    {
      value: BillingStatusEnum.INVOICED,
      label: BillingStatusEnum.INVOICED.toString(),
    },
    {
      value: BillingStatusEnum.PENDING,
      label: BillingStatusEnum.PENDING.toString(),
    },
  ];

  const fetchDistributors = async () => {
    try {
      const { data } = await listDistributors('active')
      setDistributors(data)
    } catch (error) {
      console.warn(error)
    }
  }

  const fetchDeliveryNotes = async (withLoader = true) => {
    try {
      if (withLoader) {
        setLoading(true)
      }
      const { data } = await listDeliveryNotes({
        distributorId: selectedDistributor?.id,
        billingStatus,
        page
      })
      setDeliveryNotes(data.results)
      setCount(data.count)
    } catch (error) {
      toast.error(error.message)
    } finally {
      if (withLoader) {
        setLoading(false)
      }
    }
  }

  useEffect(() => {
    fetchDistributors()
  }, [])

  useEffect(() => {
    setPage(1)
    setCount(0)
  }, [selectedDistributor, billingStatus])

  useEffect(() => {
    fetchDeliveryNotes()
  }, [selectedDistributor, billingStatus, page])

  async function onUpdateState(order: DeliveryNoteModel, state: DeliveryNoteStateEnum) {
    try {
      await updateDeliveryNoteState({
        ...order,
        state,
      })
      fetchDeliveryNotes(false)
      toast.success(intl.formatMessage({ id: 'deliveryNotes.updateStateSuccess' }))
    } catch (error) {
      toast.error(intl.formatMessage({ id: 'deliveryNotes.updateStateError' }))
      console.warn(error)
    }
  }

  const distributorOptions = [
    ...distributors.map((distributor) => ({
      value: distributor.id,
      label: distributor.name,
    })),
    {
      value: '',
      label: intl.formatMessage({ id: 'deliveryNotes.anyDistributor' }),
    }
  ]

  function onGenerateInvoice(deliveryNote: DeliveryNoteModel) {
    setDeliveryNoteForInvoice(deliveryNote)
    setGenerateInvoiceModalVisible(true)
  }

  async function onUpdateDeliveryNote({
    deliveryNoteId,
    request,
  }) {
    try {
      setLoading(true)
      setEditModalVisible(false)

      const { data: updatedDeliveryNote } = await updateDeliveryNote({
        deliveryNoteId,
        request,
      })
      
      const index = deliveryNotes.findIndex((d) => d.id === deliveryNoteId)
      const newDeliveryNotes = [
        ...deliveryNotes.slice(0, index),
        {
          ...updatedDeliveryNote,
          delivery_note_lines: deliveryNotes[index].delivery_note_lines.map((line) => ({
            ...line,
            served_units: updatedDeliveryNote.delivery_note_lines.find((l) => l.id === line.id)?.served_units
          })),
        },
        ...deliveryNotes.slice(index + 1),
      ];
      
      setDeliveryNotes(newDeliveryNotes)
      toast.success(intl.formatMessage({ id: 'deliveryNotes.updateSuccess' }))
    } catch (error) {
      toast.error(intl.formatMessage({ id: 'deliveryNotes.updateError' }))
    } finally {
      setLoading(false)
    }

  }

  function onEdit(deliveryNote: DeliveryNoteModel) {
    setEditingDeliveryNote(deliveryNote)
    setEditModalVisible(true)
  }


  async function onPerformBulkAction(action: BulkActionsEnum, deliveryNoteIds: number[]) {
    return performBulkAction({
      action,
      deliveryNoteIds,
      distributor: selectedDistributor?.id || null,
      billing_status: billingStatus || null,
    });  
  } 
  
  
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'menu.deliveryNotes' })}</PageTitle>
      <div className='row'>
        <div className='col-12'>
          <DeliveryNoteList
            loading={loading}
            deliveryNotes={deliveryNotes}
            count={count}
            page={page}
            onPageChange={setPage}
            onUpdateState={onUpdateState}
            onGenerateInvoice={onGenerateInvoice}
            onEdit={onEdit}
            onPerformBulkAction={onPerformBulkAction}
          >
            <div className='d-flex gap-4'>
              <div className='form-group'>
                <label className='fw-bolder mb-1'>
                  {intl.formatMessage({ id: 'deliveryNotes.selectDistributor' })}
                </label>
                <Select 
                  className="react-select-container w-300px"
                  classNamePrefix="react-select"
                  options={distributorOptions}
                  value={distributorOptions.find((d) => d.value === selectedDistributor?.id)}
                  onChange={(e) => {
                    const distributorId = e?.value
                    const distributor = distributors.find((d) => d.id === distributorId)
                    setSelectedDistributor(distributor)
                  }}
                  placeholder={intl.formatMessage({ id: 'planDetail.select' })}
                />
              </div>
              <div className='form-group'>
                <label className='fw-bolder mb-1'>
                  {intl.formatMessage({ id: 'deliveryNotes.selectBillingStatus' })}
                </label>
                <Select 
                  className="react-select-container w-300px"
                  classNamePrefix="react-select"
                  options={billingStatusOptions}
                  value={billingStatusOptions.find((d) => d.value == billingStatus)}
                  onChange={(e) => {
                    setBillingStatus(
                      e?.value? 
                        e.value as BillingStatusEnum
                        : null
                    )
                  }}
                  placeholder={intl.formatMessage({ id: 'planDetail.select' })}
                />
              </div>
            </div>
          </DeliveryNoteList>
          <ModalInvoiceForm
            visible={generateInvoiceModalVisible}
            onClose={() => setGenerateInvoiceModalVisible(false)}
            order={{
              id: deliveryNoteForInvoice?.order,
              code: deliveryNoteForInvoice?.order,
            }}
            invoiceKind={InvoiceKind.Invoice}
          />
          <ModalDeliveryNoteEditable
            visible={editModalVisible}
            onClose={() => setEditModalVisible(false)}
            deliveryNote={editingDeliveryNote}
            loading={loading}
            onUpdateDeliveryNote={onUpdateDeliveryNote}
          />
        </div>
      </div>
    </>
  )
}
