import {useEffect} from 'react'
import {useIntl} from 'react-intl'
import {useDispatch, useSelector} from 'react-redux'
import Pagination from '@mui/material/Pagination'

import * as taskRedux from '../redux/TaskRedux'
import TaskTable from './TaskTable'

function PossibleTable() {
  const intl = useIntl()
  const dispatch = useDispatch()
  const tasks = useSelector(taskRedux.possibleTasksSelector)
  const count = useSelector(taskRedux.possibleCountSelector)
  const status = useSelector(taskRedux.possibleTasksStatusSelector)
  const page = useSelector(taskRedux.possiblePageSelector)

  useEffect(() => {
    dispatch(taskRedux.actions.retrievePossibleTasks())
  }, [])

  function onPageChange(event, page) {
    dispatch(taskRedux.actions.retrievePossibleTasks(page))
  }

  return (
    <div className='card w-100'>
      <div className='card-body position-relative' id='kt_not_reachable_body'>
        <TaskTable
          tasks={tasks}
          displayOwner={false}
          loading={status === 'loading'}
          onRefresh={() => dispatch(taskRedux.actions.retrievePossibleTasks())}
        />
        {count > 0 && (
          <Pagination
            className='mx-auto mt-4 d-flex justify-content-center'
            count={Math.ceil(count / taskRedux.TASKS_PER_PAGE)}
            page={page}
            onChange={onPageChange}
          />
        )}
      </div>
    </div>
  )
}

export default PossibleTable
